import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {usermanageService} from '../../services/usermanageService';
import { taskmanageService } from "services/taskmanageService";

import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import DataTable from 'react-data-table-component';
import { SetFilter } from "ag-grid-enterprise";


 const ClientList = (props) => {
 const history = useHistory();
 const [userdata, setUserdata] = useState([]);
 const [filterdata, setfilterData] = useState('');
 
  useEffect(()=>{
    getuser_list();
  },[]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getuser_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getuser_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getuser_list(next);
  };

  const getuser_list = async (pageNumber = 1,client='') => {

      usermanageService.getclientpagination(pageNumber,client).then((response) => {
        // console.log(response.data.users); 
        console.log(response.data); 
        setUserdata(response.data.data.data); 
        setCurrentPage(response.data.data.current_page);
        setLastPageData(response.data.data.last_page);
    });
    
  }

  
  const handleclick = (id) => {
     console.log(id);
    //  return;
     history.push({
      pathname: 'editclientmaster/'+id,
    });
  }

  const adduser = () =>{
    history.push({
      pathname: 'addclientmaster',
    });
  }

  const searchcompany = (e) => {
     setfilterData(e.target.value);
    getuser_list(1,e.target.value);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      searchcompany(event);
    }
  }
  function downloadReport(){
 
    taskmanageService.download_clientList().then((response) => {
    
    });
  }

  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <Row>
          <div className="col-2">
             <Button className="col-12 " color="primary" type="button" onClick={adduser}>
                Add Client
             </Button>
          </div>
          
          
          </Row>
        <br></br>  
        <div className="row">
            <div className="col-xl-12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{ padding: "0 25px" }}>
                  <div style={{display:'flex'}}>
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                    Client List
                  </h3>
                  
                  <Button
                    className="btn-icon-clipboard"
                    type="button"
                    onClick={downloadReport}
                    style={{marginLeft:'10px',marginTop:'-10px',width:"120px"}}
                    title="Download Report"
                  >
                  <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button>
                  </div>
                  <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0" style={{marginTop:"-20px"}}>
              <InputGroup className="input-group-alternative searchbox-container">
                <Input placeholder="Search client..." id="companydata" className="search-box"  type="text"  onBlur={searchcompany} onKeyDown={handleKeyDown} />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
          
                  <br></br>
                  <br></br>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Client Name</th>
                  <th scope="col">Client Email</th>
                  <th scope="col">Contact Name </th>
                  <th scope="col">Phone no.</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </thead>
                <tbody>
                  {userdata && userdata.length && userdata != null ? (
                    userdata.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              i % 2 == 0
                                ? "blue-row"
                                : i % 3 == 0
                                ? "purple-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.contact_name}</td>
                            <td>{item.phone_no}</td>
                            <td>{(item.service_status == 1) ? <span style={{
                                    color:
                                       "#02C779",
                                        textAlign: "center",
                                  }} >Active</span> : <span style={{
                                    color:
                                       "#FF1717",
                                        textAlign: "center",
                                  }}  >InActive</span>}</td>
                            <td className="whitespace">
                            <> <Button className="btn btn-info" 
                            onClick={(e) =>
                              handleclick(
                                item.id,i
                              )
                            }><i class="fa fa-edit"></i></Button></>
                            
                                </td>
                           
                          </tr>
                        </>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
            </div>
        </div>
      </Container>
    </>
  );
};

export default ClientList;
