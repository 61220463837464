import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";
const moment = require('moment');
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import swal from "sweetalert";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Navbuttons from "components/Navigations/Navbuttons.js";
import { stringToArray } from "ag-grid-community";

const Analytics = (props) => {
  let sdate = moment().startOf('month');
  var month = sdate._d.getMonth() + 1;
  var date = sdate._d.getDate();
  var year = sdate._d.getFullYear();
  var start_d = date + '-' + month + '-' + year;

  let edate = moment();
  var month = edate._d.getMonth() + 1;
  var date = edate._d.getDate();
  var year = edate._d.getFullYear();
  var end_d = date + '-' + month + '-' + year;

  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [sortfield, setsortfield] = useState("");
  const [sortorder, setsortorder] = useState("asc");
  const [state, setState] = useState({startDate:sdate,endDate:edate});
  const [statesend, setStatesend] = useState({startDate:start_d,endDate:end_d});

  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data('',statesend.startDate, statesend.endDate);
  },[sortorder]);

  function get_report_data(e,start_date=null,end_date=null){
    reportmanageService.getReportData(start_date,end_date,sortfield,sortorder).then((response) => {
      console.log(response.data)
      setReportdata(response.data); 
    });
  }
  function report_details(user_id,type,status=''){
    if(statesend.startDate!=null && statesend.endDate!=null && (user_info.role_id == 14 || user_info.role_id == 12)){
      var drange = "/"+statesend.startDate+"/"+statesend.endDate
    }else{
      var drange = "";
    }
     history.push({
       pathname: "report_details/"+user_id+"/"+type+drange+"/"+status,
     });

  }

  function break_line(paragraph){
     return paragraph.replaceAll("\n",'<br>');
  }
  
  const dailyreport = () =>{
    history.push({
      pathname: 'dailyreport',
    });
  }

  const weeklyreport = () =>{
    history.push({
      pathname: 'weeklyreport',
    });
  }
  const monthlyreport = () =>{
    history.push({
      pathname: 'monthlyreport',
    });
  }
// date range
function setEndDateRange(e){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend,endDate: dd });
  setState({ ...state,endDate: e });
  get_report_data('',statesend.startDate, dd);
}

function setStartDateRange(e){
  var month = e._d.getMonth() + 1;
  var date = e._d.getDate();
  var year = e._d.getFullYear();
  var dd = date + '-' + month + '-' + year;
  setStatesend({ ...statesend, startDate: dd })
  setState({ ...state, startDate: e })
  get_report_data('',dd, statesend.endDate);
}
// end
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
            {user_info.role_id != 14 && user_info.role_id != 12 ? 
            <div className="col-2">
          <Button className="col-12 " color="primary" type="button" >
                 Report
             </Button>
             </div>
             : null }
             { (reportdata.show == 1 && user_info.role_id != 14 && user_info.role_id != 12) ? 
             <>
            <div className="col-3">
             <Button className="col-12 " type="button" onClick={dailyreport}>
                Daily Report
             </Button>
            
          </div>
          <div className="col-3">
          <Button className="col-12 " type="button" onClick={weeklyreport}>
                Weekly Report
             </Button>
          </div>
          <div className="col-3">
          <Button className="col-12 " type="button" onClick={monthlyreport}>
                Monthly Report
             </Button>
          </div></>
          : null }
              <Col xl="12">
              {user_info.role_id == 14 || user_info.role_id == 12 ? 
                    <Row>
                    <Col xs={4}>
                      Start Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date",
                              value:state.startDate.date()+'/'+(state.startDate.month()+1)+'/'+state.startDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                    End Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                      
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date",
                              value:state.endDate.date()+'/'+(state.endDate.month()+1)+'/'+state.endDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                  </Row>
               : null }
                 {/* <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h4 className="text-muted ls-1 mb-1">
                          Report
                        </h4>
                      </div>
                    </Row>
                  </CardHeader> */}
                  {reportdata.phase0fields &&
                    reportdata.phase0fields.length &&
                    reportdata.phase0fields != null  ?(
                   <Card style={{padding:'0px 0px 0px 0px'}}>     
                    <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                          <tr>
                          { reportdata.phase0fields.map((item, i) => (
                            <th><span dangerouslySetInnerHTML={{__html:break_line(item)}}/></th>
                          ))}
                          <th style={{textAlign:"left"}}>Action</th>
                          </tr>
                      </thead>
                    <tbody>
                    {reportdata.phase0 &&
                      reportdata.phase0.length &&
                      reportdata.phase0 != null ? (
                        reportdata.phase0.map((user, index) => (
                          <tr>
                      <td>{user.name}</td>
                      { <td>
                    {(user[reportdata.phase0fields[1]] > 0) ?
                    <span class={reportdata.phase0fields[1].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.phase0fields[1]) }}>{user[reportdata.phase0fields[1]]}</span>
                    :  user[reportdata.phase0fields[1]] }</td> }
                     { <td>
                    {(user[reportdata.phase0fields[2]] > 0) ?
                    <span class={reportdata.phase0fields[2].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.phase0fields[2]) }}>{user[reportdata.phase0fields[2]]}</span>
                    :  user[reportdata.phase0fields[2]] }</td> }
                     { <td>
                    {(user[reportdata.phase0fields[3]] > 0) ?
                    <span class={reportdata.phase0fields[3].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.phase0fields[3]) }}>{user[reportdata.phase0fields[3]]}</span>
                    :  user[reportdata.phase0fields[3]] }</td> }
                      { <td>
                    {(user[reportdata.phase0fields[4]] > 0) ?
                    <span class={reportdata.phase0fields[4].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.phase0fields[4]) }}>{user[reportdata.phase0fields[4]]}</span>
                    :  user[reportdata.phase0fields[4]] }</td> }
                      <td style={{textAlign:"left"}}> 
                          <button class="report-view-btn"  color="primary" 
                               size="sm" type="button" 
                               onClick={(e) =>{ report_details(user.id,user.type) }}>
                            View
                          </button>
                      </td>
                      </tr>
                      ))
                      ):null }
                    </tbody>
                     </Table>
                   </Card> 
                  ) : ""
                }
                   
                 
                 <br></br>
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                      {reportdata.fields &&
                        reportdata.fields.length &&
                        reportdata.fields != null ? (
                      <tr>
                        {reportdata.fields.map((item, i) => (
                        <th className="sortclass" onClick={(e) => sortTable(item)} colSpan={1}>{item}</th>
                        ))}
                        <th>Action</th>
                        </tr>
                        ) : ""}
                      </thead>
                  <tbody>
                  {reportdata.data &&
                    reportdata.data.length &&
                    reportdata.data != null ? (
                      reportdata.data.map((user, index) => (
                        <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td>{user.name}</td>
                            { 
                              (reportdata.fields[1] != undefined) ?
                              <td>
                              {(user[reportdata.fields[1]] > 0) ?
                              <span class={reportdata.fields[2].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.fields[1]) }}>{user[reportdata.fields[1]]}</span>
                              :  user[reportdata.fields[1]] }</td> 
                              : ''
                            }

                            { 
                              (reportdata.fields[2] != undefined) ?
                                <td class={reportdata.fields[2].replaceAll(" ","").toLowerCase()}>
                                {(user[reportdata.fields[2]] > 0) ?
                                <span class={reportdata.fields[2].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.fields[2]) }} 
                                  >{user[reportdata.fields[2]]}</span>
                                :  user[reportdata.fields[2]] }</td> 
                            : '' 
                           }

                            { 
                             (reportdata.fields[3] != undefined) ?
                              <td class={reportdata.fields[3].replaceAll(" ","").toLowerCase()}>
                              {(user[reportdata.fields[3]] > 0) ?
                              <span class={reportdata.fields[3].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.fields[3]) }} 
                                >{user[reportdata.fields[3]]}</span>
                              :  user[reportdata.fields[3]] }</td>
                              : ''
                            }

                            {(reportdata.fields[4] != undefined) ?
                                  <td class={reportdata.fields[4].replaceAll(" ","").toLowerCase()}>
                                  {(user[reportdata.fields[4]] > 0) ?
                                  <span class={reportdata.fields[4].replaceAll(" ","").toLowerCase()} onClick={() => { report_details(user.id,user.type,reportdata.fields[4]) }} 
                                       >{user[reportdata.fields[4]]}</span>
                                  :  user[reportdata.fields[4]] }</td>
                                : ''  
                            } 
                           
                            {(reportdata.fields[5])? 
                                 (user[reportdata.fields[5]] > 0) ?
                                 <td><span>{user[reportdata.fields[5]]}</span></td>
                                 :  <td>-</td> 
                                 : ''
                             } 
                           {(reportdata.fields[6])? 
                                 (user[reportdata.fields[6]] > 0) ?
                                 <td><span>{user[reportdata.fields[6]]}</span></td>
                                 :  <td>-</td> 
                                 : ''
                             } 
                            
                            <td> 
                              <button class="report-view-btn"  color="primary"
                                      size="sm" type="button" onClick={(e) =>{ report_details(user.id,user.type) } }>
                                View
                              </button>
                            </td>
                        </tr>
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default Analytics;
