import { useState, useEffect } from "react";
import React from 'react';
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { toast } from 'react-toastify';
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import Select from 'react-select';
const Incentives = () => {
  const [trans_data, setTransData] = useState({ processors: [], reviewer: [], tl: [] });

  useEffect(() => {
    get_unique_userslist()
    getIncentives();
  }, []);

  function getIncentives(user='',month='') {
    clientmanageService.getIncentives(user,month).then((response) => {
      if (response.status === 200) {
        setTransData(response.data);
      } else {
        toast.error('Something went wrong!');
      }
    });
  }

  function addChecklistMaster(e) {
    toast.success('Data updated');
  }
  const [userslist, setUsersData] = useState([]);
  function get_unique_userslist(){
    clientmanageService.getusers().then((response) => {
      setUsersData(response.data.users)
    });
  }
const [selecteduser,setSelectedUser]=useState("");
const setUser = (e)=>{
  setSelectedUser(e.value)
  getIncentives(e.value)
}
function downloadReport(){
    
  clientmanageService.getIncentives(selecteduser,month,1).then((response) => {
  
  });
}
const [month, setMonth] = useState('');
 function inputhandler1(e,field) {
  setMonth(e.target.value)
  getIncentives(selecteduser,e.target.value);
  
}
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <h3>Incentives</h3>
<Row className="mt-1">
  <div className="col-xl-3" >
  {(userslist.length > 0) ? 
                   <Select 
                   
                   className="input-group-alternative " 
                   name="user_id"
                   options={userslist} // set list of the data
                   onChange={(e) => setUser(e,'user_id')}
                  //  value={usersearch}
                   searchable
                   isClearable={false}
                 />
                 : null }
                 
  </div>
  <div className="col-xl-3">
  <input type="month"
                                      className="form-control"
                                      name="month"
                                      onChange={(e) => { inputhandler1(e,'month')}}
                                       

                                      />
  </div>
  <div><Button
                      className=" mt--1"
                      type="button"
                      onClick={downloadReport}
                      
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button></div>
</Row>
        <Row className="mt-1">
          <div className="col" style={{ padding: '0%' }}>
            <Card className="shadow">
              {/* Processor Section */}
              <h4>Processor</h4>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Processor</th>
                    <th></th>
                    <th>Payslips Processed</th>
                    <th>Additional Payslips</th>
                    <th>Processor</th>
                    <th>Clients processed</th>
                    <th>Additional Clients</th>
                    <th>Processor</th>
                    <th>Proposed Pay</th>
                    <th>CCRP</th>
                    <th>Incentive Pay</th>
                  </tr>
                </thead>
                <tbody>
                  {trans_data.processors && trans_data.processors.length ? (
                    trans_data.processors.map((tdata, i) => (
                      <>
                        {i === 0 && (
                        <tr className="blue-row">
                          <td colSpan="3"></td>
                          <td>{tdata.default_payslips}</td>
                          <td>{tdata.p_amount}</td>
                          <td></td>
                          <td>{tdata.default_c_processed}</td>
                          <td>{tdata.c_amount}</td>
                          <td colSpan="3"></td>
                        </tr>
                      )}
                      <tr key={i} className={i % 2 === 0 ? 'purple-row' : 'orange-row'}>
                        <td>{tdata.processor}</td>
                        <td></td>
                        <td>{tdata.payslips}</td>
                        <td>{tdata.additional_payslips}</td> 
                        <td>{tdata.payslip_inc}</td>
                        <td>{tdata.client_processed}</td>
                        <td>{tdata.additional_client}</td>
                        <td>{tdata.client_inc}</td>
                        <td>{tdata.tot_before_errors}</td>
                        <td>{tdata.errors || 0}</td> 
                        <td>{tdata.total_inc}</td> 
                      </tr>
                    
                    </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* Reviewer Section */}
              <h4>Reviewer</h4>
              <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                  <tr>
                    <th>Processor</th>
                    <th>Reviewer</th>
                    <th>Payslips Processed</th>
                    <th>Additional Payslips</th>
                    <th>Processor</th>
                    <th>Clients processed</th>
                    <th>Additional Clients</th>
                    <th>Reviewer</th>
                    <th>Final Pay</th>
                    <th>CCRP</th>
                    <th>Incentive Pay</th>
                  </tr>
                </thead>
                <tbody>
                  {trans_data.reviewer && trans_data.reviewer.length ? (
                    trans_data.reviewer.map((tdata, i) => (
                      <>
                      {i === 0 && (
                        <tr className="blue-row">
                        <td colSpan="3"></td>
                          <td>{tdata.default_payslips}</td>
                          <td>{tdata.p_amount}</td>
                          <td></td>
                          <td>{tdata.default_c_processed}</td>
                          <td>{tdata.c_amount}</td>
                          <td colSpan="3"></td>
                        </tr>
                      )}
                      <tr key={i} className={i % 2 === 0 ? 'purple-row' : 'orange-row'}>
                        
                        <td>{tdata.processor}</td>
                        <td>{tdata.reviewer}</td>
                        <td>{tdata.payslips}</td>
                        <td>{tdata.additional_payslips}</td> 
                        <td>{tdata.payslip_inc}</td>
                        <td>{tdata.client_processed}</td>
                        <td>{tdata.additional_client}</td>
                        <td>{tdata.client_inc}</td>
                        <td>{tdata.tot_before_errors}</td>
                        <td>{tdata.errors || 0}</td> 
                        <td>{tdata.total_inc}</td> 
                      </tr>
                      
                    </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* Team Leader (TL) Section */}
              <h4>Team Leader (TL)</h4>
              <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                  <tr>  
                    <th>Processor</th>
                    <th>TL</th>
                    <th>Payslips Processed</th>
                    <th>Additional Payslips</th>
                    <th>Processor</th>
                    <th>Clients processed</th>
                    <th>Additional Clients</th>
                    <th>TL</th>
                    <th>Final Pay</th>
                    <th>CCRP</th>
                    <th>Incentive Pay</th>
                  </tr>
                </thead>
                <tbody>
                  {trans_data.tl && trans_data.tl.length ? (
                    trans_data.tl.map((tdata, i) => (
                      <>
                      {i === 0 && (
                        <tr className="blue-row">
                          <td colSpan="3"></td>
                          <td>{tdata.default_payslips}</td>
                          <td>{tdata.p_amount}</td>
                          <td></td>
                          <td>{tdata.default_c_processed}</td>
                          <td>{tdata.c_amount}</td>
                          <td colSpan="3"></td>
                        </tr>
                      )}
                      <tr key={i} className={i % 2 === 0 ? 'purple-row' : 'orange-row'}>
                        <td>{tdata.processor}</td>
                        <td>{tdata.reviewer}</td>
                        <td>{tdata.payslips}</td>
                        <td>{tdata.additional_payslips}</td> 
                        <td>{tdata.payslip_inc}</td>
                        <td>{tdata.client_processed}</td>
                        <td>{tdata.additional_client}</td>
                        <td>{tdata.client_inc}</td>
                        <td>{tdata.tot_before_errors}</td>
                        <td>{tdata.errors || 0}</td> 
                        <td>{tdata.total_inc}</td> 
                      </tr>
                      
                    </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>

            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Incentives;
