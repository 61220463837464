import React,{ useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { usermanageService } from "../../../services/usermanageService";
import { taskmanageService } from "../../../services/taskmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { ExitStatus, forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const Assignreviewer = (props) => {
  const [userdatas, setUserdatad] = useState([]);
  const [user_roles, setUserroles] = useState([]);
  const [user_phases, setUserphase] = useState([]);
  const [inputFields, setInputfields] = useState({});
  const [displayphase, setDisplayphase] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [atl_lists, setAtlLists] = useState([]);
  const [displayqatl, setDisplayqatl] = useState(false);
  const [displayclientqatl, setDisplayClientqatl] = useState(false);
  const [displayatl, setDisplayatl] = useState(false);

  function inputhandler2(e) {
    console.log(e)
    let phseup = {}
    phseup = { ...inputFields, roles: e.value } 
    setInputfields(phseup);
    getqatl_list(e.value);
  }
  function inputhandler1(e) {
    let phseup = {}
    if(Array.isArray(e)){   
    phseup = {...inputFields,atl : Array.isArray(e) ? e.map(x1 => x1.value) : []}
    }
    setInputfields(phseup);
  }
  function inputhandler(e) {
 
    let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,qatl : Array.isArray(e) ? e.map(x => x.value) : []}
    }else{
      if(e.target.name == 'roles'){
        getqatl_list(e.target.value);
        
      }
        phseup = { ...inputFields, [e.target.name]: e.target.value }    
      
    }
    setInputfields(phseup);
    console.log(inputFields)
  }

  useEffect(() => {
    getuser_list();
   // getqatl_list();
    return () => {
      setInputfields({});
    };
  }, []);

  function getuser_list() {
    usermanageService.getreviewers().then((response) => {
      console.log(response);
      setUserroles(response.data.reviewers);
      setUserdata(response.data.processors);
    });
  }

  function getqatl_list(id) {
    usermanageService.getProcessorListforassign(id).then((response) => {
  
        const selectdata = {roles:id,qatl: (response.data.data) ? response.data.data.map(x => x.id) : []}
        console.log(selectdata)
        setInputfields(selectdata)
        console.log(inputFields)
      });
  }

  const assignmanager = () =>{
    history.push({
      pathname: 'assignmanager',
    });
  }
  function addmanager(e){
    e.preventDefault();
    // console.log(inputFields);
    // return;
    usermanageService.assignProcessors(inputFields).then((response)=>{
      if(response.status == 200){
       
         toast.success(response.data.message); 
        
         document.getElementById("adduser-form").reset();
         setInputfields({});
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      location.reload();
    });
  }
  const styles = {
    'color':'#4a4444',
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Assign Reviewer</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                        <Buttoncomponent/>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
              <Form role="form" id="adduser-form" onSubmit={addmanager}>
                <div className="row">
                    <div className="col-md-4">
                    <FormGroup>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="roles" 
                            allowSelectAll={true} 
                           
                            options={user_roles} // set list of the data
                            onChange={inputhandler2}

                            isClearable
                          />
                          {inputFields.roles && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            
                              </div>}
                        </FormGroup>
                        {/* <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="roles" onChange={inputhandler} placeholder="Role">
                              <option key="role_1"  value="">Select lead</option>
                              {user_roles.map(roles =>
                                  <option key={roles.id} value={roles.id}>{roles.name}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup> */}
                    </div>
                    
                    <div className="col-md-4" >
                      
                      <FormGroup>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="qatl" 
                            allowSelectAll={true} 
                            value={(inputFields.qatl) ? userdata.filter(obj => inputFields.qatl.includes(obj.value)) : ''} // set selected values
                            options={userdata} // set list of the data
                            onChange={inputhandler}
                            isMulti
                            isClearable
                          />
                          {inputFields.qatl && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            
                              </div>}
                        </FormGroup>
                    </div>
                    
                    
                </div>
                <div class="row">
                <div className="col-md-4">
                        
                          <Button className="col-12 " color="primary" type="submit">
                            Assign
                         </Button>
                    </div>
                    
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Assignreviewer;
