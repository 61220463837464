import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  FormGroup ,
  InputGroup ,
  InputGroupAddon ,
  InputGroupText 
} from "reactstrap";
import ReactDatetime from "react-datetime";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../services/reportmanageService';
import { taskmanageService } from "../../services/taskmanageService";
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { manageService } from "../../services/manageaccountservices";
import { clientmanageService } from "../../services/clientmanageService.js";
import Paginationcomponent from "components/Paginationcomponent.js"
 const ExternalRejection = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [reportRejectiondata, setReportRejectionData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null);
  const [clientsearch, setclientsearch] = useState('');
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');
  const [allclient, setAllclient] = useState(true);
const [errorcategory,seterrorcategory] = useState([]);
const [rowsPerPage, setRowsPerPage] = useState(25);
const [total_page,setTotalData] = useState(0);
const rowsPerPageOptions = [5, 10, 25, 50, 100];
const [state, setState] = useState({startDate:'',endDate:''});
const [statesend, setStatesend] = useState({startDate:'',endDate:''});
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    get_status_list();
    getrejection_list();
    get_unique_companieslist();
    get_error_category();
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getrejectionstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function get_error_category(){
    taskmanageService.geterrorcategories().then((response) => {
      seterrorcategory(response.data)
    })
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getrejection_list(index,null,'','',rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getrejection_list(prev,null,'','',rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getrejection_list(next,null,'','',rowsPerPage);
  };
  const getrejection_list = async (pageNumber = 1,e,filterFields,filtertype='',rowsPerPage='',start_date='',end_date='') => { 
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    const cloneValues = {...filterFields};
    let status ='';
    if(params.status!='undefined' && params.status!=undefined){
      status = params.status;
      filtertype = status;
    }
    let user_id ='';
    if(params.user_id!='undefined' && params.user_id!=undefined){
      user_id = params.user_id;
      cloneValues[9] = {
        ...cloneValues[9],
        ['field']: 'user_id',
        ['value']: user_id,
        ['operator']: '='
      };
    }
   
    setfilterFields(cloneValues);
    reportmanageService.getReportRejectionsExternal(pageNumber,textbox,filterFields,filtertype,rowsPerPage,start_date,end_date).then((response) => {
      //console.log(response.data.data); 
         setReportRejectionData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
         setTotalData(response.data.data.total);
    });
  };
  const handlerejeditRow = (index) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...reportRejectiondata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setReportRejectionData(temp_state);
      // }

  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const formatDate = (dateStr) => {
  if(dateStr!='' && dateStr!= null){
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
  }else{
    return null;
  }
};
  function downloadReport(){
    
    reportmanageService.download_external_rejection_report(filterFields,filtertype).then((response) => {
    
    });
  }
  const editRejField = (value, field, id,index) => {
    taskmanageService.editexternalRejectionRow(value, field, id).then((response) => {
      if (field == "type"){
        field = 'errortype';
      } 
      let temp_st = [...reportRejectiondata];
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      // temp_ele[field] = value;
      if (field == "approval") {
        if (value == 1) {
          temp_ele.approval = "Approved";
          temp_ele['flag'] = 0;
        } else if (value == 2) {
          temp_ele.approval = "Denied";
          temp_ele['flag'] = 2;
        }else{
          temp_ele.approval = "";
        }
      }
     
      if (field == "status" || field == "final_status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          if(field == 'status'){
            temp_ele['status_name'] = v.name;
            temp_ele['status_color_code'] = v.color_code;
            temp_ele['status_id'] = value;
          }else{
            temp_ele['final_status_name'] = v.name;
            temp_ele['fstatus_color_code'] = v.color_code;
            temp_ele['fstatus_id'] = value;
          }
          temp_ele['approval'] = 0;
            if(value==11){
              temp_ele['flag'] = 0;
            }
          
       }, {});
       }
       if (field == "category_id") {
        Object.entries(errorcategory).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
            temp_ele['category_name'] = v.name;
      }, {});
       }
       if (field != "status" && field != "approval") {
        temp_ele[field] = value;
      }
      temp_st[index] = temp_ele;
      setReportRejectionData(temp_st);
    })
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  
  const taskDetail = (task_id,index) => {
    taskmanageService.gettaskdetail(task_id,'','').then((response) => {
      settaskdetails(response.data.data);
      settaskdetailPopup(!taskdetailPopup);
    })
  }
  const withouterrorreport = () =>{
    history.push({
      pathname: 'reportwithouterror',
    });
  }
  const rejectionsummary = () =>{
    history.push({
      pathname: 'rejectionsummary',
    });
  }
  const upload_doc_popup = () => {
    history.push("/admin/addextrejection");
 }
 const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
 function get_unique_companieslist(){
  clientmanageService.getclientlist().then((response)=>{
     setUniqueCompaniesData(response.data);
   });
 }
 const [ subclientlist, setsubclientlist ] = useState([])
 
   function subclientList(clientname=''){
     clientmanageService.getsubclientlist(clientname).then((response) => {
         if(response.status === 200){
           setsubclientlist(response.data)
         }else {
           toast.error('Something went wrong!');
         }
     });
   }
   function setclient(e){
     subclientList(e.target.value)
     // var index = e.target.selectedIndex;
     // setclientsearch(e.target[index].text)
     setclientsearch(e.target.value);
     const cloneValues = {...filterFields};
     delete cloneValues[2];
    setfilterFields(cloneValues);
   }
   const companyfilter = (e,index) => {
    if(e.target.value == ''){
      setAllclient(true);
    }else{
      setAllclient(false);
    }
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getrejection_list(1,null,cloneValues,filtertype);
  }
  function filterallcom({type}){
    setfiltertype(type)
    // setmainfilter(type);
    getrejection_list(1,null,filterFields,type);
  }
  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    
    setCurrentPage(1); 
    getrejection_list(1,null,filterFields,filtertype,newRowsPerPage)
  
  };
  function setEndDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
    getrejection_list(1,null,filterFields,filtertype,'',statesend.startDate, dd);
  }
  function setStartDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
    
  }
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row className="align-items-center row">
            <Col xl="10">
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => upload_doc_popup()}
              >
                {" "}
              + Add CCRP{" "}
              </Button>{" "}
            </Col>
            <Col xl="2">
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </Col>
                   
          </Row><br></br>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                  
                  <Row className="mt-12">
                  <div className="col-xl-3 col-md-2 col-xs-6">
          <button type="button" style={filtertype == 'open' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'open'})} className=" btn btn-secondary text-center">
            Open
          </button>
        
          <button type="button" style={filtertype == 'close' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'close'})} className=" btn btn-secondary text-center">
            Close
           
          </button>
          </div>
                
                  <div className="col-xl-2 col-md-3 col-xs-12">
                  <h5 className="clientformtag"> Client</h5>
                        {(uniquecompanieslist.length > 0 && (user_info.role_id != 13))?
                              <select className="form-control" name="company" onChange={(e) => { companyfilter(e,1),setclient(e);}}   >
                                  <option value="" key="al_key_1">All Clients</option>
                                  {(uniquecompanieslist).map((valuel,k) =>
                                      <option key={k} value={valuel.name}>
                                        {valuel.name}
                                      </option>
                                  )}
                              </select>
                            : null }
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Sub Client</h5>
                            <select  class="form-control" name="sub_client"  onChange={(e) => companyfilter(e,2)}>
                              <option value=""> Select Sub Client</option>
                              {subclientlist.map(res => (
                                  <option
                                    key={res.sub_client}
                                    value={res.sub_client}
                                  >
                                    {res.sub_client}
                                  </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-xl-5 col-md-3 col-xs-12 d-flex">
                        <div className="col-6">
                    <label>Start Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-6">
                      <label>End Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div >
                        </div>
                      
        </Row>
                    <Row className="align-items-center">
                  
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Rejection Report
                        </h4>
                      </div>
                   
                      
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Sub Client</th>
                    
                    <th className="whitespace">Feedback</th>
                    
                    <th scope="col">Date</th>
                    {/* <th scope="col" className="whitespace">QA Lead</th>
                    <th className="whitespace" scope="col">QA TM</th> */}
                    <th className="whitespace" scope="col">Manager</th>
                    <th className="whitespace" scope="col">Error Raised by</th>
                   
                    <th scope="col" className="whitespace">QA Status</th>
                    <th scope="col" className="whitespace">Manager Status</th>
                    <th className="whitespace">Root Cause</th>
                    <th className="whitespace">Action Items</th>
                    <th scope="col" className="whitespace">CCRP Meeting date</th>
                    <th scope="col" className="whitespace">Type</th>
                    <th scope="col" className="whitespace">Source</th>
                    <th scope="col" className="whitespace">Category</th>
                    <th>Action</th>
                    
                </thead>
                <tbody>
                  {reportRejectiondata &&
                    reportRejectiondata.length &&
                    reportRejectiondata != null ? (
                      reportRejectiondata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row" onClick={() => taskDetail(item.task_id, i)}  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.name} ({item.type}) {" "} &nbsp;&nbsp;
                            </span>
                          </th>
                          
                          <td className="whitespace">{item.sub_client}  </td>
                         
                          {/* <td className="whitespace">{item.comment}</td> */}
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing && (user_info.id == item.manager_id || (item.manager_id == null))  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "comment",
                                item.id,
                                i
                              )
                            }
                          >{item.comment}</textarea>
                        ) : (
                          <span>{item.comment}</span>
                        )}
                      </td>
                          <td className="whitespace">{item.date}</td>
                          {/* <td className="whitespace">{item.lead}</td>
                          <td className="whitespace">{item.member}</td> */}
                          <td className="whitespace">{item.manager}</td> 
                          <td className="whitespace">{item.raised_error_by}</td> 
                          
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}}><br></br>{item.status_name}</p>
                              )} 
                              </td>  
                              <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing && (user_info.id == item.manager_id || (item.manager_id == null))  ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "final_status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.final_status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                             <p style={{color: (item.fstatus_color_code!=null)?`${item.fstatus_color_code}`:'#000'}}><br></br>{item.final_status_name}</p>
                              )} 
                              </td> 
                              <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing && (user_info.id == item.manager_id || (item.manager_id == null))  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "root_cause",
                                item.id,
                                i
                              )
                            }
                          >{item.root_cause}</textarea>
                        ) : (
                          <span>{item.root_cause}</span>
                        )}
                      </td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing && (user_info.id == item.manager_id || (item.manager_id == null)) && item.action_editting==1  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "action_plan",
                                item.id,
                                i
                              )
                            }
                          >{item.action_plan}</textarea>
                        ) : (
                          <span>{item.action_plan}</span>
                        )}
                      </td>     
                              <td  onClick={()=> handlerejeditRow(i)} >
                                    {item.editing && (user_info.id == item.manager_id || (item.manager_id == null)) ? (
                                      <input type="date"
                                      className="form-control"
                                        defaultValue={item.ccrp_meeting_date}
                                      
                                        onBlur={(e) =>
                                          editRejField(
                                            e.target.value,
                                            "ccrp_meeting_date",
                                            item.id,
                                            i
                                          )
                                        }
                                      />
                                    ) : (
                                      <span>{(item.ccrp_meeting_date!='') ? formatDate(item.ccrp_meeting_date) : ''}</span>
                                    )}
                                  </td>
                                   <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'type',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.errortype}
                             >
                               <option value="A">Amends</option>
                                <option value="E">Error</option>
                             </select>
                           ) : (item.errortype!=null)?item.errortype:''}
                         </td>
                         <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'source',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.source}
                             >
                               <option value="Email">Email</option>
                              <option value="Call">Call</option>
                              <option value="WPs">WPs</option>
                              <option value="Spreadsheet">Spreadsheet</option>
                              <option value="Governance Call">Governance Call</option>
                              <option value="Slack">Slack</option>
                             </select>
                           ) : (item.source!=null)?item.source:''}
                         </td>
                         <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'category_id',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.cat_id}
                             >
                              {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                             </select>
                           ) : (item.category_name!=null)?item.category_name:''}
                         </td>
                                  <td>
                                  {item.flag == 1 && (user_info.id == item.manager_id || (item.manager_id == null)) ? (
                                    <>
                                    <Button
                                      color="primary"
                                      style={{
                                        background: "#02C779",
                                        border: "#02C779",
                                        borderRadius: "18px",
                                      }}
                                      size="sm"
                                      type="button"
                                      onClick={(e) =>
                                        editRejField(
                                          1,
                                          "approval",
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      Approve
                                    </Button>
                                  
                                    <Button
                                      color="primary"
                                      style={{
                                        border: "1px solid #FF6430",
                                        background:'none',
                                        color: "#FF1717",
                                        borderRadius: "18px",
                                      }}
                                      size="sm"
                                      type="button"
                                      onClick={(e) =>
                                        editRejField(
                                          2,
                                          "approval",
                                          item.id,
                                          i
                                        )
                                      }
                                      
                                    >
                                      Deny
                                    </Button>
                                    </>
                                  ) : (item.flag == 2)  ? <span style={{ color : "#FF1717",cursor : 'pointer' }}>Rejected </span> : null }
                                </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                    <div>
     
     <Paginationcomponent
currentPage={currentPage}
totalItems={total_page}
rowsPerPage={rowsPerPage}
rowsPerPageOptions={rowsPerPageOptions}
handlePageClick={handlePageClick}
handlePreviousClick={handlePreviousClick}
handleNextClick={handleNextClick}
handleRowsPerPageChange={handleRowsPerPageChange}
/>
       </div>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
        </Row>
      </Container>
    </>
  );
};
export default ExternalRejection;
