import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Input,
  Col,
  FormGroup ,
  InputGroup 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";
const axios = require("axios");
import { useParams } from "react-router-dom";

const AddKROTypeRating = (props) => {
  const params  = useParams();
  const [clientsearch, setclientsearch] = useState('');
  const [trans_data,settransData] = useState([]);
  const [state, setState] = useState({});
  const [disablestate, setdisablestate] = useState(false);
  const [newbox, setNewBox] = useState(0);
  const [defaultkey, setdefaultKey] = useState(0);
  const history = useHistory();
  const[formData,setFormData]=useState([]);
  console.log(params)
useEffect(() => {
  getkrotypes();
  getroles();
  if(params.role_id && params.id){
    getsinglekrotypeData();
  }
}, []);


const [krotypes, setKroTypes] = useState([]);
const [inputFields, setInputfields] = useState({type_id:'',roles:''});
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}
const [roles, setRoles] = useState([]);
function getroles(){
  usermanageService.getroles().then((response)=>{
    setRoles(response.data.roles);
  });
}
function getsinglekrotypeData(){
  usermanageService.KROTypeRatingList(1,params.id,params.role_id).then((response)=>{
    if(response.status === 200){
      setInputfields({...inputFields,type_id:params.id})
      let type = Object.keys(response.data.data)[0];
      settransData(response.data.data[type])
      setFormData(response.data.data[type])
      setState(response.data.data[type])      
      setdefaultKey(response.data.data[type].length )
      setNewBox(response.data.data[type].length  )
      
      console.log(defaultkey+"=="+newbox)
    }
  });
}

function inputhandler(e,index) {
  let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,roles : Array.isArray(e) ? e.map(x => x.value) : []}
    }else{

      phseup = { ...inputFields, [e.target.name]: e.target.value } 
    }
    setInputfields(phseup);
}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  usermanageService.addKROTypeRating(inputFields,state).then((response) => {
    if (response.status==200) {
      toast.success(response.data.message); 
      location.reload();
  } else {console.log(response.data)
    for (const field in response.data) {
      toast.error(response.data[field][0]);
      }
  }
    setdisablestate(false)
  });
}

 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}
const krotyperating = ()=>{
  history.push("/admin/krotyperating/");
}

function inputhandlernew(e,index) {
  console.log(index)
  console.log(state)
  // const { name, value } = e.target;
  let temp_st = {...state};
  let temp_ele = { ...temp_st[index] };
  temp_ele[e.target.name] = e.target.value;
  temp_ele['user_id'] = clientsearch;
  temp_st[index] = temp_ele;
  setState(temp_st);
  // setState(prevFormData => {
  //           const newData = [...prevFormData];
  //           console.log(newData)
  //           newData[rowIndex] = {
  //               ...newData[rowIndex],
  //               [name]: value
  //           };
            
  //           return newData;
  //       });
      // setState({ ...state, formData });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      
        
        <Row className="mt-12" >
        <Card className="shadow"  style={{width:"100%"}}>
          <CardHeader>
          <div className="row">
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Type</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select className="form-control"  name="type_id"   onChange={(e) => inputhandler(e)} defaultValue={params.id ? params.id : ''}>
                            <option value="">Select Type</option>
                                    {krotypes.map(type =>
                                      <option key={type.value} value={type.value}>{type.label}</option>
                                    )}
                        </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                        
                          <label>Select roles</label>
                        <Select 
                            className="input-group-alternative mb-3" 
                            name="qatl" 
                            allowSelectAll={true} 
    
                            options={roles} // set list of the data
                            onChange={inputhandler}
                            isMulti
                            isClearable
                          />
                        
                      </FormGroup>    
                    </div> 
                    {/* <div className="col-md-4">
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Crietaria"
                              type="text"
                              name="crietaria"
                              onChange={inputhandler}
                             
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div>  */}
                    </div>
          </CardHeader>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
                    <Table  className="align-items-center table-flush" responsive>
                <thead>
                  <tr style={{border : "1px solid #000"}}>
                     <th style={{border : "1px solid #000"}}>Sr.No.</th> 
                    <th style={{border : "1px solid #000"}} className="whitespace">Crietaria</th>
                    <th style={{border : "1px solid #000"}} className="whitespace">Rating</th>
                  </tr>
                </thead>
                <tbody>
                {formData && formData.length > 0 && formData !== null ? (
  // Object.keys(trans_data).map((type, index) => (
    formData.map((item, i) => (
      <tr key={i} className="whitespace" style={{ border: "1px solid #000" }}>
        <td className="whitespace" style={{ border: "1px solid #000" }}>{i + 1}</td>
        <td className="whitespace" style={{ border: "1px solid #000" }}>
          <Input
            placeholder="Criteria"
            type="text"
            name="criteria"
            defaultValue={item.criteria}
            onChange={(e) => inputhandlernew(e, i)}
            required
          />
        </td>
        <td className="whitespace" style={{ border: "1px solid #000" }}>
          <select
            className="form-control"
            name="rating"
            onChange={(e) => inputhandlernew(e, i)}
            defaultValue={item.rating}
          >
            <option value="1">Needs Action</option>
            <option value="2">Below Expectation</option>
            <option value="3">Meet Expectation</option>
            <option value="4">Exceed Expectation</option>
            <option value="5">Exceptional</option>
          </select>
        </td>
      </tr>
    ))
  // ))
) : (
  null
)}


                {(() => {
            const arr1 = [];
            for (let k = defaultkey; k < newbox; k++) {
                arr1.push(
                  <>
                    
                  <tr className="whitespace" style={{border : "1px solid #000"}}>
                  
                  <td style={{border : "1px solid #000"}}>{k+1}</td>
                 <td style={{border : "1px solid #000"}}>
                  <Input
                              placeholder="Crietaria"
                              type="text"
                              name="criteria"
                              onChange={(e) => inputhandlernew(e,k)}
                             
                              required
                            />
                </td>
                
                
                  
                <td className="whitespace" style={{border : "1px solid #000"}}>
               
                <select className="form-control"  name="rating"   onChange={(e) => inputhandlernew(e,k)}>
                                  <option value="1">Needs Action</option>
                                  <option value="2">Below Expectation</option>
                                  <option value="3">Meet Expectation</option>
                                  <option value="4">Exceed Expectation</option>
                                  <option value="5">Exceptional</option>
                        </select>
             
                  </td>
              
                
                </tr>
                  </>
                );
            }
            return arr1;
            
        })()}   

               
                </tbody>
              </Table> 
                    {/* <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-12">
                    <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                    <select className="form-control"  name="rating"   onChange={(e) => inputhandler(e)}>
                                  <option value="1">Needs Action</option>
                                  <option value="2">Below Expectation</option>
                                  <option value="3">Meet Expectation</option>
                                  <option value="4">Exceed Expectation</option>
                                  <option value="5">Exceptional</option>
                        </select>
                    </InputGroup>
                    </FormGroup> 
                  </div>
                    
                    
                    
                    
                </div> */}
                
                
                {/* <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup> */}
              </Form>
            <br></br>
              
                <div class="d-flex">
                <Button color="primary" className="col-xl-1" style={{flex:"0 0 4.66667%"}} type="button" onClick={(e) => addNewbox()} >
              <i class="fa fa-plus"></i>  Add 
              </Button>
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              </div>
              
             
              </Card>
              </Row>

      </Container>
    </>
  );
};

 
export default AddKROTypeRating;
