import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const AddBudget = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select Client',value:'al_key_1'}]);
 

useEffect(() => {
 
  get_unique_companieslist();
  
}, []);

const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
    taskmanageService.get_budget_client().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}

 

const initial_budget = {
    budet_user_id: '',
    budget: '',
  };
  const [inputFieldsBudget, setInputfieldsBudget] = useState(initial_budget);
  
  function inputhandlerbudget(e){
    if(e.target && e.target.name=='budget'){
      setInputfieldsBudget({ ...inputFieldsBudget, budget: e.target.value } );
    }else{
        console.log(e.value)
      let arr = e.value.split('@@');
      console.log(arr)
      let user_id = arr[0];

      setInputfieldsBudget({ ...inputFieldsBudget, budet_user_id: arr[0] ,budget:arr[1]} );
    }
    
    
  }
function save_budget(){
    taskmanageService.save_budget(inputFieldsBudget).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        //location.reload();
       
      } else {
        toast.error(response.data.message);
      }

    });
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            <form id="budgetform">
            {(uniquecompanieslist.length > 0 && (user_info.role_id != 13) && (params.client_id=='undefined' || params.client_id==undefined))?
                   <>
              <CardHeader className="border-0">
              <Row >
               
                   <div className="col-xl-4 col-md-4 col-xs-12">
                    <h3 className="clientformtag"> Client</h3>
                    <Select 
                   
                    className="input-group-alternative " 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => { inputhandlerbudget(e)}}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                  </div>
                  <div className="col-xl-6 col-md-6 col-xs-12" >
                  <h3 className="clientformtag"> Budget</h3>
                    <input name="budget" style={{width:'100px',height:'38px'}} type="number" defaultValue={inputFieldsBudget.budget} onChange={(e) => inputhandlerbudget(e)} />
             
                </div>
                </Row>
                <br></br>
              <Button type="button"
              className="btn btn-primary"
                      onClick={save_budget}
                      style={{height:'36px',width:'60px',top:'8px',padding:'5px'}}
                    >
                   Save
                    </Button>
              </CardHeader>
              </>
               : null }
              
              

</form>
            </Card>
          </div>
        </Row>
    

      </Container>
    </>
  );
};


export default AddBudget;
