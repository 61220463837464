import { useState, useEffect, useMemo,useRef } from "react";
import ReactDatetime from "react-datetime";
import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover,
  
} from "reactstrap"; 
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../services/authentication";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { disposeEmitNodes, ExitStatus, forEachChild } from "typescript";
import { taskmanageService } from "../../services/taskmanageService";
import { usermanageService } from "../../services/usermanageService";
import { clientmanageService } from "../../services/clientmanageService";
import { manageService } from "../../services/manageaccountservices";
import { permissions, rolename, roletype } from "global.js";
import { map } from "jquery";
import 'assets/css/themecustom-style.css';
import { connect } from "react-redux";
import moment from 'moment';
import Select from 'react-select';
import {todoService} from '../../services/todoService.js';
import Paginationcomponent from "components/Paginationcomponent.js"
const Dashboard = (props) => {
  let start_d =  moment().startOf('month').format('YYYY-MM-DD');
  let end_d = moment().endOf('month').format('YYYY-MM-DD');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [state, setState] = useState({startDate:'',endDate:''});
  const [statesend, setStatesend] = useState({startDate:'',endDate:''});
  const history = useHistory();
  const [dashboarddata, setDashboarddata] = useState([]);
  const [tempdashboarddata, setTempDashboarddata] = useState([]);
  const [statuslist, setStatusdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);

  const [companieslist, setCompaniesData] = useState([]);
  const [tempcompanieslist, setTempCompaniesData] = useState([]);
  const [dashboardStatistics, setdashboardStatistics] = useState([]);
  const [phaselistsselection, setPhaseListsSelection] = useState([]);
  const [analystListsSelection, setAnalystListsSelection] = useState({assign_lead_id : '',due_date:''});
  const [processOrder, setprocessOrder] = useState(0);
  const [analysteamleaderlist, setAnalystTeamLeaderList] = useState([]);
  const [companylist, setCompanies] = useState([]);
  const [tempallcompanylist, setAlltempCompanieslist] = useState([]);
  const [total_page,setTotalData] = useState(0);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [documentModalOpen, setdocumentModalOpen] = useState(false);
  const [rejectionModalOpen, setrejectionModalOpen] = useState(false);
  const [otherInfoModalOpen, setotherInfoModalOpen] = useState(false);
  const [expandedRows, setexpandedRows] = useState([]);
  const [commentlists, setCommentLists] = useState([]);
  const [documentlists, setDocumentLists] = useState([]);
  const [rejectionlists, setRejectionLists] = useState([]);
  const [dataloaded,setdataloaded] = useState(false);
  const [mainfilter,setmainfilter] = useState('assigned');
  const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
  const [disablestate, setdisablestate] = useState(false);
  const [clienttransModalOpen, setClienttransModalOpen] = useState(false);
  const [clienttranslists, setClienttransLists] = useState([]);
  const [reviewChecklist,setReviewChecklistModalOpen] = useState(false);
  const [reviewMasterChecklist,setReviewChecklistMasterModalOpen] = useState(false);
  
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [checklistData, setCheckListData] = useState([]);
  const [checklistMasterData, setCheckListMasterData] = useState([]);
  const formRef = useRef();
  const initialStateData = {
    task_id: "",
    analyst_id: "",
    due_date: "",
  };
  const initialStateDataRejection = {
    id: "",
    type : "",
    value : "",
    comment : "",
    errors : []
  };
  const initiaPhaseData = {
    phases: [],
  };
  const commentData = {
    task_id: "",
    comment: "",
    is_client : 0
  };
  
  const [inputFields, setInputfields] = useState(initialStateData);
  const [inputPhases, setinputPhases] = useState(initiaPhaseData);
  const [inputFieldsRejection, setInputfieldsRejection] = useState(initialStateDataRejection);
  const [inputPhasesRejection, setinputPhasesRejection] = useState(initiaPhaseData);
  const [inputComments, setinputComments] = useState(commentData);
  const [error, setError] = useState(null);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('assigned');
  const [successMsg, setsuccessMsg] = useState(null);
  const [completedCount,setCompletedCount] = useState(0);
  const [errorcategory,seterrorcategory] = useState([]);
  const [errorbox,seterrorbox] = useState([
    0
  ]);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );
  const successDiv = successMsg ? (
    <UncontrolledAlert className="alert-success" fade={false}>
      {successMsg}
    </UncontrolledAlert>
  ) : (
    ""
  );
  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function inputhandlercomment(e) {
    setinputComments({ ...inputComments, [e.target.name]: e.target.value });
  }

  const handleclick = (e, value) => {
    history.push({
      pathname: "addedit",
    });
  };
  const todohandleclick = (e, value) => {
    history.push({
      pathname: "todolist",
    });
  };
  useEffect(() => {
    if(!dataloaded){
      get_status_list();
      get_companieslist();
      get_dashboard_data();
      getPhases();
    }
    search_all_company();
    return () => {
      setDashboarddata([]);
      setCompaniesData([]);
      setdashboardStatistics([]);
      setAnalystTeamLeaderList([]);
    };
  }, [props.name]);

  // sorting functon
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  useEffect(() => {
    if(dataloaded){
    get_list(1,null,filterFields,filtertype);
    }
  }, [sortorder]);
  const[filterphaselist,setFilterPhaseList]=useState([]);
  function getPhases(){
    taskmanageService.getPhases().then((response)=>{
      if (response.status == 200) {
 
        setFilterPhaseList(response.data.data)
      }
    })
  }

  // end sorting functon

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const get_list = async (pageNumber = 1,e,filterFields,filtertype='',start_date='',end_date='',rowsPerPage='',phase_id='') => {
    let  textbox = '';
 console.log(start_date,end_date)
    if(e != null){
       textbox = e.target.value;
    }
   
    taskmanageService.gettasks(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder,start_date,end_date,rowsPerPage,phase_id).then((response) => {
      setDashboarddata(response.data.data.data);
      setTempDashboarddata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setCompletedCount(response.data.completed_count)
      setLastPageData(response.data.data.last_page);
      setTotalData(response.data.data.total);
     // setRowsPerPage(response.data.data.per_page);
    });
  };
  function get_status_list() {
    taskmanageService.getstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function get_dashboard_data() {
    taskmanageService.getDashboardData().then((response) => {
      setdashboardStatistics(response.data.data);
    });
  }
  function get_companieslist(e) {
    let textbox = '';
    if(e != null){
       textbox = e.target.value;
       if(e.key === 'Enter'){
        e.preventDefault();
        taskmanageService.getcompanieslistsname(textbox).then((response) => {
          setCompaniesData(response.data.data);
          setTempCompaniesData(response.data.data);
          setdataloaded(true);
        });
      }else if(e.target.value.length <= 1){
        taskmanageService.getcompanieslistsname(textbox).then((response) => {
          setCompaniesData(response.data.data);
          setTempCompaniesData(response.data.data);
          setdataloaded(true);
        });  
      }
    }else{
      taskmanageService.getcompanieslistsname(textbox).then((response) => {
        setCompaniesData(response.data.data);
        setTempCompaniesData(response.data.data);
        setdataloaded(true);
      });
    }
  }

  function searchcompany(e){
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    let empty_arr = [];
    companieslist.map((value,index) => {
       if(value.name == textbox){
           empty_arr.push(value); 
       }else if(textbox == ''){
           empty_arr.push(value);    
       }
    });
    setTempCompaniesData(empty_arr); 
  }
   
  function getcompanies(){
    manageService.get_allcompanies_with_subclient().then((response)=>{
       setCompanies(response.data);
       setAlltempCompanieslist(response.data);
    });
  }

  function search_all_company(){
    let textbox = props.name;
    let pageNumber = 1;
    taskmanageService.gettasks(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder,statesend.startDate,statesend.endDate,rowsPerPage).then((response) => {
      if (response.status == 200) {
      setDashboarddata(response.data.data.data);
      setTempDashboarddata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
       setTotalData(response.data.data.total);
      setLastPageData(response.data.data.last_page);
    
      //setRowsPerPage(response.data.data.per_page);
      
      }
    });
    // let empty_company_arr = [];
    // if(tempdashboarddata.length > 0){
    //    for(let i = 0;i<tempdashboarddata.length;i++){
    //      if(props.name == tempdashboarddata[i].company.name){
    //         empty_company_arr.push(tempdashboarddata[i]);
    //      }
    //    }
    //     if(props.name.length > 0){
    //         setTempDashboarddata(empty_company_arr);
    //     }else{
    //         setTempDashboarddata(dashboarddata);
    //     }
    //  }else{
    //       setTempDashboarddata(dashboarddata);
    //  }
  }

  function get_analystteamleaderlist() {
    taskmanageService.getanalystteamleaderlist().then((response) => {
      setAnalystTeamLeaderList(response.data.data);
    });
  }
  const [clicktasskid,setclicktasskid] = useState('');
  const get_rejection_phase_selection = async (task_id,edittype,type=null) => {
    
    taskmanageService.getrejectionphaselists(task_id).then((response) => {
      if(type == null){
        setPhaseListsSelection(response.data.data);
      }else{
        setPhaseListsSelection([]);
      }
      let initdata = {
        task_id: task_id,
      };
      setInputfields(initdata);
      let cloneValues = [];
      if (response.data.data) {
        let pp = {};
        response.data.data.map((ele, i) => {
          if (ele.already_assign == true) {
            pp = {
              ["phase_id"]: ele.id,
              ["main_id"]: ele.main_id,
              ["assign_lead_id"]: ele.assign_lead_id,
              ["due_date"]: ele.due_date,
            };
            cloneValues[i] = pp;
          }
        });
      }
      setinputPhasesRejection(cloneValues);
    })
  }
  const get_phase_selection = async (task_id,assigned_only=false) => {
    setclicktasskid(task_id);
    get_analystteamleaderlist();
    if(assigned_only == false || assigned_only =='tasks'){
    taskmanageService.getphaselistsselection(task_id).then((response) => {
    
        setPhaseListsSelection(response.data.data);
    
      setprocessOrder(response.data.process_order);
      if (response.data.analyst) {
        setAnalystListsSelection(response.data.analyst);
        let initdata = {
          task_id: task_id,
          analyst_id: response.data.analyst.assign_lead_id,
          due_date: response.data.analyst.due_date,
        };
        setInputfields(initdata);
      } else {
        let initdata = {
          task_id: task_id,
        };
        setInputfields(initdata);
      }

      let cloneValues = [];
      if (response.data.data) {
        let pp = {};
        response.data.data.map((ele, i) => {
          if (ele.already_assign == true) {
            pp = {
              ["phase_id"]: ele.id,
              ["main_id"]: ele.main_id,
              ["assign_lead_id"]: ele.assign_lead_id,
              ["due_date"]: ele.due_date,
            };
            cloneValues[i] = pp;
          }
        });
      }
      setinputPhases(cloneValues);
      if(assigned_only==false){
        setCompanySelectionModal(!CompanySelectionModal);
      }else{
        setinputPhasesRejection(cloneValues);
      }
    });
  }
  };
  function add_phase_selection(e) {
    setdisablestate(true)
    e.preventDefault();
    taskmanageService.addphase(inputFields, inputPhases).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.data.message);
        setCompanySelectionModal(!CompanySelectionModal);
        setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
        setInputfields(initialStateData);
        setinputPhases(initiaPhaseData);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }

      setdisablestate(false)
    });
  }

  function skipPhase(){
   
    swal({
      title: "Are you sure?",
      text: "It effects process!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((isConfirm) => {
      if (isConfirm){
          taskmanageService.skipphase(clicktasskid).then((response) => {
            // console.log(response);
            if (response.status == 200) {
              toast.success(response.data.message);
              setCompanySelectionModal(!CompanySelectionModal);
              setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
              setInputfields(initialStateData);
              setinputPhases(initiaPhaseData);
            } else if (response.status == 201) {
              toast.error(response.data.message);
            }
          });
      } 
  });
  }

  function backPhase(){
   
    swal({
      title: "Are you sure?",
      text: "It effects process!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((isConfirm) => {
      if (isConfirm){
          taskmanageService.backphase(clicktasskid).then((response) => {
            // console.log(response);
            if (response.status == 200) {
              toast.success(response.data.message);
              setCompanySelectionModal(!CompanySelectionModal);
              setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
              setInputfields(initialStateData);
              setinputPhases(initiaPhaseData);
            } else if (response.status == 201) {
              toast.error(response.data.message);
            }
          });
      } 
  });
  }

  function add_comment(e){
    if(e.target.form.comment.value!=''){
    taskmanageService.addcomment(inputComments).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        setCommentModalOpen(!commentModalOpen);
        setinputComments(commentData);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    });
  }else{
    toast.error('Comment is required!'); 
  }
  }

  function addcommentform(id,index,type='',main_id=''){
    setinputComments({task_id:id});
    setCommentModalOpen(!commentModalOpen);
    get_comment_lists(id,type,main_id);
    let temp_state = [...dashboarddata];
    let temp_element = { ...temp_state[index] };
    temp_element.comment_count = 0;
    temp_state[index] = temp_element;
    setDashboarddata(temp_state);
  }
  
  function addform(id,index,type='',main_id=''){
    setClienttransModalOpen(!clienttransModalOpen);
    taskmanageService.getclienttransByTask(id).then((response) => {
      setClienttransLists(response.data.data);
    });
  }

  function get_comment_lists(task_id,type,id){
    taskmanageService.getcommentlists(task_id,type,id).then((response) => {
      setCommentLists(response.data.data);
    });
  };

  const radioHandler = (status) => {
    setStatus(status);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };

  let button = "";
  let phase = "";
  let amendement = "";
  let taskapprove = false;
  let taskstatusedit = false;
  let phaseapprove = false;
  let phasestatusedit = false;
  let analystapprove = false;
  let analyststatusedit = false;
  let taskassign = false;
  let phaseassign = false;
  let analystassign = false;
  let taskedit = false;
  let phaseedit = false;
  let analystedit = false;
  let todo = "";
  if (permissions.includes("company-create") == true) {
    button = (
      <Col xl="2">
        <Button
          className="col-12 dash-button"
          color="primary"
          type="button"
          onClick={handleclick}
        >
          {" "}
         + Add Client{" "}
        </Button>{" "}
      </Col>
    );
    phase = (
      <Col xl="2">
        <Button
          className="col-12 phase-button qatl-main-btn qatl-main-btn-sec"
          color="light"
          type="button"
          onClick={() => {get_companieslist(),get_analystteamleaderlist(),setPhaseSelectionModalOpen(!PhaseSelectionModalOpen)}}
        >
          Phase selection
        </Button>{" "}
      </Col>
    );
    amendement = (
      <Col xl="2">
        <Button
          className="col-12 am-link"
          color="link"
          type="button"
          onClick={amendmentModal}
        >
          Amendement
        </Button>
      </Col>
    );
    
  }
  todo = (
    <Col xl="2">
      <Button
        className="col-12 dash-button"
        color="primary"
        type="button"
        onClick={todohandleclick}
      >
        {" "}
        Todo List{" "}
      </Button>{" "}
    </Col>
  );
  if (permissions.includes("task-approve") == true) {
    taskapprove = true;
  }
  if (
    permissions.includes("phase-approve") == true) {
    phaseapprove = true;
  }
  if (permissions.includes("analyst-approve") == true) {
    analystapprove = true;
  }
  if (permissions.includes("task-edit") == true) {
    taskedit = true;
  }
  if (permissions.includes("phase-status-edit") == true) {
    phasestatusedit = true;
  }
  if (permissions.includes("analyst-status-edit") == true) {
    analyststatusedit = true;
  }
  if (permissions.includes("task-status-edit") == true) {
    taskstatusedit = true;
  }
  if (permissions.includes("phase-edit") == true) {
    phaseedit = true;
  }
  if (permissions.includes("analyst-edit") == true) {
    analystedit = true;
  }
  if (permissions.includes("task-assign") == true) {
    taskassign = true;
  }
  if (permissions.includes("phase-assign") == true) {
    phaseassign = true;
  }
  if (permissions.includes("analyst-assign") == true) {
    analystassign = true;
  }

  const [
    PhaseSelectionModalOpen,
    setPhaseSelectionModalOpen,
    collapseOpen,
    setCollapseOpen,
  ] = useState(false);
  const [CompanySelectionModal, setCompanySelectionModal] = useState(false);
  const [isCheckedPhase, setIsCheckedPhase] = useState(false);
  const [isCheckedPhase_test, setIsCheckedPhase_test] = useState({});
  const [amendmentModalOpen, setAmendmentModal] = useState(false);
  const [amendmentsecondModal, setAmendmentsecondModal] = useState(false);
  const [opensecondpopup, setsecondpopup] = useState(false);
  const [editbox, seteditbox] = useState({ids: []});
  const [checkindex, setcheckindex] = useState([]);
  const initial_amend = {
    am_company: '',
    am_company_name: '',
    am_analyst: '',
    select_due_date: '',
    am_budget: '',
  };
  const [amendmentdata, setAmendmentdata] = useState(initial_amend);
  const [filterlist, setfilterlist] = useState([]);
  const [showfilterbox,setshowfilterbox] = useState(false);
  const [filterApplyFields,setfilterApplyFields] = useState([]);
  const [showRejection,setshowRejection] = useState(false);
  const [rejectionindex,setrejectionindex] = useState('');

const [checkstate, setchecklistState] = useState({task_id:null,phase_id:null,data:null});
  const [decesionmakingmatrixModalOpen, setotherDecisionmakingmatrixModalOpen] = useState(false);
  useEffect(() => {
    if(filterApplyFields.length > 0){

      applyFilter('true');
    }
  }, [filterApplyFields]);


  function inputphasehandler(e, index) {
    const cloneValues = [...inputPhases];
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };
    setinputPhases(cloneValues);
  }

  const handleOnChange = (e, index) => {
    let temp_state = [...phaselistsselection];
    let temp_element = { ...temp_state[index] };
    temp_element.already_assign = temp_element.already_assign ? false : true;
    temp_state[index] = temp_element;
    setPhaseListsSelection(temp_state);
    if (temp_element.already_assign) {
      const cloneValues = [...inputPhases];
      cloneValues[index] = {
        ...cloneValues[index],
        [e.target.name]: e.target.value,
      };
      setinputPhases(cloneValues);
      setinputPhasesRejection(cloneValues);
    } else {
      let state = [...inputPhases];
      console.log(state)
      delete state[index];
      setinputPhases(state);
      let cloneValues = [...inputPhasesRejection];
      delete cloneValues[index];
      
      setinputPhasesRejection(cloneValues);
    }
   
  };

  function get_error_category(){
    taskmanageService.geterrorcategories().then((response) => {
      seterrorcategory(response.data)
    })
  }

  function openRejectionModal(value='',id='',type='',task_id='',index){
    get_error_category();
    setshowRejection(!showRejection);
    const dataRejection = {
      id: id,
      type : type,
      value : value,
      comment : '',
      errors : []
    };
    setInputfieldsRejection(dataRejection);
    if(id==''){
      seterrorbox([0])
      setRowsError(1)
    }
    setrejectionindex(index)
  }

  function inputtaskrejectionhandler(e,index) {
    const cloneValues = {...inputFieldsRejection};
      cloneValues.errors[index] = {
        ...cloneValues.errors[index],
        [e.target.name]: e.target.value,
      };
    console.log(cloneValues)
    
    setInputfieldsRejection(cloneValues);
    
    if(e.target.name == 'no_error'){
      const errorsInput = [...errorbox];
      errorsInput[index] = e.target.value;
      seterrorbox(errorsInput)
    }
  }
  
  const [rowsError, setRowsError] = useState(1);
 
  const addCommetbox = ()=>{
    const setdata = rowsError+1;
      setRowsError(setdata)
    const errorsInput = [...errorbox];
    errorsInput[rowsError] = 0;
    seterrorbox(errorsInput)
  }

  function inputrejectionhandler(e, index) {
    const cloneValues = [...inputPhasesRejection];
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };
    console.log(cloneValues)
    setinputPhasesRejection(cloneValues);
    if(e.target.name == 'no_error'){
      const errorsInput = [...errorbox];
      errorsInput[index] = e.target.value;
      seterrorbox(errorsInput)
    }
  }

  function add_phase_rejection_selection(){
    setdisablestate(true)
    console.log(inputFieldsRejection)
    setinputPhasesRejection(inputPhasesRejection);
    taskmanageService.rejectionphase(inputFieldsRejection, inputPhasesRejection).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.data.message);
        let temp_st = [...dashboarddata];
        let temp_ele = { ...temp_st[rejectionindex] };
        temp_ele.flag = response.data.data.return_flag;
        temp_ele.approval = response.data.data.return_approval;
        Object.entries(statuslist).filter(([k, v]) => v.id == response.data.data.return_status).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
          temp_ele['status_color_code'] = v.color_code;
       }, {});
        temp_ele['status_id'] = response.data.data.return_status;
        temp_st[rejectionindex] = temp_ele;
        setDashboarddata(temp_st);
        setshowRejection(!showRejection);
        setInputfieldsRejection(initialStateDataRejection)
        setinputPhasesRejection(initiaPhaseData)
        seterrorbox([0])
        setRowsError(1)
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }

  const editJobConfirm = (value1, id, type, index) => {
    swal({
      title: "No error job",
      text: "Please select either this job is 'No Error Job' or not!",
      dangerMode: false,
      buttons : {
        cancel: "Cancel",
        yes: {
          text: "Yes, Error exists!",
          value: "yes",
        },
        no: {
          text : "No Error job!",
          value : "no"
        }
      }
    })
    .then((value) => {
      switch (value) {

        case "no":
          editJob(value1, id, type, index)
          break;
     
        case "yes":
          openRejectionModal(
            2,
            id,
            type,
            id,index
          )
           get_rejection_phase_selection(id,type)
          break;
     
        default:
          null
      }
    
    })
  }

  const editJob = (value, id, type, index) => {
  
    taskmanageService.editTask(value, id, type).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        let temp_st = [...dashboarddata];
        let temp_ele = { ...temp_st[index] };
        temp_ele.flag = response.data.data.return_flag;
        temp_ele.approval = response.data.data.return_approval;
        Object.entries(statuslist).filter(([k, v]) => v.id == response.data.data.return_status).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
          temp_ele['status_color_code'] = v.color_code;
       }, {});
        temp_ele['status_id'] = response.data.data.return_status;
        temp_st[index] = temp_ele;
        setDashboarddata(temp_st);
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    })
    
  }

  const handleOnCheckboxClick = (e,type,index) => {
    const { value, checked } = e.target;
    const { ids } = editbox;
    // Case 1 : The user checks the box
    if (checked) {
      seteditbox({
        ids: [...ids, e.target.value]
      });
      setcheckindex([...checkindex, index]);
    }
    // Case 2  : The user unchecks the box
    else {
      seteditbox({
        ids: ids.filter((e) => e !== value)
      });
      setcheckindex(checkindex.filter((e) => e !== index));
    }
    let temp_state = [...dashboarddata];
    let temp_element = { ...temp_state[index] };
    temp_element.rowcheckbox = temp_element.rowcheckbox ? false : true;
    temp_state[index] = temp_element;
    setDashboarddata(temp_state);
  };

  const removeeditbox = () => {
    seteditbox({ids: []});
    setcheckindex([]);
    let temp_state = [...dashboarddata];
    temp_state.map(element => element.rowcheckbox =  false);
    setDashboarddata(temp_state);
  };

  const archieveRows = () => {
    taskmanageService.archivetask(editbox).then((response) => {
      if(response.status == 200){
        seteditbox({ids: []});
        let state = [...dashboarddata];
        checkindex.map(element => delete state[element]);
        setDashboarddata(state);
       toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
    });
  }

  const urgencyFlagUpdate = () => {
    taskmanageService.updateurgencyFlag(editbox).then((response) => {
      if(response.status == 200){
        removeeditbox()
       toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }

    });
  }

  const handlerejeditRow = (index) => {
    if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...rejectionlists];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setRejectionLists(temp_state);
      }

  };
  const handlemaineditRow = (index) => {
    if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...dashboarddata];
      let temp_element = { ...temp_state[index] };
      temp_element.mainediting = true;
      temp_state[index] = temp_element;
      setDashboarddata(temp_state);
      }

  };
  const handleeditRow = (index) => {
    if(phasestatusedit == true || taskstatusedit == true || analyststatusedit == true){
      let temp_state = [...dashboarddata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setDashboarddata(temp_state);
      }

  };

  const handlePageClick = (e, index) => {
 
    e.preventDefault();
    get_list(index,null,filterFields,filtertype,'','',rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev,null,filterFields,filtertype,'','',rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next,null,filterFields,filtertype,'','',rowsPerPage);
  };

  const getFilterList = () => {
    taskmanageService.getfilterlist().then((response) => {
      setfilterlist(response.data.filter);
      setshowfilterbox(!showfilterbox);
    })
  }
  const editRejField = (value, field, id,index) => {
    taskmanageService.editRejectionRow(value, field, id).then((response) => {
      let temp_st = [...rejectionlists];
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      temp_ele[field] = value;
      if (field == "status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
       }, {});
       }
      temp_st[index] = temp_ele;
      setRejectionLists(temp_st);
    })
  }
  const editData = (value1, field, id, type, index,e='') => {
    if(type == 'tasks'){
      swal({
        title: "No error job",
        text: "Please select either this job is 'No Error Job' or not!",
        dangerMode: false,
        buttons : {
          cancel: "Cancel",
          yes: {
            text: "Yes, Error exists!",
            value: "yes",
          },
          no: {
            text : "No Error job!",
            value : "no"
          }
        }
      })
      .then((value) => {
        switch (value) {
 
          case "no":
             editField(value1, field, id, type, index,e,1)
            break;
       
          case "yes":
            openRejectionModal(
              2,
              id,
              type,
              id,index
            )
             get_rejection_phase_selection(id,type,'deny')
            break;
       
          default:
            null
        }
      
      })
    }else{
      editField(value1, field, id, type, index,e)
    }
  };

  const editField = (value, field, id, type, index,e='',no_error_data='',phase='') => {
    taskmanageService.editRow(value, field, id, type,no_error_data).then((response) => {
      if(phase!=''){ // if task detail phase edit
        const cloneValues = {...taskdetails};
          var index1 = e.target.selectedIndex;
          if(index1){
            
            if(field=='assign_lead_id' || field == 'tl_id'){
              cloneValues.phases[index] = {
                ...cloneValues.phases[index],
                mainediting: false,
                leader : e.target[index1].text,
                assign_lead_id : value
              };
            }else if(field=='assign_member_id' || field == 'tm_id'){
              cloneValues.phases[index] = {
                ...cloneValues.phases[index],
                mainediting: false,
                member : e.target[index1].text,
                assign_member_id : value
              };
            }else{
              cloneValues.phases[index] = {
                ...cloneValues.phases[index],
                mainediting: false,
                field : value
              };
            }
           
          }
          settaskdetails(cloneValues);
          if(response.status == 200){
            toast.success(response.data.message);
          }else{
            toast.error(response.data.message);
          }
      }else{ 
      let temp_st = [...dashboarddata];
      let temp_ele = { ...temp_st[index] };
     
      if(response.status == 200){
          if (field == "approval") {
            if (value == 1) {
              temp_ele.approval = "Approved";
            } else if (value == 2) {
              temp_ele.approval = "Denied";
            }else{
              temp_ele.approval = "";
            }
          }
          if (field == "status") {
            Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
              temp_ele['status_name'] = v.name;
              temp_ele['status_color_code'] = v.color_code;
           }, {});
            temp_ele['status_id'] = value;
            temp_ele['approval'] = 0;
            if(value==3){
              temp_ele['flag'] = 0;
            }
            if(value==3){
              let newDate = new Date()
              let months = newDate.getMonth() + 1;
              let month = (months<10)?'0'+months:months;
              temp_ele['completed_date'] = newDate.getFullYear()+'-'+month+'-'+newDate.getDate();
            }
           }
          toast.success(response.data.message);
          if (field != "status" && field != "approval") {
            temp_ele[field] = value;
          }
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      temp_ele.mainediting = false;
      
      
      // for assign user case
      if(e!='' && value!=''){
        var index1 = e.target.selectedIndex;
        if(index1){
          if(field=='assign_lead_id' || field=='tl_id'){
            temp_ele.lead =  e.target[index1].text;
            temp_ele.lead_id =  value;
          }else{
            temp_ele.member =  e.target[index1].text;
            temp_ele.member_id =  value;
          }
        }
      }else{

        if(field=='assign_lead_id' || field=='tl_id'){
          temp_ele.lead = 'Select Assign lead';
          temp_ele.lead_id =  value;
        }else{
          if(field == "assign_member_id" || field=='tm_id'){
            temp_ele.member =  'Select Assign member';
            temp_ele.member_id =  value;
          }
        }
      }

      temp_st[index] = temp_ele;
      setDashboarddata(temp_st);
      get_dashboard_data();
    }
    });
  };



  function phaseModalCancel() {
    setCompanySelectionModal(!CompanySelectionModal);
    setInputfields(initialStateData);
    setinputPhases(initiaPhaseData);
  }

  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const taskDetail = (task_id,index,id,type) => {
    taskmanageService.gettaskdetail(task_id,id,type).then((response) => {
      settaskdetails(response.data.data);
      setDocumentLists(response.data.files);
      setRejectionLists(response.data.rejections);
      settaskdetailPopup(!taskdetailPopup);
      setuserid(response.data.data.user_id)
    })
  }

  const handleExpand = (task, index) => {
    let temp_state = [...dashboarddata];
    let temp_element = { ...temp_state[index] };
    temp_element.toggle = temp_element.toggle ? false : true;
    temp_state[index] = temp_element;
    setDashboarddata(temp_state);
  };

  const applyFilter = (e='') => {
    get_list(1,null,filterFields,filtertype);
    if(e==''){
      setfilterApplyFields(filterFields);
      document.getElementById('tooltipfilter').click();
    }
  }
  const removefromFilter = (index) => {
    let state = {...filterFields};
    Object.entries(state) // converts each entry to [key, value]
      .filter(([k, v]) => v.field == index) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
         delete state[k];
         setfilterFields(state);
         setfilterApplyFields(state);
      }, {});
  }

 
  function filterInputHandler(e,index,op_name='') {
  const cloneValues = {...filterFields};
    if(op_name!=''){
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: op_name,
      };
    }
    var index1 = e.target.selectedIndex;
    if(index1){
      cloneValues[index] = {
        ...cloneValues[index],
        ['val_name']: e.target[index1].text,
      };
    }
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };

    console.log('filter data');
    console.log(cloneValues);
    setfilterFields(cloneValues);
  
  }
  function filterClickHandler(name,value,index) {
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      [name]: value,
    };
    setfilterFields(cloneValues);
    // console.log(filterFields)
  }
  function filterPhaseHandler(e,index){
    //get_list(1,null,filterFields,filtertype);
    get_list(1,null,filterFields,filtertype,'','',rowsPerPage,e.target.value);
  }
  function amendmentModal() {
    getcompanies();
    get_analystteamleaderlist();
    setAmendmentModal(!amendmentModalOpen);
    // console.log(companieslist);
  }

  function amendmantsecond_modal() {
    setAmendmentsecondModal(!amendmentsecondModal);
    let model_opened = !amendmentsecondModal;
    // if(model_opened == false){
    //     setAmendmentdata(initial_amend);
    // }
  }

  function amenmentdatadata(e,cm='') {
    if(e!=null){
    if(cm!=''){
      setAmendmentdata({ ...amendmentdata, [cm]: e.value , ['am_company_name'] : e.label });
      setsecondpopup(true);
    }else{
      if (e.target.value != "") {
        setAmendmentdata({ ...amendmentdata, [e.target.name]: e.target.value });
        setsecondpopup(true);
      } else {
        setsecondpopup(false);
      }
    }
  }
  }

  function apply_amendment() {
    // console.log(amendmentdata);
    console.log(amendmentdata);
    // return;
    clientmanageService.create_amendment(amendmentdata).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        setAmendmentModal(false);
        setAmendmentsecondModal(false);
      } else {
        toast.error(response.data.message);
      }
    });
  }

  function editclient(){
    // console.log('edit box');
    // console.log(editbox.ids[0]);
    history.push({
      pathname: "editclient/"+editbox.ids[0],
      // search: '?id='+editbox.ids[0],
    });

  }
function downloadReport(){
 
  taskmanageService.download_report(filterFields,filtertype,statesend.startDate,statesend.endDate).then((response) => {
  
  });
}

function ageingreport(){
  history.push({
    pathname: "ageingreport/",
  });
  
}

const [loading, setLoading] = useState(false);
const downloadfile = (path,name,extension,task_id) => {
  setLoading(true)
  taskmanageService.download_file(path,name,extension,task_id).then((response) => {
    setLoading(false)
  });
}

const downloadAllfile = (userid,taskid) => {
  setLoading(true)
  taskmanageService.download_all_file(userid,taskid).then((response) => {
    setLoading(false)
  });
}
function filterallcom({type}){
   setfiltertype(type)
   setmainfilter(type);
   get_list(1,null,filterFields,type);
}

function Companysearch(e){
    e.preventDefault();
    //  console.log('event target');
    //  console.log(e.target.compnayname.value);
    //  var company_data = document.getElementById('companydata').value;
    //  console.log(company_data);
}
const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

const downloadfiles = () => {
   history.push('uploadedfiles/${}'); 
}
const [formdetails,setformdetails] = useState({});
const [formdname,setformname] = useState({});
const [userid,setuserid] = useState(false);
function getdocument(trans_id){
  taskmanageService.transitionForm(userid,trans_id).then((response) => {
    setformdetails(response.data);
    let name = (trans_id==2) ? 'Decision Making Matrix – Bookkeeping' : 'VAT Policy';
    setformname(name);
    setotherDecisionmakingmatrixModalOpen(true);

  })
}
const [ assignuserlist, setassignuserlist ] = useState([])
const assignuser = ()=>{
  todoService.get_assignuser(user_info.id).then((response) => {
  
    if(response.status === 200){
      setassignuserlist(response.data.data)
      
    }
  });
}

const phasedelete = (id,disablephase) => {
  if(disablephase == false){
    toast.error('Could not delete.');
    return;
  }
  swal({
    title: "Are you sure?",
    text: "Phase ones deleted could not restore.",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((isConfirm) => {
    if (isConfirm){
  taskmanageService.phasedelete(id).then((response) => {
  
    if(response.status === 200){
      setassignuserlist(response.data.data)
      setCompanySelectionModal(!CompanySelectionModal);
      setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
    }
  });
} 
});
}
function setEndDateRange(e) {
  let dateObj;

  // Check if e is a moment object or a plain JavaScript Date object
  if (e && moment.isMoment(e)) {
    dateObj = e.toDate(); // e is a moment object, so convert to native Date object
  } else if (e && e instanceof Date) {
    dateObj = e; // e is already a Date object, so use it directly
  }

  if (dateObj) {
    const month = dateObj.getMonth() + 1; // months are zero-indexed, so add 1
    const date = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${date}-${month}-${year}`;
console.log(formattedDate)
    // Update state
    setStatesend({ ...statesend, endDate: formattedDate });
    setState({ ...state, endDate: e });

    // Call the filter function when end date changes
    get_list(1, null,filterFields,filtertype, statesend.startDate, formattedDate);
  } else {
    console.error('Invalid date selected or no date selected.');
  }
};

const setStartDateRange = (e) => {
 // Inspect the value of e
  if (e && e._d) {
    const month = e._d.getMonth() + 1; // months are zero-indexed, so add 1
    const date = e._d.getDate();
    const year = e._d.getFullYear();

    const formattedDate = `${date}-${month}-${year}`;

    setStatesend((prevState) => ({
      ...prevState,
      startDate: formattedDate,
    }));

    setState((prevState) => ({
      ...prevState,
      startDate: e,
    }));
  } else {
    console.error('Invalid date selected or no date selected.');
  }
};


//let handleRowsPerPageChange ;
// const [ assignuserlist, setassignuserlist ] = useState([])



const handleRowsPerPageChange = (newRowsPerPage) => {

  setRowsPerPage(newRowsPerPage);
  
  setCurrentPage(1); 
  get_list(1,null,filterFields,filtertype,statesend.startDate,statesend.endDate,newRowsPerPage)

};

function add_checklist_data(e) {

  e.preventDefault();
  var type = '';
  if(user_info.phase_id != null && user_info.phase_id != ''){
     type ='phases';
  }else{
     type ='tasks';
  }
  taskmanageService.addChecklistData(checkstate,type).then((response) => {
    console.log(response);

    if (response.status == 200) {
      toast.success(response.data.message);
      setReviewChecklistModalOpen(!reviewChecklist);
      setReviewChecklistMasterModalOpen(false);
    }else{
      toast.error(response.data.message);
    }
    setdisablestate(false)
  });
}
function addreviewchecklist(id, main_id = '', type) {
  let url = '';
  if (type === "tasks") {
    url = `/admin/checklistform/${id}/`;
  } else {
    url = `/admin/checklistform/${id}/${main_id}/${type}`;
  }
  
  // Log the constructed URL for debugging
  console.log("Redirecting to URL:", url);
  
  // Redirect to the URL
  window.location.href = url;
}
// function addreviewchecklist(id,main_id='',type){
//   if (type === "tasks") {
//     // Open the URL in a new tab
//     window.open(`/admin/checklistform/${id}/`, "_blank");
//   } else {
//     window.open(`/admin/checklistform/${id}/${main_id}/${type}`, "_blank");
//   // setCheckListData([]);
//   // getchecklistData(id,main_id,type)
//   }
  
// }
function getchecklistData(task_id,id,type){

    taskmanageService.getchecklistData(task_id,type,id).then((response) => {
      if(type == 'phases'){
        setCheckListData(response.data.data);
      
        setchecklistState({ ...checkstate,data: response.data.data,task_id: response.data.task_id,phase_id: response.data.phase_id});  
      }else{
        setCheckListMasterData(response.data.data)
      }
      
    });
    if(type == 'phases'){
      setReviewChecklistModalOpen(!reviewChecklist);
    }else{
      setReviewChecklistMasterModalOpen(!reviewMasterChecklist);
    }
   
};
function inputhandlerchecklist(e, field, index,form_id,task_id,phase_id,master_id) {
  let temp_st = { ...checkstate.data };
  var value = '';
  if (field === "phase_value" || field === "qa_value") {
    const { checked } = e.target;
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = checked ? 1 : 0;
    value = checked ? 1 : 0;
  } else {
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = e.target.value;
    value = e.target.value;
  }
  
  setchecklistState({ ...checkstate, data: temp_st });
  taskmanageService.editChecklistForm(value, field, form_id, task_id,phase_id,master_id).then((response) => {

  })
}
  const scrollContainerRef = useRef(null);
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
    }
  };

  const handlephaseeditRow = (index) => {
    if(taskedit == true){
      const cloneValues = {...taskdetails};
      cloneValues.phases[index] = {
        ...cloneValues.phases[index],
        mainediting: true,
      };
    
    settaskdetails(cloneValues);
      }

  };
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
       
        {/* Buttons in row --start */}
        <Row>
          {/* {button} */}
          {phase}
          {amendement}
          {/* {todo} */}
          <Col xl="2">
        <Button
          className="col-12"
          color="primary"
          type="button"
          onClick={ageingreport}
        >
          Ageing Report
        </Button>
      </Col>
        </Row>
        {/* Buttons in row --end */}
        <br></br>

        {/* Phase selection modal --start */}
        <Row>
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setPhaseSelectionModalOpen(!PhaseSelectionModalOpen)}
            isOpen={PhaseSelectionModalOpen}
          >
            <div className="modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
                Phase selection
              </h5>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {
                  setPhaseSelectionModalOpen(!PhaseSelectionModalOpen);
                  setInputfields(initialStateData);
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            <p>Please press Enter after entering the search result</p>
            <Form onSubmit={Companysearch} className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0 col-md-12">
              <InputGroup className="input-group-alternative searchbox-container col-md-12">
                <Input placeholder="Search company..." id="companydata" name="compnayname" className="search-box" type="text" style={{fontWeight:"600",color:"black"}}
                onKeyUp={(e) => get_companieslist(e)}  
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form><br></br>
              {tempcompanieslist && tempcompanieslist.length ? (
                tempcompanieslist.map((item, i) => (
                  <React.Fragment key={i}>
                    <Row key={i} className="d-flex justify-content-center">
                      <Button
                        type="button"
                        onClick={() => get_phase_selection(item.id)}
                      >
                        <span>
                          {item.name}({item.type})
                        </span>
                        <h6>{item.sub_client}</h6>
                      </Button>
                    </Row>
                    <br></br>
                  </React.Fragment>
                ))
              ) : (
                <p>No Data Found</p>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </Row>
        {/* Phase selection modal --end */}

        {/* Select phases for company form --start */}
        <Row>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setCompanySelectionModal(!CompanySelectionModal)}
            isOpen={CompanySelectionModal}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => phaseModalCancel()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <h3>Select Phases</h3>
              <h1>{isCheckedPhase_test["phase-1"]}</h1>
              {errorDiv}
              {successDiv}
              <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_selection}
              >
                {phaselistsselection && phaselistsselection.length ? (
                  phaselistsselection.map((item, i) => (
                    <React.Fragment key={i}>
                      <Row key={i} className={(item.already_assign==false)?'disablerow':''}>
                        <Col md="1">
                           <FormGroup>
                            <input
                              id={`phasechecked-${i}`}
                              checked={item.already_assign}
                              type="checkbox"
                              name="phase_id"
                              value={item.id}
                              onChange={(e) => handleOnChange(e, i)}
                            ></input>{" "}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <label id={`phase-${i}`}>{item.desc}</label>
                         
                        </Col>
                        <Col md="2">
                          <Input
                            bsSize="sm"
                            name="assign_lead_id"
                            required
                            type="select"
                            id={`phase-${i}`}
                            defaultValue={
                              item.already_assign ? item.assign_lead_id : ""
                            }
                            
                            disabled={!item.already_assign}
                            onChange={(e) => inputphasehandler(e, i)}
                          >
                            <option key="lead_assign">Assign lead for phase</option>
                            {item.name == 'Phase 8' ? 
                            (<option key="lead_self_assign" value="complete" >Mark this phase as completed</option>)
                            : null }
                            {item.teamleader.map((teamleader) => {
                              return (
                                <option key={teamleader.id} value={teamleader.id}>
                                  {teamleader.name}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>
                        
                        <Col md="3">
                          <Input
                            defaultValue={item.already_assign ? item.due_date : ""}
                            bsSize="sm"
                            name="due_date"
                            required
                            // value={item.already_assign ? item.due_date : ""}
                            placeholder="Select Due Date"
                            id="example-date-input"
                            type="date"
                            disabled={!item.already_assign}
                            onChange={(e) => inputphasehandler(e, i)}
                            min={disablePastDate()}
                          ></Input>
                        </Col>
                        <Col md="3">
                        <Input
                            defaultValue={item.already_assign ? item.remarks : ""}
                            bsSize="sm"
                            name="remarks"
                            placeholder="Remarks"
                            id="example-date-input"
                            type="textarea"
                            disabled={!item.already_assign}
                            onChange={(e) => inputphasehandler(e, i)}
                          ></Input>
                        </Col>
                        <Col md="1">
                        <span onClick={(e) => phasedelete(item.main_id,item.already_assign)}  color="danger"><i style={{color:"red"}} className="fa fa-trash"></i></span>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))
                ) : (
                  <p>No Data Found</p>
                )}
                
              </Form>
            </ModalBody>
            <ModalFooter style={{justifyContent:'space-between'}}>
            <Button
                color="secondary"
                type="button"
                onClick={() => backPhase()}
              >
                Back Phase
              </Button>
             <Button
                color="secondary"
                type="button"
                onClick={() => skipPhase()}
              >
                Skip Phase
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={add_phase_selection}
                disabled={disablestate}
              >
                {disablestate ? 'Sending...' : 'Apply'}
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={() => phaseModalCancel()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
        {/* Select phases for company form --end */}
        <Row className="dash-analy">
          <Col xl="2" md="6" xs="12"  className="dash-col-analy" >
           
                <Row className={(filtertype=='total' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'total'),setfiltertype('total')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Total
                    </h6>
                    <h1 style={{ color: "#0060B8" }}>
                      {dashboardStatistics.assign_tasks}
                    </h1>
                    <h3 className="mb-0" style={{ color: "#0060B8" }}>
                      Total Tasks
                    </h3>
                  </div>
                </Row>
             
          </Col>
          <Col xl="2" md="6" xs="12"  className="dash-col-analy" >
           
                <Row className={(filtertype=='assigned' ? "align-item-s-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'assigned'),setfiltertype('assigned')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Pending
                    </h6>
                    <h1 style={{ color: "#FF1717" }}>
                      {dashboardStatistics.pending_tasks}
                    </h1>
                    <h3 className="mb-0" style={{ color: "#FF1717" }}>
                      Pending 
                    </h3>
                  </div>
                </Row>
             
          </Col>
          <Col xl="2" md="6" xs="12" className="dash-col-analy">
            {/* <Card className="shadow">
              <CardHeader className="bg-transparent"> */}
                <Row  className={(filtertype=='overdue' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'underreview'),setfiltertype('underreview')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Under Review
                    </h6>
                    <h1 style={{ color: "#FF8C00" }}>
                      {dashboardStatistics.underreview_tasks}
                    </h1>
                    <h3 style={{ color: "#FF8C00" }} className="mb-0">
                    Under Review 
                    </h3>
                  </div>
                </Row>
              {/* </CardHeader>
            </Card> */}
          </Col>
        
          <Col xl="2" md="6" xs="12" className="dash-col-analy">
             <Row  className={(filtertype=='completed' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'completed'),setfiltertype('completed')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Completed
                    </h6>
                    <h1 style={{ color: "#0DAF84" }}>
                      {dashboardStatistics.completed_tasks}
                    </h1>
                    <h3 style={{ color: "#0DAF84" }} className="mb-0">
                      Completed 
                    </h3>
                  </div>
                </Row>
           
          </Col>
          <Col xl="2" md="6" xs="12"className="dash-col-analy">
              <Row  className={(filtertype=='urgent' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'urgent'),setfiltertype('urgent')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Urgent
                    </h6>
                    <h1 style={{ color: "#FF1717" }}>
                      {dashboardStatistics.urgent_tasks}
                    </h1>
                    <h3 style={{ color: "#FF1717" }} className="mb-0">
                      Urgent 
                    </h3>
                  </div>
                </Row>
          </Col>
          <Col xl="2" md="6" xs="12" className="dash-col-analy">
          
          <Row  className={(filtertype=='onhold' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
            <div className="col" onClick={() => {get_list(1,null,filterFields,'onhold'),setfiltertype('onhold')}}>
              <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                On Hold
              </h6>
              <h1 style={{ color: "#FF8C00" }}>
                {dashboardStatistics.onhold_tasks}
              </h1>
              <h3 style={{ color: "#FF8C00" }} className="mb-0">
               On Hold
              </h3>
            </div>
          </Row>
        {/* </CardHeader>
      </Card> */}
    </Col>
        </Row>


        <Row className="mt-4">
          <button type="button" style={filtertype == 'assigned' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'assigned'})} className=" btn btn-secondary text-center">
            Pending
          </button>
          {/* <button type="button" style={filtertype == 'pending_approval' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'pending_approval'})}  className=" btn btn-secondary text-center">
            Pending Approval
          </button>   */}
          <button type="button" style={filtertype == 'completed' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'completed'})} className=" btn btn-secondary text-center">
            Completed
           {(completedCount > 0) ? 
            <Badge title="Approval Pending" className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
              {completedCount}
            </Badge> : null }
          </button>
        </Row>
      
        <Row className="mt-4">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter">
                    <h3 className="mb-0" style={{fontSize:'20px'}}>Task Master &nbsp;&nbsp;</h3><br></br>
                    <div   style={{maxWidth:'50%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    {[6, 7, 10].includes(user_info.role_id) && filterphaselist && filterphaselist.length > 1 && (
                    <div style={{ marginLeft: '10px' }}>
                      <label>Select Phase</label>
                      <FormGroup>
                        <select className="form-control" name="phase_id" onChange={(e) => filterPhaseHandler(e,0)}>
                          <option value="">Select Phase</option>
                          {filterphaselist.map((phase) => (
                            <option key={phase.id} value={phase.id}>
                              {phase.name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </div>
)}


                    </div>
                    <div style={{display:'flex'}}>
                    <div className="">
                    <label>Start Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "YYYY-MM-DD",
                              type: "text", // Allow user to type in text format
                            }}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div >
                      <label>End Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                             inputProps={{
                              placeholder: "YYYY-MM-DD",
                              type: "text", // Allow user to type in text format
                            }}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div >
                    </div>
                    <div>
                      <button onClick={(e) => getFilterList()}  type="button" className="btn-icon-clipboard" data-clipboard-text="bold-down" title="Copy to clipboard" style={{padding: 10}}>
                        <div>
                          <img alt="..." src={require("../../assets/img/brand/filter.png")} style={{height:15, width:15}}/>
                          <span style={{"fontSize":16,fontFamily:"inter"}}>Filters</span>&nbsp;
                          {(showfilterbox) ? (<i className="ni ni-bold-up"></i>) : 
                            (<i className="ni ni-bold-down"></i>)}
                        </div>
                      </button>
                    </div>
                   
                  
                </Row>
              
                {(showfilterbox== true)?
                <Row style={{paddingLeft:'1%',textAlign:'right'}}>
                  <Col xl="12">
                   {(filterApplyFields) ?
                    (Object.values(filterApplyFields)).map((t,k) => (
                        <button key={k} className="btn btn-icon btn-3 btn-primary filter-button" type="button" style={{color:'#3F3F3F'}}>
                          <span className="btn-inner--text"><b>{t.field} #</b> {t.op_name} {(t.val_name)?t.val_name:t.value}</span>
                          <span className="btn-inner--icon" onClick={() => removefromFilter(t.field)}><i className="ni ni-fat-remove"></i></span>
                        </button>
                      )):null} 
                    <button style={{color:'#3F3F3F'}} type="button" className="btn btn-icon btn-primary filter-button" data-placement="right" id="tooltipfilter" >
                        +
                    </button>
                    {(filterlist.length > 0) ?
                    (<UncontrolledPopover placement="right" target="tooltipfilter">
                      <PopoverBody>
                      {filterlist.map((filter, i) => (
                      <React.Fragment key={i}>
                      <p key="para" data-placement="right" id={`tooltipraised-${i}`} style={{cursor:"pointer"}} name="field" value={filter.field} onClick={(e) => {filterClickHandler('field',filter.field,i),setfiltertype('')}}>{filter.field}</p>
                      {/* {(filter.values.length > 0)? */}
                      <UncontrolledPopover key={i} placement="right"  target={`tooltipraised-${i}`}>
                      <PopoverBody>
                         <Form>
                         {(filter.operators).map((operator,j) => (
                         <div key={j} className="custom-control custom-radio mb-3" style={{paddingLeft:'1rem'}}>
                          <input id="customRadio5"  value={operator.symbol} name="operator" type="radio" onChange={(e) => filterInputHandler(e,i,operator.value)} />
                          {/* <label className="custom-control-label" htmlFor="customRadio5"> */}
                           &nbsp;&nbsp; {operator.value}
                          {/* </label> */}
                        </div>
                         )) }
                          <div id="textboxes" >
                        {(filter.values.length > 0)?
                          <select className="form-control" name="value" onChange={(e) => filterInputHandler(e,i)}>
                              <option value="" key="al_key_1">Select values</option>
                              {(filter.values).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        :
                        <input className="form-control" type={filter.type} name="value" onChange={(e) => filterInputHandler(e,i)} />
                        }
                        <br></br>
                        <button type="button" className="col-8 dash-button btn btn-primary" onClick={(e) => applyFilter()} > Apply </button>
                        {/* <button type="button" className="col-6 phase-button  btn btn-light">Cancel</button> */}
                         </div>
                         </Form>
                      </PopoverBody>
                    </UncontrolledPopover>
                       {/* :null }  */}
                     </React.Fragment>
                      ))}
                        </PopoverBody>
                    </UncontrolledPopover>):null }   
                  
                  </Col>  
                </Row> : null }
                {(editbox.ids.length > 0)?
                <div className="edit-row row" style={{background:"#F6F6F6",margin:'1%',paddingLeft:'1%'}}> 
                  <span onClick={(e) => removeeditbox()}  style={{border: '1px solid #3F3F3F',padding:'0px 1px',borderRadius:'4px',height:'20px',marginTop:'11px'}}><i className="ni ni-fat-delete"></i></span>
                  {(permissions.includes("company-edit") == true && editbox.ids.length == 1)?
                  <span onClick={editclient}><i className="far fa-edit"></i> Edit</span> : null }
                  {(permissions.includes("task-delete") == true)?
                  <span onClick={(e) => archieveRows()}><i className="far fa-file-archive"></i> Archive</span> : null }
                   {/* {(permissions.includes("task-edit") == true)?
                  <span onClick={(e) => urgencyFlagUpdate()}><i classNzame="far fa-flag"></i> Flag as Urgent</span> : null } */}
              </div>:null }
              <div className="scroll-div"><button className="scroll-button" onClick={scrollLeft}><i className="fa fa-angle-left"></i></button>&nbsp;&nbsp;<button className="scroll-button" onClick={scrollRight}><i className="fa fa-angle-right"></i></button></div>
              </CardHeader>
              
                <div className="scroll-container"  ref={scrollContainerRef}>
              <div className="scroll-content">
              
              <Table className="align-items-center table-flush"   responsive>
                <thead className="thead-light">
                  
                <tr>
                {(permissions.includes("company-edit") == true || permissions.includes("task-delete") == true || permissions.includes("task-edit") == true)?
                    <th></th> : null }
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('name')}>Client Name</th>
                    <th scope="col"><i className="ni ni-chat-round"></i></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('sub_client')}>Sub Client</th>
                    <th>checklist</th>
                    {user_info.role_id == 10 || user_info.role_id == 6 || user_info.role_id == 7 ?
                    <th scope="col" className="whitespace">Phase name</th> :
                   null }
                    <th scope="col">Year endDate</th>
                    <th scope="col" className="whitespace">Status</th>
                    <th scope="col">Action</th>
                    <th>Assign Lead</th> 
                    {/* {(user_info.role_id == 3) ? <th className="whitespace">Analyst TL</th> : null} */}
                    <th className="whitespace" scope="col">Assign TM</th>
                    <th scope="col" className="whitespace">Raised by</th>
                   
                    <th scope="col" className="whitespace">Budget</th>
                    <th scope="col" className="whitespace">Acc. s/w</th>
                    <th scope="col" className="whitespace">BK. s/w</th>
                    {user_info.role_id != 10 && user_info.role_id != 6  && user_info.role_id != 7 ?
                    <th scope="col">Raised on</th> : null }
                    {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 12 || user_info.role_id == 14 ) ? <th className="whitespace">Document received date</th> : null}
                    {/* {user_info.role_id > 3 ?
                    <th scope="col">Assigned on</th> : null } */}
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Date</th>
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Days</th>
                    
                    {(user_info.role_id == 3 || user_info.role_id == 2) ? <th>Priority</th> : null }
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('completed_date')}>Comp. Date</th>
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('approval_date')}>Approv. Date</th>
                   
                    <th className="whitespace" scope="col">Client Transition Log</th>
                    <th scope="col">Last Modify Date</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboarddata &&
                    dashboarddata.length &&
                    dashboarddata != null && dashboarddata != undefined ? (
                      dashboarddata.map((item, i) => (
                        item != undefined ? 
                        <React.Fragment key={i}>
                      {/* className={(item.status )?((item.status.id == 1)? 'blue-row':(item.status.id == 2)?'orange-row':'purple-row'):null } */}
                        <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item != undefined ? item.edittype : ''} edit-id={item != undefined ? item.id : ''}>
                        {(permissions.includes("company-edit") == true || permissions.includes("task-delete") == true || permissions.includes("task-edit") == true)?
                         <td><input type="checkbox"
                              name="id"
                              className="rowcheckbox"
                              value={item != undefined ? item.task_id : ''}
                              checked={item != undefined ? item.rowcheckbox : ''}
                              onChange={(e) => handleOnCheckboxClick(e,item != undefined ? item.edittype : '',i)} /></td> : null }
                          <td scope="row"  className="whitespace"   >
                            <span className="mb-0 text-sm text-bold"  style={{cursor:'pointer',fontWeight:'600'}}  onClick={() => taskDetail(item.task_id, i,item.id,item.edittype)}>
                              {item != undefined ? item.name : ''} ({item != undefined ? item.type : ''}) {" "} &nbsp;&nbsp;
                            </span>

                            {(item != undefined ? item.phases : '') && item.phases.length ? (
                              <Button
                                color=""
                                type="button"
                                style={{
                                  padding: "revert"
                                }}
                                onClick={() => handleExpand(item, i)}
                              >
                                {item.toggle ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
                              </Button>
                            ) : null}
                          </td>
                          <td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          <img alt="..." src={require("../../assets/img/brand/chat-bubble.png")} style={{height:20, width:20}} id={item.id} onClick={(e) => addcommentform(item.task_id,i,item.edittype,item.id)}/>
                          {item.comment_count != null && item.comment_count > 0 ? (
                            <Badge className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
                              {item.comment_count}
                            </Badge>
                          ) : null}
                            </div>
                          </td>
                          <td className="whitespace">{item.sub_client}</td>
                          <td>
                            <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                              <a style={{height:20, width:20}} id={item.id} onClick={(e) => addreviewchecklist(item.task_id,item.phase_id,item.edittype)}><i class="fa fa-list-alt" aria-hidden="true"></i></a>
                            </div>
                          </td>
                          {user_info.role_id == 10 || user_info.role_id == 6 || user_info.role_id == 7 ?
                          <td className="whitespace">{(item.phases_list!=null)?item.phases_list.desc:''}</td> : null }
                          <td>{formatDate(item.year_end_date)}</td> 
                          {(taskstatusedit == true ||
                          phasestatusedit == true ||
                          analyststatusedit == true) && (item.approval != 'Approved') ? (
                            <td className="whitespace" style={{textAlign : 'center'}}>
                            
                              {
                              (item.status_id == 1 && (!item.editing || item.editing == false))? (<img alt="..." src={require('../../assets/img/brand/inprogress.svg').default} onClick={()=> handleeditRow(i)}/>) : (' ')
                              }
                              {
                                 (item.status_id == 2 && (!item.editing || item.editing == false))? <img alt="..." src={require('../../assets/img/brand/onhold.svg').default} onClick={()=> handleeditRow(i)}/>: null
                              }
                              {
                              (item.status_id == 3 && (!item.editing || item.editing == false))? <img alt="..." src={require('../../assets/img/brand/complete.svg').default} onClick={()=> handleeditRow(i)}/>: null
                              }
                              
                               {( (!item.editing  || item.editing == false))?
                              (<span style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}} onClick={()=> handleeditRow(i)}><br></br>{item.status_name}</span>)
                              : null }
                              {(( item.status_id == null) || item.editing== true)?
                              <select
                              style={{background: (item.status_color_code!=null)?`${item.status_color_code}`:'none',color:(item.status_color_code!=null)?'#fff':'#000'}}
                                className="form-control"
                                onChange={(e) =>
                                  editField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                                defaultValue={item.status_id}
                              >
                                <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} style={{background:`${status.color_code}`,color:'#fff'}}>
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select>
                              : null }
                            </td>
                          ) : (
                            <td className="whitespace" style={{textAlign : 'center'}}>
                            
                            {
                            (item.status_id == 1)? (<img alt="..." src={require('../../assets/img/brand/inprogress.svg').default} />) : (' ')
                            }
                            {
                               (item.status_id == 2)? <img alt="..." src={require('../../assets/img/brand/onhold.svg').default}/>: null
                            }
                            {
                            (item.status_id == 3)? <img alt="..." src={require('../../assets/img/brand/complete.svg').default}/>: null
                            }
                             {(item.status_id >= 3)?
                            (<span style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}}><br></br>{item.status_name}</span>)
                            : null }
                            </td>
                          )}
                          {(taskapprove == true ||
                            phaseapprove == true ||
                            analystapprove == true) &&
                            (user_info.role_id != 3) &&
                          (item.approval == "") && (item.status_id == 3 || item.status_id == 4 || item.status_id == 6 || item.status_id == 16) ? (
                            <td>
                              <Button
                                color="primary"
                                style={{
                                  background: "#02C779",
                                  border: "#02C779",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editData(
                                    1,
                                    "approval",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              >
                                Approve
                              </Button>
                              { user_info.role_id == 2 ?
                              
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                // onClick={(e) =>
                                //   { openRejectionModal(
                                //      2,
                                //      item.id,
                                //      item.edittype,
                                //      item.task_id,i
                                //    ), get_rejection_phase_selection(item.task_id,item.edittype,'deny') }
                                //  }
                                // onClick={(e) => addreviewchecklist(item.task_id,item.phase_id,item.edittype)}
                                onClick={(e) => {
                                  if (item.type == 'Personal Tax Return') {
                                    openRejectionModal(
                                      2,
                                      item.id,
                                      item.edittype,
                                      item.task_id,
                                      i
                                    );
                                    get_rejection_phase_selection(item.task_id, item.edittype, 'deny');
                                  } else {
                                    addreviewchecklist(item.task_id, item.phase_id, item.edittype);
                                  }
                                }}
                              >
                                Deny
                              </Button>
                              :
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editField(
                                    2,
                                    "approval",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                                
                              >
                                Deny
                              </Button>
                           
                              }
                            </td>
                          ) : (
                            item.approval != "" ? (
                            <td style={{textAlign:'center'}}>
                              <span
                                  color="primary"
                                  style={{
                                    color:
                                      item.approval == "Approved"
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >
                                  {item.approval}
                                </span>
                            </td>
                            ) :
                            <td style={{textAlign:'center'}}>
                            {((taskapprove == true ||
                            phaseapprove == true ||
                            analystapprove == true) && item.flag > 0)?
                               (item.flag == 1 && item.edittype=='tasks')? <span onClick={() => { get_phase_selection(item.task_id),setPhaseSelectionModalOpen(true) }} style={{ color : "rgb(254, 167, 50)",cursor : 'pointer' }}>Ready for Phase<br></br> selection</span>
                               : (item.flag == 2 && item.edittype=='tasks')? <span onClick={() => { get_phase_selection(item.task_id),setPhaseSelectionModalOpen(true) }} style={{ color : "rgb(254, 167, 50)",cursor : 'pointer' }}>Ready for next Phase<br></br> selection </span>
                               : (item.flag == 1 && item.edittype=='phases')? <span style={{ color : "#FF1717",cursor : 'pointer' }}>Rejected </span>
                               : (item.flag == 2 && item.edittype=='analyst')? <span style={{ color : "rgb(254, 167, 50)",cursor : 'pointer' }}> Phases <br></br> Assigned</span>
                               : ((item.flag == 3 && item.edittype=='tasks') || (item.flag == 1 && item.edittype=='analyst')) ?<span style={{ color : "#02C779" }}> Ready for review <br></br>
                               <Button
                                color="primary"
                                style={{
                                  background: "#02C779",
                                  border: "#02C779",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editJobConfirm(
                                    1,
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              >
                                Approve
                              </Button>
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                // onClick={(e) =>
                                //  { openRejectionModal(
                                //     2,
                                //     item.id,
                                //     item.edittype,
                                //     item.task_id,i
                                //   ), get_rejection_phase_selection(item.task_id,item.edittype) }
                                // }
                                // onClick={(e) => addreviewchecklist(item.task_id,item.phase_id,item.edittype)}
                                onClick={(e) => {
                                  if (item.type == 'Personal Tax Return') {
                                    openRejectionModal(
                                      2,
                                      item.id,
                                      item.edittype,
                                      item.task_id,
                                      i
                                    );
                                    get_rejection_phase_selection(item.task_id,item.edittype);
                                  } else {
                                    addreviewchecklist(item.task_id,item.phase_id,item.edittype);
                                  }
                                }}
                              >
                                Reject
                              </Button>
                               </span>
                               : ((item.flag == 3 && item.edittype=='analyst')) ?<span style={{ color : "#FF1717" }}> Rejected <br></br>
                             
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                // onClick={(e) =>
                                //  { openRejectionModal(
                                //     2,
                                //     item.id,
                                //     item.edittype,
                                //     item.task_id,i
                                //   ), get_phase_selection(item.task_id,item.edittype) }
                                // }
                                // onClick={(e) => addreviewchecklist(item.task_id,item.phase_id,item.edittype)}
                                onClick={(e) => {
                                  if (item.type == 'Personal Tax Return') {
                                    openRejectionModal(
                                      2,
                                      item.id,
                                      item.edittype,
                                      item.task_id,
                                      i
                                    );
                                    get_rejection_phase_selection(item.task_id,item.edittype);
                                  } else {
                                    addreviewchecklist(item.task_id,item.phase_id,item.edittype);
                                  }
                                }}
                              >
                                Back to Phases
                              </Button>
                               </span> 
                            : (item.flag == 4) ?<span style={{ color : "#FF6430",cursor : 'pointer' }}>Reviewed</span>
                            : (item.flag == 5) ?<span style={{ color : "#02C779",cursor : 'pointer' }}>Completed</span> : null
                            : null }
                            </td>
                          
                          )}
                           <td className="whitespace" onClick={()=> handlemaineditRow(i)}>
                           {item.assign_leads && item.assign_leads.length && user_info.role_id == 2 && (item.mainediting || item.lead==null) ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                 editField(
                                   e.target.value,
                                   item.assign_lead_field,
                                   item.id,
                                   item.edittype,
                                   i,e
                                 )
                               }
                               defaultValue={item.lead_id}
                             >
                               <option value="" key="asslead_key_1">Select Assign Lead</option>
                               {item.assign_leads.map((user) =>
                               
                                   <option key={user.id} value={user.id}>
                                     {user.name}
                                   </option>
                               )}
                             </select>
                           ) : (item.lead!=null)?item.lead:''}
                         </td>
                         {/* {(user_info.role_id == 3) ?
                           <td className="whitespace">{(item.analyst!=null)?item.analyst.analystlead : null}</td>
                          :
                          null
                         } */}
                          {taskassign == true ||
                          phaseassign == true ||
                          analystassign == true ? (
                            <td className="whitespace" onClick={()=> handlemaineditRow(i)}>
                              {item.assign_users && item.assign_users.length && (item.mainediting || item.member==null) ? (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    editField(
                                      e.target.value,
                                      item.assign_field,
                                      item.id,
                                      item.edittype,
                                      i,e
                                    )
                                  }
                                  defaultValue={item.member_id}
                                >
                                  <option value="" key="assign_mem">Select Assign member</option>
                                  {item.assign_users.map((user) =>
                                   
                                      <option key={user.id} value={user.id}>
                                        {user.name}
                                      </option>
                                  )}
                                </select>
                              ) : (item.member!=null)?item.member:''}
                            </td>
                          ) : (
                            <td>{item.member}</td>
                          )}
                          <td className="whitespace">{item.raised_by}</td>

                          <td className="whitespace">{item.budget}</td>
                          <td className="whitespace">{item.account_prod_software}</td>
                          <td className="whitespace">{item.book_keeping_software}</td>
                          {user_info.role_id != 10 && user_info.role_id != 6  && user_info.role_id != 7 ?
                          <td>{formatDate(item.raised_date)}</td> : null }
                          {(user_info.role_id == 3 || user_info.role_id == 2 || user_info.role_id == 12 || user_info.role_id == 14 ) ? 
                          <td onClick={()=> handlemaineditRow(i)}>
                            {item.mainediting ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={item.doc_received_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "doc_received_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              /> 
                            ) : (
                              <span>{formatDate(item.doc_received_date)}</span>
                            )}
                            </td> 
                          : null }
                          
                          {/* {user_info.role_id > 3 ?
                          <td className="whitespace">{item.assign_date}</td> : null} */}
                          <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="date"
                              className="form-control"
                              defaultValue={item.due_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "due_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                                min={disablePastDate()}
                              />
                            ) : (
                              <span>{formatDate(item.due_date)}</span>
                            )}
                          </td>
                         
                          <td>{(item.status_id != 3 && item.status_id != 4 && item.status_id != 6)? item.due_days : ''}</td>
                          {(user_info.role_id == 3 || user_info.role_id == 2) ?
                          <td onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="number"
                              className="form-control"
                              defaultValue={item.priority_no}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "priority_no",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.priority_no}</span>
                            )}
                          </td>
                          : null }
                          <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={item.completed_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "completed_date",
                                    item.id,
                                    item.edittype,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{formatDate(item.completed_date)}</span>
                            )}
                          </td>
                          <td onClick={()=> handlemaineditRow(i)} >
                          {(user_info.phase_id) && item.mainediting ?
                           <input type="date"
                           className="form-control"
                             defaultValue={item.approval_date}
                           
                             onBlur={(e) =>
                               editField(
                                 e.target.value,
                                 "approval_date",
                                 item.id,
                                 item.edittype,
                                 i
                               )
                             }
                           /> :
                            <span>{formatDate(item.approval_date)}</span>}</td>

                          

                        <td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          <img alt="..." src={require("../../assets/img/brand/chat-bubble.png")} style={{height:20, width:20}} id={item.id} onClick={(e) => addform(item.user_id)}/>
                          {item.dmm_count != null && item.dmm_count > 0 ? (
                            <Badge className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
                              {item.dmm_count}
                            </Badge>
                          ) : null}
                            </div>
                          </td>
                            <td>{item.last_updated_date}</td>
                        </tr>

                        {item.phases && item.phases.length
                          ? // <div id={"collapse"+i} className="thead-light" style={{display: item.toggle ? 'block' : 'none' }}                                >
                            item.phases.map((phase,j) => (
                              <tr key={j} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }
                              id={"collapse" + i}
                                style={{
                                  display: item.toggle ? "revert" : "none"
                                }}
                              >
                                {(permissions.includes("company-edit") == true || permissions.includes("task-delete") == true || permissions.includes("task-edit") == true)?
                                <td></td> : null }
                                <td scope="row">
                                  <span className="mb-0 text-sm">
                                    {phase.phases_list.desc}
                                  </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{item.raised_by}</td>
                                <td>{item.raised_date}</td>
                                <td>{phase.due_date}</td>
                                <td></td>
                                <td>{phase.member}</td>
                                <td>{phase.completed_date}</td>
                                <td>{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}</td>
                              </tr>
                            ))
                          : //  </div>
                           ( null)}
                        </React.Fragment>
                      : ''
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              </div>
              </div>
              <CardFooter className="py-4">
              <div>
     
              <Paginationcomponent
        currentPage={currentPage}
        totalItems={total_page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handlePageClick={handlePageClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <Row>
          {/* amendment first modal */}
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => amendmentModal()}
            isOpen={amendmentModalOpen}
          >
            <div className="modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
                Amendment
              </h5>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => amendmentModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <>
                <Row className="d-flex justify-content-center">
                  <label className="col-12">Select company</label>
                  <div className="col-12">
                  <Select 
                            className="input-group-alternative mb-3" 
                            name="am_company" 
                            options={companylist} // set list of the data
                            onChange={(e) => amenmentdatadata(e,'am_company')}
                            value={{label: amendmentdata.am_company_name,value: amendmentdata.am_company}}
                            searchable
                            isClearable={false}
                          />
                    {/* <select
                      className="input-group-alternative mb-3"
                      name="am_company"
                      onChange={amenmentdatadata}
                    > 
                      <option key="comp_key_1" value="">
                        Select company
                      </option>
                      {companylist.length > 0 && companylist != "" ? (
                        companylist.map((item, i) => (
                          <option
                            key={companylist[i].id}
                            value={companylist[i].id}
                          >
                            {companylist[i].name} ({companylist[i].sub_client})
                          </option>
                        ))
                      ) : (
                        <option key="comp_key_2" value="">
                          No data
                        </option>
                      )} */}

                      {/* <option>Xyz company</option>
                            <option>Test Xyz company</option> */}
                    {/* </select> */}
                  </div><br></br><br></br>
                  <label className="col-12">Add Budget</label>
                  <div className="col-12">
                    <Input
                        type="number"
                        placeholder="Budget"
                        name="am_budget"
                        onChange={amenmentdatadata}
                        inputmode="numeric"
                      />
                  </div><br></br><br></br>
                  <label className="col-12">Due date</label>
                  <div className="col-12">
                  <Input
                      type="date"
                      placeholder="Select due date"
                      defaultValue={amendmentdata.select_due_date}
                      name="select_due_date"
                      onChange={amenmentdatadata}
                      min={disablePastDate()}
                    />
                  </div>
                  <div className="mt-4 col-12">
                    {opensecondpopup ? (
                      <Button
                        className="btn btn-success"
                        onClick={apply_amendment}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button className="btn btn-success" disabled={true}>
                        Save
                      </Button>
                    )}
                    <Button
                      className="ml-4"
                      type="button"
                      onClick={() => amendmentModal()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Row>
                <br></br>
              </>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

          {/* amendment second modal */}
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => amendmantsecond_modal()}
            isOpen={amendmentsecondModal}
          >
            <div className="modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
                Atlas Finance company
              </h5>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => amendmantsecond_modal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <>
                <Row>
                  <div className="col-2"></div>
                  {/* <div className="col-7">
                    <label>Select analyst</label>
                  </div> */}
                  {/* <div className="col-3">
                    <label>Select due date</label>
                  </div> */}
                </Row>
                <Row className="d-flex justify-content-center">
                  <div className="col-2">
                    <span>
                      <label>
                        <b>Due date</b>
                      </label>
                    </span>
                  </div>

                  {/* <div className="col-7">
                    <select
                      className="form-control"
                      name="am_analyst"
                      onChange={amenmentdatadata}
                      defaultValue={amendmentdata.am_analyst}
                    >
                      <option key="analyst_key_1" value="0">
                        Select Analyst
                      </option>
                      {analysteamleaderlist.length > 0 && analysteamleaderlist != "" ? (
                        analysteamleaderlist.map((item, i) => (
                          <option
                            key={analysteamleaderlist[i].id}
                            value={analysteamleaderlist[i].id}
                          >
                            {analysteamleaderlist[i].name}
                          </option>
                        ))
                      ) : (
                        <option key="analyst_key_2" value="">
                          No data
                        </option>
                      )}
                    </select>
                  </div> */}

                  <div className="col-3">
                    <Input
                      type="date"
                      placeholder="Select due date"
                      defaultValue={amendmentdata.select_due_date}
                      name="select_due_date"
                      onChange={amenmentdatadata}
                      min={disablePastDate()}
                    />
                  </div>

                  <br></br>
                  <div className="mt-4 col-12">
                    <div>
                      <Button
                        className="btn btn-success"
                        onClick={apply_amendment}
                      >
                        Apply
                      </Button>
                      <Button
                        className="ml-4"
                        type="button"
                        onClick={() => amendmantsecond_modal()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Row>
                <br></br>
              </>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

         
            {/* Comment Modal Start */}
            <Modal toggle={(e) => addcommentform(item.task_id,i)} isOpen={commentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Comment Box
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setCommentModalOpen(!commentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Form role="form" id="add_comment_form" onSubmit={add_comment}> 
                <FormGroup>
                <div className="col-md-12">
                   <Row> 
                     <Input type="textarea" rows="1" className="col-9" placeholder="Comment" name ="comment" onChange={inputhandlercomment}/>
                  &nbsp;&nbsp;<Button color="primary" type="button" className="col-2"  onClick={add_comment}>Send</Button>
                  </Row> 
                </div>
                <br/>
                
                <div className="col-md-12">
                  <Row style={{marginTop:"6px"}}> 
                 <b> Send to : </b>&nbsp;&nbsp;
                <input style={{maxWidth:"3%"}} type="radio" className="col-1" name="is_client" value="0" onChange={inputhandlercomment}  checked /> &nbsp;Internal team
                &nbsp;<input style={{maxWidth:"3%"}} type="radio" className="col-1" name="is_client" value="1" onChange={inputhandlercomment} /> &nbsp;Client
                  </Row> 
                </div>
                </FormGroup>
              </Form>
              <Table>
                <thead>
                  <tr>
                    <th className=" text-center">Name</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                {commentlists.length > 0 && commentlists  ? (
                        commentlists.map((comment, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="text-center">{comment.name}</td>
                    <td>{comment.comment}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
            {/* <ModalFooter>
            
              <Button color="secondary" type="button" onClick={() => setCommentModalOpen(!commentModalOpen)}>Close</Button>
              <Button color="primary" type="button"  onClick={add_comment}>Save changes</Button>
            </ModalFooter> */}
          </Modal>
          {/* Comment Modal End */}
          {/* Client Trans Modal Start */}
                      <Modal  isOpen={clienttransModalOpen} size="lg" scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Details
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setClienttransModalOpen(!clienttransModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
                <thead>
                  <tr>
                    
                    <th>Client Name</th>
                    <th>Transition Name</th>
                    <th>Field Name</th>
                    <th>Scenerio</th>
                    <th>Procedures</th>
                    <th>Old Remark</th>
                    <th>New Remark</th>
                  </tr>
                </thead>
                <tbody>
                {clienttranslists.length > 0 && clienttranslists  ? (
                        clienttranslists.map((item, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                  
                    <td className="text-center">{item.client_name}</td>
                    <td className="text-center">{item.trans_name}</td>
                    <td className="text-center">{item.field_name}</td>
                    <td className="text-center">{item.scenerio}</td>
                    <td className="text-center">{item.procedures}</td>
                    <td className="text-center">{item.old_value}</td>
                    <td className="text-center">{item.new_value}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
           
          </Modal>
          {/* Client trans Modal End */}
        </Row>
        <Row>
          <Modal
          className="taskdetailmodal"
            bsSize="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setshowRejection(!showRejection)}
            isOpen={showRejection}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => openRejectionModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <h3>Add comment for rejection</h3>
              <h1>{isCheckedPhase_test["phase-1"]}</h1>
              {errorDiv}
              {successDiv}
              <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_rejection_selection}
              >
                {phaselistsselection && phaselistsselection.length ? (
                 <>
                  <Row>  
				<Col md="1">
            <a onClick={(e) => addCommetbox()}>+ ADD</a>
          </Col></Row><br></br>
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                  <Row key={k}>
				  <Col md="1">
                          <FormGroup>
						  <Input
                            size="sm"
                            name="phase_id"
                            type="select"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          >
                           <option>Select Phase  </option>
                             {phaselistsselection.map((item, i) => {
                              return (
                                (item.already_assign == true)?
                                <option value={item.main_id}>
                                {item.desc}
                              </option>
                                : null
                            );
							})}
                          </Input>
                            
                          </FormGroup>
                        </Col>
                  <Col md="3">
                          <Input
                            size="sm"
                            name="category_id"
                            type="select"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          >
                            <option>Category </option>
                            {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>  
                        <Col md="3">
                          <Input
                            size="sm"
                            name="no_error"
                            type="number"
                            min="1"
                            placeholder="0"
                            onChange={(e) => inputrejectionhandler(e,k)}
                          ></Input>
                        </Col>  
                  <Col md="4">
                  <>
        {(() => {
            const arr = [];
            for (let j = 0; j < errorbox[k]; j++) {
                arr.push(
                  <Input
                  size="sm"
                  name={"comment"+j}
                  required
                  placeholder="Comment"
                  id="example-date-input"
                  type="text"
                  onChange={(e) => inputrejectionhandler(e,k)}
                ></Input>
                );
            }
            return arr;
        })()}
    </>                         
                        </Col>
                       
                 </Row>
				  );
            }
            return arr1;
            
        })()}
                 </>
                ) : (
                  <>
                <Row>  <Col md="1">
            <a onClick={(e) => addCommetbox()}>+ ADD</a>
          </Col></Row><br></br>
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                  <Row key={k}>
                  <Col md="3">
                          <Input
                            size="sm"
                            name={"category_id"}
                            type="select"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          >
                            <option>Category </option>
                            {errorcategory.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>  
                        <Col md="3">
                          <Input
                            size="sm"
                            name="no_error"
                            type="number"
                            min="1"
                            placeholder="0"
                            onChange={(e) => inputtaskrejectionhandler(e,k)}
                          ></Input>
                        </Col>  
                  <Col md="4">
                  <>
        {(() => {
            const arr = [];
            for (let j = 0; j < errorbox[k]; j++) {
                arr.push(
                  <Input
                  size="sm"
                  name={"comment"+j}
                  required
                  placeholder="Comment"
                  id="example-date-input"
                  type="text"
                  onChange={(e) => inputtaskrejectionhandler(e,k)}
                ></Input>
                );
            }
            return arr;
        })()}
    </>                         
                        </Col>
                       
                 </Row>
                );
            }
            return arr1;
            
        })()}
    </>  
    
                )}
               
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="button"
                onClick={add_phase_rejection_selection}
                disabled={disablestate}
              >
                {disablestate ? 'Sending...' : 'Send Back'}
                
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={() => openRejectionModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>

        {/* task detail popup */}
        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {/* <div className="download-btn-wrapper" style={{padding: '0px 9px 13px 21px'}}>
               <button
                aria-label="Close"
                type="button"
                className="btn btn-secondary text-center"
                onClick={() => downloadfiles()}
              > 
                 <span className="ni ni-cloud-download-95"></span>  <span aria-hidden={true}>Download</span>
               </button> 
            </div> */}
            <ModalBody style={{paddingTop:'0px'}}>
            {documentlists.length > 0 ?
            <button type="button" onClick={() => setdocumentModalOpen(true) } title="Download Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Documents
            </button>
            : null }
            {rejectionlists.length > 0 ?
            <button type="button" onClick={() => setrejectionModalOpen(true) } title="View Rejections" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Rejections
            </button>
            : null }
             {taskdetails.user_id != null ?
            <button type="button" onClick={() => setotherInfoModalOpen(true) } title="Other Info" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Other Info
            </button>
            : null }
        
            {/* {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 12 || user_info.role_id == 14 ?
            <> */}
            <button type="button" onClick={() => getdocument(3)} title="Decision making matrix" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Decision Making Matrix – Year End
            </button>
            <button type="button" onClick={() => getdocument(5) } title="Standard Operating Procedure" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Standard Operating Procedure
            </button>
            {/* </>:''} */}
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Assign date</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                    <th>Approval Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td className="whitespace">{taskdetails.analyst.assign_date}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td className="whitespace">{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                    <td></td>
                    <td className="whitespace"></td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td className="whitespace">{phase.phases_list.desc}</td>
                                {/* <td  className="whitespace">{phase.leader}</td> */}
                                <td className="whitespace" onClick={()=> handlephaseeditRow(d)}>
                                  {phase.assign_leads && phase.assign_leads.length && (phase.mainediting ) ? (
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          'assign_lead_id',
                                          phase.id,
                                          'phases',
                                          d,e,'','phaseedit'
                                        )
                                      }
                                      defaultValue={phase.assign_lead_id}
                                    >
                                      <option value="" key="asslead_key_1">Select Assign Lead</option>
                                      {phase.assign_leads.map((user) =>
                                      
                                          <option key={user.id} value={user.id}>
                                            {user.name}
                                          </option>
                                      )}
                                    </select>
                                  ) : (phase.leader!=null)?phase.leader:''}
                                </td>
                                {/* <td  className="whitespace">{phase.member}</td> */}
                                <td className="whitespace" onClick={()=> handlephaseeditRow(d)}>
                                  {phase.assign_members && phase.assign_members.length && (phase.mainediting ) ? (
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        editField(
                                          e.target.value,
                                          'assign_member_id',
                                          phase.id,
                                          'phases',
                                          d,e,'','phaseedit'
                                        )
                                      }
                                      defaultValue={phase.assign_member_id}
                                    >
                                      <option value="" key="asslead_key_1">Select Assign Member</option>
                                      {phase.assign_members.map((user) =>
                                      
                                          <option key={user.id} value={user.id}>
                                            {user.name}
                                          </option>
                                      )}
                                    </select>
                                  ) : (phase.member!=null)?phase.member:''}
                                </td>
                                {/* <td className="whitespace">{phase.assign_date}</td> */}
                                <td className="whitespace" onClick={()=> handlephaseeditRow(d)} >
                            {phase.mainediting  ? (
                              <input type="date"
                              className="form-control"
                                defaultValue={phase.assign_date}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "created_at",
                                    phase.id,
                                    'phases',
                                    d,e,'','phaseedit'
                                  )
                                }
                              />
                            ) : (
                              <span>{phase.assign_date}</span>
                            )}
                          </td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td className="whitespace">{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>

                                <td className="whitespace">{(phase.approval_date != '') ? formatDate(phase.approval_date) : ''}</td>
                                <td className="whitespace">{phase.remarks}</td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
           
           <Modal className="taskdetailmodal"  isOpen={documentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Documents 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setdocumentModalOpen(!documentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            {(loading == true) ? 
                      <a style={{color:"#5d6cae"}}  >Downloading please wait.. </a>
                      :
            <button type="button" onClick={() => downloadAllfile(taskdetails.user_id,taskdetails.id)} title="Download All Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-download" style={{fontSize:"14px"}}></i> Download All Documents
            </button>
}
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Document Checklist</th>
                    <th className="whitespace">Remarks</th>
                    <th className="whitespace">Document</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {documentlists.length > 0 && documentlists  ? (
                        documentlists.map((document, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="whitespace">{document.doc_type}</td>
                    <td className="whitespace">{document.remark}</td>
                    <td className="whitespace">
                    {(loading == true) ? 
                      <a style={{color:"#5d6cae"}}  >Downloading please wait.. </a>
                      :
                      <a style={{cursor:"pointer",color:"#5d6cae"}} onClick={() => downloadfile(document.doc_path,document.file_name,document.extension,taskdetails.id)} download><i className="fas fa-download"> </i> Download</a>
                     }
                      </td>
                    <td className="whitespace">{document.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal className="taskdetailmodal"  isOpen={rejectionModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Rejections 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setrejectionModalOpen(!rejectionModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Rejection for</th>
                    <th className="whitespace">Phase</th>
                    <th className="whitespace">Category</th>
                    <th className="whitespace">Comment</th>
                    <th className="whitespace">Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {rejectionlists.length > 0 && rejectionlists  ? (
                        rejectionlists.map((rejection, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                    <td className="whitespace">{rejection.type}</td>
                    <td className="whitespace">{rejection.phase_name}</td>
                    <td className="whitespace">{rejection.cat_name}</td>
                    <td className="whitespace">{rejection.comment}</td>
                    <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {rejection.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    rejection.id,
                                    i
                                  )
                                }
                                defaultValue={rejection.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{rejection.status_name}</p>
                              )} 
                              </td>
                  <td className="whitespace">{rejection.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          <Modal className="otherinfomodal"  isOpen={otherInfoModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              All Info 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setotherInfoModalOpen(!otherInfoModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <p> Client Manager : {taskdetails.client_manager}</p><br></br>
              <p> Whether VAT register : {(taskdetails.vat_register == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> VAT Scheme : {taskdetails.vat_scheme}</p><br></br>
              <p> Point of contact : {taskdetails.point_of_contact}</p><br></br>
              <p> Whether Bookkeeping Done : {(taskdetails.bookkeeping_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether to be completed bookkeeping? : {(taskdetails.need_bookkeeping == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether Account Production Done : {(taskdetails.account_production_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether YE Journals to be accounted in Bkg Software? : {(taskdetails.ye_journals == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Access Details : {taskdetails.access_details}</p><br></br>
              <p> Priority No. : {taskdetails.priority_no}</p><br></br>
              <p> Plan : {taskdetails.plan}</p><br></br>
              <p> Number of Associates Entities : {taskdetails.associate_entities}</p><br></br>
              <p> Last document updated date : {taskdetails.last_doc_update_date}</p><br></br>
            </ModalBody>
          </Modal>
          <Modal className="otherinfomodal" style={{'maxWidth' : '900px'}}  isOpen={decesionmakingmatrixModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              {formdname}
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setotherDecisionmakingmatrixModalOpen(!decesionmakingmatrixModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            <Form
                role="form"
                id="phase-selection-form"
              
            
              >
              <Table  className="align-items-center table-flush" responsive>
                <thead>
                  <tr style={{border : "1px solid #000"}}>
                     <th style={{border : "1px solid #000"}}>Sr.No.</th> 
                    <th style={{border : "1px solid #000"}} className="whitespace">Particulars</th>
                    <th style={{border : "1px solid #000"}} className="whitespace">scenario</th>
                   
                    <th style={{border : "1px solid #000"}} className="whitespace">Procedures</th>
                 
                    <th style={{border : "1px solid #000"}} className="whitespace">Client Remarks</th>
                    
                  </tr>
                </thead>
                <tbody>
                

      
      {(formdetails && Object.keys(formdetails).length > 0) ? (
         Object.entries(formdetails).map(([key, value],index) =>
      
           
           <>
             {value.map((g,i) => (
            <tr className="whitespace" style={{border : "1px solid #000"}}>
              {(i ==0)?
              <><td style={{border : "1px solid #000"}}>{index+1}</td><td style={{border : "1px solid #000"}}>{key}</td></>:<><td style={{border : "1px solid #000"}}></td><td style={{border : "1px solid #000"}}></td></>}
              
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.scenerio}</td>
             
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.procedures}</td>
           
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.remarks}</td>
             </tr>
           ))}
           </>
        
       )
      ) : (<tr><td colSpan={4}>No Data found...</td></tr>)
      }
    


                        
                </tbody>
              </Table>
              </Form>
            </ModalBody>
          </Modal>
        
          <Modal  className="taskdetailmodal"  isOpen={reviewChecklist} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Review Checklist
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setReviewChecklistModalOpen(!reviewChecklist)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            {checklistData.length > 0 ?
              <Form role="form" ref={formRef} id="add_checklist_form" > 
              <Table style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th >Sr. No.</th>
                    <th >Category</th>
                    <th >Checklist</th>
                    <th >Responsibility</th>
                    <th >Auditpod Checks</th>
                    {(user_info.phase_id == null)? 
                     <th >QA Checks</th>
                    : null}
                    <th>Latest updated</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                {checklistData.length > 0 && checklistData  ? (
                        checklistData.map((list, i) => (
                <tr>
                  <td>{i+1}</td>
                  <td>{list.category}</td>
                  <td>{list.checklist}</td>
                  <td>{list.responsibility}</td>
                
                  <td >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`requiredPhaseCheckbox${i}`}
                    name="phase_value"
                    checked={checkstate.data && checkstate.data[i] ? checkstate.data[i].phase_value === 1 : false}
                    value={checkstate.data ? checkstate.data.phase_value : ''}
                    onChange={(e) => {
                      if (user_info.phase_id != null) {
                        inputhandlerchecklist(e, "phase_value", i, list.id, checkstate.task_id, list.phase_id, list.master_id);
                      } else {
                        // No action needed if user_info.phase_id is false
                      }
                    }}
                  />
                  </td>
                  {(user_info.phase_id == null)? 
                  <td >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`requiredQACheckbox${i}`}
                    name="qa_value"
                    checked={checkstate.data && checkstate.data[i] ? checkstate.data[i].qa_value === 1 : false}
                    value={checkstate.data ? checkstate.data.qa_value : ''}
                    onChange={(e) => inputhandlerchecklist(e, "qa_value", i,list.id,checkstate.task_id,list.phase_id,list.master_id)}
                  />
                  </td>
                  : null }
                  <td>{list.updated_at}</td>
                  <td>
                 
                    <Input type="textarea" placeholder="Remarks" name ="remarks"   defaultValue={list.remarks}
                    onChange={(e) =>
                      inputhandlerchecklist(
                        e,
                        "remarks",
                        i,list.id,checkstate.task_id,list.phase_id,list.master_id
                      )} />
                 
                  </td>
                 
                </tr>
                  ))
                  ) : (
                   <tr>
                   <td>No Data Found</td>
                   </tr>
                 )}
                
            
                </tbody>
              </Table>
               {/* {(datatype == 0) ? */}
               <Button color="primary" type="button" onClick={add_checklist_data} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Send'} 
              </Button> 
              <span style={{color:"red",fontSize:"12px"}}> Data is auto save so after click on send button then data will not be editable and then only job status can be changed to complete.</span>
              {/* : null } */}
              </Form> 
          : <h3>No Data Found!</h3> }  
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.companysearch.name,
});

export default connect(mapStateToProps)(Dashboard);