import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const taskmanageService = {
    gettasks,
    getstatus,
    getclientstatus,
    getclienttransitionstatus,
    getrejectionstatus,
    getcompanieslistsname,
    editRow,
    editTask,
    getDashboardData,
    getClientDashboardData,
    getphaselistsselection,
    addphase,
    rejectionphase,
    rejectionclient,
    get_qa_analystteamleaderlist,
    getanalystteamleaderlist,
    getnotification,
    read_all,
    clearall_notification,
    clearsingle_notify,
    addcomment,
    addcommentClient,
    getcommentlists,
    getClientcommentlists,
    archivetask,
    updateurgencyFlag,
    approveBilling,
    getfilterlist,
    getarchivetasks,
    restoreTask,
    gettaskdetail,
    download_report,
    getclientTasks,
    get_files_folders,
    skipphase,
    geterrorcategories,
    editRejectionRow,
    editexternalRejectionRow,
    download_all_file,
    download_file,
    getrejectionphaselists,
    getsubclientTasks,
    getgovernancestatus,
    getBillingDashboardData,
    getBillingDashboardStatistics,
    editBillingRow,
    client_task_report,
    transitionForm,
    backphase,
    clientdetailsexportmail,
    save_budget,
    get_budget_client,
    save_announcement,
    getAnnouncement,
    getsingleAnnouncement,
    update_announcement,
    deleteAnnouncement,
    getactiveAnnouncement,
    getclienttransByTask,
    phasedelete,
    getchecklistData,
    editChecklistForm,
    addChecklistData,
    get_single_manager_rejection,
    getKYCDashboard,
    download_KYCDashreport,
    download_clientList,
    getPhases,
    getPriceRevisionDashboard,
    anniversarymailsend,
    editCommonTable,
    getchecklistSubCatData,
    getPhaseClientdetail,
    anniversarydashboardCountData,
    download_anniversary_report,
    getMisMailDetails,
    getMisMailWeeklyData,
    getpodData,
    reorderTasks,
    kanbanviewdata,
    ageingreport,
    ageingdetails,
    deleteBillingBreakup,
    editChecklistUpdate,
    updateDashboardChecklistData
};

function geterrorcategories(){
  return axios({
    method: "get",
    url: `${apiUrl}/getcategories`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
    });
}

function editRejectionRow(value,field,id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-rejection-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function reorderTasks(reorderedIds){
  return axios({
    method: "POST",
    url: `${apiUrl}/reorderTasks`,
    data: { ids: reorderedIds },  // Sending the array as JSON
    headers: {
      Authorization: `Bearer ${authtoken}`,
      'Content-Type': 'application/json',  // Ensure proper content type
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        console.log("Error status:", error.response.status);
      } else {
        console.log("Error:", error.message);
      }
    });

}

function editexternalRejectionRow(value,field,id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-rejection-external`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function editBillingRow(value,field,id,table){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  bodyFormData.append("table", table);
  return axios({
      method: "POST",
      url: `${apiUrl}/billing-update`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}


function gettasks(page,search,filter,filtertype,sortfield,sortorder,start_date,end_date,per_page,phase){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/dashboard?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}&start_date=${start_date}&end_date=${end_date}&per_page=${per_page}&phase=${phase}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/statuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getcompanieslistsname(search){
  return axios({
      method: "get",
      url: `${apiUrl}/task-list?search=${search}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getphaselistsselection(task_id){
  return axios({
      method: "get",
      url: `${apiUrl}/phase-list-selection?task_id=`+task_id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getrejectionphaselists(task_id){
  return axios({
      method: "get",
      url: `${apiUrl}/rejection-phase-list?task_id=`+task_id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function gettaskdetail(task_id,id,type){
  if(id!=''){
    var ss = `&id=${id}&type=${type}`;
  }else{
    var ss = '';
  }
  return axios({
    method: "get",
    url: `${apiUrl}/task-detail?task_id=${task_id}${ss}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;
       }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });    
}
function editRow(value,field,id,type,no_error_data=''){
  var bodyFormData = new FormData();
  bodyFormData.append("type", type);
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  // bodyFormData.append("no_error_data", no_error_data);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function editTask(value,id,type){
  var bodyFormData = new FormData();
  bodyFormData.append("type", type);
  bodyFormData.append("id", id);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-approve-reject`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function addphase(analyst,phases_arr){
  let phases = {'phases' : phases_arr};
  let dta = Object.assign(analyst, phases);
  return axios({
      method: "POST",
      url: `${apiUrl}/phase-selection`,
      data:dta,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
    }

    function skipphase(task_id){
      return axios({
          method: "get",
          url: `${apiUrl}/phase-skip?task_id=`+task_id,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
        .then(function (response) {
          // if(response.status == 200){
            return response;  
          // }
        }, error => {
            return error.response;
          })
        }

    function backphase(task_id){
      return axios({
          method: "get",
          url: `${apiUrl}/phase-back?task_id=`+task_id,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
        .then(function (response) {
          // if(response.status == 200){
            return response;  
          // }
        }, error => {
            return error.response;
          })
        }

function rejectionphase(data,phases_arr){
  let phases = {'phases' : phases_arr};
  let dta = Object.assign(data, phases);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-approve-reject`,
      data: dta,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
}

function rejectionclient(data,phases_arr){
  let phases = {'phases' : phases_arr};
  let dta = Object.assign(data, phases);
  return axios({
      method: "POST",
      url: `${apiUrl}/task-client-reject`,
      data: dta,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      // if(response.status == 200){
        return response;  
      // }
    }, error => {
        return error.response;
      })
}

function getClientDashboardData(start_date,end_date,client){
  if(start_date!=null && end_date!=null){
    var sendData = 'start_date='+start_date+'&end_date='+end_date+'&client='+client;
  }else{
    var sendData = '';
  }
  return axios({
    method: "get",
    url: `${apiUrl}/client-dashboard?${sendData}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getDashboardData(){
  return axios({
      method: "get",
      url: `${apiUrl}/statistics`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function get_qa_analystteamleaderlist(){
  return axios({
      method: "get",
      url: `${apiUrl}/qaanalyst-tl`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getanalystteamleaderlist(){
  return axios({
      method: "get",
      url: `${apiUrl}/analyst-tl`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getnotification(){
  return axios({
    method: "get",
    url: `${apiUrl}/getnotification`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function read_all(){
  return axios({
    method: "post",
    url: `${apiUrl}/notification/readall`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function clearall_notification(){
  return axios({
    method: "post",
    url: `${apiUrl}/notification/clearall`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function clearsingle_notify(id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  return axios({
    method: "post",
    url: `${apiUrl}/notification/clear_single`,
    data:bodyFormData,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function addcomment(comment_data){
  console.log(comment_data)
  return axios({
      method: "post",
      url: `${apiUrl}/add-comment`,
      data: comment_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function addcommentClient(comment_data){
  var bodyFormData = new FormData();
  bodyFormData.append("task_id", comment_data.task_id);
  bodyFormData.append("comment", comment_data.comment);
  bodyFormData.append("is_client", 1);
  return axios({
      method: "post",
      url: `${apiUrl}/add-comment`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getcommentlists(task_id,type,id){
  return axios({
      method: "get",
      url: `${apiUrl}/commentlists?task_id=`+task_id+`&id=`+id+`&type=`+type,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}

function getClientcommentlists(task_id){
  return axios({
    method: "get",
    url: `${apiUrl}/clientcommentlists?task_id=`+task_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function archivetask(data){
  return axios({
    method: "post",
    url: `${apiUrl}/delete-task`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function updateurgencyFlag(data){
  return axios({
    method: "post",
    url: `${apiUrl}/update-task-flag`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function approveBilling(data){
  return axios({
    method: "post",
    url: `${apiUrl}/billing-approve`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}

function getfilterlist(){
  return axios({
    method: "get",
    url: `${apiUrl}/filter-list`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getarchivetasks(page,per_page){

    return axios({
        method: "get",
        url: `${apiUrl}/archive-list?page=${page}&per_page=${per_page}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getclientTasks(page,search,filter,filtertype,sortfield,sortorder,start_date,end_date){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
      method: "get",
      url: `${apiUrl}/client-task-list?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}&start_date=${start_date}&end_date=${end_date}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getsubclientTasks(page,client,subclient){
  return axios({
      method: "get",
      url: `${apiUrl}/subclient-task-list?page=${page}&client=${encodeURIComponent(client)}&subclient=${encodeURIComponent(subclient)}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function restoreTask(id){
  return axios({
    method: "get",
    url: `${apiUrl}/restore-task?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function download_report(filter,filtertype,start_date,end_date){
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/tasks-export?search=${search}${filters}${typefilter}&start_date=${start_date}&end_date=${end_date}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `workflow-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}


function download_all_file(userid,taskid){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/zipFileDownload?user_id=${userid}&task_id=${taskid}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `clientdata.zip`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}

function get_files_folders(task_id,user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/get_files_folders?user_id=${user_id}&task_id=${task_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}


function getclientstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/clientstatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getclienttransitionstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/clienttransitionstatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getrejectionstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/rejectionstatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function download_file(path,name,extension,task_id=''){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_file?path=${encodeURIComponent(path)}&task_id=${task_id}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `${name}.${extension}`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    // return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}
function getgovernancestatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/getgovernancestatus`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getBillingDashboardStatistics(filter){
  
  return axios({
    method: "get",
    url: `${apiUrl}/getbillingdashboarddata?department=${filter.department}&fromdate=${filter.fromdate}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}


function getBillingDashboardData(filter,api,page){
  
  return axios({
    method: "get",
    url: `${apiUrl}/getbillingdashboardtabledata?department=${filter.department}&fromdate=${filter.fromdate}&page=${page}&perpage=${filter.perpage}&url=${api}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function client_task_report(filter,filtertype){
  let filters = '';
  let search = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/client-tasks-export?search=${search}${filters}${typefilter}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `client-task-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function transitionForm(user_id,trans_id){
  return axios({
    method: "get",
    url: `${apiUrl}/transitionForm?user_id=${user_id}&trans_id=${trans_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function clientdetailsexportmail(user_id,start_date,end_date,flag,bodydata,name,data='',cc='',to){
  console.log(data)
  const formData = new FormData();
  formData.append("user_id",user_id);
  formData.append("start_date",start_date);
  formData.append("end_date",end_date);
  formData.append("bodydata",bodydata);
  formData.append("flag",flag);
  formData.append("tomail",to);
  formData.append("ccmail",cc);
  formData.append("name",encodeURIComponent(name));
  if(data.mail_attachemnt){
    data.mail_attachemnt.forEach((file, i) => {
      formData.append(`attachemnt[${i}]`,
      new Blob([file], { type: file.type }),
      file.name || ""
      );
    });

}
  console.log(data)
  return axios({
    method: "post",
    data: formData,
    url: `${apiUrl}/clientdetailsexportmail`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function save_budget(data){
  return axios({
    method: "post",
    data:data,
    url: `${apiUrl}/save_budget`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function get_budget_client(){
  return axios({
    method: "get",
    url: `${apiUrl}/get_budget_client`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function save_announcement(data){
  return axios({
    method: "post",
    data:data,
    url: `${apiUrl}/save_announcement`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getAnnouncement(){
  return axios({
    method: "get",
    url: `${apiUrl}/getAnnouncement`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getsingleAnnouncement(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getsingleAnnouncement?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function update_announcement(data){
  return axios({
    method: "post",
    url: `${apiUrl}/updateAnnouncement`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function deleteAnnouncement(id){
  return axios({
    method: "get",
    url: `${apiUrl}/deleteAnnouncement?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getactiveAnnouncement(){
  return axios({
    method: "get",
    url: `${apiUrl}/getactiveAnnouncement`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getclienttransByTask(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getclienttransByTask?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function phasedelete(id){
  return axios({
    method: "get",
    url: `${apiUrl}/phase-delete?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getchecklistData(task_id,type,id){
  return axios({
      method: "get",
      url: `${apiUrl}/getchecklistData?task_id=${task_id}&id=${id}&type=${type}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      
}
function editChecklistForm(value,field,form_id,task_id,phase_id,master_id){
  var bodyFormData = new FormData();
  bodyFormData.append("task_id", task_id);
  bodyFormData.append("phase_id", phase_id);
  bodyFormData.append("form_id", form_id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  bodyFormData.append("master_id", master_id);
  return axios({
      method: "POST",
      url: `${apiUrl}/checklistform-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function editChecklistUpdate(value,field,id){
  var bodyFormData = new FormData();
      bodyFormData.append("id", id);
      bodyFormData.append("field", field);
      bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/checklistform-update`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function addChecklistData(requestdata,type,sub_cat){
  const formData = new FormData();
  formData.append("task_id", requestdata.task_id);
  formData.append("phase_id", requestdata.phase_id);
  formData.append("type", type);
  Object.keys(requestdata.data[sub_cat]).forEach((phase, index) => {
    formData.append(`data[${index}]`, JSON.stringify(requestdata.data[sub_cat][index]));
  });
  return axios({
    method: "post",
    url: `${apiUrl}/addChecklistData`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function updateDashboardChecklistData(requestdata,type,sub_cat){

  const formData = new FormData();
  formData.append("task_id", requestdata.task_id);
  formData.append("phase_id", requestdata.phase_id);
  formData.append("type", type);
  Object.keys(requestdata.data[sub_cat]).forEach((phase, index) => {
    formData.append(`data[${index}]`, JSON.stringify(requestdata.data[sub_cat][index]));
  });
  return axios({
    method: "post",
    url: `${apiUrl}/checklistDashboardformUpdate`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function get_single_manager_rejection(id){
  return axios({
    method: "get",
    url: `${apiUrl}/get_single_manager_rejection?id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });      
}
function getKYCDashboard(page,search,filtertype,per_page){
  
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/getKYCDashboard?page=${page}&search=${search}${typefilter}&per_page=${per_page}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function download_KYCDashreport(filtertype){
  let filters = '';
  let search = '';
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/getKYCDashboard?search=${search}${typefilter}&export=1`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `KYC-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function download_clientList(client=''){

  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_clientList?client=${encodeURIComponent(client)}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `ClientList-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getPhases(){
  return axios({
    method: "get",
    url: `${apiUrl}/getPhases`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });      
}
function getPriceRevisionDashboard(page,search,filtertype,per_page,year){
  
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
    return axios({
        method: "get",
        url: `${apiUrl}/getPriceRevisionDashboard?page=${page}&search=${search}${typefilter}&per_page=${per_page}&year=${year}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function anniversarymailsend(id){
  return axios({
    method: "get",
    url: `${apiUrl}/anniversarymailsend?id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
    if(response.status == 200){
      return response;
     }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });     
}
function editCommonTable(value,column,id,table_name){
  const formData = new FormData();
  formData.append("value", value);
  formData.append("column", column);
  formData.append("id", id);
  formData.append("table_name", table_name);
  return axios({
    method: "post",
    url: `${apiUrl}/editCommonTable`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}`,'Content-Type': 'multipart/form-data', },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function getchecklistSubCatData(phase_id){
 
    return axios({
        method: "get",
        url: `${apiUrl}/getchecklistSubCatData?phase_id=${phase_id}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
      .then(function (response) {
        if(response.status == 200){
          return response;
         }
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });      
  
}
function getPhaseClientdetail(phase_id,type,start_date,end_date){
  return axios({
    method: "get",
    url: `${apiUrl}/getPhaseClientdetail?phase_id=${phase_id}&type=${type}&start_date=${start_date}&end_date=${end_date}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
    });
}
function anniversarydashboardCountData(){
 
  return axios({
      method: "get",
      url: `${apiUrl}/anniversarydashboardCountData`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}
function download_anniversary_report(page,search,filtertype,per_page,year){
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
 return axios({
   method: "get",
   responseType: 'blob', 
   url: `${apiUrl}/getPriceRevisionDashboard?search=${search}${typefilter}&download=1`,
   headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
 })
 .then(function (response) {
   const outputFilename = `anniversary-report-${Date.now()}.xlsx`;
   const url = URL.createObjectURL(new Blob([response.data]));
   const link = document.createElement('a');
   link.href = url;
   link.setAttribute('download', outputFilename);
   document.body.appendChild(link);
   link.click();
// return response;
   }, error => {
     return error.response;
   })
   .catch(function (error) {
     console.log(error.response.status);
   });  
}
function getMisMailDetails(page,per_page,id){
 
  return axios({
      method: "get",
      url: `${apiUrl}/getMisMailDetails?page=${page}&per_page=${per_page}&id=${id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}
function getMisMailWeeklyData(page,per_page){
 
  return axios({
      method: "get",
      url: `${apiUrl}/getMisMailWeeklyData?page=${page}&per_page=${per_page}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}
function getpodData(page,data){
 
  return axios({
      method: "get",
      url: `${apiUrl}/podScreen?page=${page}&process_order=${data.process_order}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}

function kanbanviewdata(page,data){
 
  return axios({
      method: "get",
      url: `${apiUrl}/kanbanBoard?page=${page}&process_order=${data.process_order}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}
function ageingreport(data){
 
  return axios({
      method: "get",
      url: `${apiUrl}/ageingreport?user_id=${data.user_id}&tl_id=${data.tl_id}&status=${data.status}&type=${data.type}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}
function ageingdetails(status,ageing,data){
 
  return axios({
      method: "get",
      url: `${apiUrl}/ageingdetail?user_id=${data.user_id}&tl_id=${data.tl_id}&status=${status}&ageing=${ageing}&type=${data.type}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });      

}

function deleteBillingBreakup(id){
  return axios({
    method: "get",
    url: `${apiUrl}/deleteBillingBreakup?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
