import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Radio,
  Modal,
  ModalBody,
  Table,
  Label
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../services/usermanageService";
import { taskmanageService } from "../../services/taskmanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const Useraddeditmaster = (props) => {
  const [inputFields, setInputfields] = useState({
    name: "",
    email: "",
    password: "",
    home_number: "",
    phone_no: "",
    company_address: "",
    users: [], // Add an array to store multiple users
    passport_expiry_date: "",
    passport_doc: null, // Use null to represent the file input
    driving_expiry_date: "",
    driving_doc: null,
    utility_expiry_date: "",
    utility_doc: null,
    servicesTaken: [],
    services : "",
    agreement_date: "",
    billing_spoc : "",
    increment_date : "",
    increment_rate : "",
    notice_period : "",
    last_day_service : "",
    client_referred : "",
    files : [],
    total_offices:"",
    total_emp_size : "",
    client_type : "",
    rates : [],
    refer : [],
    otherdocs : [],
    otherdocs1 : [],
    emailboxData : [],
    changeFile: [],
    marketting_channel:'',
    entity:"",
    director_name:"",
    status:'',
    complianceTaken: [],
    compliance:"",
    departmentTaken: [],
    department:"",
  });
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  let { userid } = useParams();

  const [apiData, setApiData] = useState([]);
  const [servicesData, setservicesData] = useState(['YearEnd','BookKeeping','PayRoll','IT','OffShore','Management Reporting','Hybrid','Admin services','TAT','Back Office','Customer Service','Finance & Accounting','Tax']);
  const [compliancesData, setcomplianceData] = useState(['AML check done','ICB portal updated']);
  const [departmentData, setdepartmentData] = useState(['YearEnd','BookKeeping']);
  useEffect(() => {
    return () => {
      setInputfields({});
    };
  }, []);

  function inputhandler(e) {
    let phseup = {}
    phseup = { ...inputFields, [e.target.name]: e.target.value }    
    setInputfields(phseup);
    if(e.target.name == 'services'){
      const serviceName = e.target.value;
      if (apiData.includes(serviceName)) {
        setApiData(apiData.filter((service) => service !== serviceName));
      } else {
        setApiData([...apiData, serviceName]);
      }
    }
  }
  function inputRateHandler(e, index) {
    const { name, value } = e.target;
    const rates = [...inputFields.rates];
    if (!rates[index]) {
      rates[index] = {}; // Initialize the user object if it doesn't exist
    }
    if(name == 'commitment'){
      rates[index][name] = e.target.checked;
    }else{
      rates[index][name] = value;
    }
    setInputfields({ ...inputFields, rates });
  }
  function handleServicesTakenChange(serviceName) {
    const updatedServicesTaken = [...inputFields.servicesTaken];
   
    if (updatedServicesTaken.includes(serviceName)) {
      updatedServicesTaken.splice(updatedServicesTaken.indexOf(serviceName), 1);
    } else {
      updatedServicesTaken.push(serviceName);
    }
    setInputfields({
      ...inputFields,
      servicesTaken: updatedServicesTaken,
    });
  }
  
const [historymodalModalOpen, sethistorymodalModalOpen] = useState(false);
const [ratehistorymodalModalOpen, setratehistorymodalModalOpen] = useState(false);
const [referhistorymodalModalOpen, setreferhistorymodalModalOpen] = useState(false);
  const styles = {
    'color':'#4a4444',
  };
  useEffect(() => {
    getuser();
    getclienthistory()
    // to resolve the memory leak
    return () => {
    };
  }, []);

const [clienthistory, setclienthistory] = useState([]);
const [showhistory, setshowhistory] = useState(false);
const [ratehistory, setratehistory] = useState([]);
const [referhistory, setreferhistory] = useState([]);
const [emailboxlength, setEmailBoxLength] = useState(0);
function getclienthistory(){
  usermanageService.getclienthistory(userid).then((response) => {
      setclienthistory(response.data.data)
  })
}

  function getuser() {
    usermanageService.getsingleclient(userid).then((response) => {

  const datainitialFormState = { 
          user_id: response.data.user.id,
          name: response.data.user.name,
          email: response.data.user.email,
          home_number: response.data.user.home_number,
          phone_no: response.data.user.phone_no,
          company_address : response.data.user.company_address,
          agreement_date: response.data.user.agreement_date,
          billing_spoc: response.data.user.billing_spoc,
          increment_date: response.data.user.increment_date,
          increment_rate: response.data.user.increment_rate,
          notice_period: response.data.user.notice_period,
          last_day_service: response.data.user.last_day_service,
          client_referred: response.data.user.client_referred,
          passport_expiry_date: response.data.user.passport_expiry_date,
          passport_doc: response.data.user.passport_doc, // Use null to represent the file input
          passport_doc_filename: response.data.user.passport_doc_filename,
          passport_doc_extension: response.data.user.passport_doc_extension,
          driving_expiry_date: response.data.user.driving_expiry_date,
          driving_doc: response.data.user.driving_doc,
          driving_doc_filename: response.data.user.driving_doc_filename,
          driving_doc_extension: response.data.user.driving_doc_extension,
          utility_expiry_date: response.data.user.utility_expiry_date,
          utility_doc: response.data.user.utility_doc,
          utility_doc_filename: response.data.user.utility_doc_filename,
          utility_doc_extension: response.data.user.utility_doc_extension,
          servicesTaken : (response.data.user.services != null) ? response.data.user.services : [],
          rates : response.data.rate_card,
          files : [],
          client_type :  response.data.user.client_type,
          total_emp_size : response.data.user.total_emp_size,
          total_offices : response.data.user.total_offices,
          refer : response.data.refer,
          otherdocs : [],
          otherdocs1 : response.data.otherdocs,
          emailboxData:response.data.emailboxData,
          other_email: response.data.user.other_email,
          changeFile: [],
          marketting_channel : response.data.user.marketting_channel, 
          entity : response.data.user.entity, 
          director_name : response.data.user.director_name,
          status : response.data.user.status, 
          complianceTaken : (response.data.user.compliance != null) ? response.data.user.compliance : [],
          departmentTaken : (response.data.user.relateddepartment != null) ? response.data.user.relateddepartment : [],
      }
      console.log('sa',datainitialFormState)
      setInputfields(datainitialFormState)
      if(response.data.rate_card.length > 0){
        setRateBox(response.data.rate_card.length)
      }
      if(response.data.refer.length > 0){
        setReferBox(response.data.refer.length)
      }
      if(response.data.otherdocs.length > 0){
        setOtherdoc1(response.data.otherdocs.length)
      }
      if(response.data.emailboxData.length > 0){
        setEmailBox(response.data.emailboxData.length)
        setEmailBoxLength(response.data.emailboxData.length)
      }

    
      // setdefault_val(response);
      
    });
  }

  // function setdefault_val(response){
  //   inputFields.name  = response.data.user.name;
  //   inputFields.email = response.data.user.email;
  // }

  function edituser(e) {
    e.preventDefault();
    const selectedServices = inputFields.servicesTaken;
    const selectedcompliance = inputFields.complianceTaken;
    const selecteddepartment = inputFields.departmentTaken;

  
    const requestPayload = {
      ...inputFields,
      services: selectedServices,
      compliance:selectedcompliance,
      department:selecteddepartment

    };
    usermanageService.editclientmaster(requestPayload).then((response) => {
      if(response.status == 200){
          getuser();
          toast.success(response.data.message);
         // location.reload();   
      }else{
          toast.success(response.data.message)
         }  
    }); 
    
  }
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    // Get the selected files from the input element
    const files = Array.from(e.target.files);
    setInputfields({ ...inputFields, ['files']: files } );
  };

  const [rowsError, setRowsError] = useState(0);
 
  const addCommetbox = ()=>{
    const setdata = rowsError+1;
      setRowsError(setdata)
   
  }
  const removeCommetbox = ()=>{
    const setdata = rowsError-1;
      setRowsError(setdata)
  
  }
  const [ratebox, setRateBox] = useState(0);
 
  const addRatebox = ()=>{
    const setdata = ratebox+1;
    setRateBox(setdata)
   
  }
  const removeRatebox = (k,id='')=>{
    if(id!=''){
      usermanageService.archiveratecard(id).then((response) => {
        if(response.status == 200){
       
         toast.success(response.data.message);
         window.location.reload(); 
        }else{
          toast.error(response.data.message);
        }
      });
    }else{
      if(ratebox > 0){
        const setdata = ratebox-1;
        setRateBox(setdata)
      }
    }
    

  }
  function handlePassportDocChange(e) {
    setInputfields({
      ...inputFields,
      passport_doc: e.target.files[0], // Update the file input value
    });
  }
  
  function handleDrivingDocChange(e) {
    setInputfields({
      ...inputFields,
      driving_doc: e.target.files[0], // Update the file input value
    });
  }
  
  function handleUtilityDocChange(e) {
    setInputfields({
      ...inputFields,
      utility_doc: e.target.files[0], // Update the file input value
    });
  }
  function inputReferHandler(e, index) {
    const { name, value } = e.target;
    const refer = [...inputFields.refer];
    if (!refer[index]) {
      refer[index] = {}; // Initialize the user object if it doesn't exist
    }
    refer[index][name] = value;
    setInputfields({ ...inputFields, refer });
  }
  function inputDocHandler(e, index,field){
    
    const otherdocs = [...inputFields.otherdocs];
    if (!otherdocs[index]) {
      otherdocs[index] = {}; // Initialize the user object if it doesn't exist
    }
    if(field == 'files'){
      const files = e.target.files[0];
      otherdocs[index][field] = files;
    }else{
      otherdocs[index][field] = e.target.value;
    }
    
   
    setInputfields({ ...inputFields, otherdocs });
    console.log(inputFields)
  }
  const [referbox, setReferBox] = useState(0);
 
  const addReferbox = ()=>{
    const setdata = referbox+1;
    setReferBox(setdata)
   
  }
  const removeReferbox = ()=>{
    if(referbox > 0){
      const setdata = referbox-1;
      setReferBox(setdata)
    }
  }
  const [otherdoc, setOtherdoc] = useState(0);
 
  const [otherdoc1, setOtherdoc1] = useState(0);
  const addOtherdoc = ()=>{
    const setdata = otherdoc+1;
    setOtherdoc(setdata)
   
  }
  const removeOtherdoc = ()=>{
    if(otherdoc > 0){
      const setdata = otherdoc-1;
      setOtherdoc(setdata)
    }
  }
  const downloadfile = (path, name, extension) => {
    taskmanageService
      .download_file(path, name, extension)
      .then((response) => {});
  };

  const [emailbox, setEmailBox] = useState(0);
 
  const addEmailbox = ()=>{
    const setdata = emailbox+1;
    setEmailBox(setdata)
   
  }
  const removeEmailbox = ()=>{
    if(emailbox > 0){
      const setdata = emailbox-1;
      setEmailBox(setdata)
    }
  }

  function inputemailhandler(e, index) {
    const { name, value } = e.target;
    const emailboxData = [...inputFields.emailboxData];
    if (!emailboxData[index]) {
      emailboxData[index] = {}; // Initialize the user object if it doesn't exist
    }
    emailboxData[index] = value;
    setInputfields({ ...inputFields, emailboxData });
    
  }
  function inputotherDocHandler(e, id, field) {
   
    const changeFile = [...inputFields.changeFile]; 
   
    let fileObject = changeFile.find(obj => obj.id === id);
    if (!fileObject) {
      fileObject = { id, [field]: [] };
      changeFile.push(fileObject);
    } else if (!Array.isArray(fileObject[field])) {
      fileObject[field] = [];
    }
  
    // Get the uploaded files and merge with existing files
    const files = Array.from(e.target.files);
    fileObject[field] = [...fileObject[field], ...files];
  
    // Update the state
    setInputfields(prevState => ({
      ...prevState,
      changeFile
    }));
  
    console.log(changeFile); // Log the updated changeFile array for debugging purposes
  }
  const removeDocument = (id,type)=>{
    swal({
      title: "Are you sure want to remove?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((isConfirm) => {
      if (isConfirm){
    usermanageService.removeDocument(id).then((response) => {
      if(response.status == 200){
     
       toast.success(response.data.message);
       window.location.reload(); 
      }else{
        toast.error(response.data.message);
      }
    });
  }
});
  }
  // function handleComplianceTakenChange(complianceName) {
  //   const updatedServicesTaken = [...inputFields.complianceTaken];
  //   if (updatedServicesTaken.includes(complianceName)) {
  //     updatedServicesTaken.splice(updatedServicesTaken.indexOf(complianceName), 1);
  //   } else {
  //     updatedServicesTaken.push(complianceName);
  //   }
  //   setInputfields({
  //     ...inputFields,
  //     complianceTaken: updatedServicesTaken,
  //   });
  // }
  const handleCheckboxChange = (field, value) => {
    
    const updatedServicesTaken = [...inputFields[field]]; // Get the current array for the given field

    if (updatedServicesTaken.includes(value)) {
        // If the value is already in the array, remove it
        updatedServicesTaken.splice(updatedServicesTaken.indexOf(value), 1);
    } else {
        // Otherwise, add the value to the array
        updatedServicesTaken.push(value);
    }

    // Update the input fields state
    setInputfields({
        ...inputFields,
        [field]: updatedServicesTaken, // Update the specific field with the new array
    });
};

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                <div class="col-md-3"><h2>Edit Client</h2></div>
               
                <div class="col-md-8">
                <div class="row">
                  {/* <div class="col-md-12 offset-md-10">
                    {Object.keys(clienthistory).length > 0 ? 
                  <button type="button" onClick={() => sethistorymodalModalOpen(true) } title="History" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
                    <i class="fas fa-eye" style={{fontSize:"14px"}}></i> History
                  </button>
                  : null }
                    
                  </div> */}
                </div>
                </div>
              </div>
              <br/>
              <Card className="card">
                <Form role="form" id="register-form" onSubmit={edituser}>
                <div className="row">
                  
                    <div className="col-md-6">
                       <FormGroup>
                       <Label>Name</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          
                          <Input
                            defaultValue={inputFields.name}
                            placeholder="Name"
                            type="text"
                            name="name"
                            onChange={inputhandler}
                            required
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-6 d-flex">
                       <FormGroup style={{width:'400px'}}>
                       <Label>Email</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              defaultValue={inputFields.email}
                              placeholder="Email"
                              type="text"
                              name="email"
                              onChange={inputhandler}
                              required
                              readOnly
                            />
                          </InputGroup>
                          
                         
                        </FormGroup>
                        <FormGroup style={{paddingTop:'30px'}}>
                        <a className="btn btn-info"  onClick={(e) => addEmailbox()}>+ </a>
                        </FormGroup>
                    </div>
                    
                    
                 
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < emailbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-4">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                    <Label>Email{k}</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Name"
                            type="text"
                            name="corient_attendees"
                            defaultValue={inputFields.emailboxData[k] ? inputFields.emailboxData[k] : ''}
                            disabled={k < emailboxlength ? 'true' : ''}
                            onChange={(e) => inputemailhandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1" style={{paddingTop:'35px'}}>
      {inputFields.emailboxData < 1 && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeEmailbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      )}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    
               

          <br></br>
          <div className="col-md-4">
                       <FormGroup>
                       <label>Other Email</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                           defaultValue={inputFields.other_email}
                            placeholder="Other Email"
                            type="text"
                            name="other_email"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Company Registration No</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                           defaultValue={inputFields.home_number}
                            placeholder="Company Registration No"
                            type="text"
                            name="home_number"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Phone no.</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Phone number"
                            defaultValue={inputFields.phone_no}
                            type="text"
                            name="phone_no"   
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Company Address</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Address"
                            defaultValue={inputFields.company_address}
                            type="text"
                            name="company_address"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Client type</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <select class="form-control" name="client_type" onChange={inputhandler} value={inputFields.client_type}>
                            <option value="">Select Client type</option>
                            <option value="SME">SME</option>
                            <option value="Accountant">Accountant</option>
                          </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Total Employee Size</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.total_emp_size}
                            placeholder="Total Employee Size"
                            type="number"
                            name="total_emp_size"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Total no. of Offices</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.total_offices}
                            placeholder="Total Offices"
                            type="number"
                            name="total_offices"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Entity</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="entity" onChange={inputhandler} value={inputFields.entity}>
                            <option value="">Select Entity</option>
                            <option value="Corient UK">Corient UK</option>
                            <option value="Corient India">Corient India</option>
                            <option value="Corient USA">Corient USA</option>
                            <option value="E2E">E2E</option>
                            <option value="Cox & Hinkins Limited">Cox & Hinkins Limited</option>
                            <option value="Cox & Hinkins">Cox & Hinkins</option>
                          </select>
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                    <FormGroup>
                       <label>Director Name</label>
                                 <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                    placeholder="Director Name" 
                                    type="text" 
                                    name="director_name" 
                                    value={inputFields.director_name != undefined ? inputFields.director_name : ''}
                                    onChange={inputhandler}
                                   
                                    />
                                </InputGroup>  
                                </FormGroup>   
                              </div>
                              {user_info.role_id=='15' && (
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Status</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="status" onChange={inputhandler} value={inputFields.status}>
                        <option value="">Select Status</option>
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                        </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    )}
                    <div className="col-md-12">KYC</div>
                    
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Passport</label>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Expiry date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            defaultValue={inputFields.passport_expiry_date}
                            placeholder="date"
                            type="date"
                            name="passport_expiry_date"
                            onChange={inputhandler}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <label>Document</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="file"
                            type="file"
                            accept=".pdf, .doc, .docx, .jpeg, .jpg, .png" // Define the accepted file types
                            onChange={handlePassportDocChange} // Handle file input change
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>
                      </div>
                      <div className="col-md-1">
                      {inputFields.passport_doc != null ? (
                         <FormGroup>
                         <label>Download</label>
                          <div
                            className="text-left"
                            onClick={() =>
                              downloadfile(
                                inputFields.passport_doc,
                                inputFields.passport_doc_filename,
                                inputFields.passport_doc_extension
                              )
                            }
                          >
                            <div className="text-muted">
                             <label>&nbsp;</label>
                              <i className="ni ni-cloud-download-95 mr-1"></i>
                            </div>
                          </div>
                        </FormGroup>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4">
                    <br></br>
                    <label>Driving License</label>
                      </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Expiry date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            defaultValue={inputFields.driving_expiry_date}
                            placeholder="date"
                            type="date"
                            name="driving_expiry_date"
                            onChange={inputhandler}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-3">
                       <FormGroup>
                       <label>Document</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <Input
                            placeholder="file"
                            type="file"
                            accept=".pdf, .doc, .docx, .jpeg, .jpg, .png" // Define the accepted file types
                            onChange={handleDrivingDocChange} // Handle file input change
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-1">
                      {inputFields.driving_doc != null ? (
                        <FormGroup>
                        <label>Download</label>
                          <div
                            className="text-left"
                            onClick={() =>
                              downloadfile(
                                inputFields.driving_doc,
                                inputFields.driving_doc_filename,
                                inputFields.driving_doc_extension
                              )
                            }
                          >
                            <div className="text-muted">
                             <label>&nbsp;</label>
                              <i className="ni ni-cloud-download-95 mr-1"></i>
                            </div>
                          </div>
                        </FormGroup>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4">
                    <br></br>
                    <label>Utility Bill</label>
                      </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Expiry date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.utility_expiry_date}
                            placeholder="date"
                            type="date"
                            name="utility_expiry_date"
                            onChange={inputhandler}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-3">
                       <FormGroup>
                       <label> Document</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <Input
                            placeholder="file"
                            type="file"
                            accept=".pdf, .doc, .docx, .jpeg, .jpg, .png" // Define the accepted file types
                            onChange={handleUtilityDocChange} // Handle file input change
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-1">
                      {inputFields.utility_doc != null ? (
                      <FormGroup>
                      <label>Download</label>
                          <div
                            className="text-left"
                            onClick={() =>
                              downloadfile(
                                inputFields.utility_doc,
                                inputFields.utility_doc_filename,
                                inputFields.utility_doc_extension
                              )
                            }
                          >
                            <div className="text-muted">
                             
                              <i className="ni ni-cloud-download-95 mr-1"></i>
                            </div>
                          </div>
                        </FormGroup>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="col-md-12">Any 2 document is required</div> */}
                    <div className="col-md-12">
                    <FormGroup>
                      <label>Services Taken</label><br />
                      {servicesData.map((service, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            name="services"
                            value={service}
                            checked={ inputFields.servicesTaken ? inputFields.servicesTaken.includes(service) : []}
                            onChange={() => handleServicesTakenChange(service)}
                          />
                          &nbsp; {service} &nbsp;
                        </label>
                      ))}
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <FormGroup>
                      <label>Compliance </label><br />
                      {compliancesData && (
                      
                      compliancesData.map((compliance, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            name="compliance"
                            value={compliance}
                            checked={inputFields.complianceTaken?.includes(compliance)}
                            onChange={() => handleCheckboxChange('complianceTaken', compliance)}
                          />
                          &nbsp; {compliance} &nbsp;
                        </label>
                      )))}
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <FormGroup>
                      <label>Department Related </label><br />
                      {departmentData && (
                      
                      departmentData.map((department, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            name="department"
                            value={department}
                            checked={inputFields.departmentTaken?.includes(department)}
                            onChange={() => handleCheckboxChange('departmentTaken', department)}
                            
                          />
                          &nbsp; {department} &nbsp;
                        </label>
                      )))}
                    </FormGroup>
                  </div>
                  <div className="col-md-9">Rate Card </div>
                  {/* {Object.keys(ratehistory).length > 0 ? 
                  <div className="col-md-3"><button type="button" onClick={() => setratehistorymodalModalOpen(true) } title="Rate History" class="btn-icon-clipboard btn btn-secondary " style={{textAlign:"center"}}>
                    <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Rate History
                  </button></div>
                  : null } */}
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < ratebox; k++) {
                arr1.push(
                 <>
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                       <div className="col-md-2">
                        <InputGroup className="input-group-alternative mb-3 ">
                          <Input
                            placeholder="Level"
                            type="text"
                            name="level"
                            defaultValue={inputFields.rates[k] ? inputFields.rates[k].level : ''}
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            required
                          />
                          </InputGroup>
                         </div>
                         <div className="col-md-2">
                          <InputGroup className="input-group-alternative mb-3 ">
                           <Input
                            placeholder="Hours"
                            type="number"
                            name="hour"
                            defaultValue={inputFields.rates[k] ? inputFields.rates[k].hour : ''}
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            required
                          />
                        </InputGroup>
                        </div>
                        <div className="col-md-2">
                          <InputGroup className="input-group-alternative mb-3 ">
                           <Input
                            placeholder="Monthly rate"
                            type="number"
                            name="rate"
                            defaultValue={inputFields.rates[k] ? inputFields.rates[k].rate : ''}
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            required
                          />
                        </InputGroup>
                        </div>
                       
                   
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Notes</label> */}
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Notes"
                            type="text"
                            name="notes"
                            defaultValue={inputFields.rates[k] ? inputFields.rates[k].notes : ''}
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                   
                    <div className="col-md-2">
                       <FormGroup>
                       {/* <label>Commitment</label> */}
                        <InputGroup className="mb-3" style={{marginTop:"10px"}}>
                          <Input
                            placeholder="Commitment"
                            type="checkbox"
                            name="commitment"
                            defaultChecked={inputFields.rates[k] && inputFields.rates[k].commitment === 1}
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            
                          />  Commitment  
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <Col md="1">
      {k >= inputFields.rates.length ? (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRatebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      ) :  <button
      type="button"
        className="btn btn-danger"
        onClick={() => removeRatebox(k,inputFields.rates[k].id)}
      >
        <i className="fa fa-remove"></i>
      </button> }
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRatebox()}>+ Add more rate </a>
          </Col>
                    <div className="col-md-4">
                       <FormGroup>
                       <Label>Start date of agreement</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.agreement_date}
                            placeholder="Start date of agreement"
                            type="date"
                            name="agreement_date"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>marketing channels</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="marketting_channel" onChange={inputhandler}  value={inputFields.marketting_channel}>
                        <option value="">Select </option>
                        <option value="ContactForm">Contact Form</option>
                        <option value="WebsiteOrganic">Website Organic</option>
                        <option value="GoogleAd">Google Ad</option>
                        <option value="Reference">Reference</option>
                        <option value="MailCampaign">Mail Campaign</option>
                        <option value="Accountex">Accountex</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="ColdCall">Cold Call</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Inbound">Inbound</option>
                        <option value="Event">Event</option>
                        <option value="Network">Network</option>
                          </select>
                        </InputGroup>
                      </FormGroup>    
                    </div>  
                    <div className="col-md-4">
                       <FormGroup>
                       <Label>Billing Contact</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.billing_spoc}
                            placeholder="Billing Spoc"
                            type="text"
                            name="billing_spoc"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <Label>Last Increment date</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.increment_date}
                            placeholder="Last Increment date"
                            type="date"
                            name="increment_date"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <Label>Last Increment Rate</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.increment_rate}
                            placeholder="Last Increment Rate"
                            type="text"
                            name="increment_rate"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Notice Period(in days)</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.notice_period}
                            placeholder="Notice Period"
                            type="number"
                            name="notice_period"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Last Service date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                          defaultValue={inputFields.last_day_service}
                            placeholder="Last Service date"
                            type="date"
                            name="last_day_service"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-9">Client Referred</div>
                    {/* {Object.keys(referhistory).length > 0 ? 
                  <div className="col-md-3"><button type="button" onClick={() => setreferhistorymodalModalOpen(true) } title="Rate History" class="btn-icon-clipboard btn btn-secondary " style={{textAlign:"center"}}>
                    <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Client Referred History
                  </button></div>
                  : null } */}
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < referbox; k++) {
                arr1.push(
                 <>
                    <div className="col-md-12">
                       <FormGroup>
                       <div className="row">
                       <div className="col-md-2">
                        <InputGroup className="input-group-alternative mb-3 ">
                          <Input
                            placeholder="Client name"
                            type="text"
                            name="client_name"
                            defaultValue={inputFields.refer[k] ? inputFields.refer[k].client_name : ''}
                            onChange={(e) => inputReferHandler(e, k)}
                            style={styles}
                            required
                          />
                          </InputGroup>
                         </div>
                         <div className="col-md-2">
                          <InputGroup className="input-group-alternative mb-3 ">
                           <Input
                            placeholder="Hours"
                            type="number"
                            name="hours"
                            defaultValue={inputFields.refer[k] ? inputFields.refer[k].hours : ''}
                            onChange={(e) => inputReferHandler(e, k)}
                            style={styles}
                            required
                          />
                        </InputGroup>
                        </div>
                 
                    <Col md="1">
      {k >= inputFields.refer.length && (
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeReferbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      )}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addReferbox()}>+ Add more client Referred  </a>
          </Col>
          <br></br>
          {(() => {
            const arr1 = [];
            for (let k = 0; k < otherdoc1; k++) {
                arr1.push(
                <>
         {inputFields?.otherdocs1 && inputFields.otherdocs1[k] && (
  <>
    <div className="col-md-3">
      <input
        type="file"
        onChange={(e) => inputotherDocHandler(e, inputFields.otherdocs1[k].id, 'editfiles')}
      />
    </div>
    <div className="col-md-3">
      <FormGroup>
        <label>Download</label>
        {/* Display the selected file names */}
        {selectedFiles.map((file, index) => (
          <div key={index}>{file.name}</div>
        ))}
        <div
          className="text-left"
          onClick={() => downloadfile(
            inputFields.otherdocs1[k].doc_path,
            inputFields.otherdocs1[k].file_name,
            inputFields.otherdocs1[k].extension
          )}
        >
          <div className="text-muted">
            <i className="ni ni-cloud-download-95 mr-1"></i>
          </div>
        </div>
      </FormGroup>
    </div>
    <div className="col-md-3">
      <FormGroup>
        <label>Remarks</label>
        <Input
          defaultValue={inputFields.otherdocs1[k].remark || ''}
          placeholder="Remarks"
          type="text"
          style={styles}
          disabled
        />
      </FormGroup>
    </div>
    <div className="col-md-2">
      <button
        type="button"
        className="btn btn-danger ml-2 mt-4"
        onClick={() => removeDocument(inputFields.otherdocs1[k].id)}
      >
        <i className="fa fa-remove"></i>
      </button>
    </div>
  </>
)}


                         </>
          );
      }
      return arr1;
      
  })()}
          {(() => {
            const arr1 = [];
            for (let k = 0; k < otherdoc; k++) {
                arr1.push(
                 <>
          <div className="col-md-3">
                    <label>Other documents</label>
                    <input
                      type="file"
                      
                      onChange={(e) => inputDocHandler(e, k,'files')}
                    />
                   
                      {/* Display the selected file names */}
                      {selectedFiles.map((file, index) => (
                        <div key={index}>{file.name}</div>
                      ))}
                    </div>
                    
                    
                    <div className="col-md-6">
                      <label>Remarks</label>
                    <Input
                         defaultValue={inputFields.otherdocs[k] ? inputFields.otherdocs[k].remark : ''}
                            placeholder="Remarks"
                            type="text"
                            name="remarks"
                            onChange={(e) => inputDocHandler(e, k,'remarks')}
                            style={styles}
                            
                          />
                    </div>
                 

<Col md="1"> <br></br>
{k >= inputFields.otherdocs.length && (
  <button
  type="button"
    className="btn btn-danger"
    onClick={() => removeOtherdoc(k)}
  >
    <i className="fa fa-remove"></i>
  </button>
)}
</Col>
                 
              </>
          );
      }
      return arr1;
      
  })()}
    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addOtherdoc()}>+ Add more document  </a>
          </Col>
   </div>
                <br></br>
              
                <div class="row">
                
                    <div className="col-md-2">
                        <Label>&nbsp;</Label>
                         <Button className="col-12 " color="primary" type="submit">
                            Update Client
                         </Button>
                    </div>
                    {Object.keys(clienthistory).length > 0 && (
                    <div className="col-md-2">
                        <Label>&nbsp;</Label>
                         <Button className="col-12 " color="secondary" type="button" onClick={() => setshowhistory(!showhistory)}>
                            {(showhistory == true) ? "Hide history" : "Show History" }
                         </Button>
                    </div>
                    )}
                </div>  
                <br></br>
                <div style={{ display: showhistory ? 'block' : 'none', height: '300px', overflow: 'auto', boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.2)', }}>
                <br />
                {Object.keys(clienthistory).map((date) => (
                  <div key={date} style={{margin :"0px 30px"}}>
                    {clienthistory[date].map((record) => (
                      <p><b>{date} : </b> {record}</p>
                    ))}
                  </div>
                ))}
              </div>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
        <Modal className="historymodal"  isOpen={historymodalModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              History
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => sethistorymodalModalOpen(!historymodalModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            {Object.keys(clienthistory).map((date) => (
        <div key={date}>
          {/* <h2>{date}</h2> */}
          {clienthistory[date].map((record) => (
            <p><b>{date} : </b>{record}</p>
          ))}
          
        </div>
      ))}

            </ModalBody>
          </Modal>
          {/* <Modal className="historymodal"  isOpen={ratehistorymodalModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Rate History
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setratehistorymodalModalOpen(!ratehistorymodalModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            
{Object.keys(ratehistory).map((date) => (
        <div key={date}>
          <h2>{date}</h2>
          <Table className="align-items-center table-flush" responsive>
            <thead>
              <tr>
                <th>Level</th>
                <th>Hour</th>
                <th>Rate</th>
                <th>Commitment</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
               {ratehistory[date].map((rate) => (
                <tr key={rate.id}>
                  <td>{rate.level}</td>
                  <td>{rate.hour}</td>
                  <td>{rate.rate}</td>
                  <td>{rate.commitment}</td>
                  <td>{rate.notes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
            </ModalBody>
          </Modal>
          <Modal className="historymodal"  isOpen={referhistorymodalModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Client Referred History
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setreferhistorymodalModalOpen(!referhistorymodalModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
            {Object.keys(referhistory).map((date) => (
              <div key={date}>
                <h2>{date}</h2>
                <Table className="align-items-center table-flush" responsive>
                  <thead>
                    <tr>
                      <th>Client Name</th>
                      <th>Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referhistory[date].map((refer) => (
                      <tr key={refer.id}>
                        <td>{refer.client_name}</td>
                        <td>{refer.hours}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
            </ModalBody>
          </Modal> */}
      </Container>
    </>
  );
};

export default Useraddeditmaster;
