import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import Clock from 'react-live-clock';
import {userService} from '../../services/authentication';
import 'assets/css/themecustom-style.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Dropdown 
} from "reactstrap";
import { connect } from "react-redux";
var ps;

const Sidebar = (props) => {
  console.log("side");
  console.log(props)
  console.log
  const [collapseOpen, setCollapseOpen] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const showHideSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const NavWithSubMenu = ({ prop, closeCollapse }) => {}
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => {
      setIsOpen(!isOpen);
    };
    const [submenuOpen, setSubmenuOpen] = useState({});
    const [activeMenu, setActiveMenu] = useState(null);
    // Function to toggle the submenu open/close state
    const toggleSubmenu = (key,e) => {
      e.preventDefault();
      setActiveMenu(activeMenu == key ? null : key);
    
      setSubmenuOpen(prevState => ({
        ...prevState,
        [key]: !prevState[key]
      }));
    };
    const togglemenu = (key,e) => {
      
      setActiveMenu(activeMenu === key ? null : key);
     
    };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
   // Render the menu items
return routes.map((prop, key) => {
  const hasSubmenu = prop.submenu && prop.submenu.length > 0;
  const isActive = activeRoute(prop.layout + prop.path);

  if (hasSubmenu) {
    return (
      <NavItem key={key}>
        <NavLink
          to={prop.layout + prop.path}
          tag={NavLinkRRD}
          //activeClassName="active"
          className={submenuOpen[key] ? "active" : ""}
          onClick={(e) => toggleSubmenu(key,e)}
        >
          <i className={prop.icon} />
          {prop.name}
          <span className="ml-1">
                        <i className="fas fa-angle-down" /> 
                      </span>
        </NavLink>
        {/* Render submenu items if submenu is open */}
        {submenuOpen[key] && (
          <ul>
            {prop.submenu.map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.layout + item.path}
                  tag={NavLinkRRD}
               
                  activeClassName={activeRoute(item.layout + item.path) ? "activesubmenu" : ""}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </NavItem>
    );
  } else {

    return (
      <NavItem key={key}>
        <NavLink
          to={prop.layout + prop.path}
          tag={NavLinkRRD}
          
          className={isActive && (activeMenu == key) ? "active" : ""}
          onClick={(e) => togglemenu(key,e)}
        >
          <i className={prop.icon} />
          {prop.name}
        </NavLink>
      </NavItem>
    );
  }
});

    
    
    // return routes.map((prop, key) => {
    //   return (
    //     // <NavItem key={key}>
    //     //   <NavLink
    //     //     to={prop.layout + prop.path}
    //     //     tag={NavLinkRRD}
    //     //     onClick={closeCollapse}
    //     //     activeClassName="active"
    //     //   >
    //     //     <i className={prop.icon} />
    //     //     {prop.name}
    //     //   </NavLink>
    //     // </NavItem>
    // //     <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
    // //     <DropdownToggle nav>
    // //                   <i className="ni ni-ui-04 d-lg-none mr-1" />
    // //                   <span className="nav-link-inner--text">Components</span>
    // //                 </DropdownToggle>
    // //   <DropdownMenu aria-labelledby="dropdownMenuButton">
    // //     <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
    // //       Action
    // //     </DropdownItem>
    // //     <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
    // //       Another action
    // //     </DropdownItem>
    // //     <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
    // //       Something else here
    // //     </DropdownItem>
      
    // //   </DropdownMenu>
      
    // // </Dropdown>
    // <UncontrolledDropdown nav inNavbar key={key}>
    //   <DropdownToggle nav caret>
    //     <i className={prop.icon} />
    //     {prop.name}
    //   </DropdownToggle>
    //   <DropdownMenu right>
    //     {/* Add your dropdown items here */}
    //     <DropdownItem href={prop.layout + prop.path} onClick={closeCollapse}>
    //       {prop.name}
    //     </DropdownItem>
    //   </DropdownMenu>
    // </UncontrolledDropdown>
    //   );
    // });
  };
console.log(props)
  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  function logout(){
    userService.logout().then((response)=>{
      if(response.status == 200 && response.data.success == true){
         window.location.href = '/';
      }
   }); 
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };
  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };
 

  const handleMenuClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen); // Toggle submenu visibility
  };
  return (
    <>
    {(sidebarOpen == false)?
 <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
     
    >
      <Container fluid>
<button class="navbar-toggler" onClick={showHideSidebar} style={{display:"block"}} type="button"><span class="navbar-toggler-icon"></span></button>
</Container>
</Navbar>
:
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <button class="navbar-toggler" onClick={showHideSidebar} style={{display:"block"}} type="button"><span class="navbar-toggler-icon"></span></button>
 
        <h1>{(user_info?.department == 'Payroll') ? 'UK Payroll Workflow' : 'YearEnd Workflow'}</h1>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0 sider-logo" {...navbarBrandProps}>
            {/* <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            /> */}
            {/* <img
              alt="..."
              className="theme-logo"
              style={{"max-height": "3rem"}}
              src="https://corientbs.hiringbull.com/Home/GetImage?handler=CompanyLogo&companyId=273"
            /> */}
          
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
      
          {/* <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
           
            
              <i className="ni ni-bell-55" />
              <div className="count-badge" style={{right:"7px"}}>
              </div>
            </DropdownToggle>
          </UncontrolledDropdown> */}
          <UncontrolledDropdown nav>
            {/* <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-1-800x800.jpg")
                        .default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle> */}
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={(e) => logout()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  <h4>WorkFlow</h4>
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
            <Input placeholder="Search company..." id="companydatas" className="search-box" type="text"  onChange={props.searchcompany}/>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}
         
        {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Components</span>
                    </DropdownToggle>
      <DropdownMenu aria-labelledby="dropdownMenuButton">
        <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
          Action
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
          Another action
        </DropdownItem>
        <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
          Something else here
        </DropdownItem>
      
      </DropdownMenu>
      
    </Dropdown> */}
    
    {/* <li className="nav-item nav-with-child">
      <a className="nav-link" onClick={handleMenuClick}>
        Multilevel
        {isSubMenuOpen ? (
          <i className="ni ni-bold-down ml-2"></i> // Down arrow icon
        ) : (
          <i className="ni ni-bold-right ml-2"></i> // Right arrow icon
        )}
      </a>
      {isSubMenuOpen && (
        <ul className="nav-item-child">
          <li className="nav-item">
            <a className="nav-link" href="#">
              Multilevel 1
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Multilevel 2
            </a>
          </li>
        </ul>
      )}
    </li> */}
                
          <li className="nav-item">
          <a className="nav-link" href="#" onClick={(e) => logout()}>
          <i className="fas fa-sign-out-alt text-th"></i>Signout</a></li>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
    } </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

// const mapStateToProps = (state) => ({
//   name: state.companysearch.name,
// });
// const mapDispatchToProps = (dispatch) => {
//   return {
//     searchcompany: (e) =>{
//      var company_name = document.getElementById('companydata');
//      if(company_name.value == ''){
//       var company_name = document.getElementById('companydatas');
//      }
//       dispatch({
//         type: "search",
//         payload: company_name.value
//       })
//     }
//   };
// };


export default Sidebar;
// export default Sidebar;
