import { useState, useEffect,useRef } from "react";
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  UncontrolledPopover 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import { manageService } from "../../services/manageaccountservices";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
const axios = require("axios");
import { saveAs } from 'file-saver';
import myImage from "./Pdfdisplayimage.js";
import headImage from "./Pdfheadimage.js";
import {apiUrl} from '../../constant';
import {authtoken} from '../../global.js';

const Billing = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [rowbox, setRowBox] = useState(0);
 
  useEffect(() => {
    // get_billing_details();
    get_unique_companieslist();
    xeroconnectstatus();
  }, []);
  const [billingdetails, setBillingDetails] = useState([]);
  
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
  const [pdfData, setPdfData] = useState(false);
  const [loading, setLoading] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [breakupPopup,setbreakuppopup] = useState(false);
 
 function get_unique_companieslist(){
  
  clientmanageService.getbillingclientlist().then((response)=>{
    if(response.status === 200){
    setUniqueCompaniesData(response.data);
    }
   });
 }
 const [ subclientlist, setsubclientlist ] = useState([])
 
   function subclientList(clientname=''){
     clientmanageService.getsubclientlist(clientname).then((response) => {
         if(response.status === 200){
           setsubclientlist(response.data)
         }else {
           toast.error('Something went wrong!');
         }
     });
   }
   const [clientsearch, setclientsearch] = useState("");
   function setclient(e){
    subclientList(e.target.value)
    // var index = e.target.selectedIndex;
  
     setclientsearch(e.target.value)
    // setclientsearch(e.target.value);
  //   const cloneValues = {...filterFields};
  //   delete cloneValues[2];
  //  setfilterFields(cloneValues);
  }

  const [PushedStatus, setPushedStatus] = useState(false);
  const [filterFields,setfilterFields] = useState({department : "",subclient : "",fromdate : "",todate : "",client : "",description:"Consulting services as agreed (20% off standard rate)",amount:100,quantity:1,account_code:200,tax_rate:''});
  const companyfilter = (e) => {
    
    setfilterFields({ ...filterFields, [e.target.name]: e.target.value } );
    if(e.target.name=='todate'){
      var start_date = filterFields.fromdate;
      var end_date = e.target.value;
      clientmanageService.getbillingclientlist(start_date,end_date).then((response)=>{
        if(response.status === 200){
        setUniqueCompaniesData(response.data);
        }
       });
      
    }
  }
  const [billingdetailsbelowtotal, setBillingDetailsBelowTotal] = useState([]);
  const [billingdetailsbreakup, setBillingDetailsBreakupData] = useState([]);
  const applyfilter = (e) => {
    setLoading(true)
    console.log(filterFields)
    clientmanageService.getbillingdata(filterFields).then((response)=>{
      if (Array.isArray(response.data.data)) {

      setBillingDetails(response.data.data);
      setBillingDetailsBelowTotal(response.data.results1)
      setBillingDetailsBreakupData(response.data.results2)

     }else{
      setBillingDetails([]);
     }
     setPushedStatus(response.data.already_pushed)
     setLoading(false)
    });
  }

  const synctotimesheet = (e) => {
    setdisablestate(true)
        console.log(filterFields)
        clientmanageService.synctotimesheet(filterFields).then((response)=>{
       
          setdisablestate(false)
        });
  }
 
  let totalFinalBillValue = 0;
  let totalbreakupValue  = 0;
 
  const pdfRef = useRef(null);
  
  const [disablestate, setdisablestate] = useState(false);
    const handleDownload = (pushzero) => {
      
      setdisablestate(true)

        clientmanageService.billingDataPDF(filterFields).then((response)=>{
        if(pushzero == 1){

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const date = new Date(filterFields.fromdate);
            const options = { year: 'numeric', month: 'long' };
            const formattedDate = date.toLocaleDateString('en-US', options);

            // Create a FormData object to send the Blob data
            var bodyFormData = new FormData();
            var fname = filterFields.client+'-'+formattedDate+'.pdf';
            bodyFormData.append('pdfFile', pdfBlob, fname);
            bodyFormData.append("billingdata", JSON.stringify(billingdetails));
            bodyFormData.append("fromdate", filterFields.fromdate);
            bodyFormData.append("todate", filterFields.todate);
            bodyFormData.append("department", filterFields.department);
            bodyFormData.append("client", filterFields.client);
            bodyFormData.append("description", filterFields.description);
            bodyFormData.append("quantity", filterFields.quantity);
            bodyFormData.append("amount", filterFields.amount);
            bodyFormData.append("account_code", filterFields.account_code);
            bodyFormData.append("tax_rate", filterFields.tax_rate);

            return axios({
              method: "post",
              url: `${apiUrl}/pushxero`,
              data: bodyFormData,
              headers: { Authorization: `Bearer ${authtoken}` },
            })
              .then(function (response) {
                console.log(response.data.message)
                toast.success(response.data.message);
                settaskdetailPopup(false);
                setdisablestate(false)
                setPushedStatus(true)
              }, error => {
                toast.error(error.response);
              //   console.log(error.response.status);
              })
              .catch(function (error) {
                // console.log(error.response.status);
              });

              }else{
                const outputFilename = `billing-report-${Date.now()}.pdf`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click(); 
              }
        });
        setdisablestate(false)
    
      setdisablestate(false)
    };
    const editField = (value, field, id, index) => {
      taskmanageService.editBillingRow(value, field, id,'timesheet_billing_data').then((response) => {
        let temp_st = [...billingdetails];
        let temp_ele = { ...temp_st[index] };
       
        if(response.status == 200){
            
            toast.success(response.data.message);
            temp_ele[field] = value;
        }else{
            toast.error(response.data.message);
        }
        temp_ele.mainediting = false;
        temp_st[index] = temp_ele;
        setBillingDetails(temp_st);
      });
    };
    const editFieldBelowTotal = (value, field, id, index) => {
      taskmanageService.editBillingRow(value, field, id,'billing_data_breakup').then((response) => {
        let temp_st = [...billingdetailsbelowtotal];
        let temp_ele = { ...temp_st[index] };
       
        if(response.status == 200){
            
            toast.success(response.data.message);
            temp_ele[field] = value;
        }else{
            toast.error(response.data.message);
        }
        temp_ele.mainediting = false;
        temp_st[index] = temp_ele;
        setBillingDetailsBelowTotal(temp_st);
      });
    };

    const handlemaineditRow = (index) => {
        let temp_state = [...billingdetails];
        let temp_element = { ...temp_state[index] };
        temp_element.mainediting = true;
        temp_state[index] = temp_element;
        setBillingDetails(temp_state);
    
    };
    const handlemaineditbelowTotalRow = (index,type) => {
      let temp_state = (type==1) ? [...billingdetailsbelowtotal] : [...billingdetailsbreakup];
      let temp_element = { ...temp_state[index] };
      console.log(temp_element)
      temp_element.mainediting = 1;
      temp_state[index] = temp_element;
      (type==1) ? setBillingDetailsBelowTotal(temp_state) : setBillingDetailsBreakupData(temp_state);

  };

    const billingapprove = () => {
      taskmanageService.approveBilling(billingdetails).then((response) => {
        // let temp_st = [...billingdetails];
        // let temp_ele = { ...temp_st[index] };
       
        if(response.status == 200){
            
            toast.success(response.data.message);
            applyfilter()
            // temp_ele[field] = value;
        }else{
            toast.error(response.data.message);
        }
        // temp_ele.mainediting = false;
        // temp_st[index] = temp_ele;
        // setBillingDetails(temp_st);
      });
    };

    const addRowbox = ()=>{
      const setdata = rowbox+1;
      setRowBox(setdata)
    }

    const removeRowbox = ()=>{
      if(rowbox > 0){
        const setdata = rowbox-1;
        setRowBox(setdata)
      }
    }

    const initialData = {
      addrows : []
    }
    const [inputFields, setInputfields] = useState(initialData);

    function inputrowhandler(e, index) {
      const { name, value } = e.target;
      const addrows = [...inputFields.addrows];
      if (!addrows[index]) {
        addrows[index] = {
          client : filterFields.client,
          date: filterFields.todate
        }; // Initialize the user object if it doesn't exist
      }
      addrows[index][name] = value;
      setInputfields({ ...inputFields, addrows });
    }

   
    function saveRow(e){
      setdisablestate(true)
      // e.preventDefault();
      clientmanageService.addBillingRow(inputFields).then((response)=>{
        if(response.status == 200){
           toast.success(response.data.message); 
           setInputfields(initialData);
           applyfilter()
           setRowBox(0)
        }else{
          console.log(response.data);
           toast.error(response.data.message);
        }
        setdisablestate(false)
      });
    }
    const [xeroconnect, setxeroconnect] = useState(false);
    function xeroConnect(){
      // if(clientsearch){
        if(confirm("Do you want connect xero account")) {
          window.location.href =  apiUrl+'/xeroConnect';
          // clientmanageService.xeroConnect(clientsearch).then((response)=>{
            
          // });
        }
      // }else{
      //   toast.error("Please select Client");
      // }
    }

    const [xeroTenantname, setxeroTenantname] = useState('');
    function xeroconnectstatus(){
      clientmanageService.xeroconnectstatus().then((response)=>{

            if(response){
              setxeroconnect(true)
              setxeroTenantname(response.data.tenant_name)
            }
        });
    }
    function xeroDisconnect(){
      clientmanageService.xeroDisconnect(clientsearch).then((response)=>{
        console.log(response)

        if(response.status == 200){
              
              location.reload();
        
            }
        });
      
    }
    const [rowboxbelowtotal, setRowBoxBelowTotal] = useState(0);
 
    const addRowboxbelowTotal = ()=>{
       const setdata = rowboxbelowtotal+1;
       setRowBoxBelowTotal(setdata)
    }
    const removeBelowTotalRowbox = ()=>{
      if(rowboxbelowtotal > 0){
        const setdata = rowboxbelowtotal-1;
        setRowBoxBelowTotal(setdata)
      }
    }
    const [rowbreakup,setRowBreakup]= useState(0);
    const addRowBreakup = ()=>{
      const setdata = rowbreakup+1;
      setRowBreakup(setdata)
   }
   const removeRowBreakup = ()=>{
     if(rowbreakup > 0){
       const setdata = rowbreakup-1;
       setRowBreakup(setdata)
     }
   }
    const initialDatabelowtotal = {
      addrows : []
    }
    const [inputfieldsbelow,setInputfieldsbelowtotal] =useState(initialDatabelowtotal);
    function saveRowBelowTotal(type){
      console.log(inputfieldsbelow)
      clientmanageService.addBillingRowbelowtotal(inputfieldsbelow,type).then((response)=>{
        if(response.status == 200){
           toast.success(response.data.message); 
           setInputfields(initialData);
           applyfilter()
           setRowBox(0)
           setRowBreakup(0)
           setRowBoxBelowTotal(0)
        }else{
          console.log(response.data);
           toast.error(response.data.message);
        }
        setdisablestate(false)
      });
    }
    function inputrowhandlerbelowtotal(e, index,type) {
     
      const { name, value } = e.target;
      const addrows = [...inputfieldsbelow.addrows];
      if (!addrows[index]) {
        addrows[index] = {
          client : filterFields.client,
          date: filterFields.todate,
          department: filterFields.department,
          type:type
        }; 
      }
      
      addrows[index][name] = value;
      setInputfieldsbelowtotal({ ...inputfieldsbelow, addrows });
    }
    console.log(billingdetailsbreakup)

    const deletebreakuprow = (id,type) => {
      taskmanageService.deleteBillingBreakup(id).then((response) => {
          if (response.status == 200) {
              toast.success(response.data.message);
              if(type == 'total'){
              const updatedBillingDetails = billingdetailsbelowtotal.filter(
                (item) => item.id !== id
              );
              // Update the state with the filtered list
              setBillingDetailsBelowTotal(updatedBillingDetails);
              }
              if(type == 'breakup'){
                const updatedBillingBreakup = billingdetailsbreakup.filter(
                  (item) => item.id !== id
                );
                // Update the state with the filtered list
                setBillingDetailsBreakupData(updatedBillingBreakup);
              }
              
             
            } else {
              
            }
      });
    }
  return (
    <>
    <Header />
    
      <Container className="mt--7" fluid>
        <Row>
        <div className="col-md-12" style={{textAlign:'right'}}>
      
                        </div>
                        </Row>
                        <Row>
                
                <div className="col-xl-12 col-md-12 col-xs-12 d-flex" >
                        
                        {xeroconnect ? 
                        <Button
                          className="dash-button "
                          color="primary"
                          type="button"
                         
                        >
                          {" "}
                          
                         
                          <span>Xero connected to {xeroTenantname}</span>
                         
                         
                          
                        </Button> :
                        <Button
                        className="dash-button "
                        color="primary"
                        type="button"
                        onClick={() => xeroConnect()}
                      >
                        {" "}
                        
                       Xero Connect
                      </Button>}
                        {xeroconnect ? 
                        <>
                        
                        <Button
                        className="dash-button "
                        color="primary"
                        type="button"
                        onClick={() => xeroDisconnect()}
                      >
                        {" "}
                      disconnect
                      </Button></> : ""}
                        </div>
                        </Row>
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter">
               <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="mb-0" style={{fontSize:'20px'}}> Billing Data &nbsp;&nbsp;</h3><br></br>
                    </div>
                    <div className="col-xl-2 col-md-2 col-xs-12">
                    <h5 className="clientformtag"> Department</h5>
                        <select class="form-control" name="department"  onChange={(e) => companyfilter(e)} required>
                              <option value=""> All Department</option>
                                <option value="Year End">Year End</option>
                                <option value="bookkeeping">Book-keeping</option>
                              </select>
                            
                  </div>
                    
                    
                        <div className="col-xl-3 col-md-2 col-xs-12">
                  <h5 className="clientformtag"> Month</h5>
                        <input type="month"
                                      className="form-control"
                                      name="todate"
                                      onChange={(e) =>
                                          companyfilter(e)
                                        }
                                    
                                        
                                      />
                        </div>
                        <div className="col-xl-2 col-md-2 col-xs-12">
                  <h5 className="clientformtag"> Client</h5>
                        {(uniquecompanieslist.length > 0)?
                              <select className="form-control" value={clientsearch} name="client" onChange={(e) => { companyfilter(e),setclient(e);}}   >
                                  <option value="" key="al_key_1">All Clients</option>
                                  {(uniquecompanieslist).map((valuel,k) =>
                                      <option key={k} value={valuel.client}>
                                        {valuel.client}
                                      </option>
                                  )}
                              </select>
                            : null }
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                          <br></br>
                         
                        <Button
                          className="dash-button "
                          color="primary"
                          type="button"
                          onClick={() => applyfilter()}
                        >
                          {" "}
                         Show
                        </Button>{" "}
                    
                        </div>
                        
                    </div>
                   
                </Row>
                <br></br>
                <Row>
                
                <div className="col-xl-12 col-md-12 col-xs-12 d-flex" >
                          
                         
                        <Button
                          className="dash-button "
                          color="secondary"
                          type="button"
                          disabled={disablestate}
                          onClick={() => synctotimesheet()}
                        >
                          {" "}
                          {disablestate ?  'Syncing' : 'Sync to timesheet' }
                        </Button>{" "}
                        
                        </div>
                        </Row>
                {(loading == true) ? 
                      <><br></br><a style={{color:"#5d6cae"}}  >Downloading please wait.. </a></>
                      :
                      <>
                <row>
                  <br></br>
                { billingdetails.length > 0 ?
                <>
                    <Button
            className="dash-button"
            color="danger"
            type="button"
            
            onClick={() => handleDownload(0)}
            
          >
            Export to PDF
          </Button>
          {/* <Button className="dash-button"
            color="danger"
            type="button" onClick={handleDownload}>Download</Button> */}
            {/* <Button
            className="dash-button"
            color="danger"
            type="button"
            onClick={exportToExcel}
          >
            Export to Excel
          </Button> */}
          {xeroconnect && PushedStatus == false ? 
          <Button
              className="dash-button"
              color="danger"
              type="button"
              onClick={() => settaskdetailPopup(true)}
            >
              {" "}
            Push to xero
            </Button>:""}
            {xeroconnect && PushedStatus == true ? 
          <Button
              className="dash-button"
              color="success"
              style={{backgroundColor: '#2ab14e'}}
              type="button"
            >
              {" "}
            Already Pushed to Xero
            </Button>:""}
          </>
                  : null }
                </row>
                
                <br></br>
                { billingdetails.length > 0 ?
                <>
              <br></br>
             <h3><b>Accountant Name : {filterFields.client}</b></h3>
             <h3><b>Date : {filterFields.fromdate} - {filterFields.todate}</b></h3>
             <button type="button" onClick={() => billingapprove()}  className=" btn btn-success text-center">
               Approve
              </button>  
             </>
             
                  : null }
              <Table className="align-items-center table-flush" responsive>
               
              { billingdetails.length > 0 ?
                <>
                 <thead className="thead-light">
                    <th>Client</th> 
                    {/* <th scope="col"  className="whitespace sortclass" onClick={(e) => sortTable('sub_client')}>Sub Client   
                   </th> */}
                     {/* <th scope="col" className="whitespace">Actual Hours</th>
                    <th scope="col" className="whitespace">Billed Hours</th> */}
                    <th scope="col" className="whitespace">Billing hours</th>
                    <th scope="col" className="whitespace">Final Billing hours</th>
                    <th scope="col" className="whitespace">Services</th>
                    <th scope="col" className="whitespace">Billing Type</th>
                    <th scope="col" className="whitespace">Approval</th>
                </thead>
                </>
                  : null }
                <tbody>
                {billingdetails.length > 0 ? (
                  <>
                    {billingdetails.map((item, i) => {
                      totalFinalBillValue += parseFloat(item.edited_billed_hours); // Calculate cumulative sum

                      return (
                        <tr key={i} className={i % 2 === 0 ? 'blue-row' : i % 3 === 0 ? 'purple-row' : 'orange-row'}>
                          {/* <td style={{ cursor: 'pointer' }}>{item.client}</td> */}
                          <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={(item.subclient_editable != null) ? item.subclient_editable : item.subclient}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "subclient_editable",
                                    item.id,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{(item.subclient_editable != null) ? item.subclient_editable : item.subclient}</span>
                            )}
                          </td>
                          {/* <td style={{ cursor: 'pointer' }}>{item.subclient_editable}</td> */}
                          <td>{item.finalbillval.toFixed(2).replace(/\.00$/, '')}</td>
                          <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="number"
                              className="form-control"
                              defaultValue={item.edited_billed_hours.toFixed(2).replace(/\.00$/, '')}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "edited_billed_hours",
                                    item.id,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.edited_billed_hours.toFixed(2).replace(/\.00$/, '')}</span>
                            )}
                          </td>
                          <td>{item.services}</td>
                          <td>{item.billingtype}</td>
                          <td>
                          <span
                                  color="primary"
                                  style={{
                                    color:
                                      item.approval == 1
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >
                                  {(item.approval == 1) ? 'Approved' : ''}
                                </span></td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={3}>No Data Found!</td>
                  </tr>
                )}
                   { billingdetails.length > 0 ?
                   <>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < rowbox; k++) {
                arr1.push(
                 <>
                  <tr className={k % 2 === 0 ? 'blue-row' : k % 3 === 0 ? 'purple-row' : 'orange-row'}>
                    <td>
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Sub client"
                              type="text"
                              name="sub_client"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    <td >
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Billing hours"
                              type="number"
                              name="finalbillval"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    <td>
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Final billing hours"
                              type="number"
                              name="edited_billed_hours"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    {/* <td>
                      <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="date"
                              type="date"
                              name="date"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td> */}
                  <td>
                  <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                  <select class="form-control" name="department"  onChange={(e) => inputrowhandler(e,k)} >
                              <option value=""> All Department</option>
                                <option value="Year End">Year End</option>
                                <option value="bookkeeping">Book-keeping</option>
                              </select>
                              </InputGroup>
                        </FormGroup>      
                  </td>
                    
                    <td >
      {/* {k > 0 && ( */}
      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
     
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRowbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
        </InputGroup>
                        </FormGroup>      
      {/* )} */}
    </td>
                  </tr> 
                    </>
                );
            }
            return arr1;
            
        })()}
          <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRowbox()}>+ Add Row </a>
            { rowbox > 0 ?
            <button
              type="button"
              className="btn btn-success"
              onClick={() => saveRow()}
            ><i className="fa fa-file"></i> Save
            </button>
            : null }
          </Col>
        </>
: null }
               
                
                { billingdetails.length > 0 ?
                <>
                            <tr>
                              <td align="left"><b>Total</b></td>
                              <td></td>
                              <td> <b>{totalFinalBillValue.toFixed(2)}</b></td>
                              <td colSpan={2}></td>
                            </tr>
                            </>
                  : null }
                  {
            billingdetails.length > 0 && billingdetailsbelowtotal.length > 0 ?
            <>
           
            {billingdetailsbelowtotal.map((item,i)=>{
            return ( <tr key={i} className={i % 2 === 0 ? 'blue-row' : i % 3 === 0 ? 'purple-row' : 'orange-row'}>
             {/* <td style={{ cursor: 'pointer' }}>{item.client}</td> */}
             <td  onClick={()=> handlemaineditbelowTotalRow(i,item.type)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={(item.remark != null) ? item.remark : ''}
                              
                                onBlur={(e) =>
                                  editFieldBelowTotal(
                                    e.target.value,
                                    "remark",
                                    item.id,
                                    i,
                                    
                                  )
                                }
                              />
                            ) : (
                              <span>{(item.remark != null) ? item.remark : ''}</span>
                            )}
                          </td>
             {/* <td style={{ cursor: 'pointer' }}>{item.remark}</td> */}
             <td  onClick={()=> handlemaineditbelowTotalRow(i,item.type)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={(item.value != null) ? item.value : ''}
                              
                                onBlur={(e) =>
                                  editFieldBelowTotal(
                                    e.target.value,
                                    "value",
                                    item.id,
                                    i,
                                    
                                  )
                                }
                              />
                            ) : (
                              <span>{(item.value != null) ? item.value : ''}</span>
                            )}
              </td>
              <td>  
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => deletebreakuprow(item.id,'total')}
        >
          <i className="fa fa-remove"></i>
        </button>
                       </td>
             {/* <td style={{ cursor: 'pointer' }}>{item.value}</td> */}
             </tr>)

            })}
            </>:""
          } 
                 { billingdetails.length > 0 ?
                   <>
                  {(() => {
            const arr2 = [];
            for (let j = 0; j < rowboxbelowtotal; j++) {
                arr2.push(
                 <>
                  <tr className={j % 2 === 0 ? 'blue-row' : j % 3 === 0 ? 'purple-row' : 'orange-row'}>
                    <td>
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Remark"
                              type="text"
                              name="remark"
                              onChange={(e) => inputrowhandlerbelowtotal(e, j,1)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    <td >
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Value"
                              type="number"
                              name="value"
                              onChange={(e) => inputrowhandlerbelowtotal(e, j,1)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>

                    
                    <td >
      {/* {k > 0 && ( */}
      
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeBelowTotalRowbox(j)}
        >
          <i className="fa fa-remove"></i>
        </button>   
      {/* )} */}
    </td>
                  </tr> 
                    </>
                );
            }
            return arr2;
            
        })()}
          <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRowboxbelowTotal()}>+ Add Row </a>
            { rowboxbelowtotal > 0 ?
            <button
              type="button"
              className="btn btn-success"
              onClick={() => saveRowBelowTotal(1)}
            ><i className="fa fa-file"></i> Save
            </button>
            : null }
          </Col>
          <Col md="12" style={{marginBottom:"10px"}}>
            
           
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setbreakuppopup(true)}
            > Add Break-up
            </button>
           
          </Col>
        </>
: null }
                  
              </tbody>
               
              </Table>
              </>
}
              </CardHeader>
              <Row>
              <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Push data to xero</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
                      <Row>
                      <Col xl="4">
                        <FormGroup>
                          <Input
                              
                              name="description"
                              type="text"
                              placeholder="description"
                              value={inputFields.description}
                              onChange={(e) => companyfilter(e)}
                            >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xl="1">
                        <FormGroup>
                          <Input
                              
                              name="quantity"
                              type="number"
                              placeholder="quantity"
                              onChange={(e) => companyfilter(e)}
                              value={inputFields.quantity}
                            >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                        <FormGroup>
                          <Input
                              
                              name="amount"
                              type="number"
                              placeholder="unit amount"
                              value={inputFields.amount}
                              onChange={(e) => companyfilter(e)}
                            >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                        <FormGroup>
                          <select class="form-control" name="account_code" value={inputFields.account_code}  onChange={(e) => companyfilter(e)}>
                            <option value="">Select Account Code</option>
                            <option value="1005">Movement in WIP</option>
                            <option value="4000">Sales</option>
                            <option value="4001">Sales Accountants y/e</option>
                            <option value="4002">Sales Payroll</option>
                            <option value="4003">SME Processing</option>
                            <option value="4004">Sales Accountants Book Keeping</option>
                            <option value="4010">Consultancy Fees (4010)</option>
                            <option value="4011">Other Revenue</option>
                            <option value="4900">Software Sales</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                        <FormGroup>
                          <select class="form-control" name="tax_rate" value={inputFields.account_code}  onChange={(e) => companyfilter(e)}>
                            <option value="">Select Tax Rate</option>
                            <option value="20% (VAT on Income)">20% (VAT on Income)</option>
                            <option value="5% (VAT on Income)">5% (VAT on Income)</option>
                            <option value="Exempt Income">Exempt Income</option>
                            <option value="No VAT">No VAT</option>
                            <option value="Zero Rated EC Goods Income">Zero Rated EC Goods Income</option>
                            <option value="Zero Rated EC Services">Zero Rated EC Services</option>
                            <option value="Zero Rated Income">Zero Rated Income</option>
                            
                          </select>
                        </FormGroup>
                      </Col>
                      </Row>
            <Button className="mt-2" color="primary" type="submit" disabled={disablestate} onClick={() => handleDownload(1)}>  {disablestate ?  'Sending' : 'Send' }</Button>
            </ModalBody>
            </Modal>
            <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setbreakuppopup(!breakupPopup)}
            isOpen={breakupPopup}
          >
            <div className="modal-header">
            <h3>Breakup of c/f hours -</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setbreakuppopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
                      <Row>
                      <Table className="align-items-center table-flush" responsive>
               
              { billingdetails.length > 0 ?
                <>
                 <thead className="thead-light">
                    <th>Month</th> 
                    <th scope="col" className="whitespace">hours</th>

                </thead>
                </>
                  : null }
                <tbody>
               

               
                
          
                  {
             billingdetailsbreakup.length > 0 ?
            <>
           
            {billingdetailsbreakup.map((item,i)=>{
              totalbreakupValue += parseFloat(item.value);
            return ( <tr key={i} className={i % 2 === 0 ? 'blue-row' : i % 3 === 0 ? 'purple-row' : 'orange-row'}>
             {/* <td style={{ cursor: 'pointer' }}>{item.client}</td> */}
             {/* <td style={{ cursor: 'pointer' }}>{item.remark}</td> */}
             <td  onClick={()=> handlemaineditbelowTotalRow(i,item.type)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={(item.remark != null) ? item.remark : ''}
                              
                                onBlur={(e) =>
                                  editFieldBelowTotal(
                                    e.target.value,
                                    "remark",
                                    item.id,
                                    i,
                                    
                                  )
                                }
                              />
                            ) : (
                              <span>{(item.remark != null) ? item.remark : ''}</span>
                            )}
              </td>
              <td  onClick={()=> handlemaineditbelowTotalRow(i,item.type)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={(item.value != null) ? item.value : ''}
                              
                                onBlur={(e) =>
                                  editFieldBelowTotal(
                                    e.target.value,
                                    "value",
                                    item.id,
                                    i,
                                    
                                  )
                                }
                              />
                            ) : (
                              <span>{(item.value != null) ? item.value : ''}</span>
                            )}
              </td>
              <td>  
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => deletebreakuprow(item.id,'breakup')}
        >
          <i className="fa fa-remove"></i>
        </button>
                       </td>
             </tr>)

            })}
            </>:(
                  <tr>
                    {rowbreakup.length==0 ?
                    <td colSpan={3}>No Data Found!</td>:''
                    }
                  </tr>
                )
          } 
                 { billingdetails.length > 0 ?
                   <>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < rowbreakup; k++) {
                arr1.push(
                 <>
                  <tr className={k % 2 === 0 ? 'blue-row' : k % 3 === 0 ? 'purple-row' : 'orange-row'}>
                    <td>
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Month"
                              type="text"
                              name="remark"
                              onChange={(e) => inputrowhandlerbelowtotal(e, k,2)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    <td >
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Hours"
                              type="number"
                              name="value"
                              onChange={(e) => inputrowhandlerbelowtotal(e, k,2)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>

                    
                    <td >
      {/* {k > 0 && ( */}
      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
     
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRowBreakup(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
        </InputGroup>
                        </FormGroup>      
      {/* )} */}
    </td>
                  </tr> 
                    </>
                );
            }
            return arr1;
            
        })()}
              
                <>
                            <tr>
                              <td align="left"><b>Total</b></td>
                              
                              <td> <b>{totalbreakupValue.toFixed(2)}</b></td>
                              <td colSpan={2}></td>
                            </tr>
                            </></>
                  : null }
          <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRowBreakup()}>+ Add Row </a>
            { rowbreakup > 0 ?
            <button
              type="button"
              className="btn btn-success"
              onClick={() => saveRowBelowTotal(2)}
            ><i className="fa fa-file"></i> Save
            </button>
            : null }
          </Col>
          
        

                  
              </tbody>
               
              </Table>
                      </Row>
           
            </ModalBody>
            </Modal>
              </Row>
              

              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
          </div>
        </Row>
        
      </Container>
    
    </>
  );
};


export default Billing;
