import React, { useState, useEffect } from "react";
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import Header from "components/Headers/Header.js";
import {
    Container,
    Row,
    Col,
    Table
} from "reactstrap";
import { clientmanageService } from "services/clientmanageService";
const GovernanceChart = ()=>{
    const today = new Date();
    const currentMonth = today.toISOString().slice(0, 7);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    useEffect(() => {
        getData(selectedMonth);
    }, []);
    const[chartdata,setChartData ]= useState([]);
    const getData = (month)=>{
        clientmanageService.governanceChartData(month).then((response) => {
            console.log("dee")
            console.log(response.data.data)
            if (response.status === 200) {
            const chartData1 = Object.values(response.data.data);
            setChartData(chartData1)
            }
        });
    }
    const handleChange = (e) => {
        setSelectedMonth(e.target.value);
        getData(e.target.value);
      };

      const renderLabel = ({ name, value }) => {
        return `${name}: ${value}`;
      };

    return (
        <>
        <Header />
        <Container className="mt--7" fluid>
        <Row>
            <Col xl="3">
            <h5 className="clientformtag">Select Month</h5>
                    <input type="month"
                                    className="form-control"
                                    name="month"
                                    value={selectedMonth}
                                    onChange={(e) => { handleChange(e);}}
                                    

                                    />
            </Col>
        </Row>
        <Row className="">
        <Col xl="12">
        {chartdata.length > 0 ? (
        <PieChart width={500} height={500}>
      <Pie
        data={chartdata}
        cx={250} // Center X
        cy={170} // Center Y
        label={renderLabel} // Custom label rendering function
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
      >
        {chartdata.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} /> // Use the color from the data
                ))}
      </Pie>
      <Tooltip />
        </PieChart>
        ) : (
            <div className="mt-5">No data available to display the pie chart.</div> 
        )}
        </Col>
        </Row>
        </Container>
        </>
    );
}
export default GovernanceChart;