import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Radio
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../services/usermanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';

const Useraddmaster = (props) => {
  const initialData = {
    name: "",
    email: "",
    home_number: "",
    phone_no: "",
    company_address: "",
    users: [], // Add an array to store multiple users
    passport_expiry_date: "",
    passport_doc: null, // Use null to represent the file input
    driving_expiry_date: "",
    driving_doc: null,
    utility_expiry_date: "",
    utility_doc: null,
    servicesTaken: [],
    services : "",
    agreement_date: "",
    billing_spoc : "",
    increment_date : "",
    increment_rate : "",
    files : [],
    total_offices:"",
    total_emp_size : "",
    client_type : "",
    rates : [],
    refer : [],
    otherdocs : [],
    marketting_channel:"",
    entity:"",
    director_name:"",
    status:1,
    complianceTaken: [],
    compliance:"",
    other_email:'',
    departmentTaken: ["YearEnd"],
    department:"YearEnd",
  };
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [inputFields, setInputfields] = useState(initialData);
  const history = useHistory();
  function inputUserHandler(e, index) {
    const { name, value } = e.target;
    const users = [...inputFields.users];
    if (!users[index]) {
      users[index] = {}; // Initialize the user object if it doesn't exist
    }
    users[index][name] = value;
    setInputfields({ ...inputFields, users });
  }
  function inputRateHandler(e, index) {
    const { name, value } = e.target;
    const rates = [...inputFields.rates];
    if (!rates[index]) {
      rates[index] = {}; // Initialize the user object if it doesn't exist
    }
    if(name == 'commitment'){
      rates[index][name] = e.target.checked;
    }else{
      rates[index][name] = value;
    }
    setInputfields({ ...inputFields, rates });
  }
  function inputReferHandler(e, index) {
    const { name, value } = e.target;
    const refer = [...inputFields.refer];
    if (!refer[index]) {
      refer[index] = {}; // Initialize the user object if it doesn't exist
    }
    refer[index][name] = value;
    setInputfields({ ...inputFields, refer });
  }
  function handleServicesTakenChange(serviceName) {
    const updatedServicesTaken = [...inputFields.servicesTaken];
    if (updatedServicesTaken.includes(serviceName)) {
      updatedServicesTaken.splice(updatedServicesTaken.indexOf(serviceName), 1);
    } else {
      updatedServicesTaken.push(serviceName);
    }
    setInputfields({
      ...inputFields,
      servicesTaken: updatedServicesTaken,
    });
  }
  // function handleComplianceTakenChange(complianceName) {
  //   const updatedServicesTaken = [...inputFields.complianceTaken];
  //   if (updatedServicesTaken.includes(complianceName)) {
  //     updatedServicesTaken.splice(updatedServicesTaken.indexOf(complianceName), 1);
  //   } else {
  //     updatedServicesTaken.push(complianceName);
  //   }
  //   setInputfields({
  //     ...inputFields,
  //     complianceTaken: updatedServicesTaken,
  //   });
  // }
  // function handleDepartmentTakenChange(departmentName) {
  //   const updatedDepartmentTaken = [...inputFields.complianceTaken];
  //   if (updatedDepartmentTaken.includes(complianceName)) {
  //     updatedDepartmentTaken.splice(updatedDepartmentTaken.indexOf(departmentName), 1);
  //   } else {
  //     updatedDepartmentTaken.push(departmentName);
  //   }
  //   setInputfields({
  //     ...inputFields,
  //     departmentTaken: updatedDepartmentTaken,
  //   });
  // }
  const handleCheckboxChange = (field, value) => {
    setInputfields(prevState => {
      const updatedArray = prevState[field].includes(value)
        ? prevState[field].filter(item => item !== value)
        : [...prevState[field], value];
      
      return {
        ...prevState,
        [field]: updatedArray
      };
    });
  };
  function inputDocHandler(e, index,field){
    
    const otherdocs = [...inputFields.otherdocs];
    if (!otherdocs[index]) {
      otherdocs[index] = {}; // Initialize the user object if it doesn't exist
    }
    if(field == 'files'){
      const files = e.target.files[0];
      otherdocs[index][field] = files;
    }else{
      otherdocs[index][field] = e.target.value;
    }
   
    setInputfields({ ...inputFields, otherdocs });
    console.log(inputFields)
  }
  function inputhandler(e) {
    let phseup = {}
    phseup = { ...inputFields, [e.target.name]: e.target.value } 
    //console.log(phseup)   
    setInputfields(phseup);
    if(e.target.name == 'services'){
      const serviceName = e.target.value;
      if (apiData.includes(serviceName)) {
        setApiData(apiData.filter((service) => service !== serviceName));
      } else {
        setApiData([...apiData, serviceName]);
      }
    }
   // console.log(inputFields)
  }
  const [apiData, setApiData] = useState([]);
  const [servicesData, setservicesData] = useState(['YearEnd','BookKeeping','PayRoll','IT','OffShore','Management Reporting','Hybrid','Admin services','TAT','Back Office','Customer Service','Finance & Accounting','Tax']);
  const [compliancesData, setcomplianceData] = useState(['AML check done','ICB portal updated']);
  const [departmentData, setdepartmentData] = useState(['YearEnd','BookKeeping']);
  
  useEffect(() => {
    return () => {
      setInputfields({});
    };
  }, []);

  function adduser(e,type=''){
    e.preventDefault();
    const selectedServices = inputFields.servicesTaken;
    const selectedcompliance = inputFields.complianceTaken;
    const selecteddepartment = inputFields.departmentTaken;

    // if (
    //   (inputFields.passport_doc && inputFields.driving_doc) ||
    //   (inputFields.passport_doc && inputFields.utility_doc) ||
    //   (inputFields.driving_doc && inputFields.utility_doc) || type == 'draft'
    // ) {
      const requestPayload = {
        ...inputFields,
        services: selectedServices,
        compliance:selectedcompliance,
        department:selecteddepartment
      };
      
        usermanageService.addclientmaster(requestPayload).then((response)=>{
          if(response.status == 200){
            toast.success(response.data.message); 
            document.getElementById("adduser-form").reset();
            history.push('users');
          }else{
            console.log(response.data);
            toast.error(response.data.message);
          }
        });
    // } else {
    //   toast.error("Please upload at least two KYC documents.");
    // }
  }
  const styles = {
    'color':'#4a4444',
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    // Get the selected files from the input element
    const files = Array.from(e.target.files);
    setInputfields({ ...inputFields, ['files']: files } );
  };

  const [rowsError, setRowsError] = useState(0);
 
  const addCommetbox = ()=>{
    const setdata = rowsError+1;
      setRowsError(setdata)
   
  }
  const removeCommetbox = ()=>{
    const setdata = rowsError-1;
      setRowsError(setdata)
  
  }
  const [ratebox, setRateBox] = useState(0);
 
  const addRatebox = ()=>{
    const setdata = ratebox+1;
    setRateBox(setdata)
   
  }
  const removeRatebox = ()=>{
    if(ratebox > 0){
      const setdata = ratebox-1;
      setRateBox(setdata)
    }
  }
  const [referbox, setReferBox] = useState(0);
 
  const addReferbox = ()=>{
    const setdata = referbox+1;
    setReferBox(setdata)
   
  }
  const removeReferbox = ()=>{
    if(referbox > 0){
      const setdata = referbox-1;
      setReferBox(setdata)
    }
  }
  function handlePassportDocChange(e) {
    setInputfields({
      ...inputFields,
      passport_doc: e.target.files[0], // Update the file input value
    });
  }
  
  function handleDrivingDocChange(e) {
    setInputfields({
      ...inputFields,
      driving_doc: e.target.files[0], // Update the file input value
    });
  }
  const [otherdoc, setOtherdoc] = useState(0);
 
  const addOtherdoc = ()=>{
    const setdata = otherdoc+1;
    setOtherdoc(setdata)
   
  }
  const removeOtherdoc = ()=>{
    if(otherdoc > 0){
      const setdata = otherdoc-1;
      setOtherdoc(setdata)
    }
  }
  function handleUtilityDocChange(e) {
    setInputfields({
      ...inputFields,
      utility_doc: e.target.files[0], // Update the file input value
    });
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Add Client</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                        <Buttoncomponent/>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
                <Form role="form" id="adduser-form" onSubmit={adduser}>
                <div className="row">
                    <div className="col-md-4">
                       <FormGroup>
                        <label>Client Name</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Client Name"
                            type="text"
                            name="name"
                            onChange={inputhandler}
                            style={styles}
                            required
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Email</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Email"
                              type="text"
                              name="email"
                              onChange={inputhandler}
                              style={styles}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Other Email</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                           defaultValue={inputFields.other_email}
                            placeholder="Other Email"
                            type="text"
                            name="other_email"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Company Registration No</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Company Registration No"
                            type="text"
                            name="home_number"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Phone no.</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Phone number"
                            type="text"
                            name="phone_no"   
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Company Address</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Address"
                            type="text"
                            name="company_address"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Client type</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <select class="form-control" name="client_type" onChange={inputhandler}>
                            <option value="">Select Client type</option>
                            <option value="SME">SME</option>
                            <option value="Accountant">Accountant</option>
                          </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Total Employee Size</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Total Employee Size"
                            type="number"
                            name="total_emp_size"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Total no. of Offices</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Total Offices"
                            type="number"
                            name="total_offices"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Entity</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="entity" onChange={inputhandler}>
                            <option value="">Select Entity</option>
                            <option value="Corient UK">Corient UK</option>
                            <option value="Corient India">Corient India</option>
                            <option value="Corient USA">Corient USA</option>
                            <option value="E2E">E2E</option>
                            <option value="Cox & Hinkins Limited">Cox & Hinkins Limited</option>
                            <option value="Cox & Hinkins">Cox & Hinkins</option>
                          </select>
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                    <FormGroup>
                       <label>Director Name</label>
                                 <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                    placeholder="Director Name" 
                                    type="text" 
                                    name="director_name" 
                                    value={inputFields.director_name != undefined ? inputFields.director_name : ''}
                                    onChange={inputhandler}
                                   
                                    />
                                </InputGroup>  
                                </FormGroup>   
                              </div>
                              {user_info.role_id=='15' && (
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Status</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="status" onChange={inputhandler}>
                        <option value="">Select Status</option>
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                        </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    )}
                    <>
                
                   <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addCommetbox()}>+ Add multiple user </a>
          </Col>
        
        {(() => {
            const arr1 = [];
            for (let k = 0; k < rowsError; k++) {
                arr1.push(
                 <>
                 {/* {inputFields.users.map((user, index) => ( */}

    <Col md="3" key={k}>
      <InputGroup className="input-group-alternative mb-3">
        <Input
          placeholder="Name"
          size="sm"
          name="name"
          type="text"
          required
          // value={user.name}
          onChange={(e) => inputUserHandler(e, k)}
        />
      </InputGroup>
    </Col>
    <Col md="3">
      <InputGroup className="input-group-alternative mb-3">
        <Input
          placeholder="Email"
          size="sm"
          name="email"
          type="text"
          required
          // value={user.email}
          onChange={(e) => inputUserHandler(e, k)}
        />
      </InputGroup>
    </Col>
    <Col md="3">
      <InputGroup className="input-group-alternative mb-3">
        <Input
          placeholder="Phone no."
          size="sm"
          name="phone_no"
          type="text"
          // value={user.phone}
          onChange={(e) => inputUserHandler(e, k)}
        />
      </InputGroup>
    </Col>
    <Col md="1">
      {/* {k > 0 && ( */}
        <button
         type="button"
          className="btn btn-danger"
          onClick={() => removeCommetbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      {/* )} */}
    </Col>
  
{/* ))} */}
                 </>
                );
            }
            return arr1;
            
        })()}
    </>  
   
                    <div className="col-md-12">KYC</div>
                    
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Passport</label>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Expiry date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="date"
                            type="date"
                            name="passport_expiry_date"
                            onChange={inputhandler}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Document</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="file"
                            type="file"
                            accept=".pdf, .doc, .docx, .jpeg, .jpg, .png" // Define the accepted file types
                            onChange={handlePassportDocChange} // Handle file input change
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                    <br></br>
                    <label>Driving License</label>
                      </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Expiry date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="date"
                            type="date"
                            name="driving_expiry_date"
                            onChange={inputhandler}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Document</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <Input
                            placeholder="file"
                            type="file"
                            accept=".pdf, .doc, .docx, .jpeg, .jpg, .png" // Define the accepted file types
                            onChange={handleDrivingDocChange} // Handle file input change
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                    <br></br>
                    <label>Utility Bill</label>
                      </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Expiry date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="date"
                            type="date"
                            name="utility_expiry_date"
                            onChange={inputhandler}
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    <div className="col-md-4">
                       <FormGroup>
                       <label> Document</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <Input
                            placeholder="file"
                            type="file"
                            accept=".pdf, .doc, .docx, .jpeg, .jpg, .png" // Define the accepted file types
                            onChange={handleUtilityDocChange} // Handle file input change
                            style={styles}
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div>
                    {/* <div className="col-md-12">Any 2 document is required</div> */}
                    <div className="col-md-12">
                    <FormGroup>
                      <label>Services Taken</label><br />
                      {servicesData && (
                      
                      servicesData.map((service, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            name="services"
                            value={service}
                            checked={inputFields.servicesTaken?.includes(service)}
                            onChange={() => handleServicesTakenChange(service)}
                          />
                          &nbsp; {service} &nbsp;
                        </label>
                      )))}
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <FormGroup>
                      <label>Compliance </label><br />
                      {compliancesData && (
                      
                      compliancesData.map((compliance, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            name="compliance"
                            value={compliance}
                            checked={inputFields.complianceTaken?.includes(compliance)}
                            onChange={() => handleCheckboxChange('complianceTaken', compliance)}
                          />
                          &nbsp; {compliance} &nbsp;
                        </label>
                      )))}
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <FormGroup>
                      <label>Department Related </label><br />
                      {departmentData && (
                      
                      departmentData.map((department, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            name="department"
                            value={department}
                            checked={inputFields.departmentTaken?.includes(department)}
                            onChange={() => handleCheckboxChange('departmentTaken', department)}
                            
                          />
                          &nbsp; {department} &nbsp;
                        </label>
                      )))}
                    </FormGroup>
                  </div>
                  <div className="col-md-12">Rate Card</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < ratebox; k++) {
                arr1.push(
                 <>
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                       <div className="col-md-2">
                        <InputGroup className="input-group-alternative mb-3 ">
                          <Input
                            placeholder="Level"
                            type="text"
                            name="level"
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            required
                          />
                          </InputGroup>
                         </div>
                         <div className="col-md-2">
                          <InputGroup className="input-group-alternative mb-3 ">
                           <Input
                            placeholder="Hours"
                            type="number"
                            name="hour"
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            required
                          />
                        </InputGroup>
                        </div>
                        <div className="col-md-2">
                          <InputGroup className="input-group-alternative mb-3 ">
                           <Input
                            placeholder="Monthly rate"
                            type="number"
                            name="rate"
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            required
                          />
                        </InputGroup>
                        </div>
                       
                   
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Notes</label> */}
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Notes"
                            type="text"
                            name="notes"
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                   
                    <div className="col-md-2">
                       <FormGroup>
                       {/* <label>Commitment</label> */}
                        <InputGroup className="mb-3" style={{marginTop:"10px"}}>
                          <Input
                            placeholder="Commitment"
                            type="checkbox"
                            name="commitment"
                            onChange={(e) => inputRateHandler(e, k)}
                            style={styles}
                            
                          />  Commitment  
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <Col md="1">
      {/* {k > 0 && ( */}
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRatebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      {/* )} */}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRatebox()}>+ Add  rate </a>
          </Col>
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Start date of agreement</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Start date of agreement"
                            type="date"
                            name="agreement_date"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>marketing channels</label>
                        <InputGroup className="input-group-alternative mb-3">
                        <select class="form-control" name="marketting_channel" onChange={inputhandler}>
                        <option value="">Select </option>
                        <option value="ContactForm">Contact Form</option>
                        <option value="WebsiteOrganic">Website Organic</option>
                        <option value="GoogleAd">Google Ad</option>
                        <option value="Reference">Reference</option>
                        <option value="MailCampaign">Mail Campaign</option>
                        <option value="Accountex">Accountex</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="ColdCall">Cold Call</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Inbound">Inbound</option>
                        <option value="Event">Event</option>
                        <option value="Network">Network</option>
                          </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Billing Contact</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Billing Contact"
                            type="text"
                            name="billing_spoc"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Last Increment date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Last Increment date"
                            type="date"
                            name="increment_date"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Last Increment Rate</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Last Increment Rate"
                            type="text"
                            name="increment_rate"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Notice Period (in days)</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Notice Period"
                            type="number"
                            name="notice_period"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <label>Last Service date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Last Service date"
                            type="date"
                            name="last_day_service"
                            onChange={inputhandler}
                            style={styles}
                            
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                   
                    
                    <div className="col-md-12">Client Referred</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < referbox; k++) {
                arr1.push(
                 <>
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                       <div className="col-md-2">
                        <InputGroup className="input-group-alternative mb-3 ">
                          <Input
                            placeholder="Client name"
                            type="text"
                            name="client_name"
                            onChange={(e) => inputReferHandler(e, k)}
                            style={styles}
                            required
                          />
                          </InputGroup>
                         </div>
                         <div className="col-md-2">
                          <InputGroup className="input-group-alternative mb-3 ">
                           <Input
                            placeholder="Hours"
                            type="number"
                            name="hours"
                            onChange={(e) => inputReferHandler(e, k)}
                            style={styles}
                            required
                          />
                        </InputGroup>
                        </div>
                 
                    <Col md="1">
      {/* {k > 0 && ( */}
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeReferbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      {/* )} */}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addReferbox()}>+ Add  client Referred  </a>
          </Col>
                   
          {(() => {
            const arr1 = [];
            for (let k = 0; k < otherdoc; k++) {
                arr1.push(
                 <>
          <div className="col-md-3">
                    <label>Other documents</label>
                    <input
                      type="file"
                      
                      onChange={(e) => inputDocHandler(e, k,'files')}
                    />
                   
                    </div>
                    
                   
                    <div className="col-md-6">
                      <label>Remarks</label>
                    <Input
                            placeholder="Remarks"
                            type="text"
                            name="remarks"
                            onChange={(e) => inputDocHandler(e, k,'remarks')}
                            style={styles}
                            
                          />
                    </div>
                 

<Col md="1"> <br></br>
{/* {k > 0 && ( */}
  <button
  type="button"
    className="btn btn-danger"
    onClick={() => removeOtherdoc(k)}
  >
    <i className="fa fa-remove"></i>
  </button>
{/* )} */}
</Col>
                 
              </>
          );
      }
      return arr1;
      
  })()}
    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addOtherdoc()}>+ Add more document  </a>
          </Col>
                </div><br></br>
                <div class="row">
                <div className="col-md-2">
                         <Button className="col-12 " color="primary" type="button"  onClick={(e) => adduser(e,'draft')}>
                            Draft
                         </Button>
                    </div>
                <div className="col-md-2">
                         <Button className="col-12 " color="primary" type="submit">
                            Save
                         </Button>
                    </div>
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Useraddmaster;
