import Profile from "views/examples/Profile.js";
import Forgotpassword from "views/examples/Forgotpassword";
import Resetpassword from "views/examples/Resetpassword";
import FinanceDashboard from "views/client/Document.js";
import AddClientTransitionFields from "views/client/AddClientTransitionFields.js";

//admins
import Dashboard from "views/user/Dashboard.js";
import User from "views/user/users/User.js";
import Useraddedit from "views/user/users/Useraddedit";
import Useradd from "views/user/users/Useradd.js";
import Roleaddedit from "views/user/roles/Roleaddedit";
import Role from "views/user/roles/Role.js";

// analyst
import AnalystDashboard from "views/analyst/Dashboard.js";
import Addclient from "views/analyst/client/AddClient.js";
import EditClient from "views/analyst/client/EditClient.js";
import Archive from "views/analyst/Archive.js";
import Uploadedfiles from "views/analyst/Uploadedfiles.js";

// phases
import Phaselist from "views/user/phase/Phaselist.js";
import Editphase from "views/user/phase/Editphase.js";

//analytics
import AnalyticsReport from "views/analyst/analytics/AnalyticsReport.js";
import ReportDetails from "views/analyst/analytics/ReportDetails.js";
import ClientDashboard from "views/client/Dashboard.js";
import ClientUser from "views/client/User.js";
import ClientUseraddedit from "views/client/Useraddedit";
import ClientUseradd from "views/client/Useradd.js";
import ClientList from "views/client/ClientList";
import ClientUseraddeditmaster from "views/client/Useraddeditmaster";
import ClientUseraddmaster from "views/client/Useraddmaster";
import Assignmanager from "views/user/users/Assignmanager";
import Assignreviewer from "views/user/users/Assignreviewer";


import DailyReport from "views/analyst/analytics/DailyReport";
import WeeklyReport from "views/analyst/analytics/WeeklyReport";
import MonthlyReport from "views/analyst/analytics/MonthlyReport";


import Uploaddocument from "views/client/Uploaddocument";
import Editjob from "views/client/Editjob";
import DailyReportDetails from "views/analyst/analytics/DailyReportDetails.js";
import WeeklyReportDetails from "views/analyst/analytics/WeeklyReportDetails.js";
import MonthlyReportDetails from "views/analyst/analytics/MonthlyReportDetails.js";
import Categorylist from "views/user/category/Categorylist";
import RejectionReport from "views/analyst/analytics/RejectionReport";
import RejectionSummary from "views/analyst/analytics/RejectionSummary";
import ReportWithoutErrors from "views/analyst/analytics/ReportWithoutErrors.js";
import ClientDetails from "views/client/ClientDetails";
import UserArchive from "views/user/users/Archive.js";
import ExternalRejection from "views/client/ExternalRejection";
import AddExternalCCRP from "views/client/AddExternalCCRP";
import Docusign from "views/client/Docusign";
import DocusignList from "views/client/DocusignList";
import GovernanceCall from "views/client/GovernanceCall";
import AddGovernanceCall from "views/client/AddGovernanceCall";
import Addfreqmaster from "views/client/Addfreqmaster";
import GovernanceSummary from "views/client/GovernanceSummary";
import EditGovernanceCall from "views/client/EditGovernanceCall";
import ClientFrequency from "views/client/ClientFrequency";
import BillingDashboard from "views/client/Billingdashboard.js";
import AddClientTransition from "views/client/AddClientTransition";
import ClientTransition from "views/client/ClientTransition";
import Billing from "views/client/Billing";
import ViewClientTransitionForm from "views/client/ViewClientTransitionForm";
import InternalGovernanceCall from "views/client/InternalGovernanceCall";
import AddInternalGovernanceCall from "views/client/AddInternalGovernanceCall";
import EditInternalGovernanceCall from "views/client/EditInternalGovernanceCall";
import BillingReport from "views/client/BillingReport";
import Clienttransitionlog from "views/client/Clienttransitionlog";
import AddBudget from "views/client/AddBudget";
import InternalCall from "views/client/InternalCall";
import AddInternalCall from "views/client/AddInternalCall";
import EditInternalCall from "views/client/EditInternalCall";
import TodoList from "views/analyst/todo/TodoList";
import AddTodo from "views/analyst/todo/AddTodo";
import SubClientSOP from "views/client/SubClientSOP";
import Announce from "views/client/Announce";
import Addannounce from "views/client/Addannounce";
import EditAnnounce from "views/client/EditAnnounce";
import MasterKRO from "views/user/users/MasterKRO";
import AddMasterKRO from "views/user/users/AddMasterKRO";
import KROCalculation from "views/user/users/KROCalculation";
import ClientTransitionDash from "views/client/ClientTransitionDash";
import KROTypeRating from "views/user/users/KROTypeRating";
import AddKROTypeRating from "views/user/users/AddKROTypeRating";
import EditKROTypeRating from "views/user/users/EditKROTypeRating";
import Krolisting from "views/user/users/KROListing";
import NotEligibleUser from "views/user/users/NotEligibleUser";
import GovernanceDashboard from "views/client/GovernanceDashboard";
import AssignClient from "views/client/AssignClient";
import MisGovernanceData from "views/client/MisGovernanceData"
import KRODashboard from "views/user/users/KRODashboard";
import MisGovernanceDataDetails from "views/client/MisGovernanceDataDetails"
import MisGovernanceActionDetails from "views/client/MisGovernanceActionDetails"
import KRODetails from "views/user/users/KRODetails";
import ManagerRejection from "views/client/ManagerRejection";
import AddManagerRejection from "views/client/AddManagerRejection";
import AddMasterChecklist from "views/client/AddMasterChecklist";
import FeedbackChecklist from "views/client/FeedbackChecklist";
import ChecklistFormData from "views/client/ChecklistFormData";
import PhasesMISReport from "views/analyst/analytics/phasesMISReport";
import EditManagerRejection from "views/client/EditManagerRejection";
import GraphChart from "views/client/GraphChart.js";
import Uploaddoc from "views/client/Uploaddoc";
import PriceReviosion from "views/client/PriceReviosion";
import BillingUnlock from "views/client/BillingUnlock";
import AddCompetencyRole from "views/user/users/AddCompetencyRole";
import GovernanceReport from "views/client/GovernanceReport";
import Competencyrole from "views/user/users/Competencyrole";
import MismailLog from "views/client/MismailLog";
import Mismail from "views/client/Mismail";
import AddMasterIncentive from "views/user/AddMasterIncentive";
import Incentives from "views/user/Incentives";
import GovernanceChart from "views/client/GovernanceChart";
import podScreen from "views/analyst/podScreen";
import Kanbanview from "views/analyst/Kanbanview";
import AgeingReport from "views/analyst/analytics/AgeingReport";


var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
        if(user_info.roletype == 'payroll'){
          user_info.rolename = user_info.rolename+'_payroll';
        }
    }else{
        user_info.rolename = 'default';
    }
}

  switch (user_info.rolename) {
    case 'admin':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },

        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/userarchive",
          name: "Users Archived",
          icon: "ni ni-archive-2 text-th",
          component: UserArchive,
          layout: "/admin",
        },
        {
          path: "/phase",
          name: "Phase",
          icon: "fa fa-sort-alpha-desc",
          component: Phaselist,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Categories",
          icon: "fa fa-lock",
          component: Categorylist,
          layout: "/admin",
        },
        {
          path: "/roles",
          name: "Roles",
          icon: "ni ni-single-02 text-th",
          component: Role,
          layout: "/admin",
        },
        {
          path: "/editphase/:phase_id",
          name: "EditPhase",
          icon: "ni ni-single-02 text-th",
          component: Editphase,
          layout: "/admin",
        },
        {
          path: "/edituser/:userid",
          name: "Edit user",
          icon: "ni ni-single-02 text-th",
          component: Useraddedit,
          layout: "/admin",
        },
        {
          path: "/adduser",
          name: "Add user",
          icon: "ni ni-single-02 text-th",
          component: Useradd,
          layout: "/admin",
        },
        {
          path: "/editrole/:id",
          name: "Edit Role",
          icon: "ni ni-single-02 text-th",
          component: Roleaddedit,
          layout: "/admin",
        },
        {
          path: "/addrole",
          name: "Add Role",
          icon: "ni ni-single-02 text-th",
          component: Roleaddedit,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/assignmanager",
          name: "Assign Manager",
          icon: "ni ni-single-02 text-th",
          component: Assignmanager,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/assignreviewer",
          name: "Assign Reviewer",
          icon: "ni ni-single-02 text-th",
          component: Assignreviewer,
          layout: "/admin",
        }
      ];
      break;
    case 'qanalyst':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: "/client",
          name: "Client Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: ClientDashboard,
          layout: "/admin",
        },{
          path: "/uploaddoc",
          name: "Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: Uploaddocument,
          layout: "/admin",
        },
        {
          path: "/addextrejection",
          name: "Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: AddExternalCCRP,
          layout: "/admin",
        },
        {
          path: "/archive",
          name: "Archived",
          icon: "ni ni-archive-2 text-th",
          component: Archive,
          layout: "/admin",
        },
        {
          path: "/userarchive",
          name: "Users Archived",
          icon: "ni ni-archive-2 text-th",
          component: UserArchive,
          layout: "/admin",
        },
        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/edituser/:userid",
          name: "Edit user",
          icon: "ni ni-single-02 text-th",
          component: Useraddedit,
          layout: "/admin",
        },

        {
          path: "/adduser",
          name: "Add user",
          icon: "ni ni-single-02 text-th",
          component: Useradd,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/addedit",
          name: "Addedit",
          icon: "ni ni-single-02 text-th",
          component: Addclient,
          layout: "/admin",
        },
        {
          path: "/editclient/:id",
          name: "Editedit",
          icon: "ni ni-single-02 text-th",
          component: EditClient,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/assignmanager",
          name: "Assign Manager",
          icon: "ni ni-single-02 text-th",
          component: Assignmanager,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/uploadedfiles/:task_id",
          name: "Uploaded Files",
          icon: "ni ni-single-02 text-th",
          component: Uploadedfiles,
          layout: "/admin",   
        },
        {
          path: "/weekly_report_details/:company",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/reportwithouterror",
          name: "0 error job report",
          icon: "ni ni-single-02 text-th",
          component: ReportWithoutErrors,
          layout: "/admin",
        },
        {
          path: "/rejectionsummary",
          name: "0 error job report",
          icon: "ni ni-single-02 text-th",
          component: RejectionSummary,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        },{
          path: "/client_details/:userid/:status?/:start_date?/:end_date?",
          name: "Client Data Details",
          icon: "ni ni-single-02 text-th",
          component: ClientDetails,
          layout: "/admin",
        },{
          path: "/externalrejection",
          name: "External CCRP",
          icon: "ni ni-single-copy-04 text-th",
          component: ExternalRejection,
          layout: "/admin",
        },
        {
          path: "/clienttransition",
          name: "Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientTransition,
          layout: "/admin",
        },
        {
          path: "/addclienttransition",
          name: "Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientTransition,
          layout: "/admin",
        },
        {
          path: "/addclienttransition/:client_id?",
          name: "Add Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientTransition,
          layout: "/admin",
        },
        {
          path: "/addclienttransitionfields",
          name: "Client Transition Fields",
          icon: "ni ni-single-copy-04 text-th",
          component: AddClientTransitionFields,
          layout: "/admin",
        },
        {
          path: "/viewclienttransitionform/:client_id/:trans_id/:client_trans_id",
          name: "Client Transition",
          icon: "ni ni-single-copy-04 text-th",
          component: ViewClientTransitionForm,
          layout: "/admin",
        },
        {
          path: "/governancedashboard",
          name: "Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceDashboard,
          layout: "/admin",
        },
        {
          path: "/internal-governance",
          name: "Weekly Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalGovernanceCall,
          layout: "/admin",
        },
        {
          path: "/addinternalgovernancecall",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddInternalGovernanceCall,
          layout: "/admin",
        },{
          path: "/editinternalgovernancecall/:id",
          name: "Governance Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditInternalGovernanceCall,
          layout: "/admin",
        },
        {
          path: "/governance",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceCall,
          layout: "/admin",
        },
        {
          path: "/addgovernancecall",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddGovernanceCall,
          layout: "/admin",
        },{
          path: "/editgovernancecall/:id",
          name: "Governance Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditGovernanceCall,
          layout: "/admin",
        },{
          path: "/showsummary",
          name: "Governance Summary",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceSummary,
          layout: "/admin",
        },{
          path: "/showclientfrequency",
          name: "Client Frequency",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientFrequency,
          layout: "/admin",
        },{
          path: "/addfreqmaster",
          name: "Governance Call",
          icon: "ni ni-single-copy-04 text-th",
          component: Addfreqmaster,
          layout: "/admin",
        },{
          path: "/addbudget",
          name: "Add Budget",
          icon: "ni ni-single-copy-04 text-th",
          component: AddBudget,
          layout: "/admin",
        },
        {
          path: "/internal-call",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalCall,
          layout: "/admin",
        },
        {
          path: "/addinternalcall",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddInternalCall,
          layout: "/admin",
        },{
          path: "/editinternalcall/:id",
          name: "Internal Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditInternalCall,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        {
          path: "/addtodo",
          name: "Todo Add",
          icon: "ni ni-single-copy-04 text-th",
          component: AddTodo,
          layout: "/admin",
        },
        {
          path: "/subclientsop",
          name: "Sub Client SOP",
          icon: "ni ni-single-copy-04 text-th",
          component: SubClientSOP,
          layout: "/admin",
        },
        {
          path: "/clienttransitiondash",
          name: "Client Transition Dashboard",
          icon: "ni ni-single-copy-04 text-th",
          component: ClientTransitionDash,
          layout: "/admin",
        },
        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
        {
          path: "/noteligibleuser",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: NotEligibleUser,
          layout: "/admin",
        },
        {
          path: "/masterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: MasterKRO,
          layout: "/admin",
        },
        {
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        },
        {
          path: "/assignclient",
          name: "Assign Client",
          icon: "ni ni-single-copy-04 text-th",
          component: AssignClient,
          layout: "/admin",
        },
        {
          path: "/misgovernancedata/:type",
          name: "Mis Governance Data",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceData,
          layout: "/admin",
        },
        {
          path: "/misgovernancedatadetails/:month/:title/:username",
          name: "Mis Governance Data Details",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceDataDetails,
          layout: "/admin",
        },
        {
          path: "/misgovernanceactiondetails/:month/:title/:username",
          name: "Mis Governance Data Details",
          icon: "ni ni-single-02 text-th",
          component: MisGovernanceActionDetails,
          layout: "/admin",
        },
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        },
        {
          path: "/addmanagerrejection",
          name: "Add Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: AddManagerRejection,
          layout: "/admin",
        },
        {
          path: "/checklistform/:task_id",
          name: "Add Checklist Data",
          icon: "ni ni-single-copy-04 text-th",
          component: ChecklistFormData,
          layout: "/admin",
        },
        {
          path: "/krotyperating",
          name: "KRO Type Rating",
          icon: "ni ni-single-02 text-th",
          component: KROTypeRating,
          layout: "/admin",
        },{
          path: "/checklistfeedback",
          name: "Checklist Feedback",
          icon: "ni ni-tv-2 text-th",
          component: FeedbackChecklist,
          layout: "/admin",
        }, {
          path: "/graphchart",
          name: "Graph Chart",
          icon: "ni ni-single-copy-04 text-th",
          component: GraphChart,
          layout: "/admin",
        },
        {
          path: "/assignreviewer",
          name: "Assign Reviewer",
          icon: "ni ni-single-02 text-th",
          component: Assignreviewer,
          layout: "/admin",
        },
        {
          path: "/mismaillog/:id?",
          name: "MIS Mail",
          icon: "ni ni-single-02 text-th",
          component: MismailLog,
          layout: "/admin",
          
        },
        {
          path: "/mismail",
          name: "MIS Mail",
          icon: "ni ni-single-02 text-th",
          component: Mismail,
          layout: "/admin",
          
        },
        {
          path: "/gpvernance-chart",
          name: "Governance Chart",
          icon: "ni ni-single-copy-04 text-th",
          component: GovernanceChart,
          layout: "/admin",
        }
        
      ];
      break;
    case 'analyst':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/addedit",
          name: "Addedit",
          icon: "ni ni-single-02 text-th",
          component: Addclient,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/weekly_report_details/:company",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
      ];
      break;
      case 'support_manager':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/edituser/:userid",
          name: "Edit user",
          icon: "ni ni-single-02 text-th",
          component: Useraddedit,
          layout: "/admin",
        },
        {
          path: "/adduser",
          name: "Add user",
          icon: "ni ni-single-02 text-th",
          component: Useradd,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/assignmanager",
          name: "Assign Manager",
          icon: "ni ni-single-02 text-th",
          component: Assignmanager,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/weekly_report_details/:company",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/reportwithouterror",
          name: "0 error job report",
          icon: "ni ni-single-02 text-th",
          component: ReportWithoutErrors,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        }, {
          path: "/rejectionsummary",
          name: "0 error job report",
          icon: "ni ni-single-02 text-th",
          component: RejectionSummary,
          layout: "/admin",
        },
        {
          path: "/internal-call",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalCall,
          layout: "/admin",
        },
        {
          path: "/addinternalcall",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddInternalCall,
          layout: "/admin",
        },{
          path: "/editinternalcall/:id",
          name: "Internal Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditInternalCall,
          layout: "/admin",
        },
        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
        {
          path: "/noteligibleuser",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: NotEligibleUser,
          layout: "/admin",
        },
        {
          path: "/masterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: MasterKRO,
          layout: "/admin",
        },
        {
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        {
          path: "/addtodo",
          name: "Todo Add",
          icon: "ni ni-single-copy-04 text-th",
          component: AddTodo,
          layout: "/admin",
        },
        {
          path: "/krotyperating",
          name: "KRO Type Rating",
          icon: "ni ni-single-02 text-th",
          component: KROTypeRating,
          layout: "/admin",
        },{
          path: "/checklistmaster",
          name: "Add Checklist Master",
          icon: "ni ni-single-copy-04 text-th",
          component: AddMasterChecklist,
          layout: "/admin",
        },
        {
          path: "/checklistfeedback",
          name: "Checklist Feedback",
          icon: "ni ni-tv-2 text-th",
          component: FeedbackChecklist,
          layout: "/admin",
        },{
          path: "/checklistform/:task_id",
          name: "Add Checklist Data",
          icon: "ni ni-single-copy-04 text-th",
          component: ChecklistFormData,
          layout: "/admin",
        },
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        },{
          path: "/addmanagerrejection",
          name: "Add Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: AddManagerRejection,
          layout: "/admin",
        },
        {
          path: "/ageingreport",
          name: "aeging report",
          icon: "ni ni-single-02 text-th",
          component: AgeingReport,
          layout: "/admin",
        },
        {
          path: "/editManagerRejection/:id",
          name: "Edit Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: EditManagerRejection,
          layout: "/admin",
        },
      ];
      break;
      case 'team':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-02 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: ReportDetails,
          layout: "/admin",
        },
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: "ni ni-single-02 text-th",
          component: DailyReport,
          layout: "/admin",
        },
        {
          path: "/weeklyreport",
          name: "Weekly Report",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReport,
          layout: "/admin",
        },
        {
          path: "/monthlyreport",
          name: "Monthly Report",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReport,
          layout: "/admin",
        },
        {
          path: "/daily_report_details/:userid/:type/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: DailyReportDetails,
          layout: "/admin",
        },
        {
          path: "/weekly_report_details/:company/:phase?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: WeeklyReportDetails,
          layout: "/admin",
        },
        {
          path: "/monthly_report_details/:company/:status?",
          name: "Report Details",
          icon: "ni ni-single-02 text-th",
          component: MonthlyReportDetails,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejection Report",
          icon: "ni ni-single-02 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/reportwithouterror",
          name: "0 error job report",
          icon: "ni ni-single-02 text-th",
          component: ReportWithoutErrors,
          layout: "/admin",
        },
        {
          path: "/rejectionsummary",
          name: "0 error job report",
          icon: "ni ni-single-02 text-th",
          component: RejectionSummary,
          layout: "/admin",
        },{
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        {
          path: "/addtodo",
          name: "Todo Add",
          icon: "ni ni-single-copy-04 text-th",
          component: AddTodo,
          layout: "/admin",
        },
        {
          path: "/internal-call",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalCall,
          layout: "/admin",
        },
        {
          path: "/addinternalcall",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: AddInternalCall,
          layout: "/admin",
        },{
          path: "/editinternalcall/:id",
          name: "Internal Call Edit",
          icon: "ni ni-single-copy-04 text-th",
          component: EditInternalCall,
          layout: "/admin",
        },
        {
          path: "/krocalculation/:user_id?/:month_year?",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: KROCalculation,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },
        {
          path: "/noteligibleuser",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: NotEligibleUser,
          layout: "/admin",
        },
        {
          path: "/krotyperating",
          name: "KRO Type Rating",
          icon: "ni ni-single-02 text-th",
          component: KROTypeRating,
          layout: "/admin",
        },
        {
          path: "/checklistfeedback",
          name: "Checklist Feedback",
          icon: "ni ni-tv-2 text-th",
          component: FeedbackChecklist,
          layout: "/admin",
        },
        {
          path: "/checklistform/:task_id/:main_id/:type",
          name: "Add Checklist Data",
          icon: "ni ni-single-copy-04 text-th",
          component: ChecklistFormData,
          layout: "/admin",
        }, {
          path: "/masterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: MasterKRO,
          layout: "/admin",
        },
        {
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        },
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        },{
          path: "/addmanagerrejection",
          name: "Add Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: AddManagerRejection,
          layout: "/admin",
        },
        {
          path: "/editManagerRejection/:id",
          name: "Edit Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: EditManagerRejection,
          layout: "/admin",
        },
      ];
      break;
      case 'manager':
        var adminroutes=[
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: AnalystDashboard,
            layout: "/admin",
          },
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-02 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
          {
            path: "/client",
            name: "Client Dashboard",
            icon: "ni ni-tv-2 text-th",
            component: ClientDashboard,
            layout: "/admin",
          },{
            path: "/archive",
            name: "Archived",
            icon: "ni ni-archive-2 text-th",
            component: Archive,
            layout: "/admin",
          },
          {
            path: "/userarchive",
            name: "Users Archived",
            icon: "ni ni-archive-2 text-th",
            component: UserArchive,
            layout: "/admin",
          },
          {
            path: "/users",
            name: "Users",
            icon: "ni ni-single-02 text-th",
            component: User,
            layout: "/admin",
          },
          {
            path: "/masterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: MasterKRO,
            layout: "/admin",
          },
          {
            path: "/addmasterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: AddMasterKRO,
            layout: "/admin",
          },
          {
            path: "/krocalculation/:user_id?/:month_year?",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: KROCalculation,
            layout: "/admin",
          },
          {
            path: "/Krolisting",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: Krolisting,
            layout: "/admin",
          },
          {
            path: "/noteligibleuser",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: NotEligibleUser,
            layout: "/admin",
          },
          {
            path: "/krotyperating",
            name: "KRO Type Rating",
            icon: "ni ni-single-02 text-th",
            component: KROTypeRating,
            layout: "/admin",
          },
          {
            path: "/addtyperating/:role_id?/:id?",
            name: "Add KRO Type Rating",
            icon: "ni ni-single-02 text-th",
            component: AddKROTypeRating,
            layout: "/admin",
          }, {
            path: "/addcompetencyrole/:role_id?",
            name: "Competency Master",
            icon: "ni ni-single-02 text-th",
            component: AddCompetencyRole,
            layout: "/admin",
          },
          {
            path: "/competencyrole",
            name: "Competency Master",
            icon: "ni ni-single-02 text-th",
            component: Competencyrole,
            layout: "/admin",
          },
          {
            path: "/edit-kro-typerating/:id",
            name: "Edit KRO Type Rating",
            icon: "ni ni-single-02 text-th",
            component: EditKROTypeRating,
            layout: "/admin",
          },
          {
            path: "/mismaillog/:id?",
            name: "MIS Mail",
            icon: "ni ni-single-02 text-th",
            component: MismailLog,
            layout: "/admin",
            
          },
          {
            path: "/mismail",
            name: "MIS Mail",
            icon: "ni ni-single-02 text-th",
            component: Mismail,
            layout: "/admin",
            
          },
          {
            path: "/krodashboard",
            name: "KRO Dashboard",
            icon: "ni ni-single-02 text-th",
            component: KRODashboard,
            layout: "/admin",
          },
          {
            path: "/krodetails/:month/:title/:username",
            name: "KRO Data Details",
            icon: "ni ni-single-02 text-th",
            component: KRODetails,
            layout: "/admin",
          },
          {
            path: "/assignmanager",
            name: "Assign Manager",
            icon: "ni ni-single-02 text-th",
            component: Assignmanager,
            layout: "/admin",
          },
          {
            path: "/edituser/:userid",
            name: "Edit user",
            icon: "ni ni-single-02 text-th",
            component: Useraddedit,
            layout: "/admin",
          },
          {
            path: "/clienttransition",
            name: "Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientTransition,
            layout: "/admin",
          },
          {
            path: "/adduser",
            name: "Add user",
            icon: "ni ni-single-02 text-th",
            component: Useradd,
            layout: "/admin",
          },
          {
            path: "/uploaddoc",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: Uploaddocument,
            layout: "/admin",
          },
          {
            path: "/user-profile",
            name: "User Profile",
            icon: "ni ni-settings-gear-65",
            component: Profile,
            layout: "/admin",
          },
          {
            path: "/addedit",
            name: "Addedit",
            icon: "ni ni-single-02 text-th",
            component: Addclient,
            layout: "/admin",
          },
          {
            path: "/editclient/:id",
            name: "Editedit",
            icon: "ni ni-single-02 text-th",
            component: EditClient,
            layout: "/admin",
          },
          {
            path: "/report_details/:userid/:type/:start_date/:end_date/:status?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: ReportDetails,
            layout: "/admin",
          },{
            path: "/client_details/:userid/:status?/:start_date?/:end_date?",
            name: "Client Data Details",
            icon: "ni ni-single-02 text-th",
            component: ClientDetails,
            layout: "/admin",
          },
          {
            path: "/rejectionreport",
            name: "Rejection Report",
            icon: "ni ni-single-02 text-th",
            component: RejectionReport,
            layout: "/admin",
          },
          {
            path: "/externalrejection",
            name: "External CCRP",
            icon: "ni ni-single-copy-04 text-th",
            component: ExternalRejection,
            layout: "/admin",
          },
          {
            path: "/reportwithouterror",
            name: "0 error job report",
            icon: "ni ni-single-02 text-th",
            component: ReportWithoutErrors,
            layout: "/admin",
          },
          {
            path: "/rejectionsummary",
            name: "0 error job report",
            icon: "ni ni-single-02 text-th",
            component: RejectionSummary,
            layout: "/admin",
          },{
            path: "/addextrejection",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: AddExternalCCRP,
            layout: "/admin",
          },
          {
            path: "/addclienttransition/:client_id?",
            name: "Add Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: AddClientTransition,
            layout: "/admin",
          },
          {
            path: "/addclienttransitionfields",
            name: "Client Transition Fields",
            icon: "ni ni-single-copy-04 text-th",
            component: AddClientTransitionFields,
            layout: "/admin",
          },
          {
            path: "/viewclienttransitionform/:client_id/:trans_id/:client_trans_id",
            name: "Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ViewClientTransitionForm,
            layout: "/admin",
          },
          {
            path: "/governancedashboard",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceDashboard,
            layout: "/admin",
          },
          {
            path: "/governance",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceCall,
            layout: "/admin",
          },
          {
            path: "/addgovernancecall",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddGovernanceCall,
            layout: "/admin",
          },{
            path: "/editgovernancecall/:id",
            name: "Governance Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditGovernanceCall,
            layout: "/admin",
          },{
            path: "/showsummary",
            name: "Governance Summary",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceSummary,
            layout: "/admin",
          },{
            path: "/showclientfrequency",
            name: "Client Frequency",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientFrequency,
            layout: "/admin",
          },{
            path: "/addfreqmaster",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: Addfreqmaster,
            layout: "/admin",
          },
          {
            path: "/internal-governance",
            name: "Weekly Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalGovernanceCall,
            layout: "/admin",
          },
          {
            path: "/addinternalgovernancecall",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddInternalGovernanceCall,
            layout: "/admin",
          },{
            path: "/editinternalgovernancecall/:id",
            name: "Governance Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditInternalGovernanceCall,
            layout: "/admin",
          },
          {
            path: "/clienttransitionlog",
            name: "Client Transition Log",
            icon: "ni ni-single-copy-04 text-th",
            component: Clienttransitionlog,
            layout: "/admin",
          },{
            path: "/addbudget",
            name: "Add Budget",
            icon: "ni ni-single-copy-04 text-th",
            component: AddBudget,
            layout: "/admin",
          },{
            path: "/internal-call",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalCall,
            layout: "/admin",
          },
          {
            path: "/addinternalcall",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddInternalCall,
            layout: "/admin",
          },{
            path: "/editinternalcall/:id",
            name: "Internal Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditInternalCall,
            layout: "/admin",
          },
          {
            path: "/subclientsop",
            name: "Sub Client SOP",
            icon: "ni ni-single-copy-04 text-th",
            component: SubClientSOP,
            layout: "/admin",
          },
          {
            path: "/todolist",
            name: "Todo List",
            icon: "ni ni-single-copy-04 text-th",
            component: TodoList,
            layout: "/admin",
          },
          {
            path: "/addtodo",
            name: "Todo Add",
            icon: "ni ni-single-copy-04 text-th",
            component: AddTodo,
            layout: "/admin",
          },
          {
            path: "/announce",
            name: "Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: Announce,
            layout: "/admin",
          },
          {
            path: "/addannounce",
            name: "Add Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: Addannounce,
            layout: "/admin",
          },  
          {
            path: "/editannounce/:id",
            name: "Edit Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: EditAnnounce,
            layout: "/admin",
          },
          {
            path: "/assignclient",
            name: "Assign Client",
            icon: "ni ni-single-copy-04 text-th",
            component: AssignClient,
            layout: "/admin",
          },
          {
            path: "/misgovernancedata/:type",
            name: "Mis Governance Data",
            icon: "ni ni-single-02 text-th",
            component: MisGovernanceData,
            layout: "/admin",
          },
          {
            path: "/misgovernancedatadetails/:month/:title/:username",
            name: "Mis Governance Data Details",
            icon: "ni ni-single-02 text-th",
            component: MisGovernanceDataDetails,
            layout: "/admin",
          },
          {
            path: "/misgovernanceactiondetails/:month/:title/:username",
            name: "Mis Governance Data Details",
            icon: "ni ni-single-02 text-th",
            component: MisGovernanceActionDetails,
            layout: "/admin",
          },
          {
            path: "/managerrejection",
            name: "Manager Rejection",
            icon: "ni ni-single-copy-04 text-th",
            component: ManagerRejection,
            layout: "/admin",
          },{
            path: "/addmanagerrejection",
            name: "Add Manager Rejection",
            icon: "ni ni-single-copy-04 text-th",
            component: AddManagerRejection,
            layout: "/admin",
          },{
            path: "/checklistmaster",
            name: "Add Checklist Master",
            icon: "ni ni-single-copy-04 text-th",
            component: AddMasterChecklist,
            layout: "/admin",
          },
          {
            path: "/checklistfeedback",
            name: "Checklist Feedback",
            icon: "ni ni-tv-2 text-th",
            component: FeedbackChecklist,
            layout: "/admin",
          },{
            path: "/checklistform/:task_id",
            name: "Add Checklist Data",
            icon: "ni ni-single-copy-04 text-th",
            component: ChecklistFormData,
            layout: "/admin",
          },{
            path: "/phasesMIS",
            name: "Phases MIS",
            icon: "ni ni-single-copy-04 text-th",
            component: PhasesMISReport,
            layout: "/admin",
          },
          {
            path: "/editManagerRejection/:id",
            name: "Edit Manager Rejection",
            icon: "ni ni-single-copy-04 text-th",
            component: EditManagerRejection,
            layout: "/admin",
          },
          {
            path: "/graphchart",
            name: "Graph Chart",
            icon: "ni ni-single-copy-04 text-th",
            component: GraphChart,
            layout: "/admin",
          },
          {
            path: "/governanceReport",
            name: "governanceReport",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceReport,
            layout: "/admin",
          },
          {
            path: "/assignreviewer",
            name: "Assign Reviewer",
            icon: "ni ni-single-02 text-th",
            component: Assignreviewer,
            layout: "/admin",
          },{
            path: "/gpvernance-chart",
            name: "Governance Chart",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceChart,
          },{
            path: "/podscreen",
            name: "Pod Data",
            icon: "ni ni-single-02 text-th",
            component: podScreen,
            layout: "/admin",
          },{
            path: "/kanbanview",
            name: "Pod Dashboard",
            icon: "ni ni-single-02 text-th",
            component: Kanbanview,
            layout: "/admin",
          },{
            path: "/ageingreport",
            name: "aeging report",
            icon: "ni ni-single-02 text-th",
            component: AgeingReport,
            layout: "/admin",
          }
          
        ];
        break;
        case 'hod':
        var adminroutes=[
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: AnalystDashboard,
            layout: "/admin",
          },
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-02 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
          {
            path: "/client",
            name: "Client Dashboard",
            icon: "ni ni-tv-2 text-th",
            component: ClientDashboard,
            layout: "/admin",
          },
          {
            path: "/users",
            name: "Users",
            icon: "ni ni-single-02 text-th",
            component: User,
            layout: "/admin",
          },
          
          {
            path: "/user-profile",
            name: "User Profile",
            icon: "ni ni-settings-gear-65",
            component: Profile,
            layout: "/admin",
          },
         
          {
            path: "/report_details/:userid/:type/:start_date/:end_date/:status?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: ReportDetails,
            layout: "/admin",
          },{
            path: "/client_details/:userid/:status?/:start_date?/:end_date?",
            name: "Client Data Details",
            icon: "ni ni-single-02 text-th",
            component: ClientDetails,
            layout: "/admin",
          },
          {
            path: "/rejectionreport",
            name: "Rejection Report",
            icon: "ni ni-single-02 text-th",
            component: RejectionReport,
            layout: "/admin",
          },
          {
            path: "/externalrejection",
            name: "External CCRP",
            icon: "ni ni-single-copy-04 text-th",
            component: ExternalRejection,
            layout: "/admin",
          },
          {
            path: "/mismaillog/:id?",
            name: "MIS Mail",
            icon: "ni ni-single-02 text-th",
            component: MismailLog,
            layout: "/admin",
            
          },
          {
            path: "/mismail",
            name: "MIS Mail",
            icon: "ni ni-single-02 text-th",
            component: Mismail,
            layout: "/admin",
            
          },
          {
            path: "/reportwithouterror",
            name: "0 error job report",
            icon: "ni ni-single-02 text-th",
            component: ReportWithoutErrors,
            layout: "/admin",
          },
          {
            path: "/graphchart",
            name: "Graph Chart",
            icon: "ni ni-single-copy-04 text-th",
            component: GraphChart,
            layout: "/admin",
          },
          {
            path: "/rejectionsummary",
            name: "0 error job report",
            icon: "ni ni-single-02 text-th",
            component: RejectionSummary,
            layout: "/admin",
          },
          {
            path: "/governancedashboard",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceDashboard,
            layout: "/admin",
          },
          {
            path: "/internal-governance",
            name: "Weekly Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalGovernanceCall,
            layout: "/admin",
          },
          {
            path: "/addinternalgovernancecall",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddInternalGovernanceCall,
            layout: "/admin",
          },{
            path: "/editinternalgovernancecall/:id",
            name: "Governance Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditInternalGovernanceCall,
            layout: "/admin",
          },
          {
            path: "/governance",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceCall,
            layout: "/admin",
          },
          {
            path: "/addgovernancecall",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddGovernanceCall,
            layout: "/admin",
          },{
            path: "/editgovernancecall/:id",
            name: "Governance Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditGovernanceCall,
            layout: "/admin",
          },{
            path: "/showsummary",
            name: "Governance Summary",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceSummary,
            layout: "/admin",
          },{
            path: "/showclientfrequency",
            name: "Client Frequency",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientFrequency,
            layout: "/admin",
          },{
            path: "/addfreqmaster",
            name: "Governance Call",
            icon: "ni ni-single-copy-04 text-th",
            component: Addfreqmaster,
            layout: "/admin",
          },{
            path: "/addbudget",
            name: "Add Budget",
            icon: "ni ni-single-copy-04 text-th",
            component: AddBudget,
            layout: "/admin",
          },{
            path: "/internal-call",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalCall,
            layout: "/admin",
          },
          {
            path: "/addinternalcall",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: AddInternalCall,
            layout: "/admin",
          },{
            path: "/editinternalcall/:id",
            name: "Internal Call Edit",
            icon: "ni ni-single-copy-04 text-th",
            component: EditInternalCall,
            layout: "/admin",
          },{
            path: "/subclientsop",
            name: "Sub Client SOP",
            icon: "ni ni-single-copy-04 text-th",
            component: SubClientSOP,
            layout: "/admin",
          },
          {
            path: "/todolist",
            name: "Todo List",
            icon: "ni ni-single-copy-04 text-th",
            component: TodoList,
            layout: "/admin",
          },
          {
            path: "/addtodo",
            name: "Todo Add",
            icon: "ni ni-single-copy-04 text-th",
            component: AddTodo,
            layout: "/admin",
          },
          {
            path: "/krocalculation/:user_id?/:month_year?",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: KROCalculation,
            layout: "/admin",
          },
          {
            path: "/Krolisting",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: Krolisting,
            layout: "/admin",
          },
          {
            path: "/noteligibleuser",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: NotEligibleUser,
            layout: "/admin",
          },
          {
            path: "/masterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: MasterKRO,
            layout: "/admin",
          },
          {
            path: "/addmasterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: AddMasterKRO,
            layout: "/admin",
          },
          {
            path: "/krodashboard",
            name: "KRO Dashboard",
            icon: "ni ni-single-02 text-th",
            component: KRODashboard,
            layout: "/admin",
          },
          {
            path: "/krodetails/:month/:title/:username",
            name: "KRO Data Details",
            icon: "ni ni-single-02 text-th",
            component: KRODetails,
            layout: "/admin",
          },
          {
            path: "/krotyperating",
            name: "KRO Type Rating",
            icon: "ni ni-single-02 text-th",
            component: KROTypeRating,
            layout: "/admin",
          },{
            path: "/phasesMIS",
            name: "Phases MIS",
            icon: "ni ni-single-copy-04 text-th",
            component: PhasesMISReport,
            layout: "/admin",
          },
        ];
        break;
        case 'deputy_manager':
        var adminroutes=[
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-02 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
          {
            path: "/report_details/:userid/:type/:status?",
            name: "Report Details",
            icon: "ni ni-single-02 text-th",
            component: ReportDetails,
            layout: "/admin",
          }
        ];
        break;
        case 'Client':
          var adminroutes=[
            {
              path: "/index",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            {
              path: "/clientuser",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: ClientUser,
              layout: "/admin",
            },
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-single-02 text-th",
              component: Profile,
              layout: "/admin",
            },
            {
              path: "/editclientuser/:userid",
              name: "Edit user",
              icon: "ni ni-single-02 text-th",
              component: ClientUseraddedit,
              layout: "/admin",
            },
            {
              path: "/addclientuser",
              name: "Add user",
              icon: "ni ni-single-02 text-th",
              component: ClientUseradd,
              layout: "/admin",
            },
            {
              path: "/uploaddoc",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: Uploaddocument,
              layout: "/admin",
            },{
              path: "/editjob/:task_id",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: Editjob,
              layout: "/admin",
              
            }
          ];
          break;
          case 'ClientManager':
          var adminroutes=[
            {
              path: "/index",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            {
              path: "/clientuser",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: ClientUser,
              layout: "/admin",
            },
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            },
            {
              path: "/editclientuser/:userid",
              name: "Edit user",
              icon: "ni ni-single-02 text-th",
              component: ClientUseraddedit,
              layout: "/admin",
            },
            {
              path: "/addclientuser",
              name: "Add user",
              icon: "ni ni-single-02 text-th",
              component: ClientUseradd,
              layout: "/admin",
            },
            {
              path: "/uploaddoc",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: Uploaddocument,
              layout: "/admin",
            },{
              path: "/editjob/:task_id",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: Editjob,
              layout: "/admin",
              
            }
          ];
          break;
          case 'finance':
            var adminroutes=[
              {
                path: "/index",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: FinanceDashboard,
                layout: "/admin",
              },
              {
                path: "/governanceReport",
                name: "governanceReport",
                icon: "ni ni-single-copy-04 text-th",
                component: GovernanceReport,
                layout: "/admin",
              },
              {
                path: "/clientuser",
                name: "Clients",
                icon: "ni ni-single-02 text-th",
                component: ClientList,
                layout: "/admin",
              },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-single-02 text-th",
                component: Profile,
                layout: "/admin",
              },
              {
                path: "/editclientmaster/:userid",
                name: "Edit user",
                icon: "ni ni-single-02 text-th",
                component: ClientUseraddeditmaster,
                layout: "/admin",
              },
              {
                path: "/adddocs",
                name: "Upload document",
                icon: "ni ni-single-copy-04 text-th",
                component: Uploaddoc,
                layout: "/admin",
              },
              {
                path: "/addclientmaster",
                name: "Add user",
                icon: "ni ni-single-02 text-th",
                component: ClientUseraddmaster,
                layout: "/admin",
              },
              {
                path: "/uploaddoc",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: Uploaddocument,
                layout: "/admin",
              },{
                path: "/editjob/:task_id",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: Editjob,
                layout: "/admin",
                
              },
              {
                path: "/docusignlist",
                name: "Docusign",
                icon: "ni ni-single-copy-04 text-th",
                component: DocusignList,
                layout: "/admin",
              },
              {
                path: "/docusign",
                name: "Docusign",
                icon: "ni ni-single-copy-04 text-th",
                component: Docusign,
                layout: "/admin",
              },
              {
                path: "/governancedashboard",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: GovernanceDashboard,
                layout: "/admin",
              },
              {
                path: "/governance",
                name: "Governance Call",
                icon: "ni ni-single-copy-04 text-th",
                component: GovernanceCall,
                layout: "/admin",
              },
              {
                path: "/addgovernancecall",
                name: "Governance Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddGovernanceCall,
                layout: "/admin",
              },{
                path: "/editgovernancecall/:id",
                name: "Governance Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditGovernanceCall,
                layout: "/admin",
              },
              {
                path: "/internal-governance",
                name: "Weekly Call",
                icon: "ni ni-single-copy-04 text-th",
                component: InternalGovernanceCall,
                layout: "/admin",
              },
              {
                path: "/addinternalgovernancecall",
                name: "Governance Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddInternalGovernanceCall,
                layout: "/admin",
              },{
                path: "/editinternalgovernancecall/:id",
                name: "Governance Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditInternalGovernanceCall,
                layout: "/admin",
              },
              {
                path: "/internal-call",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: InternalCall,
                layout: "/admin",
              },
              {
                path: "/addinternalcall",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddInternalCall,
                layout: "/admin",
              },{
                path: "/editinternalcall/:id",
                name: "Internal Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditInternalCall,
                layout: "/admin",
              },
              {
                path: "/todolist",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              },
              {
                path: "/addtodo",
                name: "Todo Add",
                icon: "ni ni-single-copy-04 text-th",
                component: AddTodo,
                layout: "/admin",
              },
              {
                path: "/krocalculation/:user_id?/:month_year?",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: KROCalculation,
                layout: "/admin",
              },
              {
                path: "/Krolisting",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: Krolisting,
                layout: "/admin",
              },
              {
                path: "/noteligibleuser",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: NotEligibleUser,
                layout: "/admin",
              },
              {
                path: "/masterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: MasterKRO,
                layout: "/admin",
              },
              {
                path: "/addmasterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: AddMasterKRO,
                layout: "/admin",
              },
              {
                path: "/krotyperating",
                name: "KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: KROTypeRating,
                layout: "/admin",
              },
              {
                path: "/price_reviosion",
                name: "Price Revision",
                icon: "ni ni-single-copy-04 text-th",
                component: PriceReviosion,
                layout: "/admin",
              }
              
            ];
            break;
            case 'billing':
            var adminroutes=[
              {
                path: "/index",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: BillingDashboard,
                layout: "/admin",
              },
              {
                path: "/billingreport",
                name: "Billing Data",
                icon: "ni ni-single-02 text-th",
                component: Billing,
                layout: "/admin",
              },
              {
                path: "/billingunlock",
                name: "Billing Unlock Data",
                icon: "ni ni-single-02 text-th",
                component: BillingUnlock,
                layout: "/admin",
              },
              {
                path: "/billing_report",
                name: "Report",
                icon: "ni ni-single-copy-04 text-th",
                component: BillingReport,
                layout: "/admin",
              },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-single-02 text-th",
                component: Profile,
                layout: "/admin",
              }, {
                path: "/internal-call",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: InternalCall,
                layout: "/admin",
              },
              {
                path: "/addinternalcall",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddInternalCall,
                layout: "/admin",
              },{
                path: "/editinternalcall/:id",
                name: "Internal Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditInternalCall,
                layout: "/admin",
              },
              {
                path: "/todolist",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              },
              {
                path: "/addtodo",
                name: "Todo Add",
                icon: "ni ni-single-copy-04 text-th",
                component: AddTodo,
                layout: "/admin",
              },
              {
                path: "/krocalculation/:user_id?/:month_year?",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: KROCalculation,
                layout: "/admin",
              },
              {
                path: "/Krolisting",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: Krolisting,
                layout: "/admin",
              },
              {
                path: "/noteligibleuser",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: NotEligibleUser,
                layout: "/admin",
              },
              {
                path: "/masterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: MasterKRO,
                layout: "/admin",
              },
              {
                path: "/addmasterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: AddMasterKRO,
                layout: "/admin",
              },
              {
                path: "/krotyperating",
                name: "KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: KROTypeRating,
                layout: "/admin",
              }
            ];
            break;
            case 'HR':  
            var adminroutes=[
               {
                path: "/index",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              },
              {
                path: "/addtodo",
                name: "Todo Add",
                icon: "ni ni-single-copy-04 text-th",
                component: AddTodo,
                layout: "/admin",
              },
              {
                path: "/Krolisting",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: Krolisting,
                layout: "/admin",
              },
              {
                path: "/noteligibleuser",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: NotEligibleUser,
                layout: "/admin",
              },
              {
                path: "/krocalculation/:user_id?/:month_year?",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: KROCalculation,
                layout: "/admin",
              },
              {
                path: "/masterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: MasterKRO,
                layout: "/admin",
              },
              {
                path: "/addmasterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: AddMasterKRO,
                layout: "/admin",
              },
              {
                path: "/krodashboard",
                name: "KRO Dashboard",
                icon: "ni ni-single-02 text-th",
                component: KRODashboard,
                layout: "/admin",
              }, {
                path: "/krodetails/:month/:title/:username",
                name: "KRO Data Details",
                icon: "ni ni-single-02 text-th",
                component: KRODetails,
                layout: "/admin",
              },
              {
                path: "/krotyperating",
                name: "KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: KROTypeRating,
                layout: "/admin",
              },{
                path: "/graphchart",
                name: "Graph Chart",
                icon: "ni ni-single-copy-04 text-th",
                component: GraphChart,
                layout: "/admin",
              },{
                path: "/addcompetencyrole/:role_id?",
                name: "Competency Master",
                icon: "ni ni-single-02 text-th",
                component: AddCompetencyRole,
                layout: "/admin",
              },
              {
                path: "/competencyrole",
                name: "Competency Master",
                icon: "ni ni-single-02 text-th",
                component: Competencyrole,
                layout: "/admin",
              },
             
            ];
            break;
            case 'CommonRole':  
            var adminroutes=[
              {
                path: "/index",
                name: "Workflow Call",
                icon: "ni ni-single-copy-04 text-th",
                component: GovernanceCall,
                layout: "/admin",
              },{
                path: "/addgovernancecall",
                name: "Governance Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddGovernanceCall,
                layout: "/admin",
              },{
                path: "/editgovernancecall/:id",
                name: "Governance Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditGovernanceCall,
                layout: "/admin",
              },{
                path: "/showsummary",
                name: "Governance Summary",
                icon: "ni ni-single-copy-04 text-th",
                component: GovernanceSummary,
                layout: "/admin",
              },{
                path: "/showclientfrequency",
                name: "Client Frequency",
                icon: "ni ni-single-copy-04 text-th",
                component: ClientFrequency,
                layout: "/admin",
              },{
                path: "/addfreqmaster",
                name: "Governance Call",
                icon: "ni ni-single-copy-04 text-th",
                component: Addfreqmaster,
                layout: "/admin",
              },
              {
              path: "/internal-governance",
              name: "Internal Governance",
              icon: "ni ni-single-copy-04 text-th",
              component: InternalGovernanceCall,
              layout: "/admin",
            },
            {
              path: "/addinternalgovernancecall",
              name: "Governance Call",
              icon: "ni ni-single-copy-04 text-th",
              component: AddInternalGovernanceCall,
              layout: "/admin",
            },{
              path: "/editinternalgovernancecall/:id",
              name: "Governance Call Edit",
              icon: "ni ni-single-copy-04 text-th",
              component: EditInternalGovernanceCall,
              layout: "/admin",
            },{
              path: "/internal-call",
              name: "Internal Call",
              icon: "ni ni-single-copy-04 text-th",
              component: InternalCall,
              layout: "/admin",
            },
            {
              path: "/addinternalcall",
              name: "Internal Call",
              icon: "ni ni-single-copy-04 text-th",
              component: AddInternalCall,
              layout: "/admin",
            },{
              path: "/editinternalcall/:id",
              name: "Internal Call Edit",
              icon: "ni ni-single-copy-04 text-th",
              component: EditInternalCall,
              layout: "/admin",
            }
            
            ];
            break;
            case 'manager_payroll':
            case 'lead_payroll':
            case 'processor_payroll':
            case 'reviewer_payroll':
              var adminroutes=[
                // {
                //   path: "/index",
                //   name: "Dashboard",
                //   icon: "ni ni-single-copy-04 text-th",
                //   component: BillingDashboard,
                //   layout: "/admin",
                // },
                {
                  path: "/governancedashboard",
                  name: "Dashboard",
                  icon: "ni ni-single-copy-04 text-th",
                  component: GovernanceDashboard,
                  layout: "/admin",
                },
                {
                  path: "/index",
                  name: "Governance",
                  icon: "ni ni-single-copy-04 text-th",
                  component: GovernanceCall,
                  layout: "/admin",
                },
                {
                  path: "/clienttransition",
                  name: "Client Transition",
                  icon: "ni ni-single-copy-04 text-th",
                  component: ClientTransition,
                  layout: "/admin",
                },  
                {
                  path: "/user-profile",
                  name: "User Profile",
                  icon: "ni ni-single-02 text-th",
                  component: Profile,
                  layout: "/admin",
                },
                {
                  path: "/addclienttransition/:client_id?",
                  name: "Add Client Transition",
                  icon: "ni ni-single-copy-04 text-th",
                  component: AddClientTransition,
                  layout: "/admin",
                },
                {
                  path: "/addclienttransitionfields",
                  name: "Client Transition Fields",
                  icon: "ni ni-single-copy-04 text-th",
                  component: AddClientTransitionFields,
                  layout: "/admin",
                },
                {
                  path: "/viewclienttransitionform/:client_id/:trans_id/:client_trans_id",
                  name: "Client Transition",
                  icon: "ni ni-single-copy-04 text-th",
                  component: ViewClientTransitionForm,
                  layout: "/admin",
                },
                {
                  path: "/governance",
                  name: "Governance Call",
                  icon: "ni ni-single-copy-04 text-th",
                  component: GovernanceCall,
                  layout: "/admin",
                },
                {
                  path: "/addgovernancecall",
                  name: "Governance Call",
                  icon: "ni ni-single-copy-04 text-th",
                  component: AddGovernanceCall,
                  layout: "/admin",
                },{
                  path: "/editgovernancecall/:id",
                  name: "Governance Call Edit",
                  icon: "ni ni-single-copy-04 text-th",
                  component: EditGovernanceCall,
                  layout: "/admin",
                },{
                  path: "/showsummary",
                  name: "Governance Summary",
                  icon: "ni ni-single-copy-04 text-th",
                  component: GovernanceSummary,
                  layout: "/admin",
                },{
                  path: "/showclientfrequency",
                  name: "Client Frequency",
                  icon: "ni ni-single-copy-04 text-th",
                  component: ClientFrequency,
                  layout: "/admin",
                },{
                  path: "/addfreqmaster",
                  name: "Governance Call",
                  icon: "ni ni-single-copy-04 text-th",
                  component: Addfreqmaster,
                  layout: "/admin",
                },
                {
                path: "/internal-governance",
                name: "Internal Governance",
                icon: "ni ni-single-copy-04 text-th",
                component: InternalGovernanceCall,
                layout: "/admin",
              },
              {
                path: "/addinternalgovernancecall",
                name: "Governance Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddInternalGovernanceCall,
                layout: "/admin",
              },{
                path: "/editinternalgovernancecall/:id",
                name: "Governance Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditInternalGovernanceCall,
                layout: "/admin",
              },{
                path: "/addextrejection",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: AddExternalCCRP,
                layout: "/admin",
              },{
                path: "/externalrejection",
                name: "External CCRP",
                icon: "ni ni-single-copy-04 text-th",
                component: ExternalRejection,
                layout: "/admin",
              },
              {
                path: "/userarchive",
                name: "Users Archived",
                icon: "ni ni-archive-2 text-th",
                component: UserArchive,
                layout: "/admin",
              },
              {
                path: "/users",
                name: "Users",
                icon: "ni ni-single-02 text-th",
                component: User,
                layout: "/admin",
              },
              {
                path: "/assignmanager",
                name: "Assign Manager",
                icon: "ni ni-single-02 text-th",
                component: Assignmanager,
                layout: "/admin",
              },
              {
                path: "/edituser/:userid",
                name: "Edit user",
                icon: "ni ni-single-02 text-th",
                component: Useraddedit,
                layout: "/admin",
              },
              {
                path: "/adduser",
                name: "Add user",
                icon: "ni ni-single-02 text-th",
                component: Useradd,
                layout: "/admin",
              },{
                path: "/internal-call",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: InternalCall,
                layout: "/admin",
              },
              {
                path: "/addinternalcall",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: AddInternalCall,
                layout: "/admin",
              },{
                path: "/editinternalcall/:id",
                name: "Internal Call Edit",
                icon: "ni ni-single-copy-04 text-th",
                component: EditInternalCall,
                layout: "/admin",
              },
              {
                path: "/todolist",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              },
              {
                path: "/addtodo",
                name: "Todo Add",
                icon: "ni ni-single-copy-04 text-th",
                component: AddTodo,
                layout: "/admin",
              },
              {
                path: "/krocalculation/:user_id?/:month_year?",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: KROCalculation,
                layout: "/admin",
              },
              {
                path: "/Krolisting",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: Krolisting,
                layout: "/admin",
              },
              {
                path: "/noteligibleuser",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: NotEligibleUser,
                layout: "/admin",
              },
              {
                path: "/masterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: MasterKRO,
                layout: "/admin",
              },
              {
                path: "/addmasterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: AddMasterKRO,
                layout: "/admin",
              },
              {
                path: "/krotyperating",
                name: "KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: KROTypeRating,
                layout: "/admin",
              },{
                path: "/addtyperating/:role_id?/:id?",
                name: "Add KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: AddKROTypeRating,
                layout: "/admin",
              },
              {
                path: "/edit-kro-typerating/:id",
                name: "Edit KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: EditKROTypeRating,
                layout: "/admin",
              },
              {
                path: "/assignreviewer",
                name: "Assign Reviewer",
                icon: "ni ni-single-02 text-th",
                component: Assignreviewer,
                layout: "/admin",
              },
              (user_info.role_id == 14)?
              {
                path: "/incentivemasters",
                name: "Add incentive",
                icon: "ni ni-single-02 text-th",
                component: AddMasterIncentive,
                layout: "/admin",
              }: {},
              (user_info.role_id == 14)?
              {
                path: "/incentives",
                name: "Incentives",
                icon: "ni ni-single-02 text-th",
                component: Incentives,
                layout: "/admin",
              }: {},
              ];
              break;
    default:
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/resetpassword",
          name: "Resetpassword",
          icon: "ni ni-circle-08 text-th",
          component: Resetpassword,
          layout: "/auth",
        },
        {
          path: "/forgotpassword",
          name: "Forgotpassword",
          icon: "ni ni-circle-08 text-th",
          component: Forgotpassword,
          layout: "/auth",
        },
        // {
        //   path: "/userreset/:resettoken",
        //   name: "UserReset",
        //   icon: "ni ni-circle-08 text-th",
        //   component: Onetimeresetpass,
        //   layout: "/auth",
        // },
      ];
  
  }
export default adminroutes;
