const iState = {
    subclientname: ""
  };
  
  const subclientsearch = (state = iState, action) => {
    if (action.type === "client_search") {
      return {
        subclientname: action.payload
      };
    } else {
      return state;
    }
    return state;
  };
  export default subclientsearch;