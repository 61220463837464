import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import './../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { clientmanageService } from "../../services/clientmanageService";

 const Clienttransitionlog = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [logdata, setlogData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null)
  
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    getclienttransitionlog()
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getclienttransitionlog(index,null);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getclienttransitionlog(prev,null);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getclienttransitionlog(next,null);
  };
  const getclienttransitionlog = async (pageNumber = 1,e) => { 
    
    clientmanageService.getclienttransitionlog(pageNumber).then((response) => {
      //console.log(response.data.data); 
        setlogData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
    });
  };
  function downloadReport(){
    
    clientmanageService.clienttransitionlogexport().then((response) => {
    
    });
  }
const user_info = JSON.parse(localStorage.getItem("user-info"));
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Client Transition Log
                        </h4>
                      </div>
                      <div>
                      <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <th scope="col" className="whitespace" >User Name</th>
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Type</th>
                    <th scope="col" className="whitespace">Count</th>
                  </thead>
                <tbody>
                  {logdata &&
                    logdata.length &&
                    logdata != null ? (
                        logdata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } >
                          <td className="whitespace">{item.user_name}</td>
                          <td className="whitespace">{item.client_name}</td>
                          <td className="whitespace">{item.trans_name}</td>
                          <td className="whitespace">{item.count}</td>
                      </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

        <Row>
        
        </Row>
      </Container>
    </>
  );
};
export default Clienttransitionlog;
