import { BrowserRouter, Route, Switch, Redirect, useHistory,useParams } from "react-router-dom";
import React from 'react';
import { useEffect,useState } from 'react';
import 'assets/css/custom.css';

import { ToastContainer, toast } from 'react-toastify';
import PageLoder from 'components/Loader/PageLoader.js';
import {
   Button,
   Row,
   Col,
   Container,
   Modal,
   ModalHeader,
   ModalBody,
   ModalFooter,
   
} from "reactstrap";
const Announcementcomponent  = ({ parameter }) => {
   const history = useHistory();
   const user_info = JSON.parse(localStorage.getItem("user-info"));
   
   let arr = [];

parameter.forEach((data) => {
    console.log(data.name);
    arr.push(data.name);
});

const announcements = arr.join(' | ');
console.log(announcements)


    return (
        <React.Fragment key={1}>
         {parameter ?
         <span >
         <section class="section">
          
            <p class="marquee text-styling">
            
            {announcements}
     

          </p>
          </section>   
           </span>
             : ''} 
        </React.Fragment>
      ); 
};

export default Announcementcomponent;