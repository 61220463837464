import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { usermanageService } from "../../services/usermanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import Select from 'react-select';
const axios = require("axios");
import { apiUrl } from "../../constant";
import { authtoken } from "global.js";

const Docusign = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select Client',value:''}]);
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [client_err, setclienterr] = useState(false);
  const [file_err, setfileerr] = useState(false);
  const regionOption = useRef(null)

 
  

  
 


useEffect(() => {
  //get_list();
   get_unique_companieslist();
}, []);

// const taskDetail = (task_id,index) => {
//   taskmanageService.gettaskdetail(task_id,'','').then((response) => {
//     settaskdetails(response.data.data);
//     settaskdetailPopup(!taskdetailPopup);
//   })
// }
  function setclient(e){ 
    let initdata = {
      client_id: e.value,
    };
    
    setInputfields({ ...inputFields, client_id: e.value });
    setclientsearch(e.value);
    //setClientdata([]);
    
    // var index = e.target.selectedIndex;
    // setclientsearch(e.target[index].text)
   
    
    
  }
const companyfilter = (e,index) => {
 get_list(1,e);
 setAllclient(false)
};


const initialStateData = {
  client_id: "",
  // message:"",
  file:[]
};
const [inputFields, setInputfields] = useState(initialStateData);

function inputhandler(e) {
  setInputfields({ ...inputFields, [e.target.name]: e.target.value });
}
const [rowsError, setRowsError] = useState(1);
const inputRef = useRef(null);




const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  usermanageService.getclient().then((response)=>{
   
    setUniqueCompaniesData(response.data.clients);
  });
}
function onFileChange(e){
  const files = Array.from(e.target.files);
  console.log(e.target.files)
  setInputfields({ ...inputFields, [e.target.name]: files });
  console.log(inputFields)
}
function connectDoc(){
  axios({
    method: "get",
    url: `${apiUrl}/connect-docusign`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(
      
      function (response) {
        //navigation.navigate( response.data.url ,'_blank');
        window.open(
          response.data.url,
          '_blank'
        );
      }
      );
}
function add_data(){
  const formData = new FormData();
  if(inputFields.client_id==''){
    setclienterr(1);
    return false;
  }
  if(inputFields.file.length ==0){
     setfileerr(1);
     return false;
  }
  setclienterr(null);
  setfileerr(null);
  formData.append(
    "client_id",
    inputFields.client_id
  );
  // formData.append(
  //   "message",
  //   inputFields.message
  // );
  inputFields.file.forEach((file, i) => {
    formData.append(`formFile[${i}]`,
    new Blob([file], { type: file.type }),
    file.name || ""
    );
  });
  axios({
    method: "post",
    url: `${apiUrl}/contract`,
    data: formData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(
      
      function (response) {
        console.log(response)
        if(response.data.status == 'success'){
          toast.success(response.data.message); 
          setclientsearch('');
          inputFields.file='';
          //inputFields.client_id='';
          document.getElementById("docusign-form").reset();
          
       }else{
        toast.error(response.data.error.formFile[0])
       }
        
      },
      (error) => {
        toast.error(response.data.message); 
      }
    )
    .catch(function (error) {});

}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      {/* <Row className="align-items-center row">
            <Col xl="6">
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => connectDoc()}
              >
                {" "}
              + Connect Docusign{" "}
              </Button>{" "}
            </Col>
            
          </Row> */}
        <Row >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            <Form
                role="form"
                id="docusign-form"
                onSubmit={add_data}
              >
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
               <div className="col-xl-6 col-md-6 col-xs-12">
               <h3 className="clientformtag"> Client</h3>
               
                    {(uniquecompanieslist.length > 0)?
                    <>
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="client_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => { setclient(e)}}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                    required
                  />
                  { client_err  && 
                    <span style={{color:'red'}}>Please Select Client</span>
                    }
                    </>
                  
   
                          
                        : null }
                    </div>
                    
                    
                    </div>
                </Row>
                <br></br>
                {/* <div className="filter" style={{width:'80%'}}>
                <div className="col-xl-6 col-md-6 col-xs-12">
                     <h3 className="clientformtag">Message</h3>
                     <Input type="textarea" rows="1" className="col-9" placeholder="Message" name ="message" onChange={inputhandler}/>
                    </div>
                     
                    </div>
          <br></br> */}
                <div className="filter" style={{width:'80%'}}>
                <div className="col-xl-6 col-md-6 col-xs-12">
                     <h3 className="clientformtag">Attachment</h3>
                     <input type="file" multiple required name="file" id="import_file" className="text-right" style={{width:"40%"}} onChange={onFileChange}  />
                     <br></br>
                     { file_err  && 
                    <span style={{color:'red'}}>Please Select File</span>
                    }
                    </div>
                     
                    </div>
                <br></br>
              </CardHeader>
              

              <CardFooter >
              
                <Row>
                <div className="filter" style={{width:'80%'}}>
                <div className="col-xl-6 col-md-6 col-xs-12">
                    <Button
                      color="primary"
                      type="button"
                      onClick={add_data}
                    >
                      {" "}
                    + Send{" "}
                    </Button>
                    </div>
                    </div>
                </Row>
              </CardFooter>
                </Form>
            </Card>
          </div>
        </Row>
    

       
      </Container>
    </>
  );
};


export default Docusign;
