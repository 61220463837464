import { useState, useEffect } from "react";
import { Container, Card, CardHeader, Row, Button } from "reactstrap";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "services/taskmanageService";
import Select from 'react-select';

const KanbanView = (props) => {
  const [podData, setPodData] = useState([]);
  const [processlist, setProcesslist] = useState([]);
  const [inputFields, setInputfields] = useState({ process_order: '', month_year: '', status: '' });
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = (e, pageNumber = 1) => {
    taskmanageService.kanbanviewdata(pageNumber, inputFields).then((response) => {
      setPodData(response.data.data.data);
      setProcesslist(response.data.processlist);
      setStatuses(response.data.statuslist);
    });
  };

  // Group tasks by status
  const groupedTasks = statuses.reduce((acc, status) => {
    acc[status.name] = podData.filter(task => task.status_name === status.name);
    return acc;
  }, {});

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3  style={{ padding: '0 1%' }} className="mb-0">Kanban Board</h3>
                <br></br>
                <Row className="filter" style={{ padding: '0 1%' }}>
                  <div className="col-xl-4 col-md-3 col-xs-12">
                    <Select
                      placeholder="Select phase"
                      className="input-group-alternative"
                      options={processlist}
                      onChange={(e) => setInputfields({ ...inputFields, process_order: e.value })}
                      isClearable={false}
                    />
                  </div>
                  <div className="col-xl-8 col-md-3 col-xs-12">
                    {/* <br></br> */}
                    <Button className="col-xl-3 btn btn-primary" onClick={() => getList()}>Search</Button>
                  </div>
                </Row>
              </CardHeader>

              <div className="kanban-board row" style={{ display: 'flex', padding: '20px' }}>
                {statuses
                  .filter(status => groupedTasks[status.name] && groupedTasks[status.name].length > 0)
                  .map((status) => (
                    <div
                      className="col-xl-3 kanban-column"
                      key={status.name}
                      style={{
                        border: `1px solid ${status.color_code}`,
                        borderRadius: '25px',
                        backgroundColor: 'rgb(93 108 174 / 10%)',
                        margin: '10px',
                        padding: '10px',
                        minWidth: '250px'
                      }}
                    >
                      <h4 style={{
                        textAlign: 'center',
                        padding: '10px',
                        backgroundColor: status.color_code,
                        color: '#fff',
                        borderRadius: '20px'
                      }}>
                        {status.name}
                      </h4>

                      <div style={{ minHeight: '300px' }}>
                        {groupedTasks[status.name]?.map((task) => (
                          <div
                            key={task.id}
                            className="kanban-card"
                            style={{
                              backgroundColor: '#fff',
                              padding: '15px',
                              marginBottom: '15px',
                              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                              borderRadius: '20px',
                              // border: `2px solid ${status.color_code}`
                            }}
                          >
                            <p style={{ fontWeight: 'bold', color: status.color_code}}>{task.name}</p>
                            <p style={{ fontSize: '12px', color: '#555' }}>{task.sub_client}</p>
                            <p style={{ fontSize: '12px', color: '#999' }}>{task.raised_date} - {task.due_date}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default KanbanView;
