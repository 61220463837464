import { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import Select from 'react-select';
const axios = require("axios");

const AddInternalGovernanceCall = (props) => {
  const history = useHistory();
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'All Clients',value:'al_key_1'}]);
  const [usersearch, setusersearch] = useState([{'label':'Select User',value:''}]);
  const [userclientsearch, setusersclientearch] = useState([{'label':'Select Client',value:''}]);
  const regionOption = useRef(null)

useEffect(() => {
  get_unique_companieslist();
  get_user_client_list();
  get_user_list();
}, []);

const months = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
const [monthdata, setmonthdata] = useState(months);
const [disablestate, setdisablestate] = useState(false);
const [disablestatebtn, setdisablestatebtn] = useState(false);

const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}
const [userclientlist, setusersclientlist] = useState([]);
function get_user_client_list(){
  clientmanageService.getusersclientlist().then((response)=>{
    setusersclientlist(response.data.users);
  });
}
const [userlist, setUserListData] = useState([]);
function get_user_list(){
    clientmanageService.getusers().then((response) => {
    setUserListData(response.data.users)
  });
}
const initialData = {
  action_items : [],
  notes : [],
  client_attendees : [],
  corient_attendees : []
}
const [inputFields, setInputfields] = useState(initialData);
 function inputhandler1(e,field) {
  setInputfields((prevInputFields) => ({
    ...prevInputFields,
    [field]: e.value,
  }));

}
function inputhandler(e) {
  let phseup = {}
  phseup = { ...inputFields, [e.target.name]: e.target.value }    
  setInputfields(phseup);
}
  function setclient(e){ 
    setclientsearch(e.label);
    setClientdata([]);
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      ['client_id']: e.value,
    }));
   
  }

  const [ratebox, setRateBox] = useState(0);
 
  const addRatebox = ()=>{
    const setdata = ratebox+1;
    setRateBox(setdata)
   
  }
  const removeRatebox = ()=>{
    if(ratebox > 0){
      const setdata = ratebox-1;
      setRateBox(setdata)
    }
  }
  const [notebox, setNoteBox] = useState(0);
 
  const addNotebox = ()=>{
    const setdata = notebox+1;
    setNoteBox(setdata)
   
  }
  const removeNotebox = ()=>{
    if(notebox > 0){
      const setdata = notebox-1;
      setNoteBox(setdata)
    }
  }
  const [corientbox, setCorientBox] = useState(0);
 
  const addCorientbox = ()=>{
    const setdata = corientbox+1;
    setCorientBox(setdata)
   
  }
  const removeCorientbox = ()=>{
    if(corientbox > 0){
      const setdata = corientbox-1;
      setCorientBox(setdata)
    }
  }
  
  function inputcorienthandler(e, index) {
    const { label, value } = e;
    const corient_attendees = [...inputFields.corient_attendees];
    if (!corient_attendees[index]) {
      corient_attendees[index] = {}; // Initialize the user object if it doesn't exist
    }
    corient_attendees[index] = value;
    setInputfields({ ...inputFields, corient_attendees });
  }

  const [clientbox, setClientBox] = useState(0);
 
  const addClientbox = ()=>{
    const setdata = clientbox+1;
    setClientBox(setdata)
   
  }
  const removeClientbox = ()=>{
    if(clientbox > 0){
      const setdata = clientbox-1;
      setClientBox(setdata)
    }
  }
  
  function inputclienthandler(e, index) {
    const { name, value } = e;
    const client_attendees = [...inputFields.client_attendees];
    if (!client_attendees[index]) {
      client_attendees[index] = {}; // Initialize the user object if it doesn't exist
    }
    client_attendees[index] = e.target.value;
    setInputfields({ ...inputFields, client_attendees });
  }

  function inputactionhandler(e, index) {
    const { name, value } = e.target;
    const action_items = [...inputFields.action_items];
    if (!action_items[index]) {
      action_items[index] = {}; // Initialize the user object if it doesn't exist
    }
    action_items[index][name] = value;
    setInputfields({ ...inputFields, action_items });
  }
  function inputnotehandler(e, index) {
    const { name, value } = e.target;
    const notes = [...inputFields.notes];
    if (!notes[index]) {
      notes[index] = {}; // Initialize the user object if it doesn't exist
    }
    notes[index][name] = value;
    setInputfields({ ...inputFields, notes });
  }

  function addgovernancecall(e){
    let flag = e.target.value;
    e.preventDefault();
    const confirmAndExecute = () => {
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((isConfirm) => {
        if (isConfirm) {
          executeAction();
        }else{setdisablestatebtn(false)}
      });
    };
    const executeAction = () => {
     
      clientmanageService.addinternalgovernancetransdata(inputFields, flag)
        .then((response) => {
          if(response.status == 200){
            toast.success(response.data.message); 
            history.goBack(); 
            document.getElementById("adduser-form").reset();
            setInputfields(initialData);
            setclientsearch([{'label':'All Clients',value:'al_key_1'}]);
         }else{
           console.log(response.data);
           Object.entries(response.data).forEach(([field, errors]) => {
             errors.forEach(error => toast.error(`${error}`));
           });
   
         }
         setdisablestate(false)
         setdisablestatebtn(false)
        })
        
    };
    
    if (flag) {
      setdisablestatebtn(flag);
      confirmAndExecute();
    } else {
      setdisablestate(true);
      executeAction();
    }
  
  }

  function inputactionresphandler(e, index){
   
    const action_items = [...inputFields.action_items];
    if (!action_items[index]) {
      action_items[index] = {}; // Initialize the user object if it doesn't exist
    }
    action_items[index]['responsibility'] = e.value;
    setInputfields({ ...inputFields, action_items });
  }

  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
       
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            <Form role="form" id="adduser-form" >
              <CardHeader className="border-0">
              <Row >
                
              <div className="col-xl-3 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Weekly call date</label>
                         <input type="date"
                                      className="form-control"
                                      name="month"
                                      onChange={(e) => inputhandler(e)}
                                      />
                    </FormGroup> 
                  </div>  
                  <div className="col-xl-3 col-md-4 col-xs-12">
                    <label className="clientformtag"> Client</label>
                    {(uniquecompanieslist && uniquecompanieslist.length > 0)?
                      <Select 
                    
                      className="input-group-alternative mb-3" 
                      name="client_id"
                      options={uniquecompanieslist} // set list of the data
                      onChange={(e) => { setclient(e)}}
                      defaultValue={clientsearch}
                      searchable
                      isClearable={false}
                    />
                          : null }
                    </div>

                    
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Department</label>
                        <select class="form-control" name="department"  onChange={(e) => inputhandler(e)} required>
                              <option value=""> Department</option>
                                <option value="YearEnd">YearEnd</option>
                                <option value="Payroll">Payroll</option>
                              </select>
                      </FormGroup> 
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Meeting Title</label>
                        <Input
                     placeholder="Meeting Title"
                     type="text"
                     name="meeting_title"
                     onChange={(e) => inputhandler(e)}
                     required
                   />
                      </FormGroup> 
                  </div>
                    <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < corientbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                        <label>Name</label>
                        {(userclientlist && userclientlist.length > 0)?
                      <Select 
                    
                      className="input-group-alternative mb-3" 
                      name="name"
                      options={userclientlist} // set list of the data
                      defaultValue={usersearch}
                      searchable
                      onChange={(e) => inputcorienthandler(e, k)}
                      isClearable={false}
                    />
                          : null }
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1">
      {/* {k > 0 && ( */}
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeCorientbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      {/* )} */}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addCorientbox()}>+ Add Corient Attendees </a>
          </Col>
               

          <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < clientbox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-3">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                  <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="name(email)"
                            type="text"
                            name="name"
                            onChange={(e) => inputclienthandler(e, k)}
                            required
                          />
                        </InputGroup>     
                  </div>

                  <div>
                         <button
       type="button"
         className="btn btn-danger" style={{"margin-top":"20px"}}
         onClick={() => removeCorientbox(k)}
       >
         <i className="fa fa-remove"></i>
       </button>
                         </div> 
                    
                    
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    { <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addClientbox()}>+ Add Client Attendees </a>
          </Col> }
               

                  <br></br>
                  <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < ratebox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-12">
                       <FormGroup>
                       {/* <label>Level</label> */}
                       <div className="row">
                       <div className="col-xl-3 col-md-2 col-xs-12">
                       <FormGroup>
                        <label>Feedback</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Feedback"
                            type="text"
                            name="remarks"
                            onChange={(e) => inputactionhandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>  
                    
                  </div>
                  <div className="col-xl-3 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Responsibility</label>
                        {(userlist.length > 0)?
                    // <select  class="form-control" name="responsibility" onChange={(e) => inputactionhandler(e, k)} >
                    // <option value=""> Select User</option>
                    // {(userlist).map((valuel,k) =>
                    //               <option key={k} value={valuel.value}>
                    //                 {valuel.label}
                    //               </option>
                    //           )}
                    // </select>
                    <Select 
                    
                         className="input-group-alternative mb-3" 
                         name="responsibility"
                         options={userlist} // set list of the data
                         defaultValue={usersearch}
                         searchable
                         onChange={(e) => inputactionresphandler(e, k)}
                         isClearable={false}
                       />
                        : null }
                      </FormGroup> 
                  </div>
                  <div className="col-xl-2 col-md-2 col-xs-12">
                    <FormGroup>
                        <label>Target date</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Target date"
                            type="date"
                            name="target_date"
                            onChange={(e) => inputactionhandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup> 
                  </div>
                       
                  <div className="col-xl-3 col-md-2 col-xs-12">
                  <FormGroup>
                        <label>Action Item</label>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Action Item"
                            type="text"
                            name="action_item"
                            onChange={(e) => inputactionhandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup> 
                  </div>

                 
                    
                    <Col md="1">
                    <br></br>
      {/* {k > 0 && ( */}
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRatebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
      {/* )} */}
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addRatebox()}>+ Add action items </a>
          </Col>

          <br></br>
                  {/* <div className="col-md-12">&nbsp;</div>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < notebox; k++) {
                arr1.push(
                 <>
                    
                    <div className="col-md-12">
                       <FormGroup>
                     
                       <div className="row">
                     
                  <div className="col-xl-10 col-md-12 col-xs-12">
                    <FormGroup>
                     
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Note"
                            type="textarea"
                            name="note"
                            onChange={(e) => inputnotehandler(e, k)}
                            required
                          />
                        </InputGroup>
                      </FormGroup>      
                  </div>

                 
                    
                    <Col md="1">
      <br></br>
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeNotebox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
     
    </Col>
                        </div>
                      </FormGroup>    
                    </div> 
                    </>
                );
            }
            return arr1;
            
        })()}
                    <Col md="12" style={{marginBottom:"10px"}}>
            <a className="btn btn-info"  onClick={(e) => addNotebox()}>+ Add Notes </a>
          </Col> */}
               
                </Row>

                
                <Row>
                <div className="col-md-12 d-flex">
                        <label>&nbsp;</label>
                        <Button className="col-2 " color="primary" type="submit" value={0} disabled={disablestate} onClick={(e) => addgovernancecall(e)}>
                          {disablestate ? 'Sending...' : 'Save'} 
                         </Button>
                         <Button className="col-4 " color="primary" type="submit" value={2} onClick={(e) => addgovernancecall(e)} disabled={disablestatebtn}>
                          {disablestatebtn && disablestatebtn==2 ? 'Sending...' : 'Save & Mail to Internal Team'} 
                         </Button>
                         <Button className="col-4 " color="primary" type="submit" value={3} onClick={(e) => addgovernancecall(e)} disabled={disablestatebtn}>
                          {disablestatebtn && disablestatebtn==3 ? 'Sending...' : 'Save & Mail to External Team'} 
                         </Button>
                    </div>
                    </Row>
                    
              </CardHeader>
              </Form>
             </Card>
             </div>
             
        </Row>
      </Container>
    </>
  );
};


export default AddInternalGovernanceCall;
