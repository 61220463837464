import { useState, useEffect } from "react";
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

const RejectionSummary = (props) => {
  const [reportdata, setReportdata] = useState([]);
  const [sortorder, setsortorder] = useState("asc");
  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data();
  },[]);
  
  function get_report_data(e){
    reportmanageService.getReportSummary().then((response) => {
      console.log(response.data) 
      setReportdata(response.data); 
    });
  }
  
  function downloadReport(){

    reportmanageService.rejection_summary_export().then((response) => {
    
    });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
           
        
          <Col xl="12">
          <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    </Col>
        
          <br></br>
          <br></br>
          <br></br>
          
              <Col xl="12">
              
                  <Card style={{padding:'0px 0px 0px 0px',marginBottom:"10px"}}>
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Category</th>
                          <th>{(user_info.role_id == 2 || user_info.role_id == 3) ? 'Analyst' : 'Phase Lead' }</th>
                          <th>Errors</th>
                        </tr>
                      </thead>
                  <tbody>
                  {reportdata.data &&
                    reportdata.data.length &&
                    reportdata.data != null ? (
                      reportdata.data.map((user, index) => (
                        
                        <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td>{user.category_name}</td>
                            <td>{user.user_name}</td>
                            <td>{user.error_count}</td>
                         </tr>
                        
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
                  
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default RejectionSummary;
