
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authHeader} from '../helpers/auth-header';
import {authtoken} from 'global.js';
const axios = require("axios");
export const todoService = {
    addtodo,
    get_assignuser,
    gettodos,
    editRow,
    download_todo_report
};

function addtodo(data){
    return axios({
        method: "post",
        url: `${apiUrl}/addtodo`,
        data: data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}
function get_assignuser(id){
  return axios({
    method: "get",
    url: `${apiUrl}/get_assignuser/${id}`,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      console.log(response);
      // localStorage.setItem("task_count", JSON.stringify(response.data.data));
      return response;
    })
    .catch(function (error) {
      return error;
    }); 
}
function gettodos(page,name){
    return axios({
        method: "get",
        url: `${apiUrl}/gettodos?page=${page}&name=${name}`,
        headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
        })
        .then(function (response) {
          console.log(response);
          // localStorage.setItem("task_count", JSON.stringify(response.data.data));
          return response;
        })
        .catch(function (error) {
          return error;
        });
}
function editRow(value,field,id){
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("field", field);
    bodyFormData.append("value", value);
    return axios({
        method: "POST",
        url: `${apiUrl}/todo-edit`,
        data: bodyFormData,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          return response;
        }, error => {
         // return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
  }
  function download_todo_report(start_date,end_date){

    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/todo-export`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `todo-summary-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }
  