import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  UncontrolledPopover 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
const axios = require("axios");
import { apiUrl } from "../../constant";
import { authtoken } from "global.js";
import {Formloader} from 'components/Loader/PageLoader.js';
import Paginationcomponent from "components/Paginationcomponent.js"
const PriceReviosion = (props) => {
  const [filtertype,setfiltertype] = useState('total');
  const [dashboarddata, setDashboarddata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [total_page,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  
  const handlePageClick = (e, index) => {
 
    e.preventDefault();
    get_list(index,null,filtertype,rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev,null,filtertype,rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next,null,filtertype,rowsPerPage);
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    
    setCurrentPage(1); 
    get_list(1,null,filtertype,newRowsPerPage)
  
  };
  const get_list = async (pageNumber = 1,e,filtertype='',rowsPerPage='',year='') => {
    let  textbox = '';
 
    if(e != null){
       textbox = e.target.value;
    }
   
    taskmanageService.getPriceRevisionDashboard(pageNumber,textbox,filtertype,rowsPerPage,year).then((response) => {
     
      setDashboarddata(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalData(response.data.total);
    });
  };
  function filterallcom({type}){
    setfiltertype(type)
    get_list(1,null,type);
 }

 useEffect(() => {
  get_dashboard_data();
  get_list(1,null,filtertype);
  
}, []);
function mailsend(id){
    taskmanageService.anniversarymailsend(id).then((response)=>{
        if (response.status == 200) {
            toast.success(response.data.message);
            get_list(1,null,filtertype);
        }
    })
}


const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }
    return years;
};
const years = getYears(2000);
const [selectedYear, setSelectedYear] = useState('');
const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    get_list(1,null,filtertype,rowsPerPage,e.target.value);
}
function editData(value,column,id){
    taskmanageService.editCommonTable(value,column,id,'anniversary_email_send').then((response)=>{
        if (response.status == 200) {
            toast.success(response.data.message);
            get_list(1,null,filtertype);
        }
    })
}
const [dashboardStatistics, setdashboardStatistics] = useState([]);
function get_dashboard_data() {
    taskmanageService.anniversarydashboardCountData().then((response) => {
      setdashboardStatistics(response.data.data);
    });
  } function downloadReport(){
    taskmanageService.download_anniversary_report(1,'',filtertype,rowsPerPage,'').then((response) => {

    });
  }

  return (
    <>
    <Header />
     <Container className="mt--7" fluid>
        <Row>
        <Col>
         <h2>Anniversary dashboard</h2>
            
         
          </Col>
        </Row>
        <Row className="dash-analy">
          <Col xl="3" md="6" xs="12"  className="dash-col-analy-3" >
           
                <Row className={(filtertype=='total' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,'total'),setfiltertype('total')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Total
                    </h6>
                    <h1 style={{ color: "#0060B8" }}>
                      {dashboardStatistics.total}
                    </h1>
                    <h3 className="mb-0" style={{ color: "#0060B8" }}>
                      Total 
                    </h3>
                  </div>
                </Row>
             
          </Col>
          <Col xl="3" md="6" xs="12"  className="dash-col-analy-3" >
           
                <Row className={(filtertype=='assigned' ? "align-item-s-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,'mail_sent'),setfiltertype('mail_sent')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Mail Sent
                    </h6>
                    <h1 style={{ color: "#FF1717" }}>
                      {dashboardStatistics.mail_sent}
                    </h1>
                    <h3 className="mb-0" style={{ color: "#FF1717" }}>
                    Mail Sent 
                    </h3>
                  </div>
                </Row>
             
          </Col>
          <Col xl="3" md="6" xs="12" className="dash-col-analy-3">
           
                <Row  className={(filtertype=='overdue' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,'approve'),setfiltertype('approve')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Approved
                    </h6>
                    <h1 style={{ color: "#FF8C00" }}>
                      {dashboardStatistics.approve}
                    </h1>
                    <h3 style={{ color: "#FF8C00" }} className="mb-0">
                    Approved 
                    </h3>
                  </div>
                </Row>
              {/* </CardHeader>
            </Card> */}
          </Col>
        
          <Col xl="3" md="6" xs="12" className="dash-col-analy-3">
             <Row  className={(filtertype=='completed' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,'rejected'),setfiltertype('rejected')}}>
                    <h6 className="text-uppercase text-muted text-dash ls-1 mb-1">
                      Rejected
                    </h6>
                    <h1 style={{ color: "#0DAF84" }}>
                      {dashboardStatistics.reject}
                    </h1>
                    <h3 style={{ color: "#0DAF84" }} className="mb-0">
                    Rejected 
                    </h3>
                  </div>
                </Row>
           
          </Col>
          
        
        </Row>

      <Row>
        
          </Row>
          
         
            <div className="col" style={{padding:'0%'}}>
              <Card className="shadow">
              <CardHeader>
                  <div className="filter">
                  
                    <div   style={{maxWidth:'100%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    {/* <select
        id="year"
        name="year"
        value={selectedYear}
        onChange={handleYearChange}
        className="form-control"
        
      >
        <option value="">Select Year</option>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select> */}

                    </div></div>
              </CardHeader>
              <Table className="align-items-center table-flush"   responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="whitespace sortclass" >Sr. no</th>
                    <th scope="col">Client name</th>
                    <th>Start agreement date </th>
                    <th scope="col">Days since agreement</th>
                    <th scope="col" className="whitespace sortclass">Email Send</th>
                    
                    
                    
                  </tr>
                </thead>
               <tbody>
               {dashboarddata.length > 0 && dashboarddata  ? (
                        dashboarddata.map((item, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                  
                    <td className="text-left">{i + 1}</td>
                    <td className="text-left">{item.name}</td>
                    <td className="text-left">{item.agreement_date}</td>
                    <td className="text-left">{item.days_since_agreement}</td>
                    <td className="text-center">
      {item.approve == null ? (
         <Button className="btn btn-success" 
         onClick={(e) =>
           mailsend(
             item.id
           )
         } class="btn btn-success"><i class="fa fa-envelope"></i></Button>
      ) : (
        <>
          {item.approve == 0 ? (
            <>
              <Button
                color="primary"
                style={{
                  background: "#02C779",
                  border: "#02C779",
                  borderRadius: "18px",
                }}
                size="sm"
                type="button"
                onClick={() =>
                  editData(1, "approve", item.id)
                }
              >
                Approve
              </Button>
              <Button
                color="primary"
                style={{
                  border: "1px solid #FF6430",
                  background: 'none',
                  color: "#FF1717",
                  borderRadius: "18px",
                }}
                size="sm"
                type="button"
                onClick={() =>
                    editData(2, "approve", item.id)
                }
              >
                Reject
              </Button>
            </>
          ) : (
            <span>{item.approve == 1 ? 'Approved' : 'Rejected'}</span>
          )}
        </>
      )}
    </td>      
                    
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
               </tbody>
              </Table>
              <CardFooter className="py-4">
              <div>
     
              <Paginationcomponent
        currentPage={currentPage}
        totalItems={total_page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handlePageClick={handlePageClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
                </div>
              </CardFooter>
              </Card>
            </div>
            
          
       
     </Container>
    </>
  );
};


export default PriceReviosion;
