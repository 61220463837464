import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";

import {
  useHistory
} from "react-router-dom";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Modal,
  ModalBody 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { reportmanageService } from "../../../services/reportmanageService";
import { taskmanageService } from "../../../services/taskmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

const PhasesMISReport = (props) => {
  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [sortorder, setsortorder] = useState("asc");
  const [state, setState] = useState({startDate:null,endDate:null});
  const [statesend, setStatesend] = useState({startDate:'',endDate:''});
  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data();
  },[]);
  
  function get_report_data(e='',start_date=statesend.startDate,end_date=statesend.endDate){
    if(e!=''){
      var phase = e.target.value;
    }else{
      var phase = '';
    }

    reportmanageService.getPhasesMISReportData(phase,start_date,end_date).then((response) => {
      console.log(response)
      setReportdata(response); 
    });
  }
  function report_details(company,status=''){
     history.push({
       pathname: "monthly_report_details/"+company+"/"+status,
     });

  }
  // date range
  function setEndDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
    get_report_data('',statesend.startDate, dd);
  }
  
  function setStartDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
  }
  // end
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  //console.log(user_info)
  function downloadReport(start_date=statesend.startDate,end_date=statesend.endDate){
    reportmanageService.getPhasesMISReportDataDownload(start_date,end_date).then((response) => {

    });
  }
  const [clientdetails,setclientdetails] = useState({});
  const [clientdetailPopup,setclientdetailPopup] = useState(false);
  const Detail = (phase_id,index,type,start_date=statesend.startDate,end_date=statesend.endDate) => {
    
    taskmanageService.getPhaseClientdetail(phase_id,type,start_date,end_date).then((response) => {
      console.log(response.data)
      setclientdetails(response.data);
      setclientdetailPopup(!clientdetailPopup);
    })
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
           
             {/* { (reportdata.show == 1) ? 
             <>
         
          <Col xl="12">
          <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    </Col>
                    </>
            : null } */}
          <br></br>
          <br></br>
          <br></br>
              <Col xl="12">
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                    <Row>
                    {user_info.role_id == 6 && reportdata.phases ? 
                      <Col xs={4}>
                          <select className="form-control" onChange={(e) => get_report_data(e)}  name="value" >
                            <option value="" key="al_key_1">All Phases</option>
                            {(reportdata.phases).map((valuel,k) =>
                                <option key={k} value={valuel.id}>
                                  {valuel.desc}
                                </option>
                            )}
                          </select>
                      </Col>
                    : null}
                    <Col xs={4}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                    <div className="col-xl-12 col-md-12 col-xs-12 ">
                      <Button
                        className="btn-icon-clipboard"
                        type="button"
                        onClick={(e)=>downloadReport()}
                        title="Download Report"
                      >
                        <i className="fas fa-download" style={{ fontSize: '14px' }}></i> Export
                      </Button>
                    </div>
                    </Col>
                  </Row>
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                           <th>Phase</th>
                           <th>Total no of Jobs received</th>
                           <th>Total no of Jobs completed</th>
                           <th>Completed in 1 day</th>
                           <th>Completed in 2 days</th>
                           <th>Completed in 2 to 4 days</th>
                           <th>Completed in 4 to 6 days</th>
                           <th>Completed in 6 to 8 days</th>
                           <th>Completed in 8 to 10 days</th>
                           <th>Completed in more than 10 days</th> 
                      </thead>
                      <tbody>
                        {reportdata.data &&
                          reportdata.data.length &&
                          reportdata.data != null ? (
                            reportdata.data.map((phase, index) => (
                              <tr style={{backgroundColor: (index % 2 == 0) ? '#F4F4F4' : '#FFFFFF'}}>
                                  <td>{phase.phase_name}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'total_jobs')}>{phase.total_jobs}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'total_completed_jobs')}>{phase.total_completed_jobs}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_1_day')}>{phase.completed_in_1_day}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_2_days')}>{phase.completed_in_2_days}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_2_to_4_days')}>{phase.completed_in_2_to_4_days}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_4_to_6_days')}>{phase.completed_in_4_to_6_days}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_6_to_8_days')}>{phase.completed_in_6_to_8_days}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_8_to_10_days')}>{phase.completed_in_8_to_10_days}</td>
                                  <td style={{cursor:'pointer'}} onClick={() => Detail(phase.phase_id, index,'completed_in_more_than_10_days')}>{phase.completed_in_more_than_10_days}</td>
                              </tr>
                          ))
                          ) : 'No records found' }
                        </tbody>
                  </Table>
    
                  </Card>
              </Col>
             </Row>
             <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setclientdetailPopup(!clientdetailPopup)}
            isOpen={clientdetailPopup}
          >
            <div className="modal-header">
            <h3>Client Details</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setclientdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          
              <Table>
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th className="whitespace">Sub Client Name</th>
                    <th className="whitespace">Status</th>
                    <th className="whitespace">Book Keeping software</th>
                    <th className="whitespace">Acc. prod. software</th>
                    <th className="whitespace">Type</th>
                    <th className="whitespace">QA TL</th>
                    <th className="whitespace">QA TM</th>
                    <th className="whitespace">Budgeted Hours</th>
                    <th className="whitespace">Year end Date</th>
                    <th className="whitespace">Job Received Date</th>
                    <th className="whitespace">Due date</th>
                    <th className="whitespace">Job Completion Date</th> 
                    
                  </tr>
                </thead>
                <tbody>
                {clientdetails && clientdetails.length
                          ?                               
                          clientdetails.map((client,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{client.name}</td>
                                <td  className="whitespace">{client.sub_client}</td>
                                <td  className="whitespace">{client.status_name}</td>
                                <td  className="whitespace">{client.book_keeping_software}</td>
                                <td  className="whitespace">{client.account_prod_software}</td>
                                <td  className="whitespace">{client.type}</td>
                                <td  className="whitespace">{client.lead}</td>
                                <td  className="whitespace">{client.member}</td>
                                <td  className="whitespace">{client.budget}</td>
                                <td  className="whitespace">{client.year_end_date}</td>
                                <td  className="whitespace">{client.raised_date}</td>
                                <td  className="whitespace">{client.due_date}</td>
                                <td  className="whitespace">{client.completed_date}</td>
                                
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
        </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default PhasesMISReport;
