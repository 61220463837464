import React, { useState } from 'react';
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroup, InputGroupText, InputGroupAddon, Row, Col, UncontrolledAlert } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { apiUrl } from '../../constant';
import { userService } from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
import VerifyTwoFactor from './VerifyTwoFactor'; // Import the VerifyTwoFactor component

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(null);
  const [is2FARequired, setIs2FARequired] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const authenticate = async (e) => {
    e.preventDefault();
    
    if (!email || !password) {
      toast.error('All fields are required!');
      return;
    }

    try {
      setIsLoading(true); 
      const response = await userService.login(email, password, remember);

      if (response.status == 200) {
        setError(null)
        if (response.data.status == '2fa_required') {
          setIs2FARequired(true);
          setUserId(response.data.user_id);
        } else {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("authtoken", response.data.access_token);
          history.push('/admin');
        }
      } else if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }else{
        toast.error("Invalid email or password!");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Invalid email or password!");
      console.error("Login error:", error);
    }
  };

  const handle2FAVerificationSuccess = () => {
    // Proceed with login after 2FA verification
    authenticate({ preventDefault: () => {} });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <ToastContainer />
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h1>Sign in</h1>
            </div>
            {error && <UncontrolledAlert className="alert-danger" fade={false}>{error}</UncontrolledAlert>}
            {is2FARequired ? (
              <VerifyTwoFactor userId={userId} onVerificationSuccess={handle2FAVerificationSuccess} />
            ) : (
              <Form id="login-form" role="form" onSubmit={authenticate}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="checkbox"
                    name="remember"
                    onChange={(e) => setRemember(e.target.checked)}
                  />
                  <label style={{fontSize:"13px"}}>
                    &nbsp; &nbsp;<span className="text-muted">Remember me</span>
                  </label>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                      <span>Loading...</span> // Simple text loader
                    ) : (
                      'Sign in'
                    )}
                    </Button>
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <p className="text-light" onClick={() => history.push('forgotpassword')}>
              <a className="text-light" href="#" onClick={(e) => e.preventDefault()}>
                <small>Forgot password?</small>
              </a>
            </p>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
