import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Card,
  CardHeader,
  Table,
  Row,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "services/taskmanageService";
import Select from 'react-select';

// Import Drag and Drop components
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PodScreen = (props) => {
  const history = useHistory();
  const [podData, setPodData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPageData] = useState(0);
  const [processlist, setProcesslist] = useState([]);
  const initialData = {
    process_order : '',
    month_year : '',
    status : ''
  }
  const [inputFields, setInputfields] = useState(initialData);
  useEffect(() => {
    getList();
  }, []);

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      const prev = currentPage - 1;
      getList(null, prev);
    }
  };

  const handleNextClick = () => {
    if (currentPage < lastPage) {
      const next = currentPage + 1;
      getList(null, next);
    }
  };

  const handlePageClick = (e, index) => {
    e.preventDefault();
    getList(null, index);
  };

  const getList = (e, pageNumber = 1) => {
    taskmanageService.getpodData(pageNumber,inputFields).then((response) => {
      setPodData(response.data.data.data);
      setProcesslist(response.data.processlist)
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };

  // Handle Drag End event to reorder items
  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside the list, exit
    
    const items = Array.from(podData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    // Update the podData state with the reordered items
    setPodData(items);
  
    // Now call API to update the new order in the backend
    try {
      const reorderedIds = items.map((item) => item.id); // Get new order of IDs
      taskmanageService.reorderTasks(reorderedIds).then((response) => {
        console.log("Reorder successful!");
      }).catch((error) => {
        console.error("Error updating order:", error);
      });
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };
  
  function inputhandler1(e,field) {
    if(field=='month_year'){
      setInputfields((prevInputFields)=>({ ...prevInputFields, [field]: e.target.value } ));
    }else{
      console.log()
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      [field]: e.value,
    }));
    }
  
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Phases data</h3>
                <Row className="filter" style={{padding:'0 1%'}}>
              
                  <div className="col-xl-4 col-md-3 col-xs-12">
                      {/* <h3 className="clientformtag"> Select Phase</h3> */}
                      <Select 
                      placeholder="Select phase"
                        className="input-group-alternative " 
                        name="reviewer_id"
                        options={processlist} // set list of the data
                        onChange={(e) => inputhandler1(e,'process_order')}
                        searchable
                        isClearable={false}
                    />
                   </div>
                   {/* <div className="col-xl-2 col-md-3 col-xs-12">
                      <h3 className="clientformtag"> Select Status</h3>
                      <Select 
                        className="input-group-alternative " 
                        name="reviewer_id"
                        // options={statuslist} // set list of the data
                        onChange={(e) => inputhandler1(e,'status')}
                        searchable
                        isClearable={false}
                    />
                   </div> */}
                  {/* <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> Select month</h3>
                    <input type="month"
                                      className="form-control"
                                      name="month_year"
                                      onChange={(e) => { inputhandler1(e,'month_year')}}
                                      value={inputFields.month_year}
                                      options={monthdata}
                                      required
                                      />
                                       {inputFields.month_year && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                      
                  </div> */}
                
                                  
                    <div className="col-xl-8 col-md-3 col-xs-12">
                    <br></br>
                      <Button className="col-xl-3 btn btn-primary"  onClick={(e) => getList()}>Search</Button>
                    </div>
                    {/* </div> */}
                </Row>
              </CardHeader>

              {/* DragDropContext wraps the entire area that allows drag and drop */}
              <DragDropContext onDragEnd={handleOnDragEnd}>
              
              <Droppable droppableId="podList">
  {(provided) => (
    <div 
      {...provided.droppableProps} 
      ref={provided.innerRef}  // Assign ref to the wrapping container
    >
     
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Client</th>
            <th scope="col">Sub client</th>
            <th scope="col">Status</th>
            <th scope="col">Raised Date</th>
            <th scope="col" style={{ width: "10%" }}>Due Date</th>
          </tr>
        </thead>
        <tbody>
          {podData.length > 0 ? (
            podData.map((item, index) => (
              <Draggable
                key={item.id} 
                draggableId={item.id.toString()} 
                index={index}
              >
                {(provided) => (
                  <tr
                    ref={provided.innerRef}  // Assign ref to the actual row
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={
                      index % 2 === 0
                        ? "blue-row"
                        : index % 3 === 0
                        ? "purple-row"
                        : "orange-row"
                    }
                  >
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.sub_client}</td>
                    <td>{item.status_name}</td>
                    <td>{item.raised_date}</td>
                    <td>{item.due_date}</td>
                  </tr>
                )}
              </Draggable>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center">
                No Data Found!
              </td>
            </tr>
          )}
          {provided.placeholder}  {/* This is important for proper spacing during drag */}
        </tbody>
      </Table>
    </div>
  )}
</Droppable>

              </DragDropContext>

              <CardFooter className="py-4">
                <nav aria-label="Pagination">
                  <Pagination className="pagination justify-content-end mb-0">
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(lastPage).keys()].map((_, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink onClick={(e) => handlePageClick(e, i + 1)}>
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= lastPage}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PodScreen;
