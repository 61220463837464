import { useState, useEffect,useRef } from "react";
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  UncontrolledPopover 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import { manageService } from "../../services/manageaccountservices";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
const axios = require("axios");
import { saveAs } from 'file-saver';
import myImage from "./Pdfdisplayimage.js";
import headImage from "./Pdfheadimage.js";
import {apiUrl} from '../../constant';
import {authtoken} from '../../global.js';

const BillingUnlock = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [rowbox, setRowBox] = useState(0);
 
  useEffect(() => {
    // get_billing_details();
    get_unique_companieslist();

  }, []);
  const [billingdetails, setBillingDetails] = useState([]);
  
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
  const [pdfData, setPdfData] = useState(false);
  const [loading, setLoading] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [breakupPopup,setbreakuppopup] = useState(false);
 
 function get_unique_companieslist(){
  
  clientmanageService.getbillingclientlist().then((response)=>{
    if(response.status === 200){
    setUniqueCompaniesData(response.data);
    }
   });
 }
 const [ subclientlist, setsubclientlist ] = useState([])
 
   function subclientList(clientname=''){
     clientmanageService.getsubclientlist(clientname).then((response) => {
         if(response.status === 200){
           setsubclientlist(response.data)
         }else {
           toast.error('Something went wrong!');
         }
     });
   }
   const [clientsearch, setclientsearch] = useState("");
   function setclient(e){
    subclientList(e.target.value)
    // var index = e.target.selectedIndex;
  
     setclientsearch(e.target.value)
    // setclientsearch(e.target.value);
  //   const cloneValues = {...filterFields};
  //   delete cloneValues[2];
  //  setfilterFields(cloneValues);
  }

  const [PushedStatus, setPushedStatus] = useState(false);
  const [filterFields,setfilterFields] = useState({department : "",subclient : "",fromdate : "",todate : "",client : "",description:"Consulting services as agreed (20% off standard rate)",amount:100,quantity:1,account_code:200,tax_rate:''});
  const companyfilter = (e) => {
    
    setfilterFields({ ...filterFields, [e.target.name]: e.target.value } );
    if(e.target.name=='todate'){
      var start_date = filterFields.fromdate;
      var end_date = e.target.value;
      clientmanageService.getunlockbillingclientlist(start_date,end_date).then((response)=>{
        if(response.status === 200){
        setUniqueCompaniesData(response.data);
        }
       });
      
    }
  }


  const applyfilter = (e) => {
    setLoading(true)
    console.log(filterFields)
    clientmanageService.getunlockbillingdata(filterFields).then((response)=>{
      if (Array.isArray(response.data.data)) {

      setBillingDetails(response.data.data);

     }else{
      setBillingDetails([]);
     }
     setPushedStatus(response.data.already_pushed)
     setLoading(false)
    });
  }



 

 
  let totalFinalBillValue = 0;
  let totalbreakupValue  = 0;
  // const calculateTotalFinalBillValue = () => {
  //   return billingdetails.reduce((sum, item) => sum + item.finalbillval, 0);
  // };
  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const pdfRef = useRef(null);
  
  const [disablestate, setdisablestate] = useState(false);

    const editField = (value, field, id, index) => {
      taskmanageService.editBillingRow(value, field, id,'timesheet_billing_data').then((response) => {
        let temp_st = [...billingdetails];
        let temp_ele = { ...temp_st[index] };
       
        if(response.status == 200){
            
            toast.success(response.data.message);
            temp_ele[field] = value;
        }else{
            toast.error(response.data.message);
        }
        temp_ele.mainediting = false;
        temp_st[index] = temp_ele;
        setBillingDetails(temp_st);
      });
    };

    const handlemaineditRow = (index) => {
        let temp_state = [...billingdetails];
        let temp_element = { ...temp_state[index] };
        temp_element.mainediting = true;
        temp_state[index] = temp_element;
        setBillingDetails(temp_state);
    
    };

    

    const addRowbox = ()=>{
      const setdata = rowbox+1;
      setRowBox(setdata)
    }

    const removeRowbox = ()=>{
      if(rowbox > 0){
        const setdata = rowbox-1;
        setRowBox(setdata)
      }
    }

    const initialData = {
      addrows : []
    }
    const [inputFields, setInputfields] = useState(initialData);

    function inputrowhandler(e, index) {
      const { name, value } = e.target;
      const addrows = [...inputFields.addrows];
      if (!addrows[index]) {
        addrows[index] = {
          client : filterFields.client,
          date: filterFields.todate
        }; // Initialize the user object if it doesn't exist
      }
      addrows[index][name] = value;
      setInputfields({ ...inputFields, addrows });
    }

   
    



   
    const initialDatabelowtotal = {
      addrows : []
    }
    const [inputfieldsbelow,setInputfieldsbelowtotal] =useState(initialDatabelowtotal);
    function saveRowBelowTotal(type){
      console.log(inputfieldsbelow)
      clientmanageService.addBillingRowbelowtotal(inputfieldsbelow,type).then((response)=>{
        if(response.status == 200){
           toast.success(response.data.message); 
           setInputfields(initialData);
           applyfilter()
           setRowBox(0)
           
          
        }else{
          console.log(response.data);
           toast.error(response.data.message);
        }
        setdisablestate(false)
      });
    }
    function inputrowhandlerbelowtotal(e, index,type) {
     
      const { name, value } = e.target;
      const addrows = [...inputfieldsbelow.addrows];
      if (!addrows[index]) {
        addrows[index] = {
          client : filterFields.client,
          date: filterFields.todate,
          department: filterFields.department,
          type:type
        }; 
      }
      
      addrows[index][name] = value;
      setInputfieldsbelowtotal({ ...inputfieldsbelow, addrows });
    }
    const synctotimesheet = (e) => {
      setdisablestate(true)
          console.log(filterFields)
          clientmanageService.synctotimesheetunlock(filterFields).then((response)=>{
         
            setdisablestate(false)
          });
    }
    function downloadReport(){
     
      clientmanageService.billingunlock_export(filterFields).then((response) => {
      });
    }
  return (
    <>
    <Header />
    
      <Container className="mt--9" fluid>
        <Row>
        <div className="col-md-12" style={{textAlign:'right'}}>
      
                        </div>
                        </Row>
   
        <Row className="mt-0">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter">
               <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="mb-0" style={{fontSize:'20px'}}> Billing Unlock Data &nbsp;&nbsp;</h3><br></br>
                    </div>
                    <div className="col-xl-2 col-md-2 col-xs-12">
                    <h5 className="clientformtag"> Department</h5>
                        <select class="form-control" name="department"  onChange={(e) => companyfilter(e)} required>
                              <option value=""> All Department</option>
                                <option value="Year End">Year End</option>
                                <option value="bookkeeping">Book-keeping</option>
                              </select>
                            
                  </div>
                    
                        {/* <div className="col-xl-2 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Sub Client</h5>
                            <select  class="form-control" name="subclient"  onChange={(e) => companyfilter(e)}>
                              <option value=""> All Sub Client</option>
                              {subclientlist.map(res => (
                                  <option
                                    key={res.sub_client}
                                    value={res.sub_client}
                                  >
                                    {res.sub_client}
                                  </option>
                                ))}
                            </select>
                        </div> */}
                        {/* <div className="col-xl-2 col-md-2 col-xs-12">
                        <h5 className="clientformtag">Start date</h5>
                        <input type="date"
                                      className="form-control"
                                      name="fromdate"
                                      onChange={(e) =>
                                          companyfilter(e)
                                        }
                                      />
                        </div>
                        <div className="col-xl-2 col-md-2 col-xs-12">
                        <h5 className="clientformtag">End date</h5>
                        <input type="date"
                                      className="form-control"
                                      name="todate"
                                      onChange={(e) =>
                                          companyfilter(e)
                                        }
                                      />
                        </div> */}
                        <div className="col-xl-3 col-md-2 col-xs-12">
                  <h5 className="clientformtag"> Month</h5>
                        <input type="month"
                                      className="form-control"
                                      name="todate"
                                      onChange={(e) =>
                                          companyfilter(e)
                                        }
                                    
                                        
                                      />
                        </div>
                        <div className="col-xl-2 col-md-2 col-xs-12">
                  <h5 className="clientformtag"> Client</h5>
                        {(uniquecompanieslist.length > 0)?
                              <select className="form-control" value={clientsearch} name="client" onChange={(e) => { companyfilter(e),setclient(e);}}   >
                                  <option value="" key="al_key_1">All Clients</option>
                                  {(uniquecompanieslist).map((valuel,k) =>
                                      <option key={k} value={valuel.client}>
                                        {valuel.client}
                                      </option>
                                  )}
                              </select>
                            : null }
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                          <br></br>
                         
                        <Button
                          className="dash-button "
                          color="primary"
                          type="button"
                          onClick={() => applyfilter()}
                        >
                          {" "}
                         Show
                        </Button>{" "}
                    
                        </div>
                        
                    </div>
                   
                </Row>
                <br></br>
                <Row>
                
                <div className="col-xl-12 col-md-12 col-xs-12 d-flex" >
                          
                         
                        <Button
                          className="dash-button "
                          color="secondary"
                          type="button"
                          disabled={disablestate}
                          onClick={() => synctotimesheet()}
                        >
                          {" "}
                          {disablestate ?  'Syncing' : 'Sync to timesheet' }
                        </Button>{" "}
                        <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center',width:'180px'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                        
                        </div>
                        </Row>
                {(loading == true) ? 
                      <><br></br><a style={{color:"#5d6cae"}}  >Downloading please wait.. </a></>
                      :
                      <>
                
                
                <br></br>
                { billingdetails.length > 0 ?
                <>
              <br></br>
             <h3><b>Accountant Name : {filterFields.client}</b></h3>
             <h3><b>Date : {filterFields.fromdate} - {filterFields.todate}</b></h3>
             
             </>
             
                  : null }
              <Table className="align-items-center table-flush" responsive>
               
              { billingdetails.length > 0 ?
                <>
                 <thead className="thead-light">
                    <th>Client</th> 
                    {/* <th scope="col"  className="whitespace sortclass" onClick={(e) => sortTable('sub_client')}>Sub Client   
                   </th> */}
                     {/* <th scope="col" className="whitespace">Actual Hours</th>
                    <th scope="col" className="whitespace">Billed Hours</th> */}
                    <th scope="col" className="whitespace">Billing hours</th>
                    <th scope="col" className="whitespace">Final Billing hours</th>
                    <th scope="col" className="whitespace">Services</th>
                    {/* <th scope="col" className="whitespace">Approval</th> */}
                </thead>
                </>
                  : null }
                <tbody>
                {billingdetails.length > 0 ? (
                  <>
                    {billingdetails.map((item, i) => {
                      totalFinalBillValue += parseFloat(item.edited_billed_hours); // Calculate cumulative sum

                      return (
                        <tr key={i} className={i % 2 === 0 ? 'blue-row' : i % 3 === 0 ? 'purple-row' : 'orange-row'}>
                          {/* <td style={{ cursor: 'pointer' }}>{item.client}</td> */}
                          <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="text"
                              className="form-control"
                              defaultValue={item.subclient}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "subclient",
                                    item.id,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.subclient}</span>
                            )}
                          </td>
                          {/* <td style={{ cursor: 'pointer' }}>{item.subclient_editable}</td> */}
                          <td>{item.finalbillval.toFixed(2).replace(/\.00$/, '')}</td>
                          <td  onClick={()=> handlemaineditRow(i)} >
                            {item.mainediting ? (
                              <input type="number"
                              className="form-control"
                              defaultValue={item.edited_billed_hours.toFixed(2).replace(/\.00$/, '')}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value,
                                    "edited_billed_hours",
                                    item.id,
                                    i
                                  )
                                }
                              />
                            ) : (
                              <span>{item.edited_billed_hours.toFixed(2).replace(/\.00$/, '')}</span>
                            )}
                          </td>
                          <td>{item.services}</td>
                          {/* <td>
                          <span
                                  color="primary"
                                  style={{
                                    color:
                                      item.approval == 1
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >
                                  {(item.approval == 1) ? 'Approved' : ''}
                                </span></td> */}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={3}>No Data Found!</td>
                  </tr>
                )}
                   { billingdetails.length > 0 ?
                   <>
                  {(() => {
            const arr1 = [];
            for (let k = 0; k < rowbox; k++) {
                arr1.push(
                 <>
                  <tr className={k % 2 === 0 ? 'blue-row' : k % 3 === 0 ? 'purple-row' : 'orange-row'}>
                    <td>
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Sub client"
                              type="text"
                              name="sub_client"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    <td >
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Billing hours"
                              type="number"
                              name="finalbillval"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    <td>
                      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Final billing hours"
                              type="number"
                              name="edited_billed_hours"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td>
                    {/* <td>
                      <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="date"
                              type="date"
                              name="date"
                              onChange={(e) => inputrowhandler(e, k)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>      
                    </td> */}
                  <td>
                  <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
                  <select class="form-control" name="department"  onChange={(e) => inputrowhandler(e,k)} >
                              <option value=""> All Department</option>
                                <option value="Year End">Year End</option>
                                <option value="bookkeeping">Book-keeping</option>
                              </select>
                              </InputGroup>
                        </FormGroup>      
                  </td>
                    
                    <td >
      {/* {k > 0 && ( */}
      <FormGroup>
                          {/* <label>Name</label> */}
                          <InputGroup className="input-group-alternative mb-3">
     
        <button
        type="button"
          className="btn btn-danger"
          onClick={() => removeRowbox(k)}
        >
          <i className="fa fa-remove"></i>
        </button>
        </InputGroup>
                        </FormGroup>      
      {/* )} */}
    </td>
                  </tr> 
                    </>
                );
            }
            return arr1;
            
        })()}
          
        </>
: null }
               
                
                { billingdetails.length > 0 ?
                <>
                            <tr>
                              <td align="left"><b>Total</b></td>
                              <td></td>
                              <td> <b>{totalFinalBillValue.toFixed(2)}</b></td>
                              <td colSpan={2}></td>
                            </tr>
                            </>
                  : null }
     
                  
              </tbody>
               
              </Table>
              </>
}
              </CardHeader>
              <Row>
              <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Push data to xero</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
                      <Row>
                      <Col xl="4">
                        <FormGroup>
                          <Input
                              
                              name="description"
                              type="text"
                              placeholder="description"
                              value={inputFields.description}
                              onChange={(e) => companyfilter(e)}
                            >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xl="1">
                        <FormGroup>
                          <Input
                              
                              name="quantity"
                              type="number"
                              placeholder="quantity"
                              onChange={(e) => companyfilter(e)}
                              value={inputFields.quantity}
                            >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                        <FormGroup>
                          <Input
                              
                              name="amount"
                              type="number"
                              placeholder="unit amount"
                              value={inputFields.amount}
                              onChange={(e) => companyfilter(e)}
                            >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                        <FormGroup>
                          <select class="form-control" name="account_code" value={inputFields.account_code}  onChange={(e) => companyfilter(e)}>
                            <option value="">Select Account Code</option>
                            <option value="1005">Movement in WIP</option>
                            <option value="4000">Sales</option>
                            <option value="4001">Sales Accountants y/e</option>
                            <option value="4002">Sales Payroll</option>
                            <option value="4003">SME Processing</option>
                            <option value="4004">Sales Accountants Book Keeping</option>
                            <option value="4010">Consultancy Fees (4010)</option>
                            <option value="4011">Other Revenue</option>
                            <option value="4900">Software Sales</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                        <FormGroup>
                          <select class="form-control" name="tax_rate" value={inputFields.account_code}  onChange={(e) => companyfilter(e)}>
                            <option value="">Select Tax Rate</option>
                            <option value="20% (VAT on Income)">20% (VAT on Income)</option>
                            <option value="5% (VAT on Income)">5% (VAT on Income)</option>
                            <option value="Exempt Income">Exempt Income</option>
                            <option value="No VAT">No VAT</option>
                            <option value="Zero Rated EC Goods Income">Zero Rated EC Goods Income</option>
                            <option value="Zero Rated EC Services">Zero Rated EC Services</option>
                            <option value="Zero Rated Income">Zero Rated Income</option>
                            
                          </select>
                        </FormGroup>
                      </Col>
                      </Row>
            <Button className="mt-2" color="primary" type="submit" disabled={disablestate} onClick={() => handleDownload(1)}>  {disablestate ?  'Sending' : 'Send' }</Button>
            </ModalBody>
            </Modal>
        
              </Row>
              

              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
          </div>
        </Row>
        
      </Container>
    
    </>
  );
};


export default BillingUnlock;
