import { useState,useEffect } from "react";
import ReactDatetime from "react-datetime";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  FormGroup ,
  InputGroup ,
  InputGroupAddon ,
  InputGroupText 
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../../services/reportmanageService';
import { taskmanageService } from "../../../services/taskmanageService";
import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import Paginationcomponent from "components/Paginationcomponent.js"

 const RejectionReport = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  let start_d =  moment().startOf('month').format('YYYY-MM-DD');
  let end_d = moment().endOf('month').format('YYYY-MM-DD');
 const params  = useParams();
 const history = useHistory();
 const [reportRejectiondata, setReportRejectionData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null)
  const [state, setState] = useState({startDate:'',endDate:''});
  const [statesend, setStatesend] = useState({startDate:'',endDate:''});
  const [total_page,setTotalData] = useState(0);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    get_status_list();
    getrejection_list()
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getrejection_list(index,null,'','',rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getrejection_list(prev,null,'','',rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getrejection_list(next,null,'','',rowsPerPage);
  };
  const getrejection_list = async (pageNumber = 1,e,start_date='',end_date='',rowsPerPage='') => { 
    
    reportmanageService.getReportRejections(pageNumber,start_date,end_date,rowsPerPage).then((response) => {
      //console.log(response.data.data); 
         setReportRejectionData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
         setTotalData(response.data.data.total);
    });
  };
  const handlerejeditRow = (index) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...reportRejectiondata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setReportRejectionData(temp_state);
      // }

  };
  function downloadReport(){
    
    reportmanageService.download_rejection_report(statesend.startDate,statesend.endDate).then((response) => {
    
    });
  }
  const editRejField = (value, field, id,index) => {
    taskmanageService.editRejectionRow(value, field, id).then((response) => {
      if (field == "type"){
        field = 'errortype';
      } 
      let temp_st = [...reportRejectiondata];
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      temp_ele[field] = value;
      if (field == "status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
       }, {});
       }
       if (field == "created_at"){
       temp_ele['date'] = value;
       }
      temp_st[index] = temp_ele;
      setReportRejectionData(temp_st);
    })
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  
  const taskDetail = (task_id,index) => {
    taskmanageService.gettaskdetail(task_id,'','').then((response) => {
      settaskdetails(response.data.data);
      settaskdetailPopup(!taskdetailPopup);
    })
  }
  const withouterrorreport = () =>{
    history.push({
      pathname: 'reportwithouterror',
    });
  }
  const rejectionsummary = () =>{
    history.push({
      pathname: 'rejectionsummary',
    });
  }
  function setEndDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
    getrejection_list(1,null,statesend.startDate, dd);
  }
  function setStartDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
    
  }
  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    getrejection_list(1,null,'', '',newRowsPerPage);
    setCurrentPage(1); 
  
  };
  const today = new Date().toISOString().split('T')[0];
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                 
                    <Row className="align-items-center">
                  
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Rejection Report
                        </h4>
                      </div>
                      
                      <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    {user_info.role_id < 6 || user_info.role_id == 12 || user_info.role_id == 10 || user_info.role_id == 14 ? 
                    <div className="col-2">
             <Button className="col-12 "  type="button" onClick={rejectionsummary}>
                 Rejection Summary
             </Button> 
             </div>
             : null }
                    <div className="col-2">
             <Button className="col-12 "  type="button" onClick={withouterrorreport}>
                 0 errors jobs report
             </Button> 
             </div>
                    </Row>
                    <Row className="align-items-center">
                    <div className="col-4">
                    <label>Start Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-4">
                      <label>End Date</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div >
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Sub Client</th>
                    <th scope="col" className="whitespace">Category</th>
                    <th scope="col" className="whitespace">Rejection For</th>
                    <th scope="col" className="whitespace">Phase name</th>
                    <th className="whitespace">Comment</th>
                    <th scope="col">Date</th>
                    {user_info.role_id == 6 || user_info.role_id == 7  || user_info.role_id == 10 ? 
                    (<><th scope="col" className="whitespace">Phase Lead</th>
                    <th className="whitespace" scope="col">Phase TM</th></>
                    ) : 
                    <><th scope="col" className="whitespace">Analyst Lead</th>
                    <th className="whitespace" scope="col">Analyst TM</th></> }

                    <th>Error Raised by</th>
                    <th scope="col" className="whitespace">Status</th>
                    <th scope="col" className="whitespace">Applicable</th>
                    <th scope="col" className="whitespace">Type</th>
                    <th scope="col" className="whitespace">Error Type</th>
                    <th scope="col" className="whitespace">Approve</th>

                </thead>
                <tbody>
                  {reportRejectiondata &&
                    reportRejectiondata.length &&
                    reportRejectiondata != null ? (
                      reportRejectiondata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row" onClick={() => taskDetail(item.task_id, i)}  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.name} ({item.type}) {" "} &nbsp;&nbsp;
                            </span>
                          </th>
                          
                          <td className="whitespace">{item.sub_client}</td>
                          <td className="whitespace">{item.category_name}</td>
                          <td className="whitespace">{item.rej_for}</td>
                          <td className="whitespace">{item.phase_name}</td>
                          <td className="whitespace">{item.comment}</td>
                          <td onClick={()=> handlerejeditRow(i)} >
                            {item.editing ? (
                              <input type="date"
                              className="form-control"
                              defaultValue={item.date}
                              
                                onBlur={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "created_at",
                                    item.id,
                                    i
                                  )
                                }
                                max={today}
                              />
                            ) : (
                              <span>{item.date}</span>
                            )}
                          </td>
                          <td className="whitespace">{item.lead}</td>
                          <td className="whitespace">{item.member}</td> 
                          <td className="whitespace">{item.raised_error_by}</td> 
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing ? (
                    <select
                                className="form-control"
                                 onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{item.status_name}</p>
                              )} 
                              </td> 
                              <td className="whitespace" onClick={()=> handlerejeditRow(i)}> 
                              {item.editing  ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "applicable",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.applicable}
                              >
                                <option value="1" key="0" >Applicable</option>
                                <option value="0">Not Applicable</option>
                               
                              </select>   ) : (item.applicable==1)?'Applicable':(item.applicable==0)?'Not Applicable':''}
                              </td>  
                              <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'type',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.errortype}
                             >
                               <option value="A">Amends</option>
                                <option value="E">Error</option>
                             </select>
                           ) : (item.errortype!=null)?item.errortype:''}
                         </td>
                         <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'error_type',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.error_type}
                             >
                               <option value="Fetal">Fetal</option>
                                <option value="Non-Fetal">Non-Fetal</option>
                             </select>
                           ) : (item.error_type!=null)?item.error_type:''}
                         </td>
                              <td className="whitespace" >
                              {item.approve < 1 ? (
                                <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "approve",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.approve}
                              >
                                <option value=""  >Select Option</option>
                                <option value="1"  >Approve</option>
                                <option value="2">Reject</option>
                               
                              </select>
                              ) : (item.approve==1)?'Approved':'Rejected'}
                            </td>      
                                      
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                    <div>
     
     <Paginationcomponent
currentPage={currentPage}
totalItems={total_page}
rowsPerPage={rowsPerPage}
rowsPerPageOptions={rowsPerPageOptions}
handlePageClick={handlePageClick}
handlePreviousClick={handlePreviousClick}
handleNextClick={handleNextClick}
handleRowsPerPageChange={handleRowsPerPageChange}
/>
       </div>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
        </Row>
      </Container>
    </>
  );
};
export default RejectionReport;
