import { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";
import { clientmanageService } from "services/clientmanageService";


const Competencyrole = (props) => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [data, setdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const[active_role,setActiverole]=useState(2);


  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e,filterFields,filtertype='',type='',active_role='') => {
    usermanageService.competencyList(pageNumber,type,active_role).then((response) => { 
        console.log(response.data.roles)
        setRoles(response.data.roles);
      setdata(response.data.data);
      setActiverole(response.data.active_role)
        
    });
  };


  useEffect(() => {
    get_list();
    //getkrotypes();
   // getroles();
  }, []);
  const [krotypes, setKroTypes] = useState([]);
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}
const [roles, setRoles] = useState([]);
function getroles(){
  usermanageService.getrolesforKROTypeRating().then((response)=>{
    setRoles(response.data.roles);
  });
}

  const handleclick = (e,id) => {

    clientmanageService.deleteCommonTableData(id,'competency_role_wise').then((response) => {
        if (response.status == 200) {
            toast.success(response.data.message);
            get_list()
           
          } else {
            for (const field in response.data) {
            toast.error(response.data);
            }
          }
    });
  }
//   function addtyperating(){
//     history.push('admin/addtyperating')
//   }
  const addtyperating = () =>{
    history.push("/admin/addcompetencyrole/"+active_role);
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));



  const [type,settype] = useState('');
  const cleartype = ()=>{
    settype('');
  }
  const copytyperating = (role_id,id)=>{
    history.push("/admin/addtyperating/"+role_id+"/"+id);

  }
 
  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
    {/* {user_info.role_id == 12 ?  */}
          <div className="col-3">
             <Button className="col-6 " color="primary" type="button" onClick={(e)=>{addtyperating('')}}>
                Add
             </Button>
          </div>
          {/* :null} */}
        
          
          </Row>
          {roles && roles.length > 0 ?
          <>
          <Row className="mt-4">
            {roles.map((role)=>(
             
          <button type="button" className=" btn btn-secondary text-center" style={role.id == active_role ?
          { backgroundColor:'#5d6cae',color:'#fff'} : null} onClick={(e)=>{get_list(1,1,'','','',role.id);cleartype();}}>
            {role.name}

          </button>
              )

            )}
       
        
        </Row>
        <Row className="mt-1">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              
         <Table  className="align-items-center table-flush" responsive>
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                  <th style={{ border: "1px solid #000" }}>Sr.No.</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Name</th>
                  {/* <th style={{ border: "1px solid #000" }} className="whitespace">Action</th> */}
                  
                </tr>
              </thead>
              <tbody>
  {data.length > 0 ? 
    data.map((da, index) => (
      <tr key={index} className="whitespace" style={{ border: "1px solid #000" }}>
        <td className="whitespace" style={{ border: "1px solid #000" }}>{index +1}</td>
        <td className="whitespace" style={{ border: "1px solid #000" }}>{da.name}</td>
        {/* <td className="whitespace" style={{ border: "1px solid #000" }}><Button className="btn btn-danger" onClick={(e) => { handleclick(e,da.id)}}   class="btn btn-danger"><i class="fa fa-trash"></i></Button></td> */}
      </tr>
    ))
    :<tr className="whitespace" style={{ border: "1px solid #000" }}><td colSpan={4} style={{ border: "1px solid #000" }}>No data found</td></tr>
  }
</tbody>

              </Table>
              </Card>
              </div>
         </Row>
         </> : null}
     </Container>
    </>
  );
};


export default Competencyrole;
