import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody, 
  UncontrolledPopover,
} from "reactstrap";
import swal from 'sweetalert';
import {AgGridReact,AllCommunityModules,AgGridColumn} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {userService} from '../../../services/authentication';
import {reportmanageService} from '../../../services/reportmanageService';
import { taskmanageService } from "../../../services/taskmanageService";
import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import Navbuttons from "components/Navigations/Navbuttons.js";
import { permissions, rolename, roletype } from "global.js";

 const ReportWithoutErrors = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [reportdata, setReportData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
  const [successMsg, setsuccessMsg] = useState(null)
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getreport_list(index,null);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getreport_list(prev,null);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getreport_list(next,null);
  };
  const getreport_list = async (pageNumber = 1,e) => { 
    
    reportmanageService.getwithoutErrorReport(pageNumber).then((response) => {
      //console.log(response.data.data); 
      setReportData(response.data.data.data); 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
    });
  };
  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  function downloadReport(){
    
    reportmanageService.download_without_error_report().then((response) => {
    
    });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  
  const taskDetail = (task_id,index) => {
    taskmanageService.gettaskdetail(task_id,'','').then((response) => {
      settaskdetails(response.data.data);
      settaskdetailPopup(!taskdetailPopup);
    })
  }
  useEffect(()=>{
    getreport_list();
  },[]);
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row>
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          0 error job report
                        </h4>
                      </div>
                      <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Sub Client</th>
                    {user_info.role_id == 6 || user_info.role_id == 7 || user_info.role_id == 10 ? 
                    <th scope="col" className="whitespace">Phase name</th>
                    : null }
                    <th scope="col" className="whitespace">Raised by</th>
                    <th scope="col" className="sortclass" onClick={(e) => sortTable('due_date')} >Due Date</th>
                    <th className="whitespace">Assign Lead</th>
                   
                    {/* {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 14 ? 
                    (<><th scope="col" className="whitespace">QA Lead</th>
                    <th className="whitespace" scope="col">QA TM</th></>
                    ) :  */}
                    <th   scope="col">Assign TM</th> 
                    <th scope="col">Comp. Date</th>
                    {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 14 ? 
                    (<th scope="col">Comp. Hours</th>) : '' }
                    {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 14 ? 
                    (<th scope="col" className="whitespace">Doc Reveived date</th>) : '' }
                    
                    <th scope="col" className="whitespace">Status</th>
                    
                </thead>
                <tbody>
                  {reportdata &&
                    reportdata.length &&
                    reportdata != null ? (
                      reportdata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row" onClick={() => taskDetail(item.task_id, i)}  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.name} ({item.type}) {" "} &nbsp;&nbsp;
                            </span>
                          </th>
                          
                          <td className="whitespace">{item.sub_client}</td>
                          {user_info.role_id == 6 || user_info.role_id == 7 || user_info.role_id == 10 ? 
                          <td className="whitespace">{item.phase_name}</td>
                          : null }
                          <td className="whitespace">{item.raised_by}</td>
                          <td>{formatDate(item.due_date)}</td>
                          <td> {(item.lead!=null)?item.lead:''}</td>
                         
                          {/* {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 14 ? 
                            (<><td className="whitespace">{item.analystlead}</td>
                             <td className="whitespace">{item.analystmember}</td> </>
                            ) :  */}
                             <td className="whitespace">{item.member}</td>
                          {/* } */}
                          <td>{item.completed_date}</td>
                          {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 14 ? 
                            (<td>{item.HOURS}</td>) : ''
                          }
                           {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 14 ? 
                            (<td className="whitespace">{item.doc_received_date}</td>) : ''
                          }
            
                          <td>{item.status_name}</td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
        </Row>
      </Container>
    </>
  );
};
export default ReportWithoutErrors;
