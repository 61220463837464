import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup, 
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Container,
  UncontrolledAlert
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {clientmanageService} from '../../../services/clientmanageService.js';
import {todoService} from '../../../services/todoService.js';
import { toast } from 'react-toastify';
import ReactDatetime from "react-datetime";
import Select from 'react-select';
const AddTodo = () => {
  const history = useHistory();
  let initialStateData = {
    assigned_by:"",
    remark:""

  };
  const [inputFields, setInputfields] = useState(initialStateData);
  const [error, setError] = useState(null)
  
  const [successMsg, setsuccessMsg] = useState(null)

  function inputhandler(e){
      setInputfields({...inputFields,[e.target.name]:e.target.value});
  } 

  const [ clientlist, setclientlist ] = useState([])
  function clientList(id){
    todoService.get_assignuser(id).then((response)=>{
          if(response.status === 200){
           
            setclientlist(response.data.data)
            
          }else {
              toast.error('Something went wrong!');
          }
        });
  }

  function addtodo(e){
    e.preventDefault();
    todoService.addtodo(inputFields).then((response)=>{
     
      if(response.status == 200){
         toast.success(response.data.message);  
         history.goBack(); 
      }else if(response.status == 201){
        setError('Enter All required field');
        setsuccessMsg(null);
        toast.error(response.data.message); 
      }
    });
  }

  function cancel_form(){
       document.getElementById('addclient-form').reset();
       setInputfields(initialStateData);
       history.goBack(); 
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  useEffect(() => { 
    clientList(user_info.id)
    }, [] ) 
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  
  function inputhandler(e){
    let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,assigned_by : Array.isArray(e) ? e.map(x => x.value) : []}
    }else{
      if(e.target.name == 'roles'){
        getqatl_list(e.target.value);
        
      }
        phseup = { ...inputFields, [e.target.name]: e.target.value }    
      
    }
    setInputfields(phseup);
  }
  return (
      <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
          <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                  <Card className="shadow">
                      <CardHeader className="bg-transparent">
                          <h3 className="mb-0">Add Todo List</h3>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-10">
                     
                      <Form role="form" id="addclient-form" onSubmit={addtodo}>
                          <FormGroup>
                           <Row>
                           <Col lg="6" md="6">
                           <label>User</label>
                           <Select 
                   
                            className="input-group-alternative mb-3" 
                            name="assigned_by"
                            options={clientlist} // set list of the data
                            onChange={inputhandler}
                          
                            searchable
                            isMulti
                            isClearable={false}
                          />

                              {/* <select  class="form-control" name="assigned_by" onChange={inputhandler}  >
                              <option value=""> Select User</option>
                              <option value={user_info.id}> Self</option>
                              {clientlist.map(res => (
                                  <option
                                    key={res.id}
                                    value={res.id}
                                  >
                                    {res.name}
                                  </option>
                                ))}
                              </select> */}
                              
                              </Col>
       
                           </Row>   
                          </FormGroup>
                          
                          <FormGroup>
                            <Row>
                            <Col lg="6" md="6">
                                 <label>Due date</label> 
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Due date"
                                        type="date"
                                        name="due_date"
                                        onChange={inputhandler}
                                        min={disablePastDate()}
                                        required
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col lg="6" md="6">
                              <label>Todo</label>
                              <Input type="textarea" rows="1"  placeholder="Todo" name="remark" onChange={inputhandler}  /> 
                                </Col>
                                
                                  </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                             
                          
                             
                               </Row></FormGroup>
                              <div className="col-12">
                             <Button className="mt-2" color="primary" type="submit">
                              Save
                             </Button>
                             <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                               Cancel
                             </Button>
                          </div>
                      </Form>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
      </Container>
      </div>
      </>
  );
};

export default AddTodo;
