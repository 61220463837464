import { useState, useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Input,
  Col,
  FormGroup ,
  InputGroup 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";
const axios = require("axios");

const EditKROTypeRating = (props) => {
    const params  = useParams();
  const [clientsearch, setclientsearch] = useState('');
  const [trans_data,settransData] = useState([]);
  const [state, setState] = useState({});
  const [disablestate, setdisablestate] = useState(false);
  const [newbox, setNewBox] = useState(0);
  const [defaultkey, setdefaultKey] = useState(0);
  const history = useHistory();
useEffect(() => {
  getkrotypes();
  getsingleKROTypeRating();
}, []);


let { id } = useParams();
const [krotypes, setKroTypes] = useState([]);
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}

function getsingleKROTypeRating(){
    usermanageService.getsingleKROTypeRating(id).then((response)=>{
        setInputfields(response.data.data);
      });
}

const [inputFields, setInputfields] = useState({});
function inputhandler(e,index) {

    setInputfields({...inputFields,[e.target.name]:e.target.value});
}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  usermanageService.updateKROTypeRating(inputFields).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      // location.reload();
    }
    setdisablestate(false)
  });
}

 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}
const krotyperating = ()=>{
  history.push("/admin/krotyperating/");
}

  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      
        
        <Row className="mt-12" >
        <Card className="shadow"  style={{width:"100%"}}>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
                   <div className="row">
                    <div className="col-md-4">
                       <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                        <select className="form-control"  name="type_id"   onChange={(e) => inputhandler(e)} value={inputFields.type_id}>
                            <option value="">Select Type</option>
                                    {krotypes.map(type =>
                                      <option key={type.value} value={type.value}>{type.label}</option>
                                    )}
                        </select>
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Crietaria"
                              type="text"
                              name="criteria"
                              onChange={inputhandler}
                              defaultValue={inputFields.criteria}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    </div>
                    <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-12">
                    <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                    <select className="form-control"  name="rating"   onChange={(e) => inputhandler(e)} value={inputFields.value}>
                                  <option value="1">Needs Action</option>
                                  <option value="2">Below Expectation</option>
                                  <option value="3">Meet Expectation</option>
                                  <option value="4">Exceed Expectation</option>
                                  <option value="5">Exceptional</option>
                        </select>
                    </InputGroup>
                    </FormGroup> 
                  </div>
                    
                    
                    
                    
                </div>
                
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
              </Form>
            <br></br>
              
                <div class="d-flex">
                
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              </div>
              
             
              </Card>
              </Row>

      </Container>
    </>
  );
};

 
export default EditKROTypeRating;
