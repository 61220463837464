import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  CardFooter 
} from "reactstrap";
import { reportmanageService } from "../../services/reportmanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

const GovernanceReport = (props) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [total_page, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [filterFields, setFilterFields] = useState({ fromDate: '', toDate: '', type: '' });

  const handlePageClick = (e, index) => {
    e.preventDefault();
    getData(index, null, filterFields);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getData(prev, null, filterFields);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getData(next, null, filterFields);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    getData(1, newRowsPerPage);
    setCurrentPage(1); 
  };

  function getData(pageNumber = 1, filters = filterFields) {
    reportmanageService.getGovernnaceReport(pageNumber, rowsPerPage, filters).then((response) => {
      console.log(response.data.data);
      setData(response.data.data); 
    });
  }

  useEffect(() => {
    getData();
  }, []);

  const applyFilter = (e, index) => {
    const { name, value } = e.target;
    setFilterFields(prevState => {
      const updatedFilters = {
        ...prevState,
        [name]: value
      };
      
      // Fetch data with the updated filters
      getData(1, updatedFilters);
      
      return updatedFilters;
    });
  };

  const governanceReport = () => {
    reportmanageService.governanceReport(filterFields).then((response) => {
      // handle the response for governance report export
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <h3>Governance Report</h3>&nbsp;&nbsp;&nbsp;&nbsp;
            <Row>
              <div className="col-xl-2 col-md-3 col-xs-12">
                <h5 className="clientformtag">From Date</h5>
                <input
                  type="date"
                  className="form-control"
                  name="fromDate"
                  onChange={(e) => { applyFilter(e, 2); }}
                />
              </div>
              <div className="col-xl-2 col-md-3 col-xs-12">
                <h5 className="clientformtag">To Date</h5>
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  onChange={(e) => { applyFilter(e, 2); }}
                />
              </div>
              <div className="col-xl-2 col-md-3 col-xs-12">
                <h5 className="clientformtag">Select Type</h5>
                <select
                  className="form-control"
                  name="type"
                  onChange={(e) => { applyFilter(e, 1); }}
                >
                  <option value="">Select Type</option>
                  <option value="1">Call done</option>
                  <option value="0">Call not done</option>
                </select>
              </div>
              <div className="col-xl-6 col-md-3 col-xs-12"></div>
              <div className="col-xl-2 col-md-3 col-xs-12">
                <h5 className="clientformtag">&nbsp;</h5>
                <Button
                  className="btn-icon-clipboard mt--2"
                  type="button"
                  onClick={governanceReport}
                  style={{ textAlign: 'center', width: '120px' }}
                  title="Summary Report"
                >
                  <i className="fas fa-eye" style={{ fontSize: '14px' }}></i> Export
                </Button>
              </div>
            </Row>
            <Row>
              <Col xl="12">
                <Card style={{ padding: '0px 0px 0px 0px' }}>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Client Name</th>
                        <th>Client Manager</th>
                        <th>Call Done</th>
                        <th>Rating</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ? (
                        data.map((item, i) => (
                          <tr key={i} className={
                            i % 2 === 0 ? 'blue-row' : i % 3 === 0 ? 'purple-row' : 'orange-row'
                          }>
                            <td>{item.name}</td>
                            <td>{item.client_manager}</td>
                            <td>{item.call_done}</td>
                            <td>{item.rating}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    {/* Pagination Component will go here */}
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GovernanceReport;
