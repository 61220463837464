import React,{ useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import swal from "sweetalert";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Navbuttons from "components/Navigations/Navbuttons.js";
import { stringToArray } from "ag-grid-community";

const WeeklyReport = (props) => {
  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [phasefilter, setphasefilter] = useState("");

  useEffect(() => {
    get_report_data();
  },[]);
  
  function get_report_data(e=''){
    if(e!=''){
      var phase = e.target.value;
      setphasefilter(phase); 
    }else{
      var phase = '';
    }
    reportmanageService.getWeeklyReportData(phase).then((response) => {
      console.log(response.data)
      setReportdata(response.data); 
    });
  }
  function report_details(company){
     history.push({
       pathname: "weekly_report_details/"+company+"/"+phasefilter,
     });

  }

  function break_line(paragraph){
     return paragraph.replaceAll("\n",'<br>');
  }
  
  const report = () =>{
    history.push({
      pathname: 'analytics_report',
    });
  }

  const dailyreport = () =>{
    history.push({
      pathname: 'dailyreport',
    });
  }
  const monthlyreport = () =>{
    history.push({
      pathname: 'monthlyreport',
    });
  }
  function downloadReport(){

    reportmanageService.weekly_report_export().then((response) => {
    
    });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
            <div className="col-2">
          <Button className="col-12 "  type="button" onClick={report}>
                 Report
             </Button>
             </div>
             { (reportdata.show == 1) ? 
             <>
            <div className="col-3">
             <Button className="col-12 " type="button" onClick={dailyreport}>
                Daily Report
             </Button>
          </div>
          </> : null }
          <div className="col-3">
          <Button className="col-12 " color="primary" type="button" >
                Weekly Report
             </Button>
          </div>
           
          <div className="col-3">
          <Button className="col-12 " type="button" onClick={monthlyreport}>
                Monthly Report
             </Button>
          </div>
          <Col xl="12">
          <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
          </div>
          </Col>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
              <Col xl="12">
              {user_info.role_id == 6 && reportdata.phases ? 
                      <select className="form-control col-3" onChange={(e) => get_report_data(e)}  name="value" >
                      <option value="" key="al_key_1">All Phases</option>
                      {(reportdata.phases).map((valuel,k) =>
                          <option key={k} value={valuel.id}>
                            {valuel.desc}
                          </option>
                      )}
                  </select>
                    : null}
                 <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h4 className="text-muted ls-1 mb-1">
                          From { reportdata.firstdayofweek } to { reportdata.lastdayofweek }
                        </h4>
                      </div>
                    </Row>
                  </CardHeader>
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                          <th>Accountant Name</th>
                          <th>No. of Jobs</th>
                          <th>Budgeted Hours</th>
                      </thead>
                  <tbody>
                  {reportdata.data &&
                    reportdata.data.length &&
                    reportdata.data != null ? (
                      reportdata.data.map((user, index) => (
                        <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td><span  style={{cursor:'pointer',color:'#fea732'}} onClick={() => { report_details(user.name) }}>{user.name}</span></td>
                            <td>{user.jobs}</td>
                            <td>{user.hours}</td>
                            {/* <td> 
                              <button class="report-view-btn"  color="primary"
                                      size="sm" type="button" onClick={(e) =>{ report_details(user.id,user.type) } }>
                                View
                              </button>
                            </td> */}
                        </tr>
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default WeeklyReport;
