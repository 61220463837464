import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paginationcomponent = ({
    currentPage,
    totalItems,
    rowsPerPage,
    rowsPerPageOptions,
    handlePageClick,
    handlePreviousClick,
    handleNextClick,
    handleRowsPerPageChange,
  }) => {
    const totalPages = Math.ceil(totalItems / rowsPerPage);
  
    const onRowsPerPageChange = (event) => {

      handleRowsPerPageChange(Number(event.target.value));
    };

  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
    <div className="d-flex align-items-center">
      <label htmlFor="rowsPerPage" className="mr-2">Rows per page:</label>
      <select
        id="rowsPerPage"
        value={rowsPerPage}
        onChange={e => onRowsPerPageChange(e)}
        className="form-control"
        style={{ width: 'auto' }}
      >
        {rowsPerPageOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
    <nav aria-label="Page navigation">
      <Pagination className="pagination mb-0">
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink onClick={handlePreviousClick} previous href="#" />
        </PaginationItem>

        {Array.from({ length: totalPages }, (_, i) => (
          <PaginationItem
            active={i + 1 === currentPage}
            key={i + 1}
          >
            <PaginationLink
              onClick={(e) => handlePageClick(e, i + 1)}
              href="#"
            >
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem disabled={currentPage >= totalPages}>
          <PaginationLink onClick={handleNextClick} next href="#" />
        </PaginationItem>
      </Pagination>
    </nav>
  </div>
  );
};

export default Paginationcomponent;
