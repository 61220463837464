import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
    Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import { usermanageService } from "../../services/usermanageService";
import Select from 'react-select';
import Buttoncomponent from 'components/Buttoncomponent.js';
const axios = require("axios");

const AssignClient = (props) => {
    const [userdatas, setUserdatad] = useState([]);
    const [user_roles, setUserroles] = useState([]);
    const [user_phases, setUserphase] = useState([]);
    const [inputFields, setInputfields] = useState({});
    const [displayphase, setDisplayphase] = useState(false);
    const [userdata, setUserdata] = useState([]);
    const [atl_lists, setAtlLists] = useState([]);
    const [displayqatl, setDisplayqatl] = useState(false);
    const [displayclientqatl, setDisplayClientqatl] = useState(false);
    const [displayatl, setDisplayatl] = useState(false);
  
    function inputhandler2(e) {
    getassign_client(e.value);
      console.log(e)
      let phseup = {}
      phseup = { ...inputFields, roles: e.value } 
      setInputfields(phseup);
      console.log(inputFields)
    }
    function inputhandler1(e) {
      let phseup = {}
      if(Array.isArray(e)){   
      phseup = {...inputFields,atl : Array.isArray(e) ? e.map(x1 => x1.value) : []}
      }
      setInputfields(phseup);
    }
    function inputhandler(e) {
 
        let phseup = {}
        if(Array.isArray(e)){     
          phseup = {...inputFields,clients : Array.isArray(e) ? e.map(x => x.value) : []}
        }else{
          if(e.target.name == 'roles'){
            getqatl_list(e.target.value);
            
          }
            phseup = { ...inputFields, [e.target.name]: e.target.value }    
          
        }
        setInputfields(phseup);
        console.log(inputFields)
      }
  
    useEffect(() => {
      getmanager_list();
     // getqatl_list();
      return () => {
        setInputfields({});
      };
    }, []);
  
    function getmanager_list() {
      usermanageService.getmanager_list().then((response) => {
        console.log(response.data);
        setUserroles(response.data.users);
        setUserdata(response.data.client);
      });
    }
  
    function getassign_client(id) {
      usermanageService.getassign_client(id).then((response) => {
        console.log("ddd")
    console.log(response.data.users)
          const selectdata = {roles:id,clients: (response.data.users) ? response.data.users.map(x => x.value) : []}
          console.log(selectdata)
          setInputfields(selectdata)
          console.log(inputFields)
        });
    }
  
    const assignmanager = () =>{
      history.push({
        pathname: 'assignmanager',
      });
    }
    function addmanager(e){
      e.preventDefault();
      // console.log(inputFields);
      // return;
      usermanageService.addclientmanager(inputFields).then((response)=>{
        if(response.status == 200){
         
           toast.success(response.data.message); 
          
           document.getElementById("adduser-form").reset();
           setInputfields({});
        }else{
          console.log(response.data);
           toast.error(response.data.message);
        }
        location.reload();
      });
    }
    const styles = {
      'color':'#4a4444',
    };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Assign Client</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                       
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
              <Form role="form" id="adduser-form" onSubmit={addmanager}>
                <div className="row">
                    <div className="col-md-4">
                    <FormGroup>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="roles" 
                            allowSelectAll={true} 
                           
                            options={user_roles} // set list of the data
                            onChange={inputhandler2}

                            
                          />
                          {inputFields.roles && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            
                              </div>}
                        </FormGroup>
                        {/* <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="roles" onChange={inputhandler} placeholder="Role">
                              <option key="role_1"  value="">Select lead</option>
                              {user_roles.map(roles =>
                                  <option key={roles.id} value={roles.id}>{roles.name}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup> */}
                    </div>
                    
                    <div className="col-md-4" >
                      
                      <FormGroup>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="qatl" 
                            allowSelectAll={true} 
                             value={(inputFields.clients) ? userdata.filter(obj => inputFields.clients.includes(obj.value)) : ''} // set selected values
                            options={userdata} // set list of the data
                            onChange={inputhandler}
                            isMulti
                            isClearable
                          />
                          {inputFields.clients && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            
                              </div>}
                        </FormGroup>
                    </div>
                    
                    
                </div>
                <div class="row">
                <div className="col-md-4">
                        
                          <Button className="col-12 " color="primary" type="submit">
                            Assign
                         </Button>
                    </div>
                    
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};


export default AssignClient;
