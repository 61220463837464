import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    UncontrolledAlert
  } from "reactstrap";
  import React, { useState,useEffect } from "react";
  import { useHistory,useParams } from "react-router-dom";
  import {clientmanageService} from '../../../services/clientmanageService.js';
  import { ToastContainer, toast } from 'react-toastify';
  import { forEachChild } from "typescript";
  
  const EditClient = () => {
    const history = useHistory();
    const params  = useParams();
    
    let initialStateData = {
      name:"",
      sub_client:"",
      year_end_date: "",
      raised_on: "",
      book_keeping_software:"",
      account_prod_software:"",
      initial_budget:"",
      client_deadline:"",
      due_date:""
    };
    const [inputFields, setInputfields] = useState(initialStateData);
    const [error, setError] = useState(null)
    const [successMsg, setsuccessMsg] = useState(null)
    const [ phasestl, setphasestl ] = useState([])
    const [ client, setparticularclient] = useState({});
    const [displayphase, setDisplayphase] = useState(false);
    const errorDiv = error 
          ? <UncontrolledAlert className="alert-danger" fade={false}>
          {error}
          </UncontrolledAlert> 
          : '';
    const successDiv = successMsg 
          ? <UncontrolledAlert className="alert-success" fade={false}>
          {successMsg}
          </UncontrolledAlert> 
          : '';

    useEffect(() => { 
       //fetchData(1);
       getclient();
    }, [] );

    function inputhandler(e){
      // e.presist();
      if(e.target.name == 'phase_lead_id'){
        if(e.target.value == ''){
          setDisplayphase(false)
        }else{
          setDisplayphase(true)
        }
      }
      setInputfields({...inputFields,[e.target.name]:e.target.value});
    } 
  
    function addclient(e){
      e.preventDefault();
      // console.log(inputFields);
      // return;
      clientmanageService.createclient(inputFields).then((response)=>{
          if(response.status == 200){
            //  setsuccessMsg(response.data.message);
            // document.getElementById('addclient-form').reset();
            // setInputfields(initialStateData);
            toast.success(response.data.message);   
            history.goBack();
          }else if(response.status == 201){
            // setError('Enter All required field');
            setsuccessMsg(null);
            toast.error(response.data.message); 
          }
      });
    }

    function getclient(){
      let task_id   = params.id; 
      let empty_obj = { }; 
      clientmanageService.getparticular_client(task_id).then((response) => {
          // console.log('response');
          //console.log(response);
          if(response.status == 200){
                setparticularclient(response.data); 
                empty_obj.name = response.data.name;
                empty_obj.sub_client = response.data.sub_client;
                empty_obj.year_end_date = response.data.year_end_date;
                empty_obj.raised_on = response.data.created_at;
                empty_obj.book_keeping_software = response.data.book_keeping_software;
                empty_obj.account_prod_software = response.data.account_prod_software;
                empty_obj.initial_budget = response.data.initial_budget;
                empty_obj.client_deadline = response.data.task_due_date;
                empty_obj.due_date = response.data.task_due_date; 
                empty_obj.company_id = response.data.company_id;
                empty_obj.task_id = task_id;
                if(response.data.assign_lead_id!=null){
                  setDisplayphase(true)
                  empty_obj.phase_lead_id = response.data.assign_lead_id;
                  empty_obj.phase_due_date = response.data.due_date;
                }else{
                  setDisplayphase(false)
                  empty_obj.phase_lead_id = '';
                  empty_obj.phase_due_date = '';
                }
                setInputfields(empty_obj);
                let pahse_id = "1," + response.data.phaselist;
                fetchData(pahse_id);
               
                
          }
      });
      // setparticularclient();
    }
  
    const fetchData = (pahseid) => {
      clientmanageService.getphasestl(pahseid).then((response)=>{
        if(response.status === 200){
          setphasestl(response.data.teamleader)
          //setInputfields({'phase_lead_id' : ''});
          //setInputfields({...inputFields,'phase_lead_id':''});
        }else {
            toast.error('Something went wrong!');
        }
      });
    }

    function cancel_form(){
         document.getElementById('addclient-form').reset();
         setInputfields(initialStateData);
         history.goBack(); 
    }
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  
    return (
        <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col lg="8" md="8">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Edit Client</h3>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-10">
                        {errorDiv}
                {successDiv}
                        <Form role="form" id="addclient-form" onSubmit={addclient}>
                            <FormGroup>
                             <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Client</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                   <InputGroup className="input-group-alternative mb-3">
                                      <Input 
                                      placeholder="Client Name" 
                                      type="text" 
                                      name="name" 
                                      defaultValue={inputFields.name}
                                      // value={inputFields.name != undefined ? inputFields.name : ''}
                                      onChange={inputhandler}
                                      required
                                      />
                                  </InputGroup>      
                                </div>
                             </Row>   
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Sub Client</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                   <InputGroup className="input-group-alternative mb-3">
                                      <Input 
                                      placeholder="Sub Client Name" 
                                      type="text" 
                                      name="sub_client" 
                                      defaultValue={inputFields.sub_client}
                                      //value={inputFields.sub_client != undefined ? inputFields.sub_client : ''}
                                      onChange={inputhandler}
                                      required
                                      />
                                  </InputGroup>    
                                </div>
                             </Row>  
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                  <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Year-End date</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                        <InputGroup className="input-group-alternative mb-3">
                                          <Input
                                          placeholder="Year - End Date"
                                          type="date"
                                          name="year_end_date"
                                          defaultValue={inputFields.year_end_date}
                                          //value={inputFields.year_end_date != undefined ? inputFields.year_end_date : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>  
                                  </div>
                              </Row>     
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                  <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Raised On</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                        <InputGroup className="input-group-alternative mb-3">
                                          <Input
                                          placeholder="Raised On"
                                          type="date"
                                          name="raised_on"
                                          defaultValue={inputFields.raised_on}
                                          //value={inputFields.year_end_date != undefined ? inputFields.year_end_date : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>  
                                  </div>
                              </Row>     
                            </FormGroup>
                            <FormGroup>
                              <Row>
                                  <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Bookkeeping Software</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Bookkeeping Software"
                                          type="text"
                                          name="book_keeping_software"
                                          defaultValue={inputFields.book_keeping_software}
                                          //value={inputFields.book_keeping_software != undefined ? inputFields.book_keeping_software : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>
                                  </div>
                              </Row> 
                            </FormGroup>
                            <FormGroup>
                               <Row>
                                  <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Accounts Production Software</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Accounts Production Software"
                                          type="text"
                                          name="account_prod_software"
                                          defaultValue={inputFields.account_prod_software}
                                          //value={inputFields.account_prod_software != undefined ? inputFields.account_prod_software : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>
                                  </div>
                              </Row> 
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                  <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Initial Budget</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Initial Budget"
                                          type="text"
                                          name="initial_budget"
                                          defaultValue={inputFields.initial_budget}
                                          //value={inputFields.initial_budget != undefined ? inputFields.initial_budget : ''}
                                          onChange={inputhandler}
                                          required
                                          />
                                      </InputGroup>
                                  </div>
                              </Row> 
                            </FormGroup>
                            <FormGroup>
                               <Row>
                                  <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Client Deadline</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Client Deadline"
                                          type="date"
                                          name="client_deadline"
                                          defaultValue={inputFields.client_deadline}
                                          //value={inputFields.client_deadline != undefined ? inputFields.client_deadline : ''}
                                          onChange={inputhandler}
                                          // min={disablePastDate()}
                                          required
                                          />
                                      </InputGroup>
                                  </div>
                              </Row> 
                            </FormGroup>
                            <FormGroup>
                              <Row>
                              <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3> Phase 0 TL</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                <select class="form-control" name="phase_lead_id" value={inputFields.phase_lead_id} id="lead_id" onChange={inputhandler} required>
                                {/* <option value=""> Select Phase 0 TL</option> */}
                                {phasestl.map(lead => (
                                    <option
                                      key={lead.id}
                                      value={lead.id}
                                    >
                                      {lead.name}
                                    </option>
                                    
                                  ))}
                                   <option value=''>Skip Phase 0</option>
                                </select>
                                </div>
                                </Row>
                                </FormGroup>
                            <FormGroup>
                              <Row style={{ display: displayphase == true ? "flex" : "none" }}>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                      <h3>Phase 0 Due date</h3> 
                                  </div>
                                  <div className="col-xl-10 col-md-12 col-xs-12">
                                      <InputGroup className="input-group-alternative">
                                          <Input
                                          placeholder="Phase 0 due date"
                                          type="date"
                                          name="phase_due_date"
                                          defaultValue={inputFields.phase_due_date}
                                          //value={inputFields.phase_due_date != undefined ? inputFields.phase_due_date : ''}
                                          onChange={inputhandler}
                                          
                                          />
                                      </InputGroup>
                                  </div>
                                  </Row>
                            </FormGroup>
                            <div className="col-12">
                               <Button className="mt-2" color="primary" type="submit">
                                Save
                               </Button>
                               <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                                 Cancel
                               </Button>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    );
  };
  
  export default EditClient;
  