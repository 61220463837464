import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header.js";
import { usermanageService } from "../../services/usermanageService";
import {
    Container,
    Row,
    Col,
    Table
} from "reactstrap";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer
} from 'recharts';

const GraphChart = () => {
    const [userdata, setUserdata] = useState([]);
    const [kroData, setKroData] = useState([]);
    const [productivityData, setProductivityData] = useState([]);
    const [accuracyData, setAccuracyData] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selecteduserdata, setSelectedUserdata] = useState('');
    const getYears = (startYear) => {
        const currentYear = new Date().getFullYear();
        let years = [];
        for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };
    const years = getYears(2000);
    const [selectedYear, setSelectedYear] = useState('');
    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        getgraphData(selecteduserdata,e.target.value);
    }
    useEffect(() => {
        getuser_list();
    }, []);

    function getuser_list(name='') {
        usermanageService.getusersforchatbox(name).then((response) => {
            setUserdata(response.data.users);
        });
    }

    const getgraphData = (user_id,year=selectedYear ) => {
        setLoading(true);
        setSelectedUserdata(user_id);
        usermanageService.getKroData(user_id, year)  // Assume the API endpoint requires a user_id and year
            .then(response => {
                const months = response.data.months;
                const kro = response.data.kro;
                const productivity = response.data.productivity;
                const accuracy = response.data.accuracy;
                const attendance = response.data.attendance;
                const billing = response.data.billing;

                // Debugging step: check the data format
                console.log('API Response:', response.data);

                // Format the data for each chart
                const formattedKroData = months.map((month, index) => ({
                    month,
                    kro: kro[index]?.average_score || 0,
                }));

                const formattedProductivityData = months.map((month, index) => ({
                    month,
                    productivity: productivity[index]?.actual_target || 0,
                    billing: billing[index]?.billing_hours || 0,
                }));

                const formattedAccuracyData = months.map((month, index) => ({
                    month,
                    accuracy: accuracy[index]?.actual_target || 0,
                }));

                const formattedAttendanceData = months.map((month, index) => ({
                  month,
                  attendance: attendance[index]?.presentdaysfinal || 0,
              }));

                console.log('Formatted KRO Data:', formattedKroData);
                console.log('Formatted Productivity Data:', formattedProductivityData);
                console.log('Formatted Accuracy Data:', formattedAccuracyData);

                setKroData(formattedKroData);
                setProductivityData(formattedProductivityData);
                setAccuracyData(formattedAccuracyData);
                setAttendanceData(formattedAttendanceData);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching graph data:", error);
                setLoading(false);
            });
    }

    // const xLabels = [
    //     'January', 'February', 'March', 'April', 'May', 'June',
    //     'July', 'August', 'September', 'October', 'November', 'December'
    // ];
    const setInput = (user)=>{
        getuser_list(user)
    }

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <Col xl="3">
                    <select
        id="year"
        name="year"
        value={selectedYear}
        onChange={handleYearChange}
        className="form-control"
        
      >
        <option value="">Select Year</option>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select> 
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xl="9">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <>
                                <h4>KRO Rating</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={kroData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="kro" stroke="green">
                                            <LabelList dataKey="kro" position="top" />
                                        </Line>
                                    </LineChart>
                                </ResponsiveContainer>

                                <h4>Productivity</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={productivityData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="productivity" stroke="steelblue">
                                            <LabelList dataKey="productivity" position="top" />
                                        </Line>
                                        <Line type="monotone" dataKey="billing" stroke="red">  
                                            <LabelList dataKey="billing" position="top" />
                                        </Line>
                                    </LineChart>
                                </ResponsiveContainer>

                                <h4>Accuracy</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={accuracyData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="accuracy" stroke="orange">
                                            <LabelList dataKey="accuracy" position="top" />
                                        </Line>
                                    </LineChart>
                                </ResponsiveContainer>

                                <h4>Attendance</h4>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={attendanceData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="attendance" stroke="purple">
                                            <LabelList dataKey="attendance" position="top" />
                                        </Line>
                                    </LineChart>
                                </ResponsiveContainer>
                            </>
                        )}
                    </Col>
                    <Col xl="3">
                        <div style={{ border: '1px solid black', height: '400px', overflowY: 'auto' }}>
                             
                            <Table className="align-items-center table-flush" responsive>
                                <tbody>
                                <input type="text" className="form-control" placeholder="Search" onInput={e => setInput(e.target.value)} onKeyDown={e => e.key === 'Enter' && getuser_list(e)}/>
                                    {userdata && userdata.length > 0 ? (
                                        userdata.map((user, i) => (
                                            <React.Fragment key={i}>
                                                <tr key={user.value} style={{ cursor: 'pointer', backgroundColor: i % 2 === 0 ? 'white' : '#f0ecec' }} onClick={() => getgraphData(user.value)}>
                                                    <td>{user.label}</td>
                                                </tr>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="1">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default GraphChart;
