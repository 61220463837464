import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";
const moment = require('moment');
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import swal from "sweetalert";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../services/authentication";
import { reportmanageService } from "../../services/reportmanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Navbuttons from "components/Navigations/Navbuttons.js";
import { stringToArray } from "ag-grid-community";

const BillingReport = (props) => {
  

  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [sortfield, setsortfield] = useState("");
  const [sortorder, setsortorder] = useState("asc");
  
  const today = new Date();
  const month = today.getMonth() + 1; // Months are zero-based
  const year = today.getFullYear();
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
  const [filterFields,setfilterFields] = useState({fromdate:formattedDate});
  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data('',formattedDate);
  },[sortorder]);

  function get_report_data(e,start_date=null){
    reportmanageService.getBillingReportData(start_date,sortfield,sortorder).then((response) => {
      console.log(response.data)
      setReportdata(response.data.data); 
    });
  }


  function break_line(paragraph){
     return paragraph.replaceAll("\n",'<br>');
  }
  
  const dailyreport = () =>{
    history.push({
      pathname: 'dailyreport',
    });
  }

  const weeklyreport = () =>{
    history.push({
      pathname: 'weeklyreport',
    });
  }
  const monthlyreport = () =>{
    history.push({
      pathname: 'monthlyreport',
    });
  }
// date range

function downloadReport(){
  var start_date = filterFields.fromdate;

    reportmanageService.billing_report_export(start_date).then((response) => {
  });
}
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const handleExpand = (task, index) => {
 
    let temp_state = [...reportdata];
    let temp_element = { ...temp_state[index] };
    temp_element.toggle = temp_element.toggle ? false : true;
    temp_state[index] = temp_element;
    setReportdata(temp_state);
  };
   const companyfilter = (e) => {
    setfilterFields({ ...filterFields, [e.target.name]: e.target.value } );
    get_report_data('',e.target.value);
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
            <div className="col-2">
            <Col xl="12">
          {/* <div   style={{maxWidth:'10%'}}> */}
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    {/* </div> */}
                    </Col>
            
             </div>
            
              <Col xl="12">
             
                    <Row>
                    <Col xs={4}>
                      Start Month
                      <FormGroup>
                      <input type="month"
                                      className="form-control"
                                      name="fromdate"
                                      onChange={(e) =>
                                          companyfilter(e)
                                        }
                                    
                                        value={filterFields.fromdate}
                                      />
                      </FormGroup>
                    </Col>
                    {/* <Col xs={4}>
                    End Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                      
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date",
                              value:state.endDate.date()+'/'+(state.endDate.month()+1)+'/'+state.endDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col> */}

                  </Row>
              
                 
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                  <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Client</th>
                          <th>Total Hours</th>
                        </tr>
                      </thead>
                  <tbody>
                  {reportdata &&
                    reportdata.length &&
                    reportdata != null ? (
                      reportdata.map((user, index) => (
                        <>
                        <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td onClick={() => handleExpand(user, index)}>{user.client}</td>
                            <td colSpan={3}>{user.total_sum.toFixed(2).replace(/\.00$/, '')}</td>
                         </tr>
                         {(user.toggle) ? 
                         <tr><th>Sub client</th><th>Services</th><th>Value</th><th>Status</th> </tr> : null }
                         {user.data && user.data.length
                          ? // <div id={"collapse"+i} className="thead-light" style={{display: item.toggle ? 'block' : 'none' }}                                >
                          user.data.map((phase,j) => (
                              <tr key={j} className={((index % 2 == 0)? 'blue-row':(index % 3 == 0)?'purple-row':'orange-row') }
                              id={"collapse" + index}
                                style={{
                                  display: user.toggle ? "revert" : "none"
                                }}
                              >
                               
                                <td scope="row">
                                  <span className="mb-0 text-sm">
                                    {phase.subclient}
                                  </span>
                                </td>
                                <td>{phase.services}</td> 
                                 <td>{phase.value.toFixed(2).replace(/\.00$/, '')}</td> 
                                 <td>{(phase.approval == 1) ? 'Complete' : 'Pending'}</td> 
                              </tr>
                            ))
                          : //  </div>
                           ( null)}
                         </> 
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
    
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default BillingReport;
