import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  UncontrolledPopover 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
const axios = require("axios");
import { apiUrl } from "../../constant";
import { authtoken } from "global.js";
import {Formloader} from 'components/Loader/PageLoader.js';
import Paginationcomponent from "components/Paginationcomponent.js"
const Document = (props) => {
  const [filtertype,setfiltertype] = useState('kycdashboard');
  const [dashboarddata, setDashboarddata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [total_page,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const handlePageClick = (e, index) => {
 
    e.preventDefault();
    get_list(index,null,filtertype,rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev,null,filtertype,rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next,null,filtertype,rowsPerPage);
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    
    setCurrentPage(1); 
    get_list(1,null,filtertype,newRowsPerPage)
  
  };
  const get_list = async (pageNumber = 1,e,filtertype='',rowsPerPage='') => {
    let  textbox = '';
 
    if(e != null){
       textbox = e.target.value;
    }
   
    taskmanageService.getKYCDashboard(pageNumber,textbox,filtertype,rowsPerPage).then((response) => {
     
      setDashboarddata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
      setTotalData(response.data.data.total);
    });
  };
  function filterallcom({type}){
    setfiltertype(type)
    get_list(1,null,type);
 }
 function downloadReport(){
 
  taskmanageService.download_KYCDashreport(filtertype).then((response) => {
  
  });
}
 useEffect(() => {
  
  get_list(1,null,filtertype);
  
}, []);

  return (
    <>
    <Header />
     <Container className="mt--7" fluid>
      <Row>
        <Col>
          <button type="button" style={filtertype == 'kycdashboard' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'kycdashboard'})} className=" btn btn-secondary text-center">
            KYC dashboard
          </button>
          </Col>
          </Row>
          
         
            <div className="col" style={{padding:'0%'}}>
              <Card className="shadow">
              <CardHeader>
                  <div className="filter">
                  
                    <div   style={{maxWidth:'100%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div></div>
              </CardHeader>
              <Table className="align-items-center table-flush"   responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="whitespace sortclass" >Sr. no</th>
                    <th scope="col">Client name</th>
                    <th scope="col" className="whitespace sortclass">Passport Expiry date</th>
                    <th scope="col" className="whitespace sortclass">Driving License Expiry date</th>
                    <th scope="col" className="whitespace sortclass"><i class='fa fa-less-than'></i> Expiry In Months</th>
                    <th scope="col" className="whitespace sortclass">Status</th>
                   
                    
                  </tr>
                </thead>
               <tbody>
               {dashboarddata.length > 0 && dashboarddata  ? (
                        dashboarddata.map((item, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                  
                    <td className="text-center">{item.id}</td>
                    <td className="text-center">{item.name}</td>
                    <td className="text-center">{item.passport_expiry_date}</td>
                    <td className="text-center">{item.driving_expiry_date}</td>
                    <td className="text-center">{item.expire_in_month}</td>
                    <td className="text-center">{item.status_name}</td>
                    
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
               </tbody>
              </Table>
              <CardFooter className="py-4">
              <div>
     
              <Paginationcomponent
        currentPage={currentPage}
        totalItems={total_page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handlePageClick={handlePageClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
                </div>
              </CardFooter>
              </Card>
            </div>
            
          
       
     </Container>
    </>
  );
};


export default Document;
