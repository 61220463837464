import { useState, useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService";
import 'assets/css/themecustom-style.css';
import Paginationcomponent from "components/Paginationcomponent.js"
const Mismail = (props) => {
    let history = useHistory();
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [misMailData , setMisMailData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total_page,setTotalData] = useState(0);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_archive_list(index,rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_archive_list(prev,rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_archive_list(next,rowsPerPage);
  };
const [week,setWeek] = useState([]);
  const get_archive_list = async (pageNumber = 1,rowsPerPage='') => { 
    
    taskmanageService.getMisMailWeeklyData(pageNumber,rowsPerPage).then((response) => {
        console.log(response.data.data)
        setMisMailData(response.data.data);
        setWeek(response.data.weeklabeldisplay);
    });
  };

  const restoreTask = (id,index) => {
    taskmanageService.restoreTask(id).then((response) => {
      if(response.status == 200){
        let state = [...archivedata];
        delete state[index];
        setArchivedata(state);
       toast.success(response.data.message);
      }else{
        toast.error(response.data.message);
      }
    });
  }

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){
      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };

  useEffect(() => {
    get_archive_list();
}, []);

const handleRowsPerPageChange = (newRowsPerPage) => {

  setRowsPerPage(newRowsPerPage);
  
  setCurrentPage(1); 
  get_archive_list(1,newRowsPerPage)

};
const wrapstyle = {
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  };
  const getmis= (id)=>{
    history.push("/admin/mismaillog/"+id);
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        
      
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 3%'}}>
                    <h3 className="mb-0" style={{fontSize:'20px'}}>Mis Mail Log</h3><br></br>
                    <br></br>
                </Row>
               
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col" className="whitespace" >Client Name</th>
                    
                    {Object.values(week).map((month, index) => (
                      <th scope="col" className="whitespace"  key={index}>{month}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(misMailData).map((clientName,i) => {
                    
                    const clientData = misMailData[clientName] ?  misMailData[clientName] : [];

                    return (
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } key={clientName}>
                        <td className="whitespace"><b>{clientName}</b></td>
                        {Object.values(week).map((monthKey, index) => {
                          const monthData = clientData[monthKey];
                          return (
                            <td onClick={()=>{getmis(monthData['id'])}} className="whitespace" style={{cursor:"pointer"}} key={index}>
                              {monthData['is_done'] }
                            </td>
                          );
                        })}
                       
                        
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              
            </Card>
          </div>
        </Row>
      
      </Container>
    </>
  );
};


export default Mismail;
