import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import { useHistory,useParams } from "react-router-dom";
const axios = require("axios");
import {usermanageService} from '../../services/usermanageService';
import {taskmanageService} from '../../services/taskmanageService';

const DocusignList = (props) => {
  const history = useHistory();
  const adddocu = () =>{
    history.push({
      pathname: 'docusign',
    });
  }
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [agreementdata, setAgreementdata] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('open');

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_client_doc_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_client_doc_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_client_doc_list(next);
  };

  const get_client_doc_list = async (pageNumber = 1,e,filterFields,filtertype='') => {
    usermanageService.getclientdoclist(pageNumber,filterFields,filtertype).then((response) => {
      setAgreementdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };

  const [loading, setLoading] = useState(false);
  const downloadfile = (path,name,extension) => {
    setLoading(true)
    taskmanageService.download_file(path,encodeURIComponent(name),extension).then((response) => {
      setLoading(false)
    });
  }
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  usermanageService.getclient().then((response)=>{
   
    setUniqueCompaniesData(response.data.clients);
  });
}
const [documenttype, setDocumentType] = useState([]);
function get_docusign_document_type_list(){
  usermanageService.getdocusigndocumenttypelist().then((response)=>{
   
    setDocumentType(response.data.data);
  });
}
const companyfilter = (e,index) => {
  
  const cloneValues = {...filterFields};
  console.log(filterFields)
  cloneValues[index] = {
    ...cloneValues[index],
    ['field']: e.target.name,
    ['value']: encodeURIComponent(e.target.value),
    ['operator']: '='
  };
  console.log(cloneValues)
  setfilterFields(cloneValues);
  get_client_doc_list(1,null,cloneValues,filtertype);
}
  useEffect(() => {
    get_docusign_document_type_list();
    get_unique_companieslist();
    get_client_doc_list();
  }, []);
  return (
    <>
    <Header />
    <Container className="mt--7" fluid>
    <Row>
          <div className="col-3">
             <Button className="col-12 " color="primary" type="button" onClick={adddocu}>
                Add agreement
             </Button>
          </div>
          
          
          </Row>
          <Row className="mt-5">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center row">
                <div className="col-xl-8 col-md-2 col-xs-12">
                  <h3 className="mb-0" style={{ fontSize: "20px" }}>
                    Agreement List
                  </h3>
                  </div>
                  <div className="col-xl-2 col-md-4 col-xs-12">
                  <h5 className="clientformtag"> Client</h5>
                        {(uniquecompanieslist.length > 0 )?
                              <select className="form-control" name="company" onChange={(e) => { companyfilter(e,1);}}   >
                                  <option value="" key="al_key_1">All Clients</option>
                                  {(uniquecompanieslist).map((valuel,k) =>
                                      <option key={k} value={valuel.label}>
                                        {valuel.label}
                                      </option>
                                  )}
                              </select>
                            : null }
                        </div>
                        <div className="col-xl-2 col-md-3 col-xs-12">
                  <h5 className="clientformtag"> Document Type</h5>
                        {(documenttype.length > 0 )?
                              <select className="form-control" name="doc_type" onChange={(e) => { companyfilter(e,2);}}   >
                                  <option value="">Select Type</option>
                                  {(documenttype).map((valuel,k) =>
                                      <option key={k} value={valuel.name}>
                                        {valuel.name}
                                      </option>
                                  )}
                              </select>
                            : null }
                        </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Client Name</th>
                  <th scope="col">Client Email</th>
                  <th scope="col">Document Type</th>
                  <th scope="col">Document</th>
                </thead>
                <tbody>
                  {agreementdata && agreementdata.length && agreementdata != null ? (
                    agreementdata.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              i % 2 == 0
                                ? "blue-row"
                                : i % 3 == 0
                                ? "purple-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.doc_type}</td>
                            <td className="whitespace">
                              {(loading == true) ? 
                                <a style={{color:"#5d6cae"}}  >Downloading please wait.. </a>
                                :
                                <a style={{cursor:"pointer",color:"#5d6cae"}} onClick={() => downloadfile(item.doc_path,encodeURIComponent(item.file_name),item.extension)} download><i className="fas fa-download"> </i> Download</a>
                              }
                                </td>
                           
                          </tr>
                        </>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
     </Container>
    </>
  );
};


export default DocusignList;
