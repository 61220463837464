import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Radio
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../services/usermanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';

const Uploaddoc = (props) => {
  const initialData = {
    files: []
  };
  const [inputFields, setInputFields] = useState(initialData);
  const history = useHistory();

  function inputDocHandler(e) {
    const files = Array.from(e.target.files);
    setInputFields({ ...inputFields, files });
   
  }
  useEffect(() => {
    console.log('Updated inputFields:', inputFields);
  }, [inputFields]);
  
  // useEffect(() => {
  //   return () => {
  //     setInputFields({});
  //   };
  // }, []);

  function adddocuments(e) {
    console.log('as',inputFields);
    e.preventDefault();
    usermanageService.adddocs(inputFields).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message);
        document.getElementById("adduser-form").reset();
        // history.push('users');
      } else {
        console.log(response.data);
        toast.error(response.data.message);
      }
    });
  }

  const styles = {
    color: '#4a4444',
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-md-6"><h2>Add Documents</h2></div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-2 offset-md-10">
                      <Buttoncomponent />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <Card>
                <Form id="adduser-form" onSubmit={adddocuments}>
                  <div className="col-md-4">
                    <FormGroup>
                      <label>Document</label>
                      <InputGroup className="input-group-alternative mb-3">
                        <Input
                          placeholder="file"
                          type="file"
                          accept=".pdf, .doc, .docx, .jpeg, .jpg, .png, .zip"
                          onChange={inputDocHandler}
                          style={styles}
                          multiple
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Button className="col-12" color="primary" type="submit">
                        Save
                      </Button>
                    </div>
                  </div>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3"></InputGroup>
                  </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Uploaddoc;
