import { useState, useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import {usermanageService} from '../../../services/usermanageService';
import "assets/css/themecustom-style.css";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';

const KROListing = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [krodata, setKROdata] = useState([]);
  const [fullloader,setLoader] = useState(false);
  const months = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
  const [monthdata, setmonthdata] = useState(months);
  
  const [statuslist, setStatusdata] = useState([]);
const [reviewerlist, setReviewerData] = useState([]);
  const [uniqueuserslist, setUniqueUsersData] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
const initialData = {
  user_id : '',
  month_year : '',
  reviewer_id : '',
  status : '',
  approval:'',
  manager_approval:''
}
const [inputFields, setInputfields] = useState(initialData);
  function get_unique_userslist(){
    usermanageService.getusersforkro().then((response)=>{
      setUniqueUsersData(response.data.users);
    });
  }
  function get_status_list() {
    usermanageService.krogetstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  const history = useHistory();
  const handlePageClick = (e, index) => {
    window.localStorage.setItem('pnum', index)
    e.preventDefault();
    get_kro_list(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    window.localStorage.setItem('pnum', prev)
    get_kro_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    window.localStorage.setItem('pnum', next)
    get_kro_list(next);
  };

  const get_kro_list = async (pageNumber = 1) => {
    usermanageService.getkrolist(pageNumber,inputFields,sortfield,sortorder).then((response) => {
      setKROdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
      setReviewerData(response.data.reviewer_arr)
    });
  };
  const addkro = ()=>{
    history.push("/admin/krocalculation/");
  }
  const noteligibleuser = ()=>{
    history.push("/admin/noteligibleuser/");
  }
  const editclick = (user_id,month_year) => {
    history.push("/admin/krocalculation/"+user_id+"/"+month_year);
    
  }
  function inputhandler1(e,field) {
    if(field=='month_year' || field=='approval' || field=='manager_approval'){
      setInputfields((prevInputFields)=>({ ...prevInputFields, [field]: e.target.value } ));
    }else{
      console.log()
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      [field]: e.value,
    }));
    }
  
  }

  const editField = (value, field, id,user_id, index) => {
    let temp_st = [...krodata];
    let temp_ele = { ...temp_st[index] };
   
    if((field == 'approval' || field == "manager_approval") && value == 2){
      // if reject then remarks should not be blank
      if(temp_ele.remarks == '' || temp_ele.remarks == null){
        toast.error('Remarks is required');
        return;
      }
    }
    usermanageService.editKRORow(value, field, id,user_id).then((response) => {
   
      if(response.status == 200){
          if (field == "approval") {
            if (value == 1) {
              temp_ele.approval = "Discussed & Approved";
            } else if (value == 2 && temp_ele.status != 17) {
              temp_ele.approval = "Rejected";
              temp_ele.status_name = "Not Complete";
            }else{
              temp_ele.approval = "";
            }
          }

          if (field == "manager_approval") {
            if (value == 1) {
              temp_ele.manager_approval = "Discussed & Approved";
            } else if (value == 2 && temp_ele.status != 17) {
              temp_ele.manager_approval = "Rejected";
              temp_ele.status_name = "Not Complete";
            }else{
              temp_ele.manager_approval = "";
            }
          }
         
          toast.success(response.data.message);
          temp_ele[field] = value;
         
      }else{
          toast.error(response.data.message);
      }
    temp_ele.mainediting = false;
     temp_st[index] = temp_ele;
     setKROdata(temp_st);
    });
  };

  const handlemaineditRow = (index) => {
      let temp_state = [...krodata];
      let temp_element = { ...temp_state[index] };
      temp_element.mainediting = true;
      temp_state[index] = temp_element;
      setKROdata(temp_state);
  };
    // sorting functon
    const [sortfield,setsortfield] = useState('');
    const [sortorder,setsortorder] = useState('asc');
    function sortTable(field){
      console.log(field)
      setsortfield(field);
      if(sortorder=='asc'){
        setsortorder('desc');
      }else{
        setsortorder('asc');
      }
    }
    
  useEffect(() => {
    const pnum = window.localStorage.getItem('pnum');
    console.log(pnum)
    get_unique_userslist();
    get_kro_list(pnum);
    get_status_list();
  }, [sortorder]);
  function downloadReport(){
    
    usermanageService.download_kro_data(inputFields).then((response) => {
    
    });
  }
 
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <Row className="align-items-center row">
            <Col xl="10">
        <Button className="dash-button "
                color="primary"
                type="button"
                onClick={addkro}>
           Add monthly KRO
        </Button>
      </Col>
      {/* <Col xl="2">
        <Button className="dash-button "
                color="secondary"
                type="button"
                onClick={noteligibleuser}>
           Not Eligible Users
        </Button>
     </Col> */}
   
     
     </Row>
        <Row className="mt-5">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               {/* <div className="filter" style={{width:'100%'}}> */}
                   <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> Select User</h3>
                   {(uniqueuserslist.length > 0) ? 
                   <Select 
                   
                   className="input-group-alternative " 
                   name="user_id"
                   options={uniqueuserslist} // set list of the data
                   onChange={(e) => inputhandler1(e,'user_id')}
                  //  value={usersearch}
                   searchable
                   isClearable={false}
                 />
                 : null }
                  </div>
                  <div className="col-xl-2 col-md-3 col-xs-12">
                      <h3 className="clientformtag"> Select Reviewer</h3>
                      <Select 
                        className="input-group-alternative " 
                        name="reviewer_id"
                        options={reviewerlist} // set list of the data
                        onChange={(e) => inputhandler1(e,'reviewer_id')}
                        searchable
                        isClearable={false}
                    />
                   </div>
                   <div className="col-xl-2 col-md-3 col-xs-12">
                      <h3 className="clientformtag"> Select Status</h3>
                      <Select 
                        className="input-group-alternative " 
                        name="reviewer_id"
                        options={statuslist} // set list of the data
                        onChange={(e) => inputhandler1(e,'status')}
                        searchable
                        isClearable={false}
                    />
                   </div>
                  <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> Select month</h3>
                    <input type="month"
                                      className="form-control"
                                      name="month_year"
                                      onChange={(e) => { inputhandler1(e,'month_year')}}
                                      value={inputFields.month_year}
                                      options={monthdata}
                                      required
                                      />
                                       {inputFields.month_year && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                      
                  </div>
                  
                  <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> R. Approval</h3>
                    <select class="form-control" name="approval" onChange={(e) => inputhandler1(e,'approval')} >
                        <option value="">Select Status</option>
                        <option value="0" >Not Approve</option>
                        <option value="1">Approved</option>
                        <option value="2">Reject</option>
                  </select>
                                       
                      
                  </div>
                  <div className="col-xl-2 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> M. Approval</h3>
                    <select class="form-control" name="manager_approval" onChange={(e) => inputhandler1(e,'manager_approval')} >
                        <option value="">Select Status</option>
                        <option value="0" >Not Approve</option>
                        <option value="1">Approved</option>
                        <option value="2">Reject</option>
                  </select>
                                       
                      
                  </div>
                                  
                    <div className="col-xl-3 col-md-3 col-xs-12">
                    <br></br>
                      <Button className="col-xl-6 btn btn-primary"  onClick={(e) => get_kro_list()}>Search</Button>
                    </div>
                    {/* </div> */}
                </Row>
                <Row className="filter" style={{ padding: "0 25px" }}>
                  <div className="d-flex">
                  <h3 className="mb-0 mt-2" style={{ fontSize: "20px" }}>
                    KRO's
                  </h3>
                  <div><Button
                      className="btn-icon-clipboard  ml-5"
                      type="button"
                      onClick={downloadReport}
                      
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button></div>
                  {/* <div className="d-flex ml-7 mt-2">
                  <select class="form-control" name="approve_type" onChange={(e) => inputhandler1(e,'approve_type')} >
                        <option value="">Select Type</option>
                        <option value="approval" >R. Approval</option>
                        <option value="manager_approval">M. Approval</option>
                  </select>&nbsp;
                  <select class="form-control" name="approve_status" onChange={(e) => inputhandler1(e,'approve_status')} >
                        <option value="">Select Status</option>
                        <option value="0" >Not Approve</option>
                        <option value="1">Approved</option>
                        <option value="2">Reject</option>
                  </select>&nbsp;
                  <Button className="col-xl-4 btn btn-primary"  onClick={(e) => get_kro_list()}>Search</Button>
                  </div> */}
                  </div>
                  <br></br>
                  <br></br>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col" >Name</th>
                  <th scope="col">Reviewer</th>
                  <th scope="col">Month</th>
                  <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('total_weightage_score')}>Score</th>
                  <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('total_weightage_score')}>Rating</th>
                  <th scope="col">Status</th>
                  <th scope="col">R. Approval</th>
                  <th scope="col">M. Approval</th>
                  <th scope="col">Remarks</th>
                  <th scope="col">Action</th>
                </thead>
                <tbody>
                  {krodata && krodata.length && krodata != null ? (
                    krodata.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              item.total_weightage_score <= 2.5
                                ? "purple-row"
                                : (item.total_weightage_score <= 3.5 && item.total_weightage_score > 2.5)
                                ? "blue-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.name}</td>
                            {/* <td>{item.email}</td> */}
                            <td>{item.reviewer}</td>
                            <td>{item.month}</td>
                            <td>{item.total_weightage_score}</td>
                            <td>{(item.total_weightage_score == '' || item.total_weightage_score == null) ? '' : (item.total_weightage_score <= 1 ? "Needs Action" : (item.total_weightage_score <= 2.5 ? "Below Expectations" : (item.total_weightage_score <= 3.5 ? "Meets Expectations" : (item.total_weightage_score <= 4.5 ? "Exceed Expectation" : (item.total_weightage_score <= 5 ? "Exceptional" : "")) )))}</td>
                            <td>{item.status_name}</td>
                            {(item.approval != 1 && item.approval != 2 && (item.status == 3 || item.status == 17) && item.reviewer_id == user_info.id) ?
                            (<td>
                              <Button
                                color="primary"
                                style={{
                                  background: "#02C779",
                                  border: "#02C779",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editField(
                                    1,
                                    "approval",
                                    item.month_ye,
                                    item.user_id,
                                    i
                                  )
                                }
                              >
                                Approve
                              </Button>
                             
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editField(
                                    2,
                                    "approval",
                                    item.month_ye,
                                    item.user_id,
                                    i
                                  )
                                }
                                
                              >
                                Reject
                              </Button>
                           
                            </td>
                      ) : <>
                      <td><span
                                  color="primary"
                                  style={{
                                    color:
                                      item.approval == "1"
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >{(item.approval== 1) ? 'Discussed & Approved' : (item.approval== 2) ? "Rejected" : "  "}</span>
                        </td>
                         
                      </> }
                      {(item.manager_approval != 1 && item.manager_approval != 2 && (item.status == 3 || item.status == 17) && item.approval == 1 && item.manager_id == user_info.id) ?
                            (<td>
                              <Button
                                color="primary"
                                style={{
                                  background: "#02C779",
                                  border: "#02C779",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editField(
                                    1,
                                    "manager_approval",
                                    item.month_ye,
                                    item.user_id,
                                    i
                                  )
                                }
                              >
                                Approve
                              </Button>
                             
                              <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  editField(
                                    2,
                                    "manager_approval",
                                    item.month_ye,
                                    item.user_id,
                                    i
                                  )
                                }
                                
                              >
                                Reject
                              </Button>
                           
                            </td>
                      ) : <>
                      <td><span
                                  color="primary"
                                  style={{
                                    color:
                                      item.manager_approval == "1"
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >{(item.manager_approval== 1) ? 'Discussed & Approved' : (item.manager_approval== 2) ? "Rejected" : "  "}</span>
                        </td>
                         
                      </> }
                      <td  onClick={(item.manager_approval != 1 || item.approval != 1) ? (e) => handlemaineditRow(i) : undefined} >
                        {item.mainediting ? (
                          <input type="text"
                          className="form-control"
                          defaultValue={item.remarks}
                          
                            onBlur={(e) =>
                              editField(
                                e.target.value,
                                "remarks",
                                item.month_ye,
                                item.user_id,
                                i
                              )
                            }
                          />
                        ) : (
                          <span title={item.remarkby}>{item.remarks}</span>
                        )}
                      </td>
                            <td>
                            {(item.status != 17) ?
                      (
                            <Button className="btn btn-success" 
                            onClick={(e) =>
                              editclick(
                                item.user_id,item.month_ye
                              )
                            } class="btn btn-success"><i class="fa fa-eye"></i></Button>) : null }
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      ) 
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default KROListing;
