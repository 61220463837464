import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Onetimeresetpass from "views/examples/Onetimeresetpass";
import Forgotpassword from "views/examples/Forgotpassword";
import Resetpassword from "views/examples/Resetpassword";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Dashboard from "views/user/Dashboard.js";
import User from "views/user/users/User.js";
import Role from "views/user/roles/Role.js";

import AnalystDashboard from "views/analyst/Dashboard.js";
import Addclient from "views/analyst/client/AddClient.js";
import Archive from "views/analyst/Archive.js";
import AddClientTransition from "views/client/AddClientTransition.js";
import AddClientTransitionFields from "views/client/AddClientTransitionFields.js";



// phases
import Phaselist from "views/user/phase/Phaselist.js";
import AnalyticsReport from "views/analyst/analytics/AnalyticsReport.js";
import ClientDashboard from "views/client/Dashboard.js";
import ClientUser from "views/client/User.js";
import ClientList from "views/client/ClientList";
import Categorylist from "views/user/category/Categorylist";
import RejectionReport from "views/analyst/analytics/RejectionReport";
import ExternalRejection from "views/client/ExternalRejection";
import Docusign from "views/client/Docusign";
import FinanceDashboard from "views/client/Document.js";
import BillingDashboard from "views/client/Billingdashboard.js";
import DocusignList from "views/client/DocusignList";
import GovernanceCall from "views/client/GovernanceCall";
import ClientTransition from "views/client/ClientTransition";
import Billing from "views/client/Billing";
import InternalGovernanceCall from "views/client/InternalGovernanceCall";
import BillingReport from "views/client/BillingReport";
import InternalCall from "views/client/InternalCall";
import Announce from "views/client/Announce";
import TodoList from "views/analyst/todo/TodoList";
import MasterKRO from "views/user/users/MasterKRO";
import AddMasterKRO from "views/user/users/AddMasterKRO";
import KROCalculation from "views/user/users/KROCalculation";
import Krolisting from "views/user/users/KROListing";
import UserDashboard from "views/user/users/UserDashboard";
import GovernanceDashboard from "views/client/GovernanceDashboard";

import AssignClient from "views/client/AssignClient";
import KRODashboard from "views/user/users/KRODashboard";
import ManagerRejection from "views/client/ManagerRejection";
import AddMasterChecklist from "views/client/AddMasterChecklist";
import KROTypeRating from "views/user/users/KROTypeRating";
import FeedbackChecklist from "views/client/FeedbackChecklist";
import PhasesMISReport from "views/analyst/analytics/phasesMISReport";
import GraphChart from "views/client/GraphChart.js";
import Uploaddoc from "views/client/Uploaddoc";
import PriceReviosion from "views/client/PriceReviosion";
import BillingUnlock from "views/client/BillingUnlock";
import Competencyrole from "views/user/users/Competencyrole";
import AddMasterIncentive from "views/user/AddMasterIncentive";
import Incentives from "views/user/Incentives";
import GovernanceChart from "views/client/GovernanceChart";
import podScreen from "views/analyst/podScreen";
import Kanbanview from "views/analyst/Kanbanview";

var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
        if(user_info.roletype == 'payroll'){
          user_info.rolename = user_info.rolename+'_payroll';
        }
    }else{
        user_info.rolename = 'default';
    }
}
  switch (user_info.rolename) {
    case 'admin':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: Dashboard,
          layout: "/admin",
        },

        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/phase",
          name: "Phase",
          icon: "fa fa-sort-alpha-desc",
          component: Phaselist,
          layout: "/admin",
        },
        {
          path: "/roles",
          name: "Roles",
          icon: "fa fa-lock",
          component: Role,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Categories",
          icon: "fa fa-lock",
          component: Categorylist,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        }, 
        
        
      ];
      break;
    case 'qanalyst':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        // {
        //   path: "/client",
        //   name: "Clients",
        //   icon: "ni ni-tv-2 text-th",
        //   component: ClientDashboard,
        //   layout: "/admin",
        // },
        // {
        //   path: "/archive",
        //   name: "Archived",
        //   icon: "ni ni-archive-2 text-th",
        //   component: Archive,
        //   layout: "/admin",
        // },
        // {
        //   path: "/users",
        //   name: "Users",
        //   icon: "ni ni-single-02 text-th",
        //   component: User,
        //   layout: "/admin",
        // },
        // {
        //   path: "/user-profile",
        //   name: "User Profile",
        //   icon: "ni ni-settings-gear-65",
        //   component: Profile,
        //   layout: "/admin",
        // },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        }
        
        // {
        //   path: "/rejectionreport",
        //   name: "Rejections",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: RejectionReport,
        //   layout: "/admin",
        // },
        // {
        //   path: "/externalrejection",
        //   name: "External CCRP",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: ExternalRejection,
        //   layout: "/admin",
        // },
        // {
        //   path: "/clienttransition",
        //   name: "Client Transition",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: ClientTransition,
        //   layout: "/admin",
        // },
        // {
        //   path: "/governance",
        //   name: "External Governance",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: GovernanceCall,
        //   layout: "/admin",
        // },
        // {
        //   path: "/internal-governance",
        //   name: "Weekly Call",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: InternalGovernanceCall,
        //   layout: "/admin",
        // },
        // {
        //   path: "/internal-call",
        //   name: "Internal Call",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: InternalCall,
        //   layout: "/admin",
        // }
        , 
        // {
        //   path: "/todolist",
        //   name: "Todo List",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: TodoList,
        //   layout: "/admin",
        // }, 
        // {
        //   path: "/Krolisting",
        //   name: "KRO's",
        //   icon: "ni ni-single-02 text-th",
        //   component: Krolisting,
        //   layout: "/admin",
        // },
        // {
        //   path: "/addmasterkro",
        //   name: "KRO Master",
        //   icon: "ni ni-single-02 text-th",
        //   component: AddMasterKRO,
        //   layout: "/admin",
        // },
        
          {
            path:'1',
             name: "User",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/users",
                name: "Users",
                icon: "ni ni-single-02 text-th",
                component: User,
                layout: "/admin",
              },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-settings-gear-65",
                component: Profile,
                layout: "/admin",
              },{
                path: "/archive",
                name: "Archived",
                icon: "ni ni-archive-2 text-th",
                component: Archive,
                layout: "/admin",
              }]
          },
          {
         path:'2',
          name: "Governance",
          icon: "ni ni-single-02 text-th",
          layout: "/admin",
          submenu:[{
            path: "/governancedashboard",
            name: "Dashboard",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceDashboard,
            layout: "/admin",
          },{
            path: "/governance",
            name: "External Governance",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceCall,
            layout: "/admin",
          },
          {
            path: "/internal-governance",
            name: "Weekly Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalGovernanceCall,
            layout: "/admin",
          }, {
            path: "/internal-call",
            name: "Internal Call",
            icon: "ni ni-single-copy-04 text-th",
            component: InternalCall,
            layout: "/admin",
          },{
            path: "/gpvernance-chart",
            name: "Governance Chart",
            icon: "ni ni-single-copy-04 text-th",
            component: GovernanceChart,
            layout: "/admin",
          }]
        },
        {
          path:'3',
           name: "CCRP",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[
          {
            path: "/rejectionreport",
            name: "Internal CCRP",
            icon: "ni ni-single-copy-04 text-th",
            component: RejectionReport,
            layout: "/admin",
          },{
            path: "/externalrejection",
            name: "External CCRP",
            icon: "ni ni-single-copy-04 text-th",
            component: ExternalRejection,
            layout: "/admin",
          },
          {
            path: "/managerrejection",
            name: "Manager Rejection",
            icon: "ni ni-single-copy-04 text-th",
            component: ManagerRejection,
            layout: "/admin",
          }
        ]
         },
         {
          path:'4',
           name: "KRO",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[{
            path: "/Krolisting",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: Krolisting,
            layout: "/admin",
          },{
            path: "/addmasterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: AddMasterKRO,
            layout: "/admin",
          },{
            path: "/krotyperating",
            name: "KRO Type Rating",
            icon: "ni ni-single-02 text-th",
            component: KROTypeRating,
            layout: "/admin",
          },{
            path: "/graphchart",
            name: "KRO Graph",
            icon: "ni ni-single-copy-04 text-th",
            component: GraphChart,
            layout: "/admin",
          }]
        },
        {
          path:'5',
           name: "Client",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[{
            path: "/client",
            name: "Clients",
            icon: "ni ni-tv-2 text-th",
            component: ClientDashboard,
            layout: "/admin",
          },{
            path: "/clienttransition",
            name: "Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientTransition,
            layout: "/admin",
          },{
            path: "/assignclient",
            name: "Assign Client",
            icon: "ni ni-single-copy-04 text-th",
            component: AssignClient,
            layout: "/admin",
          }]
        },
        {
          path:'6',
           name: "Checklist",
           icon: "ni ni-tv-2 text-th",
           layout: "/admin",
           submenu:[
          {
            path: "/checklistfeedback",
            name: "Checklist Feedback",
            icon: "ni ni-tv-2 text-th",
            component: FeedbackChecklist,
            layout: "/admin",
          }
        ]
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },
        
        
      ];
      break;
    case 'analyst':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejections",
          icon: "ni ni-single-copy-04 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        }, 
      ];
      break;
      case 'support_manager':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        // {
        //   path: "/users",
        //   name: "Users",
        //   icon: "ni ni-single-02 text-th",
        //   component: User,
        //   layout: "/admin",
        // },
        // {
        //   path: "/user-profile",
        //   name: "User Profile",
        //   icon: "ni ni-single-02 text-th",
        //   component: Profile,
        //   layout: "/admin",
        // },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },  
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejections",
          icon: "ni ni-single-copy-04 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        // {
        //   path: "/internal-call",
        //   name: "Internal Call",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: InternalCall,
        //   layout: "/admin",
        // }, 
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        }, 
        {
          path:'1',
           name: "User",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[
            {
              path: "/users",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: User,
              layout: "/admin",
            },
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            }]
        },
        {
          path:'2',
           name: "Governance",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[
            {
             path: "/internal-call",
             name: "Internal Call",
             icon: "ni ni-single-copy-04 text-th",
             component: InternalCall,
             layout: "/admin",
           }]
         },
         {
          path:'4',
           name: "KRO",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[{
            path: "/Krolisting",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: Krolisting,
            layout: "/admin",
          },{
            path: "/addmasterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: AddMasterKRO,
            layout: "/admin",
          },{
            path: "/krotyperating",
            name: "KRO Type Rating",
            icon: "ni ni-single-02 text-th",
            component: KROTypeRating,
            layout: "/admin",
          }]
        },
        {
          path:'5',
           name: "Checklist",
           icon: "ni ni-tv-2 text-th",
           layout: "/admin",
           submenu:[{
            path: "/checklistmaster",
            name: "Checklist Master",
            icon: "ni ni-tv-2 text-th",
            component: AddMasterChecklist,
            layout: "/admin",
          },
          {
            path: "/checklistfeedback",
            name: "Checklist Feedback",
            icon: "ni ni-tv-2 text-th",
            component: FeedbackChecklist,
            layout: "/admin",
          }
        ]
        },
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        } 
      ];
      break;
    case 'team':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Rejections",
          icon: "ni ni-single-copy-04 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/internal-call",
          name: "Internal Call",
          icon: "ni ni-single-copy-04 text-th",
          component: InternalCall,
          layout: "/admin",
        },
        {
          path: "/todolist",
          name: "Todo List",
          icon: "ni ni-single-copy-04 text-th",
          component: TodoList,
          layout: "/admin",
        },  
        {
          path:'1',
           name: "KRO",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[{
            path: "/Krolisting",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: Krolisting,
            layout: "/admin",
          },
          (user_info.role_id == 6)?
          {
            path: "/addmasterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: AddMasterKRO,
            layout: "/admin",
          } : {}]
        },
        {
          path: "/checklistfeedback",
          name: "Checklist Feedback",
          icon: "ni ni-tv-2 text-th",
          component: FeedbackChecklist,
          layout: "/admin",
        },
        (user_info.role_id == 6)?
        {
          path: "/managerrejection",
          name: "Manager Rejection",
          icon: "ni ni-single-copy-04 text-th",
          component: ManagerRejection,
          layout: "/admin",
        } : {}
      ];
      break;
      case 'manager':
        var routes=[
          {
            path: "/dashboard",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: AnalystDashboard,
            layout: "/admin",
          },
          {
            path:'1',
             name: "User",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/users",
                name: "Users",
                icon: "ni ni-single-02 text-th",
                component: User,
                layout: "/admin",
              },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-settings-gear-65",
                component: Profile,
                layout: "/admin",
              },{
                path: "/archive",
                name: "Archived",
                icon: "ni ni-archive-2 text-th",
                component: Archive,
                layout: "/admin",
              }]
          } ,
          {
            path:'2',
             name: "Governance",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[{
              path: "/governancedashboard",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceDashboard,
              layout: "/admin",
            },{
               path: "/governance",
               name: "External Governance",
               icon: "ni ni-single-copy-04 text-th",
               component: GovernanceCall,
               layout: "/admin",
             },
             {
               path: "/internal-governance",
               name: "Weekly Call",
               icon: "ni ni-single-copy-04 text-th",
               component: InternalGovernanceCall,
               layout: "/admin",
             }, {
               path: "/internal-call",
               name: "Internal Call",
               icon: "ni ni-single-copy-04 text-th",
               component: InternalCall,
               layout: "/admin",
             },
             {
              path: "/gpvernance-chart",
              name: "Governance Chart",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceChart,
              layout: "/admin",
            }]
           },
           {
            path:'3',
             name: "CCRP",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
            {
              path: "/rejectionreport",
              name: "Internal CCRP",
              icon: "ni ni-single-copy-04 text-th",
              component: RejectionReport,
              layout: "/admin",
            },{
              path: "/externalrejection",
              name: "External CCRP",
              icon: "ni ni-single-copy-04 text-th",
              component: ExternalRejection,
              layout: "/admin",
            },
            {
              path: "/managerrejection",
              name: "Manager Rejection",
              icon: "ni ni-single-copy-04 text-th",
              component: ManagerRejection,
              layout: "/admin",
            }
          ]
           },
           {
            path:'4',
             name: "KRO",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[{
              path: "/krodashboard",
              name: "KRO Dashboard",
              icon: "ni ni-single-02 text-th",
              component: KRODashboard,
              layout: "/admin",
            },{
              path: "/Krolisting",
              name: "KRO's",
              icon: "ni ni-single-02 text-th",
              component: Krolisting,
              layout: "/admin",
            },{
              path: "/addmasterkro",
              name: "KRO Master",
              icon: "ni ni-single-02 text-th",
              component: AddMasterKRO,
              layout: "/admin",
            },
            {
              path: "/krotyperating",
              name: "KRO Type Rating",
              icon: "ni ni-single-02 text-th",
              component: KROTypeRating,
              layout: "/admin",
            },
            {
              path: "/graphchart",
              name: "KRO Graph Chart",
              icon: "ni ni-single-copy-04 text-th",
              component: GraphChart,
              layout: "/admin",
            },
            {
              path: "/competencyrole",
              name: "Competency Master",
              icon: "ni ni-single-02 text-th",
              component: Competencyrole,
              layout: "/admin",
            }
          ]
          },
          {
            path:'5',
             name: "Client",
             icon: "ni ni-tv-2 text-th",
             layout: "/admin",
             submenu:[{
              path: "/client",
              name: "Clients",
              icon: "ni ni-tv-2 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },{
              path: "/clienttransition",
              name: "Client Transition",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientTransition,
              layout: "/admin",
            },{
              path: "/assignclient",
              name: "Assign Client",
              icon: "ni ni-single-copy-04 text-th",
              component: AssignClient,
              layout: "/admin",
            }]
          },
          {
            path:'6',
             name: "Checklist",
             icon: "ni ni-tv-2 text-th",
             layout: "/admin",
             submenu:[{
              path: "/checklistmaster",
              name: "Checklist Master",
              icon: "ni ni-tv-2 text-th",
              component: AddMasterChecklist,
              layout: "/admin",
            },
            {
              path: "/checklistfeedback",
              name: "Checklist Feedback",
              icon: "ni ni-tv-2 text-th",
              component: FeedbackChecklist,
              layout: "/admin",
            }
          ]
          },
          {
            path:'7',
             name: "Reports",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/analytics_report",
                name: "Report",
                icon: "ni ni-single-copy-04 text-th",
                component: AnalyticsReport,
                layout: "/admin",
              },
              {
                path: "/phasesMIS",
                name: "Phases MIS",
                icon: "ni ni-settings-gear-65",
                component: PhasesMISReport,
                layout: "/admin",
              }]
          } ,
          {
            path:'7',
             name: "POD",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/podscreen",
                name: "Pod Data",
                icon: "ni ni-single-copy-04 text-th",
                component: podScreen,
                layout: "/admin",
              },
              {
                path: "/kanbanview",
                name: "Dashboard",
                icon: "ni ni-settings-gear-65",
                component: Kanbanview,
                layout: "/admin",
              }]
          } ,
          
          {
            path: "/announce",
            name: "Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: Announce,
            layout: "/admin",
          },
          {
            path: "/todolist",
            name: "Todo List",
            icon: "ni ni-single-copy-04 text-th",
            component: TodoList,
            layout: "/admin",
          }
          
        ];
        break;
        case 'hod':
        var routes=[
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: AnalystDashboard,
            layout: "/admin",
          },
          {
            path: "/client",
            name: "Clients",
            icon: "ni ni-tv-2 text-th",
            component: ClientDashboard,
            layout: "/admin",
          },
          {
            path:'1',
             name: "User",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-settings-gear-65",
                component: Profile,
                layout: "/admin",
              }]
          },
          {
            path:'2',
             name: "Governance",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[{
              path: "/governancedashboard",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceDashboard,
              layout: "/admin",
            },{
               path: "/governance",
               name: "External Governance",
               icon: "ni ni-single-copy-04 text-th",
               component: GovernanceCall,
               layout: "/admin",
             },
              {
               path: "/internal-call",
               name: "Internal Call",
               icon: "ni ni-single-copy-04 text-th",
               component: InternalCall,
               layout: "/admin",
             },{
              path: "/gpvernance-chart",
              name: "Governance Chart",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceChart,
              layout: "/admin",
            }]
           },
           {
            path:'3',
             name: "CCRP",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
            {
              path: "/rejectionreport",
              name: "Internal CCRP",
              icon: "ni ni-single-copy-04 text-th",
              component: RejectionReport,
              layout: "/admin",
            },{
              path: "/externalrejection",
              name: "External CCRP",
              icon: "ni ni-single-copy-04 text-th",
              component: ExternalRejection,
              layout: "/admin",
            },
            {
              path:'4',
               name: "KRO",
               icon: "ni ni-single-02 text-th",
               layout: "/admin",
               submenu:[{
                path: "/Krolisting",
                name: "KRO's",
                icon: "ni ni-single-02 text-th",
                component: Krolisting,
                layout: "/admin",
              },{
                path: "/addmasterkro",
                name: "KRO Master",
                icon: "ni ni-single-02 text-th",
                component: AddMasterKRO,
                layout: "/admin",
              },{
                path: "/krotyperating",
                name: "KRO Type Rating",
                icon: "ni ni-single-02 text-th",
                component: KROTypeRating,
                layout: "/admin",
              },{
                path: "/graphchart",
                name: "KRO Graph",
                icon: "ni ni-single-copy-04 text-th",
                component: GraphChart,
                layout: "/admin",
              }]
            },{
              path:'5',
               name: "Reports",
               icon: "ni ni-single-02 text-th",
               layout: "/admin",
               submenu:[
                {
                  path: "/analytics_report",
                  name: "Report",
                  icon: "ni ni-single-copy-04 text-th",
                  component: AnalyticsReport,
                  layout: "/admin",
                },
                {
                  path: "/phasesMIS",
                  name: "Phases MIS",
                  icon: "ni ni-settings-gear-65",
                  component: PhasesMISReport,
                  layout: "/admin",
                }]
            } ,]
           },
           {
            path: "/todolist",
            name: "Todo List",
            icon: "ni ni-single-copy-04 text-th",
            component: TodoList,
            layout: "/admin",
          }
        ];
        break;
        case 'deputy_manager':
        var routes=[
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-copy-04 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
        ];
        break;
        case 'Client':
          var routes=[
            {
              path: "/index",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            {
              path: "/clientuser",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: ClientUser,
              layout: "/admin",
            },
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            },
          ];
          break;
          case 'ClientManager':
            var routes=[
              {
                path: "/index",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: ClientDashboard,
                layout: "/admin",
              },
              {
                path: "/clientuser",
                name: "Users",
                icon: "ni ni-single-02 text-th",
                component: ClientUser,
                layout: "/admin",
              },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-settings-gear-65",
                component: Profile,
                layout: "/admin",
              },
            ];
          break;
          case 'finance':
            var routes=[
              {
                path: "/index",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: FinanceDashboard,
                layout: "/admin",
              },
              {
                path: "/clientuser",
                name: "Clients",
                icon: "ni ni-single-02 text-th",
                component: ClientList,
                layout: "/admin",
              },
              {
                path: "/docusignlist",
                name: "Docusign",
                icon: "ni ni-single-copy-04 text-th",
                component: DocusignList,
                layout: "/admin",
              },
              // {
              //   path: "/governance",
              //   name: "External Governance",
              //   icon: "ni ni-single-copy-04 text-th",
              //   component: GovernanceCall,
              //   layout: "/admin",
              // },
              // {
              //   path: "/internal-governance",
              //   name: "Weekly Call",
              //   icon: "ni ni-single-copy-04 text-th",
              //   component: InternalGovernanceCall,
              //   layout: "/admin",
              // },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-settings-gear-65",
                component: Profile,
                layout: "/admin",
              },
              {
                path: "/adddocs",
                name: "Upload document",
                icon: "ni ni-settings-gear-65",
                component: Uploaddoc,
                layout: "/admin",
              },
              // {
              //   path: "/internal-call",
              //   name: "Internal Call",
              //   icon: "ni ni-single-copy-04 text-th",
              //   component: InternalCall,
              //   layout: "/admin",
              // }, 
              // {
              //   path: "/todolist",
              //   name: "Todo List",
              //   icon: "ni ni-single-copy-04 text-th",
              //   component: TodoList,
              //   layout: "/admin",
              // }, 
              // {
              //   path: "/Krolisting",
              //   name: "KRO's",
              //   icon: "ni ni-single-02 text-th",
              //   component: Krolisting,
              //   layout: "/admin",
              // },
              // {
              //   path: "/addmasterkro",
              //   name: "KRO Master",
              //   icon: "ni ni-single-02 text-th",
              //   component: AddMasterKRO,
              //   layout: "/admin",
              // },
              {
                path:'2',
                 name: "Governance",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[{
                  path: "/governancedashboard",
                  name: "Dashboard",
                  icon: "ni ni-single-copy-04 text-th",
                  component: GovernanceDashboard,
                  layout: "/admin",
                },{
                   path: "/governance",
                   name: "External Governance",
                   icon: "ni ni-single-copy-04 text-th",
                   component: GovernanceCall,
                   layout: "/admin",
                 },
                  {
                   path: "/internal-call",
                   name: "Internal Call",
                   icon: "ni ni-single-copy-04 text-th",
                   component: InternalCall,
                   layout: "/admin",
                 },
                 {
                  path: "/internal-governance",
                  name: "Weekly Call",
                  icon: "ni ni-single-copy-04 text-th",
                  component: InternalGovernanceCall,
                  layout: "/admin",
                },{
                  path: "/gpvernance-chart",
                  name: "Governance Chart",
                  icon: "ni ni-single-copy-04 text-th",
                  component: GovernanceChart,
                  layout: "/admin",
                }]
              },
              {
                path:'4',
                 name: "KRO",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[{
                  path: "/Krolisting",
                  name: "KRO's",
                  icon: "ni ni-single-02 text-th",
                  component: Krolisting,
                  layout: "/admin",
                },{
                  path: "/addmasterkro",
                  name: "KRO Master",
                  icon: "ni ni-single-02 text-th",
                  component: AddMasterKRO,
                  layout: "/admin",
                },{
                  path: "/krotyperating",
                  name: "KRO Type Rating",
                  icon: "ni ni-single-02 text-th",
                  component: KROTypeRating,
                  layout: "/admin",
                }]
              },
              {
                path: "/todolist",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              },
              {
                path: "/price_reviosion",
                name: "Price Revision",
                icon: "ni ni-single-copy-04 text-th",
                component: PriceReviosion,
                layout: "/admin",
              }
              
            ];
          break;
          case 'billing':
            var routes=[
              {
                path: "/index",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: BillingDashboard,
                layout: "/admin",
              },
              {
                path: "/billingreport",
                name: "Billing Data",
                icon: "ni ni-single-02 text-th",
                component: Billing,
                layout: "/admin",
              },
              {
                path: "/billingunlock",
                name: "Billing Unlock Data",
                icon: "ni ni-single-02 text-th",
                component: BillingUnlock,
                layout: "/admin",
              },
              {
                path: "/billing_report",
                name: "Report",
                icon: "ni ni-single-copy-04 text-th",
                component: BillingReport,
                layout: "/admin",
              },
              {
                path: "/user-profile",
                name: "User Profile",
                icon: "ni ni-settings-gear-65",
                component: Profile,
                layout: "/admin",
              },
              {
                path: "/internal-call",
                name: "Internal Call",
                icon: "ni ni-single-copy-04 text-th",
                component: InternalCall,
                layout: "/admin",
              }, 
              {
                path: "/todolist",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              }, 
              // {
              //   path: "/Krolisting",
              //   name: "KRO's",
              //   icon: "ni ni-single-02 text-th",
              //   component: Krolisting,
              //   layout: "/admin",
              // },
              // {
              //   path: "/addmasterkro",
              //   name: "KRO Master",
              //   icon: "ni ni-single-02 text-th",
              //   component: AddMasterKRO,
              //   layout: "/admin",
              // },
              {
                path:'4',
                 name: "KRO",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[{
                  path: "/Krolisting",
                  name: "KRO's",
                  icon: "ni ni-single-02 text-th",
                  component: Krolisting,
                  layout: "/admin",
                },{
                  path: "/addmasterkro",
                  name: "KRO Master",
                  icon: "ni ni-single-02 text-th",
                  component: AddMasterKRO,
                  layout: "/admin",
                },{
                  path: "/krotyperating",
                  name: "KRO Type Rating",
                  icon: "ni ni-single-02 text-th",
                  component: KROTypeRating,
                  layout: "/admin",
                }]
              }
            ];
          break;
          case 'HR':  
          var routes=[
            {
              path: "/index",
              name: "Todo List",
              icon: "ni ni-single-copy-04 text-th",
              component: TodoList,
              layout: "/admin",
            }, 
            {
              path: "/krodashboard",
              name: "KRO Dashboard",
              icon: "ni ni-single-02 text-th",
              component: KRODashboard,
              layout: "/admin",
            },
            {
              path: "/addmasterkro",
              name: "KRO Master",
              icon: "ni ni-single-02 text-th",
              component: AddMasterKRO,
              layout: "/admin",
            },
            {
              path: "/Krolisting",
              name: "KRO's",
              icon: "ni ni-single-02 text-th",
              component: Krolisting,
              layout: "/admin",
            },
            {
              path: "/graphchart",
              name: "KRO Graph",
              icon: "ni ni-single-copy-04 text-th",
              component: GraphChart,
              layout: "/admin",
            },
            {
              path: "/competencyrole",
              name: "Competency Master",
              icon: "ni ni-single-02 text-th",
              component: Competencyrole,
              layout: "/admin",
            }
            // {
            // path: "/krodashboard",
            //   name: "KRO Dashboard",
            //   icon: "ni ni-single-02 text-th",
            //   component: KRODashboard,
            //   layout: "/admin",
            // }
          ];
          break;
          case 'CommonRole':  
          var routes=[
            {
              path: "/index",
              name: "Governance Call",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceCall,
              layout: "/admin",
            },{
              path: "/internal-governance",
              name: "Weekly Call",
              icon: "ni ni-single-copy-04 text-th",
              component: InternalGovernanceCall,
              layout: "/admin",
            },
            {
              path: "/internal-call",
              name: "Internal Call",
              icon: "ni ni-single-copy-04 text-th",
              component: InternalCall,
              layout: "/admin",
            },{
              path: "/gpvernance-chart",
              name: "Governance Chart",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceChart,
              layout: "/admin",
            }
          ];
          break;
          case 'manager_payroll':
          case 'lead_payroll':
          case 'processor_payroll':
          case 'reviewer_payroll':
            var routes=[
              (user_info.role_id != 20 && user_info.role_id != 21)?
              {
                path:'1',
                 name: "User",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[
                  {
                    path: "/users",
                    name: "Users",
                    icon: "ni ni-single-02 text-th",
                    component: User,
                    layout: "/admin",
                  },
                  {
                    path: "/user-profile",
                    name: "User Profile",
                    icon: "ni ni-settings-gear-65",
                    component: Profile,
                    layout: "/admin",
                  },
                  {
                    path: "/archive",
                    name: "Archived",
                    icon: "ni ni-archive-2 text-th",
                    component: Archive,
                    layout: "/admin",
                  }]
              }: {},
              {
                path:'2',
                 name: "Governance",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[
                  {
                    path: "/governancedashboard",
                    name: "Dashboard",
                    icon: "ni ni-single-copy-04 text-th",
                    component: GovernanceDashboard,
                    layout: "/admin",
                  },
                  {
                    path: "/index",
                    name: "Monthly Workflow call",
                    icon: "ni ni-single-copy-04 text-th",
                    component: GovernanceCall,
                    layout: "/admin",
                  },{
                    path: "/internal-governance",
                    name: "Weekly Call",
                    icon: "ni ni-single-copy-04 text-th",
                    component: InternalGovernanceCall,
                    layout: "/admin",
                  },
                  {
                    path: "/internal-call",
                    name: "Internal Call",
                    icon: "ni ni-single-copy-04 text-th",
                    component: InternalCall,
                    layout: "/admin",
                  },{
                    path: "/gpvernance-chart",
                    name: "Governance Chart",
                    icon: "ni ni-single-copy-04 text-th",
                    component: GovernanceChart,
                    layout: "/admin",
                  }]
              },
              {
                path:'3',
                 name: "KRO",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[{
                  path: "/Krolisting",
                  name: "KRO's",
                  icon: "ni ni-single-02 text-th",
                  component: Krolisting,
                  layout: "/admin",
                },
                (user_info.role_id != 20)?
                {
                  path: "/addmasterkro",
                  name: "KRO Master",
                  icon: "ni ni-single-02 text-th",
                  component: AddMasterKRO,
                  layout: "/admin",
                }:{},
                (user_info.role_id != 20 && user_info.role_id != 21)?
                {
                  path: "/krotyperating",
                  name: "KRO Type Rating",
                  icon: "ni ni-single-02 text-th",
                  component: KROTypeRating,
                  layout: "/admin",
                }:{}
              ]
              },
              {
                path: "/todolist",
                name: "Todo List",
                icon: "ni ni-single-copy-04 text-th",
                component: TodoList,
                layout: "/admin",
              },
              (user_info.role_id == 14)?
              {
                path:'5',
                 name: "Incentive",
                 icon: "ni ni-single-02 text-th",
                 layout: "/admin",
                 submenu:[
                  {
                    path: "/incentivemasters",
                    name: "Incentive Master",
                    icon: "ni ni-single-02 text-th",
                    component: AddMasterIncentive,
                    layout: "/admin",
                  }, {
                    path: "/incentives",
                    name: "Incentive",
                    icon: "ni ni-single-02 text-th",
                    component: Incentives,
                    layout: "/admin",
                  }]
              }: {},
            ];
          break;
    default:
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        
        {
          path: "/login",
          name: "Login",
          icon: "ni ni-key-25 text-th",
          component: Login,
          layout: "/auth",
        },
        {
          path: "/register",
          name: "Register",
          icon: "ni ni-circle-08 text-th",
          component: Register,
          layout: "/auth",
        },
        {
          path: "/resetpassword",
          name: "Resetpassword",
          icon: "ni ni-circle-08 text-th",
          component: Resetpassword,
          layout: "/auth",
        },
        {
          path: "/forgotpassword",
          name: "Forgotpassword",
          icon: "ni ni-circle-08 text-th",
          component: Forgotpassword,
          layout: "/auth",
        },
        {
          path: "/userreset",
          name: "UserReset",
          icon: "ni ni-circle-08 text-th",
          component: Onetimeresetpass,
          layout: "/auth",
        },
      ];
  
  }

export default routes;
