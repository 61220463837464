import { useState, useEffect } from "react";
import React from 'react';
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { toast } from 'react-toastify';

const AddMasterIncentive = () => {
  const [trans_data,settransData] = useState([]);


useEffect(() => {
  getIncentiveMaster()
}, []);

  function getIncentiveMaster(){
  clientmanageService.getIncentiveMaster().then((response) => {
      
        if(response.status === 200){
         
          settransData(response.data.data)
     
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }

const editField = (id, field, value,index) => {
  clientmanageService.updateIncentiveMaster(id, field,value).then((response) => {
    let temp_st = [...trans_data];
    let temp_ele = { ...temp_st[index] };
   
    if(response.status == 200){
        // toast.success(response.data.message)
    }else{
        toast.error(response.data.error);
    }
    temp_ele.editing = false;
    temp_ele.mainediting = false;
    
  });
};

  function addChecklistMaster(e) {
    toast.success('Data updated');
    
  }
  return (
    <>
      <Header />
      <Container classNa
      me="mt--7"  fluid>
      <h3>Checklist Master module</h3>
              
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
        
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th>Role</th>
                    <th scope="col" className="whitespace"> Payslips Count</th>
                    <th scope="col" className="whitespace">Payslips Amount</th>
                    <th scope="col" className="whitespace">Client Processed count</th>
                    <th scope="col" className="whitespace">Client Processed amount</th>
                    <th scope="col" className="whitespace">Last Updated Date</th>
                    
                </thead>
                <tbody>
           
                { trans_data && trans_data.length
                          ?                               
                          trans_data.map((tdata,i) => (
                              <tr key={i} className={((i % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{tdata.role_name}</td>
                                <td>
                                <input  className="form-control" onBlur={(e) =>
                                  editField(
                                    tdata.id,
                                    "payslips",
                                    e.target.value,
                                    i
                                    )
                                } type="number" name="payslips" placeholder="Payslips count" defaultValue={tdata.payslips ? tdata.payslips : ""}  />
                                </td>
                                <td>
                                <input  className="form-control" onBlur={(e) =>
                                  editField(
                                    tdata.id,
                                    "p_amount",
                                    e.target.value,
                                    i
                                    )
                                } type="number" name="p_amount" placeholder="Payslip amount" defaultValue={tdata.p_amount ? tdata.p_amount : ""}  />
                                </td>
                                <td>
                                <input  className="form-control" onBlur={(e) =>
                                  editField(
                                    tdata.id,
                                    "client_processed",
                                    e.target.value,
                                    i
                                    )
                                } type="number" name="client_processed" placeholder="Client processed count" defaultValue={tdata.client_processed ? tdata.client_processed : ""}  />
                                </td>
                                <td>
                                <input  className="form-control" onBlur={(e) =>
                                  editField(
                                    tdata.id,
                                    "c_amount",
                                    e.target.value,
                                    i
                                    )
                                } type="number" name="c_amount" placeholder="Client processed amount" defaultValue={tdata.c_amount ? tdata.c_amount : ""}  />
                                </td>
                                <td>
                                
                                    <Input
                                      type="date"
                                      placeholder="Select date"
                                      name="updated_at"
                                      readonly
                                      disabled
                                      style={{color:"#000"}}
                                      value={tdata.updated_date ? tdata.updated_date : ""}
                                              
                                      
                                    />
                               </td>

                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                         
        
                </tbody>
              </Table>
              <br></br>
              <row>
             
              <Button color="primary" className="col-xl-1" style={{background:"rgb(2, 199, 121)",border:"rgb(2, 199, 121)"}} type="button" onClick={addChecklistMaster} >
                Save
              </Button> 
              
              </row>
            </Card>
          </div>
        </Row>
        

      </Container>
    </>
  );
};


export default AddMasterIncentive;
