import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";
const moment = require('moment');
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { reportmanageService } from "../../services/reportmanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";

const GovernanceSummary = (props) => {
  const [summaryData, setSummaryData] = useState([]);

  function getSummary(){
    reportmanageService.getGovernnaceSummary().then((response) => {
      setSummaryData(response.data); 
    });
  }
  
  useEffect(() => {
    getSummary()
  }, []);

  function downloadReport(){
    
    reportmanageService.download_governance_summary().then((response) => {
    
    });
  }
  const months = ['Jan','Feb','March','April','May','June','July','Aug','Sept','Oct','Nov','Dec'];
  const [monthdata, setmonthdata] = useState(months);
// end
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
           
              <Col xl="12">
              {user_info.role_id == 14 || user_info.role_id == 12 ? 
                    <Row>
                    {/* <Col xs={4}>
                      Start Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date",
                              value:state.startDate.date()+'/'+(state.startDate.month()+1)+'/'+state.startDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                    End Date
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                      
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date",
                              value:state.endDate.date()+'/'+(state.endDate.month()+1)+'/'+state.endDate.year()
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col> */}
<Col xl="2">
                  <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button>
            </Col>
                  </Row>
               : null }
               
                   <Card style={{padding:'0px 0px 0px 0px'}}>     
                   <Table className="align-items-center table-flush" responsive>
                   <thead className="thead-light">
                      <tr>
                        <th>Client</th>
                        <th>Frequency</th>
                        <th>GC Month</th>
                        <th>Open Action Items</th>
                        <th>Closed Action Items</th>
                        <th>Overall Satisfaction level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {summaryData.map((item,i) => (
                        <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }  key={item['GC Month']}>
                          <td>{item['name']}</td>
                          <td>{item['governance_freq']}</td>
                          <td className="whitespace">{monthdata[item['GC Month']-1]}  </td>
                          <td>{item['Open Items']}</td>
                          <td>{item['Closed Items']}</td>
                          <td>{item['Overall Rating']}</td>
                        </tr>
                      ))}
                    </tbody>
                    
                  </Table>
    
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default GovernanceSummary;
