import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import 'jspdf-autotable';
const axios = require("axios");

const Billingdashboard = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [dashboardStatistics, setdashboardStatistics] = useState([]);
  const [dashboardData, setdashboardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const dstatus = (user_info.role_id == 13) ? 'assigned' : '';
  const [filtertype,setfiltertype] = useState(dstatus);
  const [loading, setLoading] = useState(false);
  const [currentapi, setCurrentApi] = useState('');
  const today = new Date();
  const month = today.getMonth() + 1; // Months are zero-based
  const year = today.getFullYear();
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
  
  const [filterFields,setfilterFields] = useState({department : "Year End",perpage : 20,fromdate:formattedDate,url:currentapi});
  const companyfilter = (e) => {
    
    setfilterFields({ ...filterFields, [e.target.name]: e.target.value } );
  }
  const get_dashboard_data = async (pageNumber = 1,api) => { 
    setdashboardData([])
    setLoading(true)
    setCurrentApi(api)
    taskmanageService.getBillingDashboardData(filterFields,api,pageNumber).then((response) => {
      setLoading(false)
      
        setdashboardData(response.data.data[0].items);
        setCurrentPage(response.data.data[1].pagination.currentpage);
        setLastPageData(response.data.data[1].pagination.lastpage);
      
      
    });
  }
 
  
  const applyfilter = (e) => {
    setLoading(true)
    setdashboardData([])
    console.log(filterFields)
    taskmanageService.getBillingDashboardStatistics(filterFields).then((response)=>{
      setdashboardStatistics(response.data);
      setLoading(false)
    });
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_dashboard_data(index,currentapi);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_dashboard_data(prev,currentapi);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_dashboard_data(next,currentapi);
  };
  useEffect(() => {
    applyfilter();
  }, []);
  const handleExpand = (task, index) => {
    let temp_state = [...dashboardData];
    let temp_element = { ...temp_state[index] };
    temp_element.toggle = temp_element.toggle ? false : true;
    temp_state[index] = temp_element;
    console.log(temp_state)
    setdashboardData(temp_state);
  };
  return (
    <>
    <Header />
      <Container className="mt--7"  fluid>
         
        <Row className="mt-5" style={{marginTop:"0px !important"}}>
        <div className="col-xl-2 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Start Month</h5>
                        <input type="month"
                                      className="form-control"
                                      name="fromdate"
                                      onChange={(e) =>
                                          companyfilter(e)
                                        }
                                        value={filterFields.fromdate}

                                      />
                        </div>
                        
                        
                        <div className="col-xl-2 col-md-3 col-xs-12">
                          <br></br>
                         
                        <Button
                          className="dash-button "
                          color="primary"
                          type="button"
                          onClick={() => applyfilter()}
                        >
                          {" "}
                         Show
                        </Button>{" "}
                    
                        </div>
                        </Row>
                        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="dash-analy">
          {/* <Col xl="3" md="3" xs="12"  className="dash-col-analy-4 dashtabs" >
           
                <Row className={(filtertype=='Not Started' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_dashboard_data(1,'requestbilldatacounting'),setfiltertype('Not Started')}}>
                    <h1 style={{ color: "#FF8C00" }}>
                      {dashboardStatistics.approvedcount}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#FF8C00" }}>
                     Timesheet Approved  
                    </h2>
                  </div>
                </Row>
             
          </Col> */}
          <Col xl="2" md="2" xs="12"  className="dash-col-analy-4 dashtabs" >
           
                <Row className={(filtertype=='In Progress' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_dashboard_data(1,'pendingforbilling'),setfiltertype('In Progress')}}>
                    <h1 style={{ color: "#0060B8" }}>
                      {dashboardStatistics.notcompletedbillCount}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#0060B8" }}>
                      Pending for billing
                    </h2>
                  </div>
                </Row>
             
          </Col>
       
          <Col xl="2" md="2" xs="12"  className="dash-col-analy-4 dashtabs" >
           
           <Row className={(filtertype=='Completed' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
             <div className="col" onClick={() => {get_dashboard_data(1,'completedforbilling'),setfiltertype('Completed')}}>
               <h1 style={{ color: "#0DAF84" }}>
                 {dashboardStatistics.completedbillCount}
               </h1>
               <h2 className="mb-0" style={{ color: "#0DAF84" }}>
                Billing Completed 
               </h2>
             </div>
           </Row>
        
     </Col>

     <Col xl="2" md="2" xs="12"  className="dash-col-analy-4 dashtabs" >
           
           <Row className={(filtertype=='Completed' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
             <div className="col" onClick={() => {get_dashboard_data(1,'pushedbillCount'),setfiltertype('xeropushed')}}>
               <h1 style={{ color: "#0DAF84" }}>
                 {dashboardStatistics.pushedbillCount}
               </h1>
               <h2 className="mb-0" style={{ color: "#0DAF84" }}>
                  Xero Pushed Bills
               </h2>
             </div>
           </Row>
        
     </Col>
     </Row>
              
              </CardHeader>
              {(loading == true) ? 
                      <><br></br><a style={{color:"#2FC3B9"}}  >Downloading please wait.. </a></>
                      :
                      null }
              {dashboardData &&
                    dashboardData.length &&
                    dashboardData != null ? (
                      <>
              <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Client</th>
                          <th>Total</th>
                          
                        </tr>
                      </thead>
                  <tbody>
                  {
                      dashboardData.map((user, i) => (
                        <>
                         <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } style={{backgroundColor: (i %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td  onClick={() => handleExpand(user, i)}>{user.client}</td>
                            <td colSpan={3}>{user.count.toFixed(2).replace(/\.00$/, '')}</td>
                         </tr>
                         {(user.toggle) ? 
                         (currentapi == 'requestbilldatacounting' ) ?
                          <>
                          <tr>
                            <th>Sub client</th>
                           <th>Hours</th>
                           <th>Remarks</th>
                           <th>Status</th>
                           </tr>
                           </>
                          :   <tr><th>Sub client</th><th>Value</th> </tr> : null }
                         {user.data && user.data.length
                          ? // <div id={"collapse"+i} className="thead-light" style={{display: item.toggle ? 'block' : 'none' }}                                >
                          user.data.map((phase,j) => (
                              <tr key={j} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }
                              id={"collapse" + i}
                                style={{
                                  display: user.toggle ? "revert" : "none"
                                }}
                              >
                               
                                <td scope="row">
                                  <span className="mb-0 text-sm">
                                    {phase.subclient}
                                  </span>
                                </td>
                                {(currentapi == 'requestbilldatacounting') ?
                                  <>
                                  <td>{phase.hours.toFixed(2).replace(/\.00$/, '')}</td>
                                  <td className="whitespace">{phase.remarks}</td>
                                  <td>{phase.status}</td>
                                  </>
                                  : <td>{phase.value.toFixed(2).replace(/\.00$/, '')}</td> }
                              </tr>
                            ))
                          : //  </div>
                           ( null)}
                           </>
                    ))}
                    
                    </tbody>
                  </Table>
                    <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem disabled={currentPage <= 1}>
                          <PaginationLink onClick={handlePreviousClick} previous />
                        </PaginationItem>
                        {[...Array(last_page)].map((page, i) => (
                          <PaginationItem
                            active={i + 1 === currentPage}
                            key={i + 1}
                          >
                            <PaginationLink
                              onClick={(e) => handlePageClick(e, i + 1)}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage >= last_page}>
                          <PaginationLink onClick={handleNextClick} next />
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                  </>
             ): null }
             
            </Card>
          </div>
        </Row>
        
      </Container>
    </>
  );
};


export default Billingdashboard;
