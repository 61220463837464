import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import { usermanageService } from "services/usermanageService";
import Select from 'react-select';
const axios = require("axios");

const AddManagerRejection = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState([{'label':'Select User',value:''}]);
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const regionOption = useRef(null)
  const [disablestate, setdisablestate] = useState(false);

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index);
  };

  const formatDate = (dateStr) => {
    if(dateStr!=null && dateStr!=''){

      const [year, month, day] = dateStr.split('-');
      let newDate = `${day}-${month}-${year}`;
      return newDate;
    }
  };
  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next);
  };

  const get_list = async (pageNumber = 1,e) => { 
   
    let  textbox = '';
    if(e != null){
       textbox = e.label;
    }
    taskmanageService.getsubclientTasks(pageNumber,clientsearch,textbox).then((response) => {console.log(response.data.data.data)
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };


useEffect(() => {
  get_list();
  get_unique_companieslist();
}, []);

const taskDetail = (task_id,index) => {
  taskmanageService.gettaskdetail(task_id,'','').then((response) => {
    settaskdetails(response.data.data);
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
    usermanageService.getusersforchatbox().then((response)=>{
    setUniqueCompaniesData(response.data.users);
  });
}
const [ subclientlist, setsubclientlist ] = useState([{'label':'Select Sub Client',value:''}])

  function subclientList(clientname=''){
    //setsubclientlist([]);
    clientmanageService.getsubclientlistsearchable(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setuser(e){ 
    setclientsearch(e.label);
    regionOption.current.setValue('');
    subclientList(e.label)
    setClientdata([]);
    
    // var index = e.target.selectedIndex;
    // setclientsearch(e.target[index].text)
   
    
    
  }
const companyfilter = (e,index) => {
 get_list(1,e);
 setAllclient(false)
};
const initialStateData = {
    user:"",
    category:"",
    date: "",
    remarks:"",
    no_error:"",
};

const [inputFields, setInputfields] = useState(initialStateData);

function inputuserresphandler(e){
    let data = {}
    data = { ...inputFields, 'user': e.value } 
    setInputfields(data);
}
function inputhandler(e){
    setInputfields({...inputFields,[e.target.name]:e.target.value});
}
function saveData(){
    clientmanageService.saveManagerRejection(inputFields).then((response) => {
        if (response.status == 200) {
            setInputfields(initialStateData)
            toast.success(response.data.message);
        } else if (response.status == 201) {
         
          toast.error(response.data.message);
        }
      });
}

  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%','marginBottom':'10px'}}>
                    <div className="col-xl-6 col-md-6 col-xs-12">
               <h3 className="clientformtag"> User</h3>
                    {(uniquecompanieslist.length > 0 && (user_info.role_id != 13))?
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => inputuserresphandler(e)}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                          
                        : null }
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-12" style={{marginTop:'-10px'}}>
                    <h3 className="clientformtag"> Category</h3>
                    <select  className="form-control" name="category" onChange={(e) => inputhandler(e)}>
                    <option value="">Select Category</option>
                    <option value="Workflow Management">Workflow Management</option>
                    <option value="Tap Off">Tap Off</option>
                    <option value="On Time Billing">On Time Billing</option>
                    <option value="Training - New Employees">Training - New Employees</option>
                    <option value="Query Resolution">Query Resolution</option>
                    <option value="Timesheet Analysis and Approval">Timesheet Analysis and Approval</option>
                    <option value="Client Satisfaction">Client Satisfaction</option>
                    <option value="CCRP Meetings">CCRP Meetings</option>
                    <option value="Weekly Client Calls">Weekly Client Calls</option>
                    <option value="Employee Retention">Employee Retention</option>
                    <option value="Governance call set up">Governance call set up</option>
                    <option value="Governance call follow up process">Governance call follow up process</option>
                    <option value="CCRP Checklist implementation">CCRP Checklist implementation</option>
                    <option value="Instructions not followed">Instructions not followed</option>
                    <option value="TAP Meetings">TAP Meetings</option>
                </select>

                       
                    </div>
                </div>
                <br></br><br></br>
                <div className="filter" style={{width:'80%','marginBottom':'10px'}}>
                    <div className="col-xl-6 col-md-6 col-xs-12">
               <h3 className="clientformtag"> Date</h3>
               <Input type="date" placeholder="Select date" name="date" onChange={(e) => inputhandler(e)}/>
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Remark</h3>
                    <Input type="text" placeholder="Remark" name="remarks" defaultValue={inputFields.remarks} onChange={(e) => inputhandler(e)}/>

                       
                    </div>
                </div>
                <br></br><br></br>
                <div className="filter" style={{width:'80%'}}>
                    <div className="col-xl-6 col-md-6 col-xs-12">
               <h3 className="clientformtag"> No. of Errors</h3>
               <Input type="number" placeholder="No. of Errors" name="no_error" onChange={(e) => inputhandler(e)}/>
                    </div>
                    
                </div>
                </Row>
                <div class="row">
              <div className="col-12" style={{marginTop:'10px',marginLeft:'10px'}}>
                <Button className="mt-2" color="primary" onClick={(e) => saveData()}  >
                Save
                </Button>
                
              </div>
            </div>
              </CardHeader>
              
            </Card>
          </div>
        </Row>
        

       
      </Container>
    </>
  );
};


export default AddManagerRejection;
