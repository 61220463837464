import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row,
  Col,
  Form,
  Input,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import '@flowjs/flow.js';
import { useHistory,useParams } from "react-router-dom";
const axios = require("axios");

const ViewClientTransitionForm = (props) => {
  const params  = useParams();
  const [formdetails,setformdetails] = useState({});
  const [state, setState] = useState({});
  const [ctrans, setCurrentTrans] = useState('');
  const [disablestate, setdisablestate] = useState(false);


useEffect(() => {
  if(params.client_id!='undefined' && params.client_id!=undefined){
    generateForm(params.client_trans_id,params.client_id,params.trans_id)
  }
}, []);

const generateForm = (id,client_id,trans_id) => {
  setCurrentTrans(trans_id)
  clientmanageService.generateForm(id,client_id,trans_id).then((response) => {
    setformdetails(response.data);
    setState(response.data);
  })
}

function inputhandler(e,index,key) {
  let temp_st = {...state};
  let temp_ele = { ...temp_st[key][index] };
  temp_ele.remarks = e.target.value;
  temp_st[key][index] = temp_ele;
  setState(temp_st);

}

function add_phase_selection(e) {
  setdisablestate(true)
  e.preventDefault();
  clientmanageService.updateTransitionForm(state).then((response) => {
    console.log(response);

    if (response.status == 200) {
      toast.success(response.data.message);
    }
    setdisablestate(false)
  });
}
function downloadReport(){
  clientmanageService.download_transitionform_report(params.client_id,params.trans_id).then((response) => {
  
  });
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      <CardHeader className="bg-transparent">
                  
                
                  <Row className="align-items-center">
                
                    <div className="col-10">
                      <h4 className="text-uppercase text-muted ls-1 mb-1">
                       Form Data
                      </h4>
                      

                    </div>
                   
                 <Col xl="2">
             <Button
                 className="btn-icon-clipboard"
                 type="button"
                 onClick={downloadReport}
                 style={{textAlign:'center'}}
                 title="Download Report"
               >
               <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
             </Button>
       </Col>
                  </Row>
                </CardHeader>
     
        <Row className="mt-5" >
        
          <div className="col" style={{padding:'0%'}}>
          <Form
                role="form"
                id="phase-selection-form"
                onSubmit={add_phase_selection}
            
              >
              <Table  className="align-items-center table-flush" responsive>
                <thead>
                  <tr style={{border : "1px solid #000"}}>
                     <th style={{border : "1px solid #000"}}>Sr.No.</th> 
                    <th style={{border : "1px solid #000"}} className="whitespace">Particulars</th>
                    <th style={{border : "1px solid #000"}} className="whitespace">scenario</th>
                    { ctrans != 1 ?
                    <th style={{border : "1px solid #000"}} className="whitespace">Procedures</th>
                    : null }
                    <th style={{border : "1px solid #000"}} className="whitespace">Client Remarks</th>
                    
                  </tr>
                </thead>
                <tbody>
                

      
      {
         Object.entries(formdetails).map(([key, value],index) =>
      
           
           <>
             {value.map((g,i) => (
            <tr className="whitespace" style={{border : "1px solid #000"}}>
              {(i ==0)?
              <><td style={{border : "1px solid #000"}}>{index+1}</td><td style={{border : "1px solid #000"}}>{key}</td></>:<><td style={{border : "1px solid #000"}}></td><td style={{border : "1px solid #000"}}></td></>}
              
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.scenerio}</td>
             { ctrans != 1 ?
             <td className="whitespace" style={{border : "1px solid #000"}}>{g.procedures}</td>
             : null }
             <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}><Input
                         
                         bsSize="sm"
                         name="form-remarks"
                         placeholder="Remarks"
                         id="example-date-input"
                         type="textarea"
                         defaultValue={g.remarks ? g.remarks : ""}
                         onChange={(e) => inputhandler(e, i,key)}
                         

                         
                       /></td>
             </tr>
           ))}
           </>
        //  </tr>
       )
      }
    


                        
                </tbody>
              </Table>
              </Form>
              <Button color="primary" type="button" onClick={add_phase_selection} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
          </div>
        </Row>
        
      </Container>
    </>
  );
};


export default ViewClientTransitionForm;
