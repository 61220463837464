import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,useParams
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Label,
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../../services/usermanageService";
import { taskmanageService } from "../../../services/taskmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const Useraddedit = (props) => {
  let history = useHistory();
  const [dataloaded, setdataloaded] = useState(false);
  const [user_roles, setUserroles] = useState([]);
  const [inputFields, setInputfields] = useState({
    name:'',
    email:'',
    role:'',
    phases:[],
    user_id:'',
    qatl:[],
    atl:[],
    team_user:'',
    department:[],
    joining_date:'',
    exit_date: ''
  });
  const [qatl_lists, setQatlLists] = useState([]);
  const [qatm_lists, setQatmLists] = useState([]);
  const [atl_lists, setAtlLists] = useState([]);
  const [displayqatl, setDisplayqatl] = useState(false);
  const [displayatl, setDisplayatl] = useState(false);
  const [displayclientqatl, setDisplayClientqatl] = useState(false);
  const [team_user_select, setTeamUser] = useState([]);
  const [displayteamuser, setDisplayteamuser] = useState([false]);
  const [user_phases, setUserphases] = useState([]);
  const [displayphase, setDisplayphase] = useState(false);
  let { userid } = useParams();
  const [disablestate, setdisablestate] = useState(false);
  const [deparmentlist, setdepartment] = useState([]);
  const [sendmail,setsendmail] = useState(false);
  const [manager_user, setManagerUser] = useState([]);
  function inputhandler1(e,action) {
    let phseup = {}
    if(Array.isArray(e)){  
      if(action.name.name=='atl'){ 
      phseup = {...inputFields,atl : Array.isArray(e) ? e.map(x1 => x1.value) : []}
      }else{
        phseup = {...inputFields,phases : Array.isArray(e) ? e.map(x1 => x1.value) : []} 
      }
    }
    setInputfields(phseup);
  }
  function inputhandler(e) {
    let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputFields,qatl : Array.isArray(e) ? e.map(x => x.value) : []}
    }else{
      if(e.target.name == 'role'){
        if(e.target.value == '6' || e.target.value == '7'){
          setDisplayphase(true)
          setDisplayqatl(false)
          setDisplayatl(false)
          setDisplayClientqatl(false)
          setDisplayteamuser(true);
          phseup = { ...inputFields, [e.target.name]: e.target.value }
        }
        else if(e.target.value == '11'){
          setDisplayqatl(true)
          setDisplayatl(true)
          setDisplayphase(false)
          setDisplayClientqatl(false)
          setDisplayteamuser(false);
          phseup = { ...inputFields, [e.target.name]: e.target.value }
        }
        else if(e.target.value == '13'){
          setDisplayClientqatl(true)
          setDisplayatl(false)
          setDisplayqatl(false)
          setDisplayphase(false)
          setDisplayteamuser(false);
          phseup = { ...inputFields, [e.target.name]: e.target.value }
        }
        else{
          if(e.target.value == '2' || e.target.value == '3' || e.target.value == '4' || e.target.value == '5'){
            setDisplayteamuser(true);
          }else{
            setDisplayteamuser(false);
          }
          setDisplayphase(false)
          setDisplayqatl(false)
          setDisplayatl(false)
          setDisplayClientqatl(false)
          phseup = { ...inputFields, [e.target.name]: e.target.value, phases : '' }
        }
      }else{
        phseup = { ...inputFields, [e.target.name]: e.target.value }
      }
    }
    setInputfields(phseup);
    console.log(phseup)
  }

  useEffect(() => {
    getqatl_list();
    getatl_list();
    getphase_list();
    getuser();
    // to resolve the memory leak
    return () => {
      // setUserdata({}); 
      setUserroles([]);
      setUserphases([]);
      setQatlLists([]);
      setAtlLists([]);
    };
  }, []);

  function getuser() {
    setdepartment([{"value":"YearEnd",'label':"YearEnd"},{"value":"Payroll","label":"Payroll"},{"value":"HR","label":"HR"}]);
    usermanageService.getsingleuser(userid).then((response) => {

      let qt = [];
      let at = [];
      let phas=[];
      let dept = [];
      console.log(response.data.user.qtl_leads)
      if(response.data.user.qtl_leads != null){
          qt = response.data.user.qtl_leads.split(',').map(Number);
          //at = response.data.user.atl_leads.split(',').map(Number);
          
      }
      if(response.data.user.atl_leads != null){
       
        
        at = response.data.user.atl_leads.split(',').map(Number);
        
    }
    console.log(response.data.user.department)
    if(response.data.user.department != null){
       
        
      dept = response.data.user.department.split(',');
      
  }
      let ph = response.data.user.phase_id;
    //   if(ph != null){
    //     phas = ph.split(',').map(Number);
 
        
    // }
    
     if(ph !=null){
     
      ph = ph.toString();
      if (ph.indexOf(',') > -1) { 
         var d  = ph.split(',').map(Number);
         phas = d;
      }else{
        phas=[response.data.user.phase_id];
      }
     }

  const datainitialFormState = { 
          user_id: response.data.user.id,
          name: response.data.user.name,
          email: response.data.user.email,
          role: response.data.user.role_id,
          client_qa : response.data.user.client_qa,
          client_qatm : response.data.user.client_qatm,
          team_user : response.data.user.manager,
          department : dept,
          joining_date : response.data.user.joining_date,
          exit_date : response.data.user.exit_date,
          client_manager : response.data.user.client_manager,
      }

      const selectdata = {...datainitialFormState,qatl: Array.isArray(qt) ? qt.map(x => x) : []}
      
      const selectdata1 = {...selectdata,atl: Array.isArray(at) ? at.map(x => x) : []}
      const selectdata2 = {...selectdata1,phases: Array.isArray(phas) ? phas.map(x => x) : []}
      const selectdata3 = {...selectdata2,department: Array.isArray(dept) ? dept.map(x => x) : []}
      // setInputfields(selectdata1)
     
      setInputfields(selectdata3)
      console.log("start")
      console.log(inputFields)
      console.log("end")
      if(response.data.user.phase_id!=null){
        setDisplayphase(true)
      }
      if(response.data.user.qtl_leads!=null || response.data.user.atl_leads!=null){
        setDisplayqatl(true)
        setDisplayatl(true)
      }
      if(response.data.user.is_client!=0){
        setDisplayClientqatl(true)
      }
      if(response.data.user.role_id=='2' || response.data.user.role_id=='3' || response.data.user.role_id=='4' || response.data.user.role_id=='5' || response.data.user.role_id=='6' || response.data.user.role_id=='7'){
        setDisplayteamuser(true);
      }
      // console.log(response.data);
      setUserroles(response.data.user_roles);
      
      setTeamUser(response.data.team_users);
      setdefault_val(response);
      setsendmail(response.data.user.is_mail_send)
      setManagerUser(response.data.manager_users);
    });
  }

  function setdefault_val(response){
    inputFields.name  = response.data.user.name;
    inputFields.email = response.data.user.email;
  }

  function getqatl_list() {
    taskmanageService.get_qa_analystteamleaderlist().then((response) => {
        setQatlLists(response.data.data);
        setQatmLists(response.data.tm);
        setInputfields({...inputFields, qatl: []});
      });
  }

  function getatl_list() {
    taskmanageService.getanalystteamleaderlist().then((response) => {
      setAtlLists(response.data.result);
      setInputfields({...inputFields, atl: []});
    });
  }
  function getphase_list() {
    usermanageService.getphases().then((response) => {
      setUserphases(response.data.phases);
      setInputfields({...inputFields, phases: []});
    });
  }

  function edituser(e) {
    setdisablestate(true)
    e.preventDefault();
    usermanageService.edituser(inputFields,sendmail).then((response) => {
      if(response.status == 200){
          toast.success(response.data.message);   
      }else{
          toast.success(response.data.message)
         } 
         setdisablestate(false) 
    }); 
  }
  
  function companyinputhandler(e){
    let phseup = {}
    phseup = {...inputFields,department : Array.isArray(e) ? e.map(x1 => x1.value) : []} 
    console.log(phseup)
    setInputfields(phseup);
   }
   function  ismailsend(val) {
    if(val == 'yes'){
      setsendmail(true);
    }else{
      setsendmail(false);
    }
  } 
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                <div class="col-md-6"><h2>Edit User</h2></div>
                <div class="col-md-6">
                <div class="row">
                  <div class="col-md-2 offset-md-10">
                     <Buttoncomponent/>
                  </div>
                </div>
                </div>
              </div>
              <br/>
              <Card className="card">
                <Form role="form" id="register-form" onSubmit={edituser}>
                <div className="row">
                  
                    <div className="col-md-4">
                       <FormGroup>
                       <Label>Name</Label>
                        <InputGroup className="input-group-alternative mb-3">
                          
                          <Input
                            defaultValue={inputFields.name}
                            placeholder="Name"
                            type="text"
                            name="name"
                            onChange={inputhandler}
                            required
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                       <Label>Email</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              defaultValue={inputFields.email}
                              placeholder="Email"
                              type="text"
                              name="email"
                              onChange={inputhandler}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-12">
                    <FormGroup>
                    <Label>Department</Label>
                   
                   
                    <Select 
                            className="input-group-alternative mb-3" 
                            name="department" 
                            allowSelectAll={true} 
                            options={deparmentlist} // set list of the data
                            onChange={companyinputhandler} 
                            required
                            isMulti
                            isClearable
                            value={
                              inputFields.department != undefined
                                ? deparmentlist.filter(obj => inputFields.department.includes(obj.value))
                                : ""
                            }
                            
                          />
                              {inputFields.deparment && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            <div><b>Selected Value: </b> {JSON.stringify(inputFields.deparment, null,2)}</div>
                              </div>}
                      </FormGroup> 
                  </div>
                    <div className="col-md-4">
                        <FormGroup>
                        <Label>Role</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="role" placeholder="Role" value={inputFields.role} onChange={inputhandler}>
                              <option key="role_1"  value="">Select Role</option>
                              {user_roles.map(roles =>
                                  <option key={roles.role_id} value={roles.role_id}>{roles.role_name}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayteamuser == true ? "block" : "none" }}>
                        <FormGroup>
                        <Label>User manager for reporting</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="team_user" placeholder="User" value={inputFields.team_user} onChange={inputhandler}>
                              <option key="user_1"  value="">Select user manager for reporting</option>
                              {team_user_select.map(team =>
                                  <option key={team.id} value={team.id}>{team.name}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    <div className="col-md-4"  style={{ display: displayphase == true ? "block" : "none" }}>
                        <FormGroup>
                        <Label>Phase</Label>
                        <Select 
                            className="input-group-alternative " 
                            name="phases" 
                            allowSelectAll={true} 
                            
                            options={user_phases} 
                            
                            onChange={inputhandler1}
                            value={user_phases.filter(obj => inputFields.phases.includes(obj.value))}
                            isMulti
                            isClearable
                          />
                          {inputFields.phases && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            <div><b>Selected Value: </b> {JSON.stringify(inputFields.phases, null,2)}</div>
                              </div>}
                        </FormGroup>
                    </div>
                    <div className="col-md-4"  style={{ display: displayclientqatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <Label>QA TL For job assignment</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="client_qa" placeholder="QA" value={inputFields.client_qa} onChange={inputhandler}>
                              <option key="client_qa_1" value="">Select QA For job assignment</option>
                              {qatl_lists.map(qatl =>
                                  <option key={qatl.value} value={qatl.value}>{qatl.label}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-md-4"  style={{ display: displayclientqatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <Label>QA TM For job assignment</Label>
                          <InputGroup className="input-group-alternative mb-3">
                            <select className="form-control" name="client_qatm" placeholder="QA TM" value={inputFields.client_qatm} onChange={inputhandler}>
                              <option key="client_qa_1" value="">Select QA TM For job assignment</option>
                              {qatm_lists.map(qatm =>
                                  <option key={qatm.value} value={qatm.value}>{qatm.label}</option>
                              )}
                            </select>
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayclientqatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <select className="form-control" name="client_manager" onChange={inputhandler} value={inputFields.client_manager}>
                               <option key="client_manager_1" value="">Select Manager</option>
                                {manager_user.map(qatm =>
                                  <option key={qatm.value} value={qatm.value}>{qatm.label}</option>
                                )}
                           </select>
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayqatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <Label>&nbsp;</Label>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="qatl" 
                            allowSelectAll={true} 
                            value={qatl_lists.filter(obj => inputFields.qatl.includes(obj.value))} // set selected values
                            options={qatl_lists} // set list of the data
                            onChange={inputhandler}
                            isMulti
                            isClearable
                          />
                          {inputFields.qatl && <div style={{ marginTop: 20, lineHeight: '25px',display:"none" }}>
                            <div><b>Selected Value: </b> {JSON.stringify(inputFields.qatl, null,2)}</div>
                              </div>}
                        </FormGroup>
                    </div>
                    <div className="col-md-4" style={{ display: displayatl == true ? "block" : "none" }}>
                        <FormGroup>
                        <Label>&nbsp;</Label>
                            <Select 
                            className="input-group-alternative mb-3" 
                            name="atl" 
                            allowSelectAll={true} 
                            options={atl_lists}
                            onChange={inputhandler1}
                            value={atl_lists.filter(obj => inputFields.atl.includes(obj.value))} 
                            isMulti
                            isClearable
                            />
                            {inputFields.atl && <div style={{ marginTop: 20, lineHeight: '25px',display:"none" }}>
                            <div><b>Selected Value: </b> {JSON.stringify(inputFields.atl, null,2)}</div>
                              </div>}
                        </FormGroup>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-3 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Joining date</label>
                         <input type="date"
                                      className="form-control"
                                      name="joining_date"
                                      defaultValue={inputFields.joining_date ? inputFields.joining_date : ''}
                                      onChange={(e) => inputhandler(e)}
                                      />
                    </FormGroup> 
                  </div>  
                  <div className="col-xl-3 col-md-4 col-xs-12">
                    <FormGroup>
                        <label>Exit date</label>
                         <input type="date"
                                      className="form-control"
                                      name="exit_date"
                                      defaultValue={inputFields.exit_date ? inputFields.exit_date : ''}
                                      onChange={(e) => inputhandler(e)}
                                      />
                    </FormGroup> 
                  </div>  
                   <div className="col-md-4">
                        <FormGroup>
                            <label>Send Email</label> 
                            <br/>
                            <div className="row" style={{marginRight:0}}>
                              <div className="col-md-3">
                                 <span style={{verticalAlign:'super'}}>Yes</span> 
                                 <input type="radio" name="sendemail" value="yes"
                                  checked={sendmail == true}
                                 style={{width:'20px',height:'20px'}}
                                 onChange={() => ismailsend('yes')}
                                 />
                              </div>
                              <div className="col-md-3">
                                <span style={{verticalAlign:'super'}}>No</span> <input type="radio" name="sendemail" value="no" checked={sendmail == false}
                                 style={{width:'20px',height:'20px'}}
                                 onChange={() => ismailsend('no')}
                                 />
                              </div>
                            </div> 
                          </FormGroup>
                    </div>
                    <div className="col-md-4">
                        <Label>&nbsp;</Label>
                         <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                         {disablestate ? 'Sending...' : 'Update user'} 
                         </Button>
                    </div>
                </div>  
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Useraddedit;
