import { useState, useEffect } from "react";
import { useHistory,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import {usermanageService} from '../../../services/usermanageService';
import "assets/css/themecustom-style.css";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';

const KRODetails = (props) => {
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [krodata, setKROdata] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  useEffect(() => {
    getList();

  }, [params]);
  const getList = async () => {
    usermanageService.getkrodetails(params.month,params.title,params.username).then((response) => {
      setKROdata(response.data.data);
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
    
        <Row className="mt-5">
          <div className="col" style={{ padding: "0%" }}>
            <Card className="shadow">
              <CardHeader className="border-0">
           
                <Row className="filter" style={{ padding: "0 25px" }}>
                  <div className="d-flex">
                  <h3 className="mb-0 mt-2" style={{ fontSize: "20px" }}>
                    KRO's
                  </h3>
                  </div>
                  <br></br>
                  <br></br>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Name</th>
                  <th scope="col">Reviewer</th>
                  <th scope="col">Month</th>
                  <th scope="col">Score</th>
                  <th scope="col">Rating</th>
                  <th scope="col">Status</th>
                  <th scope="col">R. Approval</th>
                  <th scope="col">M. Approval</th>
                  <th scope="col">Remarks</th>
                </thead>
                <tbody>
                  {krodata && krodata.length && krodata != null ? (
                    krodata.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr
                            className={
                              item.total_weightage_score <= 2.5
                                ? "purple-row"
                                : (item.total_weightage_score <= 3.5 && item.total_weightage_score > 2.5)
                                ? "blue-row"
                                : "orange-row"
                            }
                          >
                            <td>{item.name}</td>
                            <td>{item.reviewer}</td>
                            <td>{item.month}</td>
                            <td>{item.total_weightage_score}</td>
                            <td>{(item.total_weightage_score == '' || item.total_weightage_score == null) ? '' : (item.total_weightage_score <= 1 ? "Needs Action" : (item.total_weightage_score <= 2.5 ? "Below Expectations" : (item.total_weightage_score <= 3.5 ? "Meets Expectations" : (item.total_weightage_score <= 4.5 ? "Exceed Expectation" : (item.total_weightage_score <= 5 ? "Exceptional" : "")) )))}</td>
                            <td>{item.status_name}</td>
                           
                      <td><span
                                  color="primary"
                                  style={{
                                    color:
                                      item.approval == "1"
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >{(item.approval== 1) ? 'Discussed & Approved' : (item.approval== 2) ? "Rejected" : "  "}</span>
                        </td>
                      
                      <td><span
                                  color="primary"
                                  style={{
                                    color:
                                      item.manager_approval == "1"
                                        ? "#02C779"
                                        : "#FF1717",
                                        textAlign: "center",
                                  }}
                                >{(item.manager_approval== 1) ? 'Discussed & Approved' : (item.manager_approval== 2) ? "Rejected" : "  "}</span>
                        </td>
                         
                      <td  >
                      
                          <span title={item.remarkby}>{item.remarks}</span>
                      </td>
                           
                          </tr>
                        </>
                      ) : (
                        <></>
                      ) 
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default KRODetails;
