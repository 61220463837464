import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Card,
  CardHeader,
  Table,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink 
   
} from "reactstrap";
import swal from 'sweetalert';
import {AgGridReact,AllCommunityModules,AgGridColumn} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {userService} from '../../../services/authentication';
import {usermanageService} from '../../../services/usermanageService';

import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import Useraddedit from "./Useraddedit.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import Navbuttons from "components/Navigations/Navbuttons.js";
import { ToastContainer, toast } from 'react-toastify';


 const User = (props) => {
 const history = useHistory();
 const [userdata, setUserdata] = useState([]);
 const [user_roles, setUserroles] = useState([]);
 const [user_phases, setUserphase] = useState([]);
 const [displayphase, setDisplayphase] = useState(false);
//  const [loaduseredit,setcompo] = useState(false);
const [inputuserData, setInput] = useState('');
const [inputroleData, setInputrole] = useState('');
const [inputphaseData, setInputphase] = useState('');
const [currentPage, setCurrentPage] = useState(null);
const [last_page, setLastPageData] = useState(0);
  useEffect(()=>{
    getuser_list();
  },[]);
  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getuser_list(null,prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getuser_list(null,next);
  };
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getuser_list(null,index);
  };

  function getuser_list(e,pageNumber = 1){

    let textbox = '';
    let textrolevalue = '';
    let textphasevalue = '';
    if(e != null){
       textbox = e.target.value;
    }
    if(inputroleData !=''){
      textrolevalue=inputroleData;
    } if(inputphaseData !=''){
      textphasevalue=inputphaseData;
    }
      usermanageService.getusers(textbox,textrolevalue,textphasevalue,pageNumber).then((response) => {
        setUserdata(response.data.users.data); 
        setUserphase(response.data.phases);
        setUserroles(response.data.roles);
        setCurrentPage(response.data.users.current_page);
        setLastPageData(response.data.users.last_page);
    });
    
  }

  const archieveRows = (e,id) => {
    e.preventDefault();
    usermanageService.archiveuser(id).then((response) => {
      if(response.status == 200){
       
        getuser_list();
       toast.success(response.data.message);
        
       
      }else{
        toast.error(response.data.message);
      }
    });
  }
  const handleclick = (e,id) => {
    e.preventDefault();
    //  console.log(e.id);
    //  return;
     history.push({
      pathname: 'edituser/'+id,
    });
  }

  const adduser = () =>{
    history.push({
      pathname: 'adduser',
    });
  }

  

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
    },
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Role',
        selector: row => row.rolename,
    },
    {
      name: 'Phase',
      selector: row => row.phase1,
    },
    {
      name: "Action",
      selector: row => row.id,
			cell: (response) => <> <Button className="btn btn-success" onClick={handleclick.bind(this, response)} class="btn btn-success"><i class="fa fa-edit"></i></Button><Button className="btn btn-danger" onClick={archieveRows.bind(this,response)} class="btn btn-danger"><i class="fa fa-trash"></i></Button></>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
];
function downloadReport(){
  usermanageService.download_report().then((response) => {
   
  });
}
const assignmanager = () =>{
  history.push({
    pathname: 'assignmanager',
  });
}
const assignreviewer = () =>{
  history.push({
    pathname: 'assignreviewer',
  });
}

function inputhandler(e) {
  let textrolevalue = '';
  let textphasevalue = '';
  let searchvalue = '';
  if(e != null){
      if(e.target.name == 'roles'){
        setInputrole(e.target.value);
        textrolevalue = e.target.value;
        textphasevalue = inputphaseData;
        if(e.target.value == '6' || e.target.value == '7'){
          setDisplayphase(true);
        }else{
          setDisplayphase(false);
          textphasevalue='';
        }
      }else{
        setInputphase(e.target.value);
        textphasevalue = e.target.value;
        textrolevalue = inputroleData;
      }
  }
  searchvalue = inputuserData;
  
  usermanageService.getusers(searchvalue,textrolevalue,textphasevalue,1).then((response) => {
      // console.log(response.data.users); 
      console.log(response.data); 
      setUserdata(response.data.users.data); 
  });
}
const user_info = JSON.parse(localStorage.getItem("user-info"));
const customStyles = {
  rows: {
      style: {
           minHeight: '72px', // override the row height
      },
  },
  headCells: {
      style: {
          background:'#ebebeb',
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
      },
  },
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
      },
  },
};
 
const archievelist = () =>{
  history.push({
    pathname: 'userarchive',
  });
}
  const data = userdata;
  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <Row>
          <div className="col-1">
             <Button className="col-12 " color="primary" type="button" onClick={adduser}>
                Add 
             </Button>
          </div>
          <div className="col-2">
                        
                          <Button className="col-12 " color="primary" onClick={assignmanager}>
                            Assign Manager
                         </Button>
                    </div>
                    {user_info.department == 'Payroll' ? 
                    <div className="col-2">
                        
                          <Button className="col-12 " color="primary" onClick={assignreviewer}>
                            Assign Reviewer
                         </Button>
                    </div>:null}
                    <div className="col-2">
                
                <Button className="col-12 " color="primary" onClick={archievelist}>
                 Archieved List
                </Button>
          </div>
          <div className="col-2">
             <Button
                      className="col-12"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export 
                    </Button>
          </div>
          
          <div className="col-2">
          <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
              <select className="form-control" name="roles" onChange={inputhandler}  placeholder="Role" style={{width:'170px'}}>
                <option key="role_1"  value="">Select Role</option>
                {user_roles.map(roles =>
                    <option key={roles.role_id} value={roles.role_id}>{roles.role_name}</option>
                )}
              </select>
              </InputGroup>
            </div>
          </div>
          </div>
          
          
          <div className="col-2" style={{ display: displayphase == true ? "block" : "none" }}>
          <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
              <select className="form-control" name="phases" onChange={inputhandler}   placeholder="Phase" style={{width:'170px'}}>
                <option key="role_1"  value="">Select Phase</option>
                {user_phases.map(phases =>
                    <option key={phases.value} value={phases.value}>{phases.label}</option>
                )}
              </select>
              </InputGroup>
            </div>
          </div>
          </div>
          
          <div className="col-2">
          <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
                <Input placeholder="Search user by name or email..." id="userdata" className="search-box" type="text" onInput={e => setInput(e.target.value)} onKeyDown={e => e.key === 'Enter' && getuser_list(e)}  />
                
              </InputGroup>
            </div>
          </div>
          </div>
          </Row>
        <br></br>  
        <div className="row">
            <div className="col-xl-12">
              <div>
              <Card className="shadow">
              <CardHeader className="border-0">

              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Assign Manager</th>
                  <th scope="col" className="whitespace" style={{width:"10%"}}>Phase</th>
                  <th scope="col">Action</th>
                </thead>
                <tbody>
                  {userdata && userdata.length && userdata != null ? (
                    userdata.map((item, i) =>
                      item != undefined ? (
                        <>
                          <tr  className={
                              i % 2 == 0
                                ? "blue-row"
                                : i % 3 == 0
                                ? "purple-row"
                                : "orange-row"
                            }>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.rolename}</td>
                            <td>{item.tmanager}</td>
                            <td>{item.phase_id}</td>
                            <td>
                            <Button className="btn btn-success" onClick={(e) => { handleclick(e,item.id);}}  class="btn btn-success"><i class="fa fa-edit"></i></Button>
                            <Button className="btn btn-danger" onClick={(e) => { archieveRows(e,item.id);}}   class="btn btn-danger"><i class="fa fa-trash"></i></Button>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      ) 
                    )
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
               </div>
            </div>
        </div>
      </Container>
    </>
  );
};

export default User;
