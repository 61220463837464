import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
import Select from 'react-select';
const axios = require("axios");

const SubClientSOP = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [clientdata, setClientdata] = useState([]);
  const [clientsearch, setclientsearch] = useState('');
  const [trans_data,settransData] = useState([]);
  const [formdetails,setformdetails] = useState({});
  const [formdetailsLen,setformdetailsLength] = useState(0);
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  const [allclient, setAllclient] = useState(true);
  const regionOption = useRef(null);
  const [changeclient,setchangeclient] = useState(false);
  const [status,setstatus] = useState([]);
  const [state, setState] = useState({});
  const [ctrans, setCurrentTrans] = useState('');
  const [disablestate, setdisablestate] = useState(false);

useEffect(() => {
  get_unique_companieslist();
  if(params.client_id!='undefined' && params.client_id!=undefined){
      getTransitionData(params.client_id)
      getclientstatus();
  }
}, []);

const generateForm = (client_id) => {
  setCurrentTrans(5)
  clientmanageService.generateSOPForm(client_id,5).then((response) => {
    setformdetails(response.data.list);
    setformdetailsLength(Object.entries(response.data.list).length);
    setState(response.data.list);
    console.log(formdetails)
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}

  function getTransitionData(clientname=''){
    //setsubclientlist([]);
    
    
    clientmanageService.gettransitionData(clientname).then((response) => {
      
        if(response.status === 200){
          setchangeclient(true);
          settransData(response.data)
    
          
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function getclientstatus(){
    taskmanageService.getclienttransitionstatus().then((res) => {
          
      if(res.status === 200){
        setstatus(res.data.status);
        console.log(status)
      }
  });
  }
  const [ subclientlist, setsubclientlist ] = useState([])

  function subclientList(clientname=''){
    clientmanageService.getsubclientlist(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setclient(e){ 
    console.log(e)
    setclientsearch(e.value)
    subclientList(e.label)
    // getTransitionData(e.value);
    getclientstatus();
    
    
  }
  const [ subclient, setsubclient ] = useState([]);
const companyfilter = (e,index) => {
 setAllclient(false)
 generateForm(e.value)
 //setsubclient(e.target.value);
 setclientsearch(e.value)
};

const editField = (client_id,trans_id, field, value,index) => {
  clientmanageService.updateTransData(client_id, trans_id, field,value).then((response) => {
    let temp_st = [...trans_data];
    let temp_ele = { ...temp_st[index] };
   
    if(response.status == 200){
      getTransitionData(client_id);
        toast.success(response.data.success)
    }else{
        toast.error(response.data.error);
    }
    temp_ele.editing = false;
    temp_ele.mainediting = false;
    
  });
};
function inputhandler(e,index,key) {

  let temp_st = {...state};
  let temp_ele = { ...temp_st[key][index] };
  temp_ele.remarks = e.target.value;
  temp_st[key][index] = temp_ele;
  setState(temp_st);

}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  clientmanageService.updateTransitionSOPForm(state).then((response) => {
   

    if (response.status == 200) {
      toast.success(response.data.message);
      location.reload();
    }
    setdisablestate(false)
  });
}

const [newbox, setNewBox] = useState(0);
 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}

const [checkstate, setchecklistState] = useState({task_id:null,list:null});
const [formData, setFormData] = useState([]);
function inputhandlernew(e,rowIndex) {
  const { name, value } = e.target;
        setFormData(prevFormData => {
            // Create a copy of the previous state
            const newData = [...prevFormData];
            // Update the specific row with the new value
            newData[rowIndex] = {
                ...newData[rowIndex],
                [name]: value
            };
            newData[rowIndex]['client_id']=clientsearch;
            newData[rowIndex]['subclient']=subclient;
            return newData;
        });
console.log(state)
     //setFormData({ list: temp_st });
      setState({ ...state, formData });
      console.log(formData)
  }
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-12" >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            {(uniquecompanieslist.length > 0 && (user_info.role_id != 13) && (params.client_id=='undefined' || params.client_id==undefined))?
                   <>
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter" style={{width:'80%'}}>
                   <div className="col-xl-6 col-md-6 col-xs-12">
                    <h3 className="clientformtag"> Client</h3>
                    <Select 
                   
                    className="input-group-alternative mb-3" 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => companyfilter(e,9)}
                    defaultValue={clientsearch}
                    searchable
                    isClearable={false}
                  />
                  </div>
                  
                    </div>
                </Row>
              </CardHeader>
              </>
               : null }
              
            


            </Card>
          </div>
        </Row>
        <Row className="mt-12" >
        <Card className="shadow"  style={{width:"100%"}}>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
              <Table  className="align-items-center table-flush" responsive>
              {Object.entries(formdetails).length > 0 && (
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                  <th style={{ border: "1px solid #000" }}>Sr.No.</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Particulars</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Procedures</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Remarks</th>
                </tr>
              </thead>
            )}
                <tbody>
              {
              Object.entries(formdetails).map(([key, value],index) =>
              <>
                  {value.map((g,i) => (
                  <tr className="whitespace" style={{border : "1px solid #000"}}>
                   {
                      (i ==0)?
                      <><td style={{border : "1px solid #000"}}>{index+1}</td>
                      <td style={{border : "1px solid #000"}}>{key}</td></>
                      :<>
                      <td style={{border : "1px solid #000"}}></td>
                      <td style={{border : "1px solid #000"}}></td></>}
                    
                    
                  <td className="whitespace" style={{border : "1px solid #000"}}>
                 {g.procedures}
                  {/* {(g.scenerio != '') ? g.scenerio : g.procedures } */}
                    </td>
                
                  <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}><Input
                              
                              bsSize="sm"
                              name="form-remarks"
                              placeholder="Remarks"
                              id="example-date-input"
                              type="textarea"
                              defaultValue={g.remarks ? g.remarks : ""}
                              onChange={(e) => inputhandler(e, i,key)}
                  /></td>
                  </tr>
                ))}
                </>
            )
            }
            {(() => {
            const arr1 = [];
            for (let key = 0; key < newbox; key++) {
                arr1.push(
                 <>
                    
                    <tr className="whitespace" style={{border : "1px solid #000"}}>
                    
                    <td style={{border : "1px solid #000"}}>{formdetailsLen+key+1}</td>
                   <td style={{border : "1px solid #000"}}><Input
                              
                   bsSize="sm"
                   name="field_name"
                   placeholder="Particular"
                   id="example-date-input"
                   type="textarea"
                   
                   onChange={(e) => inputhandlernew(e,key)}
                  /></td>
                  
                  
                    
                  <td className="whitespace" style={{border : "1px solid #000"}}>
                 
                  <Input
                              
                              bsSize="sm"
                              name="procedures"
                              placeholder="procedures"
                              id="example-date-input"
                              type="textarea"
                             
                              onChange={(e) => inputhandlernew(e,key)}
                  /> 
                    
               
                    </td>
                
                  <td className="whitespace" style={{width:"50%",border : "1px solid #000"}}><Input
                              
                              bsSize="sm"
                              name="remarks"
                              placeholder="Remarks"
                              id="example-date-input"
                              type="textarea"
                             
                              onChange={(e) => inputhandlernew(e,key)}
                  /></td>
                  </tr>
                    </>
                );
            }
            return arr1;
            
        })()}
            </tbody>
              </Table>
              </Form>
            <br></br>
              {Object.entries(formdetails).length > 0 && (
               
                <div class="d-flex">
                <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={(e) => addNewbox()} >
            Add<i class="fa fa-plus"></i>
              </Button>
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 4.66667%"}} type="button" onClick={addsopform} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button>
              </div>
              
              )}
              </Card>
              </Row>

      </Container>
    </>
  );
};

 
export default SubClientSOP;
