import { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import swal from "sweetalert";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Navbuttons from "components/Navigations/Navbuttons.js";
import { stringToArray } from "ag-grid-community";

const MonthlyReport = (props) => {
  const history = useHistory();
  const [reportdata, setReportdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [sortfield, setsortfield] = useState("");
  const [sortorder, setsortorder] = useState("asc");
  const [state, setState] = useState({startDate:null,endDate:null});
  const [statesend, setStatesend] = useState({startDate:null,endDate:null});
  function sortTable(field) {
    console.log(field);
    setsortfield(field);
    if (sortorder == "asc") {
      setsortorder("desc");
    } else {
      setsortorder("asc");
    }
  }
  useEffect(() => {
    get_report_data();
  },[]);
  
  function get_report_data(e='',start_date=statesend.startDate,end_date=statesend.endDate){
    if(e!=''){
      var phase = e.target.value;
    }else{
      var phase = '';
    }

    reportmanageService.getMonthlyReportData(phase,start_date,end_date).then((response) => {
      setReportdata(response.data); 
    });
  }
  function report_details(company,status=''){
     history.push({
       pathname: "monthly_report_details/"+company+"/"+status,
     });

  }

  function break_line(paragraph){
     return paragraph.replaceAll("\n",'<br>');
  }
  
  const report = () =>{
    history.push({
      pathname: 'analytics_report',
    });
  }

  const dailyreport = () =>{
    history.push({
      pathname: 'dailyreport',
    });
  }
  const monthlyreport = () =>{
    history.push({
      pathname: 'monthlyreport',
    });
  }
  const weeklyreport = () =>{
    history.push({
      pathname: 'weeklyreport',
    });
  }
  function downloadReport(){
    var start_date = statesend.startDate;
    var end_date = statesend.endDate;
      reportmanageService.monthly_report_export(start_date,end_date).then((response) => {
    });
  }
  // date range
  function setEndDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
    get_report_data('',statesend.startDate, dd);
  }
  
  function setStartDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
  }
  // end
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  //console.log(user_info)
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
            <div className="col-2">
          <Button className="col-12 "  type="button" onClick={report}>
                 Report
             </Button>
             </div>
             { (reportdata.show == 1) ? 
             <>
            <div className="col-3">
             <Button className="col-12 " type="button" onClick={dailyreport}>
                Daily Report
             </Button>
          </div>
        
          <div className="col-3">
          <Button className="col-12 " type="button" onClick={weeklyreport} >
                Weekly Report
             </Button>
          </div>
            <div className="col-3">
          <Button className="col-12 " color="primary" type="button" onClick={monthlyreport}>
                Monthly Report
             </Button>
          </div>
          <Col xl="12">
          <div   style={{maxWidth:'10%'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    </Col>
                    </>
            : null }
          <br></br>
          <br></br>
          <br></br>
              <Col xl="12">
                  <Card style={{padding:'0px 0px 0px 0px'}}>
                    <Row>
                    {user_info.role_id == 6 && reportdata.phases ? 
                      <Col xs={4}>
                          <select className="form-control" onChange={(e) => get_report_data(e)}  name="value" >
                            <option value="" key="al_key_1">All Phases</option>
                            {(reportdata.phases).map((valuel,k) =>
                                <option key={k} value={valuel.id}>
                                  {valuel.desc}
                                </option>
                            )}
                          </select>
                      </Col>
                    : null}
                    <Col xs={4}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Table className="align-items-center table-flush custom-style" responsive>
                  
                        
                      <thead className="thead-light">
                      
                          {(user_info.role_id==2) ?
                          <>
                           {/* <th>Month</th> */}
                           <th>Client</th>
                           <th>No. of Jobs</th>
                           <th>WIP Jobs</th>
                           <th>On Hold Jobs</th>
                           <th>Completed Jobs</th>
                           <th>Completed Hours</th>
                           </>
                           
                          :(user_info.role_id==3 || user_info.role_id==6 || user_info.role_id==10) ?
                          <>
                          {/* <th>Month</th> */}
                          <th>Client</th>
                          <th>Sub Client</th>
                          <th>Status</th>
                          <th>Completed Date</th>
                          <th>Completed Hours</th>
                          </>
                          :(user_info.role_id==4) ?
                          <>
                          {/* <th>Month</th> */}
                          <th>Analyst</th>
                          <th>No. of Jobs</th>
                          <th>WIP Jobs</th>
                          <th>On Hold Jobs</th>
                          <th>Completed Jobs</th>
                          <th>Completed Hours</th>
                          </>
                          :''
                          }
                          
                       
                      </thead>
                   
                  <tbody>
                  {reportdata.data &&
                    reportdata.data.length &&
                    reportdata.data != null ? (
                      reportdata.data.map((user, index) => (
                        <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                          
                          {(reportdata.user_role==2 || reportdata.user_role==4) ?
                          <>
                            {/* <td>{user.month}</td> */}
                            <td>{user.name}</td>
                            <td><span style={{cursor:'pointer',color:'#fea732'}} onClick={() => { (reportdata.user_role == 2) ? report_details(user.name) : report_details(user.id) }}>{user.tot}</span></td>
                            {(user.Inprogress > 0) ?
                            <td><span class="inprogress"  style={{cursor:'pointer'}} onClick={() => { (reportdata.user_role == 2) ? report_details(user.name,'Inprogress') : report_details(user.id,'Inprogress') }}>{user.Inprogress}</span></td>
                            :<td>{user.Inprogress}</td>}
                            {(user.Onhold > 0) ?
                            <td><span class="notstarted" style={{cursor:'pointer'}} onClick={() => { (reportdata.user_role == 2) ? report_details(user.name,'Onhold') :report_details(user.id,'Onhold') }}>{user.Onhold}</span></td>
                            :<td>{user.Onhold}</td>}
                            {(user.Complete > 0) ?
                            <td><span class="complete" style={{cursor:'pointer'}} onClick={() => { (reportdata.user_role == 2) ? report_details(user.name,'Complete'): report_details(user.id,'Complete') }}>{user.Complete}</span></td>
                            :<td>{user.Complete}</td>}
                            <td>{user.Hours}</td></>
                           : (reportdata.user_role==3 || reportdata.user_role==6 || reportdata.user_role==10) ?
                           <>
                            {/* <td>{user.month}</td> */}
                            <td>{user.name}</td>
                            <td>{user.sub_client}</td>
                            <td>{user.status}</td>
                            <td>{user.completed_date}</td>
                            <td>{user.completed_hours}</td>
                            </>
                            :''}
                           
                        </tr>
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
    
                  </Card>
              </Col>
             </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default MonthlyReport;
