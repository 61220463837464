import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  UncontrolledAlert,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {apiUrl} from '../../constant';
import {userService} from '../../services/authentication';
import 'assets/css/custom.css';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
// import './credentialstyle.css';
const axios = require("axios");

const Profile = () => {
  const user_info = JSON.parse(localStorage.getItem("user"));
  const [user_data, setuser_data] = useState({
    data:JSON.parse(localStorage.getItem('user-info')),
  });
  const [error, setError] = useState(null)
  const [successMsg, setsuccessMsg] = useState(null);
  const [disablestate, setdisablestate] = useState(false);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';

  const [inputFields, setInputfields] = useState({
    name:user_data.data.name,
    email: user_data.data.email,
    password:"",
    confirm_password:"",
    zoho_password : user_info.zoho_password,
    zoho_email : user_info.zoho_email,
    nextcloud_username : user_info.nextcloud_username,
    nextcloud_app_password : user_info.nextcloud_app_password
  });

  function inputhandler(e){
    setInputfields({...inputFields,[e.target.name]:e.target.value});
  } 
  
  function profileupdate(e) {
    setdisablestate(true)
    e.preventDefault();
    userService.updateprofile(user_data.data.id,inputFields.name,inputFields.email,inputFields.password,inputFields.confirm_password,inputFields.zoho_email,inputFields.zoho_password,inputFields.nextcloud_username,inputFields.nextcloud_app_password).then((response)=>{
      console.log(response);
       if(response.status == 200){
        toast.success(response.data.message);
          
       }else{
        if (response.data.email) {
          toast.error(response.data.email[0]);
        }
        if (response.data.name) {
          toast.error('The name field is required.');
        }
        if (response.data.l_name) {
          toast.error('The last name field is required.');
        }
        if (response.data.password) {
          toast.error(response.data.password[0]);
          if (response.data.password[1]) {
            toast.error(response.data.password[1]);
          }
        }
        // setError('Enter All required field');
        setsuccessMsg(null);
        
            if (response.data.email) {
              toast.error(response.data.email[0]);
            }
            // if (response.data.error.f_name) {
            //   toast.error('The first name field is required.');
            // }
            // if (response.data.error.l_name) {
            //   toast.error('The last name field is required.');
            // }
            if (response.data.name) {
              toast.error('The name field is required.');
            }
            if (response.data.password) {
              toast.error(response.data.password[0]);
              if (response.data.password[1]) {
                toast.error(response.data.password[1]);
              }
            }
         
          // alert(response.data.message);
        
       }
       setdisablestate(false)
    });
  }
  return (
    <>
    
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
      
        <Row className="justify-content-md-center">
          
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
             
              <CardBody>
              {errorDiv}
              {successDiv}
                <Form onSubmit={profileupdate}>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="justify-content-md-center">
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                             Name
                            <span className="text-danger"> *</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields.name}
                            onChange={inputhandler}
                            id="input-name"
                            placeholder=" Name"
                            name="name"
                            type="text"
                            required
                            key={2}
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                            <span className="text-danger"> *</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={inputFields.email}
                            onChange={inputhandler}
                            placeholder="Email"
                            type="email"
                            name="email"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name">
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-first-name"
                            placeholder="Must be at least 10 characters long"
                            onChange={inputhandler}
                            type="password"
                            name="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Confirm Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-last-name"
                            placeholder="Confirm new password"
                            type="password"
                            name="confirm_password"
                            onChange={inputhandler}
                            required
                          />
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Password confirmation should match the input in the “New Password” field.
                          </label>
                        </FormGroup>
                      </Col>
                      {user_info.role_id != 13 ?
                      <>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                           ZOHO Email (For send mail through ZOHO ID)
                            <span className="text-danger"> *</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={inputFields.zoho_email}
                            onChange={inputhandler}
                            placeholder="ZOHO Email"
                            type="email"
                            name="zoho_email"
                            
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name">
                            ZOHO App Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields.zoho_password}
                            id="input-first-name"
                            placeholder="ZOHO app password"
                            onChange={inputhandler}
                            type="password"
                            name="zoho_password"
                            
                          />
                        </FormGroup>
                      </Col>
                      </>
                      : null }

                    {user_info.role_id == 12 ?
                      <>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                           C-Drive Email (For upload files in c drive)
                            <span className="text-danger"> *</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={inputFields.nextcloud_username}
                            onChange={inputhandler}
                            placeholder="C-Drive Email"
                            type="email"
                            name="nextcloud_username"
                            
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name">
                            C-Drive App Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields.nextcloud_app_password}
                            id="input-first-name"
                            placeholder="C-Drive app password"
                            onChange={inputhandler}
                            type="password"
                            name="nextcloud_app_password"
                            
                          />
                        </FormGroup>
                      </Col>
                      </>
                      : null }
                    </Row>
                    <div className="text-center">
                        <button type="submit" className="btn btn-success mt-4" onClick={profileupdate} disabled={disablestate}>
                        {disablestate ? 'Sending...' : 'Update'} 
                        </button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
