import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {usermanageService} from '../../services/usermanageService';

import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import DataTable from 'react-data-table-component';


 const User = (props) => {
 const history = useHistory();
 const [userdata, setUserdata] = useState([]);
  useEffect(()=>{
    getuser_list();
  },[]);

  function getuser_list(e){

      usermanageService.getclient().then((response) => {
        // console.log(response.data.users); 
        console.log(response.data); 
        setUserdata(response.data.data); 
    });
    
  }

  
  const handleclick = (e,value) => {
    //  console.log(e.id);
    //  return;
     history.push({
      pathname: 'editclientuser/'+e.id,
    });
  }

  const adduser = () =>{
    history.push({
      pathname: 'addclientuser',
    });
  }

  

  const columns = [
    
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
      name: "Action",
      selector: row => row.id,
			cell: (response) => <> <Button className="btn btn-success" onClick={handleclick.bind(this, response)} class="btn btn-success"><i class="fa fa-edit"></i></Button></>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
];
const customStyles = {
  rows: {
      style: {
           minHeight: '72px', // override the row height
      },
  },
  headCells: {
      style: {
          background:'#ebebeb',
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
      },
  },
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
      },
  },
};
 
  const data = userdata;
  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <Row>
          <div className="col-1">
             <Button className="col-12 " color="primary" type="button" onClick={adduser}>
                Add 
             </Button>
          </div>
          
          
          </Row>
        <br></br>  
        <div className="row">
            <div className="col-xl-12">
              <div>
                  <DataTable
                     columns={columns}
                     data={data}
                     customStyles={customStyles} 
                     pagination
                  />
               </div>
            </div>
        </div>
      </Container>
    </>
  );
};

export default User;
