import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  UncontrolledAlert
} from "reactstrap";
import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {clientmanageService} from '../../../services/clientmanageService.js';
import { ToastContainer, toast } from 'react-toastify';
import { forEachChild } from "typescript";

const AddClient = () => {
  const history = useHistory();
  let initialStateData = {
    name:"",
    sub_client:"",
    year_end_date: "",
    book_keeping_software:"",
    account_prod_software:"",
    initial_budget:"",
    client_deadline:"",
  };
  const [inputFields, setInputfields] = useState(initialStateData);
  const [error, setError] = useState(null)
  const [successMsg, setsuccessMsg] = useState(null)
  const [ phasestl, setphasestl ] = useState([])
  const [displayphase, setDisplayphase] = useState(false);
  const [displayclient, setDisplayclient] = useState(false);
  const [displaysubclient, setDisplaysubclient] = useState(false);
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  function inputhandler(e){
    // e.presist();
    if(e.target.name == 'phase_lead_id'){
      if(e.target.value == ''){
        setDisplayphase(false)
      }else{
        setDisplayphase(true)
      }
    }
    setInputfields({...inputFields,[e.target.name]:e.target.value});
  } 
  function showclientfield(){
    setDisplayclient(true)
  }
  function showsubclientfield(){
    setDisplaysubclient(true)
  }
  const [ clientlist, setclientlist ] = useState([])
  const [ subclientlist, setsubclientlist ] = useState([])
  function clientList(){
    clientmanageService.getclientlist().then((response)=>{
          if(response.status === 200){
            setclientlist(response.data)
          }else {
              toast.error('Something went wrong!');
          }
        });
  }
  function subclientList(clientname=''){
    clientmanageService.getsubclientlist(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }
  function setclient(e){
    subclientList(e.target.value)
  }

  function addclient(e){
    e.preventDefault();
    // console.log(inputFields);
    // return;
    clientmanageService.addclient(inputFields).then((response)=>{
      // console.log(response)
      if(response.status == 200){
        //  setsuccessMsg(response.data.message);
         document.getElementById('addclient-form').reset();
         setError(null);
         setInputfields(initialStateData);
         toast.success(response.data.message);  
         history.goBack(); 
      }else if(response.status == 201){
        setError('Enter All required field');
        setsuccessMsg(null);
        toast.error(response.data.message); 
      }
    });
  }

  const fetchData = () => {
    clientmanageService.getphasestl(1).then((response)=>{
      if(response.status === 200){
        setphasestl(response.data.teamleader)
        // response.data.teamleader[0].id
        setInputfields({'phase_lead_id' : ''});
      }else {
          toast.error('Something went wrong!');
      }
    });
  }

  function cancel_form(){
       document.getElementById('addclient-form').reset();
       setInputfields(initialStateData);
       history.goBack(); 
  }

  useEffect(() => { 
    fetchData()
    clientList()
    }, [] ) 
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate() + 1).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
  return (
      <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
          <Row className="justify-content-md-center">
              <Col lg="8" md="8">
                  <Card className="shadow">
                      <CardHeader className="bg-transparent">
                          <h3 className="mb-0">Add Client</h3>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-10">
                      {errorDiv}
              {successDiv}
                      <Form role="form" id="addclient-form" onSubmit={addclient}>
                          <FormGroup>
                           <Row>
                              <div className="col-xl-2 col-md-12 col-xs-12">
                                  <h3>Client</h3> 
                              </div>
                              <div className="col-xl-6 col-md-12 col-xs-12" style={{ display: displayclient == false ? "flex" : "none" }}>
                              <select  class="form-control" name="name" onChange={(e) => { inputhandler(e); setclient(e);}} required={(displayclient == false) ? true : false } >
                              <option value=""> Select Client</option>
                              {clientlist.map(res => (
                                  <option
                                    key={res.name}
                                    value={res.name}
                                  >
                                    {res.name}
                                  </option>
                                ))}
                              </select>
                              </div>
                              <div  style={{ display: displayclient == false ? "flex" : "none" }} className="col-xl-4 col-md-12 col-xs-12">
                                <p> OR &nbsp;<b onClick={showclientfield}> Add New Client</b></p>
                                </div>
                              <div style={{ display: displayclient == true ? "flex" : "none" }} className="col-xl-10 col-md-12 col-xs-12">
                                 <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                    placeholder="Client Name" 
                                    type="text" 
                                    name="name" 
                                    value={inputFields.name != undefined ? inputFields.name : ''}
                                    onChange={inputhandler}
                                    required={(displayclient == true) ? true : false }
                                    />
                                </InputGroup>      
                              </div>
                           </Row>   
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <div className="col-xl-2 col-md-12 col-xs-12">
                                  <h3>Sub Client</h3> 
                              </div>
                              <div className="col-xl-6 col-md-12 col-xs-12" style={{ display: displaysubclient == false ? "flex" : "none" }}>
                              <select  class="form-control" name="sub_client" onChange={inputhandler} required={(displaysubclient == false) ? true : false } >
                              <option value=""> Select Sub Client</option>
                              {subclientlist.map(res => (
                                  <option
                                    key={res.sub_client}
                                    value={res.sub_client}
                                  >
                                    {res.sub_client}
                                  </option>
                                ))}
                              </select>
                              </div>
                              <div  style={{ display: displaysubclient == false ? "flex" : "none" }} className="col-xl-4 col-md-12 col-xs-12">
                                <p> OR &nbsp;<b onClick={showsubclientfield}> Add Sub Client</b></p>
                                </div>
                              <div style={{ display: displaysubclient == true ? "flex" : "none" }}  className="col-xl-10 col-md-12 col-xs-12">
                                 <InputGroup className="input-group-alternative mb-3">
                                    <Input 
                                    placeholder="Sub Client Name" 
                                    type="text" 
                                    name="sub_client" 
                                    value={inputFields.sub_client != undefined ? inputFields.sub_client : ''}
                                    onChange={inputhandler}
                                    required={(displaysubclient == true) ? true : false }
                                    />
                                </InputGroup>    
                              </div>
                           </Row>  
                          </FormGroup>
                          <FormGroup>
                            <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Year-End date</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                      <InputGroup className="input-group-alternative mb-3">
                                        <Input
                                        placeholder="Year - End Date"
                                        type="date"
                                        name="year_end_date"
                                        value={inputFields.year_end_date != undefined ? inputFields.year_end_date : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>  
                                </div>
                            </Row>     
                          </FormGroup>
                          <FormGroup>
                            <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Bookkeeping Software</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Bookkeeping Software"
                                        type="text"
                                        name="book_keeping_software"
                                        value={inputFields.book_keeping_software != undefined ? inputFields.book_keeping_software : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                </div>
                            </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Accounts Production Software</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Accounts Production Software"
                                        type="text"
                                        name="account_prod_software"
                                        value={inputFields.account_prod_software != undefined ? inputFields.account_prod_software : ''}
                                        onChange={inputhandler}
                                        required
                                        />
                                    </InputGroup>
                                </div>
                            </Row> 
                          </FormGroup>
                          <FormGroup>
                              <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Initial Budget Hours</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Initial Budget"
                                        type="number"
                                        name="initial_budget"
                                        value={inputFields.initial_budget != undefined ? inputFields.initial_budget : ''}
                                        onChange={inputhandler}
                                        required
                                        inputmode="numeric"
                                        />
                                    </InputGroup>
                                </div>
                            </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Client Deadline</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Client Deadline"
                                        type="date"
                                        name="client_deadline"
                                        value={inputFields.client_deadline != undefined ? inputFields.client_deadline : ''}
                                        onChange={inputhandler}
                                        min={disablePastDate()}
                                        required
                                        />
                                    </InputGroup>
                                </div>
                            </Row> 
                          </FormGroup>
                          <FormGroup>
                             <Row>
                                <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3>Job added date</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Job added date"
                                        type="date"
                                        name="created_at"
                                        value={inputFields.created_at != undefined ? inputFields.created_at : ''}
                                        onChange={inputhandler}
                                        />
                                    </InputGroup>
                                </div>
                            </Row> 
                          </FormGroup>
                          <FormGroup>
                            <Row>
                            <div className="col-xl-2 col-md-12 col-xs-12">
                                    <h3> Phase 0 TL</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                              <select class="form-control" name="phase_lead_id" id="lead_id" onChange={inputhandler} required>
                              {/* <option value=""> Select Phase 0 TL</option> */}
                              {phasestl.map(lead => (
                                  <option
                                    key={lead.id}
                                    value={lead.id}
                                  >
                                    {lead.name}
                                  </option>
                                ))}
                                <option value=''>Skip Phase 0</option>
                              </select>
                              </div>
                              </Row>
                              </FormGroup>
                          <FormGroup>
                            <Row style={{ display: displayphase == true ? "flex" : "none" }}>
                              <div className="col-xl-2 col-md-12 col-xs-12" >
                                    <h3>Phase 0 Due date</h3> 
                                </div>
                                <div className="col-xl-10 col-md-12 col-xs-12">
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                        placeholder="Client Deadline"
                                        type="date"
                                        name="phase_due_date"
                                        value={inputFields.phase_due_date != undefined ? inputFields.phase_due_date : ''}
                                        onChange={inputhandler}
                                        />
                                    </InputGroup>
                                </div>
                                </Row>
                          </FormGroup>
                          <div className="col-12">
                             <Button className="mt-2" color="primary" type="submit">
                              Save
                             </Button>
                             <Button className="mt-2 ml-2" color="secondary " type="button" onClick={cancel_form}>
                               Cancel
                             </Button>
                          </div>
                      </Form>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
      </Container>
      </div>
      </>
  );
};

export default AddClient;
