import { useState,useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../services/reportmanageService';
import { taskmanageService } from "../../services/taskmanageService";
import '../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { manageService } from "../../services/manageaccountservices";
import { clientmanageService } from "../../services/clientmanageService.js";
import Paginationcomponent from "components/Paginationcomponent.js"
 const InternalGovernanceCall = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total_page,setTotalData] = useState(0);
 const params  = useParams();
 const history = useHistory();
 const [governancedata, setGovernancedata] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null);
  const [clientsearch, setclientsearch] = useState('');
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('all');
  const [allclient, setAllclient] = useState(true);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    get_status_list();
    getdata_list();
    get_unique_companieslist();
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getgovernancestatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getdata_list(index,null,'','',rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getdata_list(prev,null,'','',rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getdata_list(next,null,'','',rowsPerPage);
  };
  const getdata_list = async (pageNumber = 1,e,filterFields,filtertype='',rowsPerPage='') => { 
    let  textbox = '';
    if(e != null){
       textbox = e.target.value;
    }
    // const cloneValues = {...filterFields};
    clientmanageService.getInternalGovernanceCalltransdata(pageNumber,textbox,filterFields,filtertype,rowsPerPage).then((response) => {
      console.log(response.data.data)
      setGovernancedata(response.data.data); 
         setCurrentPage(response.data.current_page);
         setLastPageData(response.data.last_page);
         setTotalData(response.data.total);
    });
  };
  const handlerejeditRow = (index) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...governancedata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      setGovernancedata(temp_state);
      // }

  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const formatDate = (dateStr) => {
  if(dateStr!='' && dateStr!= null){
  const [year, month, day] = dateStr.split('-');
  let newDate = `${day}-${month}-${year}`;
  return newDate;
  }else{
    return null;
  }
};
  function downloadReport(){
    
    reportmanageService.download_internal_governance_data(filterFields,filtertype).then((response) => {
    
    });
  }
  const editRejField = (value, field, id,index) => {
    clientmanageService.editgovernancedata(value, field, id,'weeklydaatedit').then((response) => {
      let temp_st = [...governancedata];
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      // temp_ele[field] = value;
      
      if (field == "status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          if(field == 'status'){
            temp_ele['status_name'] = v.name;
            temp_ele['status_color_code'] = v.color_code;
            temp_ele['status_id'] = value;
          }else{
            temp_ele['final_status_name'] = v.name;
            temp_ele['fstatus_color_code'] = v.color_code;
            temp_ele['fstatus_id'] = value;
          }
          temp_ele['approval'] = 0;
            if(value==11){
              temp_ele['flag'] = 0;
            }
          
       }, {});
       }
       if (field != "status") {
        temp_ele[field] = value;
      }
      temp_st[index] = temp_ele;
      setGovernancedata(temp_st);
    })
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const add_governance_call = () => {
    history.push("/admin/addinternalgovernancecall");
 }
 const show_client_frequency = () => {
  history.push("/admin/showclientfrequency");
 }
 

 const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
 function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
     setUniqueCompaniesData(response.data);
   });
 }
 const months = ['Jan','Feb','March','April','May','June','July','Aug','Sept','Oct','Nov','Dec'];
const [monthdata, setmonthdata] = useState(months);
 const [ subclientlist, setsubclientlist ] = useState([])
 
   function subclientList(clientname=''){
     clientmanageService.getsubclientlist(clientname).then((response) => {
         if(response.status === 200){
           setsubclientlist(response.data)
         }else {
           toast.error('Something went wrong!');
         }
     });
   }
   function setclient(e){
     subclientList(e.target.value)
     // var index = e.target.selectedIndex;
     // setclientsearch(e.target[index].text)
     setclientsearch(e.target.value);
     const cloneValues = {...filterFields};
     delete cloneValues[2];
    setfilterFields(cloneValues);
   }
   const companyfilter = (e,index) => {
    if(e.target.value == ''){
      setAllclient(true);
    }else{
      setAllclient(false);
    }
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getdata_list(1,null,cloneValues,filtertype);
  }
  function filterallcom({type}){
    setfiltertype(type)
    // setmainfilter(type);
    
    getdata_list(1,null,filterFields,type);
  }
  
  const handleclick = (id) => {
     console.log(id);
    //  return;
     history.push({
      pathname: 'editinternalgovernancecall/'+id,
    });
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    
    setCurrentPage(1); 
    getdata_list(1,null,filterFields,filtertype,newRowsPerPage)
  
  };  
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
            <div className="col-md-12 col-12">
            </div>
            <Row className="align-items-center row">
            <Col xl="10">
            
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => add_governance_call()}
              >
                {" "}
              + Add Call{" "}
              </Button>{" "}
             
            </Col>
            <Col xl="2">
                  <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button>
            </Col>
            
                   
          </Row><br></br>
            <Row>
            
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                  
                  <Row className="mt-12">
                  <div className="col-xl-8 col-md-2 col-xs-6">
                  <button type="button" style={filtertype == 'all' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'all'})} className=" btn btn-secondary text-center">
            All
          </button>
          <button type="button" style={filtertype == 'not_started' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'not_started'})} className=" btn btn-secondary text-center">
            Not Started
          </button>
          <button type="button" style={filtertype == 'open' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'open'})} className=" btn btn-secondary text-center">
            In Progress
          </button>
        
          <button type="button" style={filtertype == 'close' ?
          { backgroundColor:'#5d6cae',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'close'})} className=" btn btn-secondary text-center">
            Completed
           
          </button>
          </div>
                
                  <div className="col-xl-2 col-md-3 col-xs-12">
                  {/* <h5 className="clientformtag"> Client</h5>
                        {(uniquecompanieslist.length > 0 && (user_info.role_id != 13))?
                              <select className="form-control" name="company" onChange={(e) => { companyfilter(e,1),setclient(e);}}   >
                                  <option value="" key="al_key_1">All Clients</option>
                                  {(uniquecompanieslist).map((valuel,k) =>
                                      <option key={k} value={valuel.label}>
                                        {valuel.label}
                                      </option>
                                  )}
                              </select>
                            : null } */}
                        </div>
                      
                        <div className="col-xl-2 col-md-3 col-xs-12">
                        <h5 className="clientformtag">Select Date</h5>
                        <input type="date"
                                      className="form-control"
                                      name="month"
                                      onChange={(e) => { companyfilter(e,1);}}
                                      />
                        </div>
                      
        </Row>
                    <Row className="align-items-center">
                  
                      <div className="col">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Internal Governance Call Data
                        </h4>
                      </div>
                   
                      
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace" >Call Date</th>
                    {/* <th scope="col" className="whitespace" >Corient Attendees</th>
                    <th scope="col" className="whitespace" >Client Attendees</th> */}
                    <th scope="col" className="whitespace">Action Item</th>
                    <th scope="col" className="whitespace">Responsibility</th>
                    <th scope="col">Target Date</th>
                    <th scope="col" className="whitespace"> Status</th>
                    <th scope="col" className="whitespace">Feedback</th>
                    {/* <th scope="col" className="whitespace">Notes</th> */}
                    {/* <th scope="col" className="whitespace">Rating</th> */}
                    <th>Action</th>
                    
                </thead>
                <tbody>
                  {governancedata &&
                    governancedata.length &&
                    governancedata != null ? (
                      governancedata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <td className="whitespace">{item.name}  </td>
                          <td className="whitespace">{item.month}  </td>
                          {/* <td className="whitespace">{item.corient_attendees}  </td>
                          <td className="whitespace">{item.client_attendees}  </td> */}
                          <td className="whitespace">{item.action_item}</td>
                          <td className="whitespace">{item.responsibility ? item.responsibility : item.responsibility_name}</td>
                          <td className="whitespace">{item.target_date}</td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p style={{color: (item.status_color_code!=null)?`${item.status_color_code}`:'#000'}}><br></br>{item.status_name}</p>
                              )} 
                              </td>  
                              <td className="whitespace">{item.remarks}</td>
                              {/* <td className="whitespace">{item.notes.replace(/<br>/g, '\n')}</td> */}
                              {/* <td className="whitespace">{item.rating}</td> */}
                              <td className="whitespace">
                            <> <Button className="btn btn-success" 
                            onClick={(e) =>
                              handleclick(
                                item.gid,i
                              )
                            } class="btn btn-success"><i class="fa fa-edit"></i></Button></>
                            
                                </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                    <div>
     
     <Paginationcomponent
currentPage={currentPage}
totalItems={total_page}
rowsPerPage={rowsPerPage}
rowsPerPageOptions={rowsPerPageOptions}
handlePageClick={handlePageClick}
handlePreviousClick={handlePreviousClick}
handleNextClick={handleNextClick}
handleRowsPerPageChange={handleRowsPerPageChange}
/>
       </div>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

       
      </Container>
    </>
  );
}; 
export default InternalGovernanceCall;
