import { BrowserRouter, Route, Switch, Redirect, useHistory,useParams } from "react-router-dom";
import React from 'react';
import { useEffect,useState } from 'react';
import 'assets/css/custom.css';
import {taskmanageService} from 'services/taskmanageService.js';
import { ToastContainer, toast } from 'react-toastify';
import PageLoder from 'components/Loader/PageLoader.js';
import {
   Button,
   Row,
   Col,
   Container,
   Modal,
   ModalHeader,
   ModalBody,
   ModalFooter,
} from "reactstrap";
const Notificationcomponent  = (props) => {
   const history = useHistory();
   const [modelbool,setmodelbool] = useState(false);
   // notification data
   const [notifications,setnotification] = useState([]);
   const [notifications_cnt,setnotificationcnt] = useState(0);
   const [tmpnotifications,settmpnotification] = useState([]);

   const [notify_boolean,setnotifyloaderbool] = useState(false);
   const [view_all_notify,setviewall] = useState(false);
   const user_info = JSON.parse(localStorage.getItem("user-info"));

   useEffect(() => {
     getnotifications();
   },[]);

   function modeltoggle(){
     setmodelbool(!modelbool);         
   }

   function getnotifications(){
      let emp_arr = [];
      taskmanageService.getnotification().then((response) => {
        //  console.log(response.data);
           
         setnotification(response.data.data);
         setnotificationcnt(response.data.count);
         for (var i = 0; i <  response.data.data.length; i++) {
            emp_arr.push(response.data.data[i]); 
            if (i == 3) {
                break;
            }
          } 
          settmpnotification(emp_arr);
      });
   }

   function readall(){
     taskmanageService.read_all().then((response) => {
        // console.log(response.data);  
        if(response.status == 200){
            toast.success(response.data.message); 
            getnotifications();
        }else{
            toast.success(response.data.message); 
        }
        // setnotification(response.data);     
     });  
   }

   function clearall(){
      setnotifyloaderbool(true);
      taskmanageService.clearall_notification().then((response) => {
        // console.log(response.data);  
        if(response.status == 200){
            toast.success(response.data.message); 
            getnotifications();
            setnotifyloaderbool(false); 
        }else{
            toast.success(response.data.message);
            setnotifyloaderbool(false);  
        }
        // setnotification(response.data);     
     }); 
   }

   function clearsingle(noti_id){
      taskmanageService.clearsingle_notify(noti_id).then((response) => {
        // console.log(response.data);  
        if(response.status == 200){
            toast.success(response.data.message); 
            getnotifications();
            // setnotifyloaderbool(false); 
        }else{
            toast.success(response.data.message);
            //setnotifyloaderbool(false);  
        }
        // setnotification(response.data);     
     }); 
   }

   function viewall(){
     let emp_arr = [];  
     setviewall(!view_all_notify);   
        if(notifications.length > 0){
            if(!view_all_notify){
                for (var i = 0; i <  notifications.length; i++) {
                        emp_arr.push(notifications[i]); 
                        if (i == 3) {
                            break;
                        }
                }
            }else{
                emp_arr = notifications;
                
            } 
        }
        settmpnotification(emp_arr);
        // console.log(tmpnotifications); 
    //   console.log(emp_arr);  
   }

   function gettypebuttons({type,id,read_status,value}){
       let  view_icon = '';

       if(read_status == 1){
        view_icon = <i className="fa fa-eye" style={{color:'#11cdef'}}></i>           
       }else{
        view_icon = <i className="fa fa-eye-slash" style={{color:'grey'}}></i>
       } 
       let type_status = 'btn-info';
       if(type == 'NEW'){
        type_status = 'btn-info';
       }else if(type == 'APPROVED'){
        type_status = 'btn-success';
       }else if(type == 'DENIED'){
        type_status = 'btn-danger';
       }else if(type == 'STATUS CHANGE'){
        type_status = 'btn-status';
       }else if(type == 'COMMENT'){
        type_status = 'btn-comment';
       }else if(type == 'AMENDEMENT'){
        type_status = 'btn-comment';
       }

          return(
            <React.Fragment key={id}>
              <Col className="col-6">
                <button key={id + '_newbtn'} className={'btn '+type_status+' btn-c-style'}  onClick={() => function(){}}>{type}</button>
                  <div className="mt-3">
                      <b>{value.name} ({value.sub_client}) ({value.task_type})</b>
                    {/* {user_info.role_id != 13 ? */}
                      <p>{value.message}</p> 
                      {/*  : null } */}
                  </div>
              </Col>
              <Col className="col-4">
                  <div>
                     <i className="fa fa-clock"></i> <span>{value.created_at}</span>
                     <br/> 
                     {view_icon} 
                  </div>
              </Col>
            </React.Fragment> 
          );    
    
   }


   function view_all_btn(){
       if(notifications.length > 4){
            return (
              <React.Fragment key={1}>
                <div className="text-center notify-footer">
                  <span onClick={viewall}>
                        View All
                    </span> 
                </div> 
              </React.Fragment>
          );            
       }else{
          return (
            <React.Fragment key={1}>
             <div className="text-center notify-footer" style={{backgroundColor:'#21c1c769'}}>
               <span class="fa fa-close"></span>
               <span style={{paddingRight:'10px'}}></span>
               <span>
                    View All
                </span> 
             </div> 
           </React.Fragment>
         );  
       }  
   }

    return (
        <React.Fragment key={1}>
         {notifications_cnt != 0 ? (<span className="noti-span" onClick={modeltoggle}>You have {notifications_cnt} new notifications</span>) : (<></>) } 
         <span key="noti" className="notify-container">
         {/* <i className="fa fa-bell" title="Notification" onClick={modeltoggle}>
                 {notifications_cnt != 0 ? (<span className="notify-count">{notifications_cnt}</span>) : (<></>) } 
               </i> */}
                <Modal
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   isOpen={modelbool}
               >
                   <div className="modal-header notify-header">
                       <div className="row">
                           <Col className="col-4">
                               <span className=" modal-title" id="exampleModalLabel">
                                   <b>Notification</b>
                               </span>                 
                           </Col> 
                           <div className="col-8">
                               <div className="text-center header-right-el">
                               {/* <button onClick={readall}> */}
                               <span onClick={readall}>
                                   Mark all as read 
                               </span>
                               {/* </button> */}
                               <span onClick={clearall}>
                                 Clear all
                               </span>
                                  <button
                                       aria-label="Close"
                                       className=" close"
                                       type="button"
                                       onClick={() => modeltoggle()}
                                   >
                                  <span aria-hidden={true}><big><b>×</b></big></span>
                                 </button>  
                               </div>
                           </div>
                        </div>
                   </div>
                   <ModalBody>
                     {notify_boolean ? (<PageLoder height="50"/>) : ( 
                       <React.Fragment key={1}>
                        {
                            tmpnotifications.length > 0 ? 
                            tmpnotifications.map((value,index)=>(
                               <React.Fragment key={1}>
                                 <Row key={`row_${index}`}>
                                      {gettypebuttons({type:value.type,read_status:value.view,value:value})}
                                      <Col className="col-2" style={{textAlign:'end'}}>
                                        <Button key={value.id+ '_clearsingle'} onClick={() => clearsingle(value.id)}>X</Button>
                                      </Col>
                                  </Row> 
                                <br/>
                               </React.Fragment>    
                            )) : (
                                <>
                                  <span>No notification found</span>
                                </>
                            )
                        }
                        </React.Fragment>
                      )
                     }  
                   </ModalBody>
                   {view_all_btn()}
               </Modal>       
           </span>
        </React.Fragment>
      ); 
};

export default Notificationcomponent;