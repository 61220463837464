import { useState, useEffect,useRef } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../services/authentication";
import { usermanageService } from "../../services/usermanageService";
import { clientmanageService } from "../../services/clientmanageService.js";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';

const Addfreqmaster = (props) => {
  const [inputFields, setInputfields] = useState({});
  const [disablestate, setdisablestate] = useState(false);
  const [freqdata, setfreqdata] = useState([]);
  const daydata = [];
  for(let i = 1; i <= 31; i++){
    if(i<=9 && i>=1){
      daydata.push({value : '0'+i,label : '0'+i})
    }else{
      daydata.push({value : i,label : i})
    }
  }
  const monthdaydata = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:'10',label :'Oct'},{value:'11',label :'Nov'},{value:'12',label :'Dec'}];
  async function inputhandler(e) {
    console.log(e);
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      user_id: e.value,
    }));
  
    try {
      const response = await clientmanageService.getclientfreq(e.value);
  
      if (response.data.data.governance_freq != null) {
        setInputfields((prevInputFields) => ({
          ...prevInputFields,
          frequency: response.data.data.governance_freq,
        }));
        if(response.data.data.governance_freq=='Monthly'){
          setfreqdata(daydata)
          setInputfields((prevInputFields) => ({
            ...prevInputFields,
            freq_months : []
          }));
        }else{
          setfreqdata(monthdaydata)
        }
      } else {
        setInputfields((prevInputFields) => ({
          ...prevInputFields,
          frequency: '',
          freq_months : []
        }));
      }
      
      if(response.data.data.freq_months != null){
       let freq_months = response.data.data.freq_months.split(',');
       setInputfields((prevInputFields) => ({
        ...prevInputFields,
        freq_months: Array.isArray(freq_months) ? freq_months.map(x => x) : [],
      }));
      }else{
        setInputfields((prevInputFields) => ({
          ...prevInputFields,
          freq_months : []
        }));
      }
 
    } catch (error) {
      // Handle any API call errors here
    }
  }
  const [disableMonth,setDisableMonth] = useState(false);
  function inputhandler1(e) {
    if(Array.isArray(e)){   
      setInputfields({...inputFields,freq_months : Array.isArray(e) ? e.map(x => x.value) : []})  

    }else{
      setInputfields({ ...inputFields, [e.target.name]: e.target.value } );
      if(e.target.name == 'frequency'){
        setfreqdata([]);
        if(e.target.value=='Monthly'){
          setInputfields((prevInputFields) => ({
            ...prevInputFields,
            freq_months:  [],
          }));
          setDisableMonth(true)
        }else{
          setfreqdata(monthdaydata)
        }
    }
  }
 }

  useEffect(() => {
    get_unique_companieslist();
  }, []);



  function addclientfrequency(e){
    setdisablestate(true)
    e.preventDefault();
    clientmanageService.addclientfrequency(inputFields).then((response)=>{
      
      if(response.status == 200){
         toast.success(response.data.message); 
         document.getElementById("adduser-form").reset();
         setInputfields({});
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  clientmanageService.getuniqueclient().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}
  const styles = {
    'color':'#4a4444',
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Governance Call Master Data</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                        <Buttoncomponent/>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
              <Form role="form" id="adduser-form" onSubmit={addclientfrequency}>
                <div className="row">
                <div className="col-md-4">
                  <label>Select Client</label>
                {(uniquecompanieslist.length > 0)?
                    <Select 
                   className="input-group-alternative mb-3" 
                    name="user_id"
                    options={uniquecompanieslist} // set list of the data
                    onChange={(e) => { inputhandler(e)}}
                    searchable
                    isClearable={false}
                  />
                        : null }
                     </div>
                    <div className="col-md-4">
                    <label>Select Frequency</label>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                          <select class="form-control" name="frequency" value={inputFields.frequency} onChange={inputhandler1} required>
                              <option value=""> Select frequency</option>
                              <option value="BiMonthly">Bi-Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annually">Annually</option>
                                <option value="HalfYearly">HalfYearly</option>
                              </select>
                          </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-md-4">
                  {!disableMonth ?
                  <>
                    <label>Months</label>
                                <Select 
                                    className="input-group-alternative mb-3" 
                                    name="freq_months" 
                                    allowSelectAll={true} 
                                    value={(inputFields.freq_months) ? freqdata.filter(obj => inputFields.freq_months.includes(obj.value)) : ''} // set selected values
                                    options={freqdata} // set list of the data
                                    onChange={inputhandler1}
                                    isMulti
                                    isClearable
                                    
                                  />
                                  {inputFields.freq_months && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                                  </>
                    
                    :''}
                        </div>
                
                <div className="col-md-2">
                        <label>&nbsp;</label>
                          <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                          {disablestate ? 'Sending...' : 'Save'} 
                         </Button>
                    </div>
                    
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Addfreqmaster;
